import { components } from "../../api/schema";

export type RowParams = {
  row: {
    id: number;
    isScheduled: boolean;
    name: string;
    cpuWindow: string | undefined;
    memoryWindow: string | undefined;
    cpuHeadroom: string | undefined;
    memoryHeadroom: string | undefined;
    scaleUpCpu: string | undefined;
    scaleDownCpu: string | undefined;
    stabilizationWindowScaleUp: string | undefined;
    stabilizationWindowScaleDown: string | undefined;
    rowPolicyData: components["schemas"]["V1alpha1Policy"];
    isBuiltIn: boolean | undefined;
  };
};

export enum PolicyType {
  Cost = "cost",
  Dynamic = "dynamic",
  CustomPolicy = "customPolicy",
  Hibernate = "hibernate",
  HighAvailability = "high-availability",
  Production = "production",
  WeekendOptimization = "weekend-optimization",
  Spark = "spark",
  Flink = "flink",
  Batch = "batch",
}

export const PolicyDescription = {
  [PolicyType.Cost]: "A cost effective and dynamic policy, suited for development environments.",
  [PolicyType.Dynamic]: "A more dynamic version the Development policy, suited for rapidly changing workloads.",
  [PolicyType.CustomPolicy]: "Customized policy",
  [PolicyType.Hibernate]: "Assign policies according to peak and off hours.",
  [PolicyType.HighAvailability]:
    "Similar to Production policy with extra availability, suited for critical production workloads.",
  [PolicyType.Production]: "Extra headroom suited for production workloads",
  [PolicyType.WeekendOptimization]: "A policy that allows updates only on weekends, considering the week's usage.",
  [PolicyType.Spark]: "A policy optimized for spark workloads",
  [PolicyType.Flink]: "A policy optimized for flink workloads",
  [PolicyType.Batch]: "A policy optimized for batch workloads",
};

export const shouldDisableDefaultPolicyInputs = false;
