import { useQuery } from "@tanstack/react-query";
import AlertBar, { AlertBarTheme } from "../AlertBar";
import { GetScaleopsPodsHealth, GetScaleopsPodsHealthResponse } from "../../api/fetcher";
import React from "react";

const { queryKey, queryFn } = GetScaleopsPodsHealth();

const IMAGE_MATCH_CHART_ENABLED = true;
const OverviewAlertBars = () => {
  const { data: healthStatus } = useQuery<GetScaleopsPodsHealthResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
    refetchInterval: 1000 * 60,
  });

  const alertData: React.ReactNode[] = [];
  if (healthStatus && !healthStatus.isFreshInstall) {
    if (healthStatus?.admissionController?.healthy === false) {
      alertData.push(
        <>
          {healthStatus?.isGcp && (
            <>
              <span>
                We have identified that the cluster is running on GKE and defined as a Private Cluster. For ScaleOps
                automated rightsizing to work, some network rules must be created to allow communication between the API
                Server and ScaleOps Admissions Controller WebHook.
              </span>
              <>
                <br />
                Please take a look at the following GKE documentation page
                <br />
                <a
                  href="https://cloud.google.com/kubernetes-engine/docs/how-to/private-clusters#add_firewall_rules"
                  target="_blank"
                  className="text-main-blue hover:cursor-pointer hover:underline"
                  style={{
                    fontWeight: 600,
                  }}
                >
                  Adding firewall rules
                </a>
              </>
            </>
          )}
          {healthStatus?.isGcp === false && (
            <>
              The <b>Admission Controller</b> is not healthy. This may cause some of the recommendations not to be
              applied. Please check the logs for more details or reach out to the ScaleOps support team.
            </>
          )}
        </>
      );
    }
    if (healthStatus?.prometheus?.healthy === false) {
      alertData.push(
        <>
          The <b>scaleops-prometheus-server</b> pod is currently marked as unhealthy, affecting the performance of
          ScaleOps. Please check the pod or contact our support for assistance.
        </>
      );
    }
    if (healthStatus?.kubeStateMetrics?.healthy === false) {
      alertData.push(
        <>
          The <b>scaleops-kube-state-metrics</b> pod is currently marked as unhealthy, affecting the performance of
          ScaleOps. Please check the pod or contact our support for assistance.
        </>
      );
    }
    if (healthStatus?.updater?.healthy === false) {
      alertData.push(
        <>
          The <b>scaleops-updater</b> pod is currently marked as unhealthy, affecting the performance of ScaleOps.
          Please check the pod or contact our support for assistance.
        </>
      );
    }
    if (healthStatus?.recommender?.healthy === false) {
      alertData.push(
        <>
          The <b>scaleops-recommender</b> pod is currently marked as unhealthy, affecting the performance of ScaleOps.
          Please check the pod or contact our support for assistance.
        </>
      );
    }
    if (healthStatus?.agentController?.healthy === false) {
      alertData.push(
        <>
          The <b>scaleops-agent</b> pod is currently marked as unhealthy, affecting the performance of ScaleOps. Please
          check the pod or contact our support for assistance.
        </>
      );
    }
    if (IMAGE_MATCH_CHART_ENABLED && healthStatus?.imageMatchesChart === false) {
      alertData.push(
        <>
          The <b>scaleops image doesn't match the chart version</b>. This can cause unexpected behavior. Please follow
          the installation instructions.
        </>
      );
    }
  }

  return (
    (healthStatus &&
      !healthStatus.isFreshInstall &&
      (healthStatus?.admissionController?.healthy === false ||
        healthStatus?.prometheus?.healthy === false ||
        healthStatus?.kubeStateMetrics?.healthy === false ||
        healthStatus?.updater?.healthy === false ||
        healthStatus?.recommender?.healthy === false ||
        healthStatus?.agentController?.healthy === false ||
        (IMAGE_MATCH_CHART_ENABLED && healthStatus?.imageMatchesChart === false)) && (
        <div className="sticky self-start top-0 z-50 w-full">
          <AlertBar title={"Health Check"} description={alertData} theme={AlertBarTheme.LightRed} />
        </div>
      )) ||
    null
  );
};

export default OverviewAlertBars;
