import clsx from "clsx";
import { useField, FieldHookConfig } from "formik";
import { Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorLabel from "./ErrorLabel";
import Switch from "../Switch";
import Tooltip from "../Tooltip";

const defaultSetOnChangeValue = (checked: boolean) => checked;
const defaultGetCheckedValue = (value: number | string | boolean) => !!value;

type Props = {
  label?: React.ReactNode;
  info?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  secondaryOnChange?: (checked: boolean) => void;
  disableChange?: boolean;
  setOnChangeValue?: (checked: boolean) => number | string | boolean;
  getCheckedValue?: (value: number | string | boolean) => boolean;
  additionalOnChange?: (value: number | string | boolean) => void;
} & FieldHookConfig<boolean> &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const FormSwitch = ({
  label,
  info,
  className,
  disabled,
  secondaryOnChange,
  disableChange,
  setOnChangeValue = defaultSetOnChangeValue,
  getCheckedValue = defaultGetCheckedValue,
  additionalOnChange,
  ...props
}: Props) => {
  const [field, meta] = useField(props);

  return (
    <div className={clsx(className, "flex flex-col gap-1 relative")}>
      <div className="flex gap-1">
        <Switch
          checked={getCheckedValue(field.value)}
          disabled={disabled}
          onChange={(checked) => {
            if (disableChange) return undefined;
            if (secondaryOnChange) secondaryOnChange(checked);
            field.onChange({
              target: {
                name: field.name,
                value: setOnChangeValue(checked),
              },
            });

            if (additionalOnChange) additionalOnChange(setOnChangeValue(checked));
          }}
        />
        {label && (
          <Typography
            variant="body2"
            className={clsx("text-ellipsis flex gap-1 items-center", {
              "text-text-lightBlack": !disabled,
              "text-text-disable": disabled,
            })}
          >
            {label}
            {info && (
              <Tooltip title={info}>
                <InfoOutlinedIcon style={{ width: 14 }} />
              </Tooltip>
            )}
          </Typography>
        )}
      </div>
      {meta.touched && meta.error ? <ErrorLabel label={meta.error} /> : null}
    </div>
  );
};

export default FormSwitch;
