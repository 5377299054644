import { ListItemText, MenuItem } from "@mui/material";
import { useState } from "react";
import SingleSelect from "../../../components/SingleSelect";
import { TableType } from "../Tables/utils";
import DailyChartContainer from "./DailyChartContainer";
import EntireWindowChartContainer from "./EntireWindowChartContainer";
import { ChartType } from "./utils";

interface Props {
  selectedTable: TableType;
}

const ChartsContainer = ({ selectedTable }: Props) => {
  const [selectedChart, setSelectedChart] = useState<ChartType>(ChartType.EntireWindow);

  return (
    <div className="border bg-white border-border rounded-lg p-5 pt-20 h-[400px] flex items-center justify-center relative">
      <div className="absolute bg-white rounded-lg top-4 right-5" style={{ zIndex: 9999 }}>
        <SingleSelect<ChartType>
          label="Chart type"
          className="w-[160px]"
          selected={selectedChart}
          setSelected={(option) => {
            setSelectedChart(option);
          }}
          renderOptionsFunc={() =>
            Object.entries(ChartType).map((option) => {
              return (
                <MenuItem value={option[1]} key={option[1]}>
                  <ListItemText primary={option[1]} />
                </MenuItem>
              );
            })
          }
        />
      </div>
      {selectedChart === ChartType.Daily && <DailyChartContainer selectedTable={selectedTable} />}
      {selectedChart === ChartType.EntireWindow && <EntireWindowChartContainer selectedTable={selectedTable} />}
    </div>
  );
};

export default ChartsContainer;
