import clsx from "clsx";
import { cloneElement, useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import EditIcon from "../../Icons/EditIcon";

const PADDING_BASE = 20;
const MIN_WIDTH_BASE = 5;

export type MenuItem = {
  id: string;
  title: string;
  subLevel?: number;
  icon?: JSX.Element;
  notClickable?: boolean;
  noPaddingForFirstLevel?: boolean;
};

interface Props {
  menuItems: MenuItem[];
  selectedMenuItem: string;
  setSelectedMenuItem: (id: string) => void;
  className?: string;
  noPaddingForFirstLevel?: boolean;
}

const SideMenu: React.FC<Props> = ({
  menuItems,
  selectedMenuItem,
  setSelectedMenuItem,
  className,
  noPaddingForFirstLevel,
}) => {
  const refsArr = useRef<HTMLSpanElement[]>([]);
  const [minItemWidth, setMinItemWidth] = useState<number>(0);

  useEffect(() => {
    setMinItemWidth((Math.max(...refsArr.current.map((ref) => ref.offsetWidth)) ?? 0) + MIN_WIDTH_BASE);
  }, [menuItems]);

  return (
    <div
      className={clsx(className, "bg-guideline-lightGray flex flex-col pt-[10px] gap-2 shadow md:shadow-lg")}
      style={{
        minWidth: minItemWidth,
      }}
    >
      {menuItems.map((item, index) => {
        const itemIcon = item.icon ?? <EditIcon />;
        const isSelected = selectedMenuItem === item.id;

        return (
          <Typography
            ref={(el) => {
              refsArr.current[index] = el as HTMLSpanElement;
            }}
            variant={item.subLevel ? "body2" : "body1"}
            fontWeight={isSelected ? "600" : "normal"}
            color={isSelected ? "#2E2599" : ""}
            sx={{
              paddingLeft: `${PADDING_BASE + (item.subLevel ?? 0) * PADDING_BASE}px`,
              paddingRight: `${PADDING_BASE}px`,
              marginTop: item.subLevel || (noPaddingForFirstLevel && index) ? "0px" : "30px",
              textDecoration: isSelected ? "underline" : "none",
            }}
            className={clsx("flex items-center gap-2 cursor-pointer", {
              "cursor-pointer hover:bg-guideline-darkGray": !item.notClickable,
              "cursor-default": item.notClickable,
            })}
            onClick={() => {
              if (!item.notClickable) setSelectedMenuItem(item.id);
            }}
          >
            {cloneElement(itemIcon, {
              width: item.subLevel ? 14 : 20,
            })}
            {item.title}
          </Typography>
        );
      })}
    </div>
  );
};

export default SideMenu;
