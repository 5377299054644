import { useState } from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import prettyBytes from "pretty-bytes";
import { SCALEOPS_COLORS } from "../../../../colors";
import { FeatureEnabled } from "../../../../utils/FeaturesHelper";
import { OPTIMIZED_REQUEST } from "../../../../utils/namesUtils";
import { CORE_ELEMENT_CLASS_NAME, DateType, GroupBy, GroupByToTimePeriod } from "../utils";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import MultipleLinesChart from "./MultipleLinesChart";
import ResourcesOverTimeChartsContainer from "./ResourcesOverTimeChartsContainer";
import { GraphLine } from "./graphUtils";

const HAS_UNKNOWN_OWNERS_GRAPHS = false;

interface Props {
  date: DateType;
  setDate: (date: DateType) => void;
  isMulticluster?: boolean;
}

const GraphsContainer = ({ date, setDate, isMulticluster }: Props) => {
  const [selectedTab] = useState<GroupBy>(GroupBy.Day);
  const isDemoVersion = FeatureEnabled("DemoVersion");

  const periodString = GroupByToTimePeriod(selectedTab);

  return (
    <div className="w-full">
      <div className={clsx(CORE_ELEMENT_CLASS_NAME, "p-4 rounded-tl-none flex flex-col gap-[20px]")}>
        <ResourcesOverTimeChartsContainer
          queryParams={{
            from: date.range ? undefined : date.from,
            to: date.range ? undefined : date.to,
            range: date.range,
            groupBy: "hour",
          }}
          setDate={setDate}
          displayNameFormatter={(name: string) => {
            if (name === "Requests origin") {
              return "Original requests";
            }
            if (name.toLocaleLowerCase() === GraphLine.Recommendation) {
              return OPTIMIZED_REQUEST;
            }
            return name;
          }}
          isMulticluster={isMulticluster}
        />
        <div className="flex w-full gap-[20px]">
          <div className="w-[calc(50%-10px)]">
            <BarChart
              title="Cost"
              color="#FFD747"
              label="cost"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                types: ["costOverTime"],
                groupBy: selectedTab,
              }}
              setDate={setDate}
              tooltipValuePrefix="$"
              isMulticluster={isMulticluster}
            />
          </div>
          {!isDemoVersion && (
            <div className="w-[calc(50%-10px)]">
              <BarChart
                title="Nodes"
                color="#FF9141"
                label="nodes"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  types: ["numberOfNodes"],
                  groupBy: selectedTab,
                }}
                shouldRoundValues
                setDate={setDate}
                isMulticluster={isMulticluster}
              />
            </div>
          )}
          {isDemoVersion && (
            <div className="w-[calc(50%-10px)]">
              <LineChart
                title="Nodes"
                color="#FF9141"
                label="nodes"
                queryParams={{
                  from: date.range ? undefined : dayjs(date.from).startOf("day").unix() * 1000,
                  to: date.range ? undefined : dayjs(date.to).startOf("day").unix() * 1000,
                  range: date.range,
                  types: ["demoNodes"],
                }}
                setDate={setDate}
                xAxisInterval={5}
                isDemoVersion
                isMulticluster={isMulticluster}
              />
            </div>
          )}
        </div>
        <div className="flex w-full gap-[20px]">
          <div className="w-[calc(50%-10px)]">
            <BarChart
              title="Active Savings"
              color={SCALEOPS_COLORS.main.green}
              label="savings"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                types: ["activeSavingsOverTime"],
                groupBy: selectedTab,
              }}
              setDate={setDate}
              tooltipValuePrefix="$"
              isMulticluster={isMulticluster}
            />
          </div>
          <div className="w-[calc(50%-10px)]">
            <BarChart
              title="Savings available"
              color={SCALEOPS_COLORS.main.red}
              label="savings"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                types: ["availableSavingsOverTime"],
                groupBy: selectedTab,
              }}
              setDate={setDate}
              tooltipValuePrefix="$"
              isMulticluster={isMulticluster}
            />
          </div>
        </div>

        <div className="flex w-full gap-[20px]">
          <div className="w-[calc(50%-10px)]">
            <MultipleLinesChart
              title="Number of pods"
              elements={[
                {
                  key: "numberOfAutomatedPods",
                  label: "Total automated pods",
                  color: "#52D39D",
                  fill: "#52D39D",
                },
                {
                  key: "totalNumberOfPods",
                  label: "Total number of pods",
                  color: "#6C70FF",
                },
              ]}
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                types: ["totalNumberOfPods", "numberOfAutomatedPods"],
                groupBy: "hour",
              }}
              setDate={setDate}
              isMulticluster={isMulticluster}
            />
          </div>
          <div className="w-[calc(50%-10px)]">
            <MultipleLinesChart
              title="Number of workloads"
              elements={[
                {
                  key: "totalAutomatedWorkloads",
                  label: "Total automated workloads",
                  color: "#52D39D",
                  fill: "#52D39D",
                },
                {
                  key: "totalNumberOfWorkloads",
                  label: "Total number of workloads",
                  color: "#f6a730",
                },
              ]}
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                types: ["totalAutomatedWorkloads", "totalNumberOfWorkloads"],
                groupBy: "hour",
              }}
              setDate={setDate}
              isMulticluster={isMulticluster}
            />
          </div>
        </div>
        <div className="flex w-full gap-[20px]">
          <div className="w-[calc(50%-10px)]">
            <MultipleLinesChart
              title="Automated CPU requests"
              elements={[
                {
                  key: "totalAutomatedCpu",
                  label: "Total automated CPU requests",
                  color: "#52D39D",
                  fill: "#52D39D",
                },
                {
                  key: "totalCpuUsed",
                  label: "Total CPU requests",
                  color: "#6C70FF",
                },
              ]}
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                types: ["totalAutomatedCpu", "totalCpuUsed"],
                groupBy: "hour",
              }}
              setDate={setDate}
              isMulticluster={isMulticluster}
            />
          </div>
          <div className="w-[calc(50%-10px)]">
            <MultipleLinesChart
              title="Automated memory requests"
              elements={[
                {
                  key: "totalAutomatedMemory",
                  label: "Total automated memory requests",
                  color: "#52D39D",
                  fill: "#52D39D",
                },
                {
                  key: "totalMemoryUsed",
                  label: "Total memory requests",
                  color: "#f6a730",
                },
              ]}
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                types: ["totalAutomatedMemory", "totalMemoryUsed"],
                groupBy: "hour",
              }}
              setDate={setDate}
              YAxisFormatter={(tick) => {
                if (Number(tick) < 0 || Number.isNaN(Number(tick))) return "";
                return prettyBytes(Number(tick) || 0.0, {
                  bits: false,
                  binary: true,
                });
              }}
              isMulticluster={isMulticluster}
            />
          </div>
        </div>
        {HAS_UNKNOWN_OWNERS_GRAPHS && (
          <div className="flex w-full gap-[20px]">
            <ResourcesOverTimeChartsContainer
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                groupBy: selectedTab,
              }}
              setDate={setDate}
              displayNameFormatter={(name: string) => {
                if (name.toLocaleLowerCase().includes("usage")) {
                  return "Usage";
                }
                if (name.toLocaleLowerCase().includes("requests")) {
                  return "Requests";
                }
                return name;
              }}
              isMulticluster={isMulticluster}
              showUnknownGraphs
            />
          </div>
        )}
        <div className="flex w-full gap-[20px]">
          <div className="w-[calc(50%-10px)]">
            <BarChart
              title={`${periodString} automated pods`}
              color={SCALEOPS_COLORS.main.green}
              label="pods"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                types: ["hourlyAutomatedPods"],
                groupBy: selectedTab,
              }}
              setDate={setDate}
              isMulticluster={isMulticluster}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphsContainer;
