import { Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { StringParam, useQueryParam } from "use-query-params";
import { components } from "../../api/schema";
import { MAIN_YELLOW } from "../../colors";
import WarningIcon from "../../Icons/WarningIcon";
import { getDataGridSx } from "../../utils/styleUtils";
import useStateWithLocalStorage from "../../utils/useStateWithLocalStorage";
import Chip, { CHIP_THEME } from "../Chip";
import CustomColumnsFilterButton from "../CustomColumnsFilterButton";
import ExportCSV, { HAS_EXPORT_TABLE_AS_CSV } from "../exportCSV/ExportCSV";
import MultiSelect from "../MultiSelect";
import CustomTooltip from "../Tooltip";
import CustomHeader from "./CustomHeader";
import { NodeFilterBar } from "./NodeFilterBar";
import NodeOverviewContainer from "./NodeOverview/NodeOverviewContainer";
import NotScalingDownTooltipContent from "./NotScalingDownTooltipContent";
import UsageAndRequestChart, { Elements } from "./UsageAndRequestChart";
import { DIFF_DEFAULT_PROPS, formatXDigits, SimpleEllipsisWithTooltip } from "./Utils";

const HAS_NODE_OVERVIEW = true;

const DEFAULT_PAGE_SIZE = 25;
const ROWS_PER_PAGE_OPTIONS = Array.from({ length: 4 }, (_, i) => DEFAULT_PAGE_SIZE * (i + 1));
const NODE_BREAKDOWN_PAGE_SIZE_LOCAL_STORAGE_KEY = "nodeBreakdownPageSize";

type CSVExportType = components["schemas"]["NodeGroupsNodeStats"] & {
  id: string;
  cpuRequestVsAllocatableDiff?: number;
  cpuUsageVsAllocatableDiff?: number;
  memoryRequestVsAllocatableDiff?: number;
  memoryUsageVsAllocatableDiff: number;
  gpuRequestVsAllocatableDiff: number;
};

enum Columns {
  Name = "Name",
  NodeGroup = "Groups",
  Cost = "cost",
  InstanceType = "Type",
  IsSpot = "Pricing",
  podsScheduledVsAllocatable = "Pods Scheduled",
  podsDaemonSetVsAllocatable = "DaemonSet Pods",
  CpuRequestVsAllocatableDiff = "CPU (Request vs Allocatable)",
  CpuUsageVsAllocatableDiff = "CPU (Usage vs Allocatable)",
  AvailabilityZone = "Availability Zone",
  MemoryRequestVsAllocatableDiff = "Memory (Request vs Allocatable)",
  MemoryUsageVsAllocatableDiff = "Memory (Usage vs Allocatable)",

  GpuRequestVsAllocatableDiff = "GPU (Request vs Allocatable)",
}

const COLUMNS_MENU_OPTIONS = [
  Columns.CpuRequestVsAllocatableDiff,
  Columns.CpuUsageVsAllocatableDiff,
  Columns.MemoryRequestVsAllocatableDiff,
  Columns.MemoryUsageVsAllocatableDiff,
  Columns.podsScheduledVsAllocatable,
  Columns.podsDaemonSetVsAllocatable,
  Columns.GpuRequestVsAllocatableDiff,
  Columns.AvailabilityZone,
];

type NodeStatsResponseTypeSchema = components["schemas"]["NodeGroupsNodeStats"];

export type NodeGroupRowEntry = {
  name: string;
  cpuRequestVsAllocatableDiff: number;
  cpuUsageVsAllocatableDiff: number;
  memoryRequestVsAllocatableDiff: number;
  memoryUsageVsAllocatableDiff: number;
  numNodes: { min: number; current: number; max: number };
  cpuRequests: number;
  cpuAllocatable: number;
  cpuUsage: number;
  memoryRequest: number;
  memoryAllocatable: number;
  memoryUsage: number;
  cost: number;
  provisioner: string;
};

const renderNodeGroupCell = (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
  const cellText = params.row.nodePool || params.row.nodeGroup || params.row.provisioner;
  const tooltipPrefix = params.row.nodeGroup ? (
    <b>Node group: </b>
  ) : params.row.nodePool ? (
    <b>Node Pool: </b>
  ) : (
    <b>Provisioner: </b>
  );

  return (
    <div className="max-w-full">
      <CustomTooltip
        title={
          <div>
            {tooltipPrefix}
            {cellText}
          </div>
        }
      >
        <p className="truncate">{cellText}</p>
      </CustomTooltip>
    </div>
  );
};
const renderCostCell = (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
  return <p>${formatXDigits(params.row.cost)}</p>;
};

const renderInstanceTypeCell = (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
  return (
    <div className="w-full">
      <CustomTooltip title={params.row.instanceType}>
        <Typography variant="body2" noWrap={true}>
          {params.row.instanceType}
        </Typography>
      </CustomTooltip>
    </div>
  );
};

const getColumns = (
  hasNodeGroups: boolean,
  selectedColumns: Columns[],
  nodeGroups: components["schemas"]["NodeGroupsNodeGroupInfo"][] | undefined
): GridColDef[] => {
  return [
    {
      field: "name",
      headerName: "Name",
      // hide: !selectedColumns.includes(Columns.Name),
      flex: 2,
      minWidth: 300,
      type: "string",
      align: "left",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
        const hasProvisionersWarning = params.row?.limitScaleDownMessage && params.row.limitScaleDownMessage.length > 0;

        return (
          <div className="w-full flex gap-2 items-center">
            {hasProvisionersWarning && (
              <ErrorBoundary fallback={<></>}>
                <CustomTooltip
                  maxWidth={500}
                  title={<NotScalingDownTooltipContent params={params} nodeGroups={nodeGroups} />}
                >
                  <WarningIcon className="grow-1" width={14} height={14} fill={MAIN_YELLOW} />
                </CustomTooltip>
              </ErrorBoundary>
            )}
            <p className="truncate w-full">
              <SimpleEllipsisWithTooltip text={params.row.name} />
            </p>
          </div>
        );
      },
    },
    {
      field: "nodeGroup",
      headerName: "nodeGroup",
      // hide: !selectedColumns.includes(Columns.NodeGroup) || !hasNodeGroups,
      hide: !hasNodeGroups,
      renderHeader: () => <CustomHeader title="Group" tooltipContent="Node Group / Provisioners / Node Pool" />,
      flex: 1,
      minWidth: 180,
      type: "string",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: renderNodeGroupCell,
    },
    // {
    //   field: "provisioner",
    //   headerName: "Provisioners",
    //   hide: true,
    //   width: 180,
    //   type: "string",
    //   align: "center",
    //   headerAlign: "center",
    //   disableColumnMenu: true,
    //   sortable: true,
    //   renderCell: (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
    //     return <SimpleEllipsisWithTooltip text={params.row.provisioner} />;
    //   },
    // },
    {
      field: "cost",
      headerName: "Monthly Cost",
      headerAlign: "center",
      // hide: !selectedColumns.includes(Columns.Cost),
      flex: 1,
      minWidth: 150,
      type: "number",
      align: "center",
      // headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: renderCostCell,
    },
    {
      field: "instanceType",
      headerName: "Type",
      // hide: !selectedColumns.includes(Columns.InstanceType),
      flex: 1,
      minWidth: 150,
      type: "string",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      renderCell: renderInstanceTypeCell,
    },
    {
      field: "isSpot",
      headerName: "Life Cycle",
      // hide: !selectedColumns.includes(Columns.IsSpot),
      flex: 1,
      minWidth: 100,
      type: "string",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
        if (params.row.isSpot) {
          return <Chip label="Spot" theme={CHIP_THEME.DARK} />;
        }
        return <Chip label="OD" tooltip="On Demand" theme={CHIP_THEME.DARK} />;
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "availabilityZone",
        headerName: "availabilityZone",
        hide: !selectedColumns.includes(Columns.AvailabilityZone),
        renderHeader: () => <CustomHeader title="Availability Zone" tooltipContent="" />,
        renderCell: (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
          return <div>{params.row.availabilityZone}</div>;
        },
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "cpuRequestVsAllocatableDiff",
        headerName: "cpuRequestVsAllocatableDiff",
        hide: !selectedColumns.includes(Columns.CpuRequestVsAllocatableDiff),
        renderHeader: () => <CustomHeader title="CPU Request" tooltipContent="Request vs Allocatable" />,
        renderCell: (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
          if (params.row.cpuAllocatable === 0) {
            return null;
          }
          return (
            <UsageAndRequestChart
              usage={Math.round((params.row.cpuUsage / params.row.cpuAllocatable) * 100)}
              request={Math.round((params.row.cpuRequest / params.row.cpuAllocatable) * 100)}
              tooltipData={{
                usage: params.row.cpuUsage,
                request: params.row.cpuRequest,
                allocatable: params.row.cpuAllocatable,
              }}
              elementToDisplay={[Elements.Request, Elements.Allocatable]}
              showMetricsTitles={false}
              showAllocatableBellow
            />
          );
        },
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "cpuUsageVsAllocatableDiff",
        headerName: "cpuUsageVsAllocatableDiff",
        hide: !selectedColumns.includes(Columns.CpuUsageVsAllocatableDiff),
        renderHeader: () => <CustomHeader title="CPU Usage" tooltipContent="Usage vs Allocatable" />,
        renderCell: (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
          if (params.row.cpuAllocatable === 0) {
            return null;
          }
          return (
            <UsageAndRequestChart
              usage={Math.round((params.row.cpuUsage / params.row.cpuAllocatable) * 100)}
              request={Math.round((params.row.cpuRequest / params.row.cpuAllocatable) * 100)}
              tooltipData={{
                usage: params.row.cpuUsage,
                request: params.row.cpuRequest,
                allocatable: params.row.cpuAllocatable,
              }}
              elementToDisplay={[Elements.Usage, Elements.Allocatable]}
              showMetricsTitles={false}
              showAllocatableBellow
            />
          );
        },
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "memoryRequestVsAllocatableDiff",
        headerName: "memoryRequestVsAllocatableDiff",
        hide: !selectedColumns.includes(Columns.MemoryRequestVsAllocatableDiff),
        renderHeader: () => <CustomHeader title="Memory Request" tooltipContent="Request vs Allocatable" />,
        renderCell: (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
          if (params.row.memoryAllocatable === 0) {
            return null;
          }
          return (
            <UsageAndRequestChart
              usage={Math.round((params.row.memoryUsage / params.row.memoryAllocatable) * 100)}
              request={Math.round((params.row.memoryRequest / params.row.memoryAllocatable) * 100)}
              tooltipData={{
                usage: params.row.memoryUsage,
                request: params.row.memoryRequest,
                allocatable: params.row.memoryAllocatable,
              }}
              elementToDisplay={[Elements.Request, Elements.Allocatable]}
              showMetricsTitles={false}
              showAllocatableBellow
            />
          );
        },
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "memoryUsageVsAllocatableDiff",
        headerName: "memoryUsageVsAllocatableDiff",
        hide: !selectedColumns.includes(Columns.MemoryUsageVsAllocatableDiff),
        ...DIFF_DEFAULT_PROPS,
        ...{
          renderHeader: () => <CustomHeader title="Memory Usage" tooltipContent="Usage vs Allocatable" />,
          renderCell: (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
            if (params.row.memoryAllocatable === 0) {
              return null;
            }
            return (
              <UsageAndRequestChart
                usage={Math.round((params.row.memoryUsage / params.row.memoryAllocatable) * 100)}
                request={Math.round((params.row.memoryRequest / params.row.memoryAllocatable) * 100)}
                tooltipData={{
                  usage: params.row.memoryUsage,
                  request: params.row.memoryRequest,
                  allocatable: params.row.memoryAllocatable,
                }}
                elementToDisplay={[Elements.Usage, Elements.Allocatable]}
                showMetricsTitles={false}
                showAllocatableBellow
              />
            );
          },
        },
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "podScheduledVsAllocatableDiff",
        headerName: "podScheduledVsAllocatableDiff",
        hide: !selectedColumns.includes(Columns.podsScheduledVsAllocatable),
        ...DIFF_DEFAULT_PROPS,
        ...{
          renderHeader: () => <CustomHeader title="Pods Scheduled" tooltipContent="Scheduled vs Allocatable" />,
          renderCell: (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
            if (params.row.maxPods === 0) {
              return null;
            }
            const pending = params.row.currentPendingPods || 0;
            const running = params.row.currentRunningPods || 0;
            const total = pending + running;
            return (
              <UsageAndRequestChart
                usage={Math.round((total / params.row.maxPods) * 100)}
                request={Math.round((total / params.row.maxPods) * 100)}
                tooltipData={{
                  usage: total,
                  request: total,
                  allocatable: params.row.maxPods,
                }}
                elementToDisplay={[Elements.MaxPods, Elements.RunningPods]}
                showMetricsTitles={false}
                showAllocatableBellow
                styleOverride={{ request: { backgroundColor: "#b779ea", borderColor: "#b779ea" } }}
              />
            );
          },
        },
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "podsDaemonSetVsAllocatable",
        headerName: "podsDaemonSetVsAllocatable",
        hide: !selectedColumns.includes(Columns.podsDaemonSetVsAllocatable),
        ...DIFF_DEFAULT_PROPS,
        ...{
          renderHeader: () => <CustomHeader title="DaemonSet Pods" tooltipContent="DaemonSet vs All" />,
          renderCell: (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
            const daemonSetPods = params.row.currentDaemonSetPods || 0;
            const maxPods = params.row.maxPods || 0;

            if (maxPods === 0) {
              return null;
            }

            return (
              <UsageAndRequestChart
                usage={Math.round((daemonSetPods / maxPods) * 100)}
                request={Math.round((daemonSetPods / maxPods) * 100)}
                tooltipData={{
                  usage: daemonSetPods,
                  request: daemonSetPods,
                  allocatable: maxPods,
                }}
                elementToDisplay={[Elements.DaemonSetPods, Elements.MaxPods]}
                showMetricsTitles={false}
                showAllocatableBellow
              />
            );
          },
        },
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "gpuRequestVsAllocatableDiff",
        headerName: "gpuRequestVsAllocatableDiff",
        hide: !selectedColumns.includes(Columns.GpuRequestVsAllocatableDiff),
        renderHeader: () => <CustomHeader title="GPU Request" tooltipContent="Request vs Allocatable" />,
        renderCell: (params: GridRenderCellParams<string, NodeStatsResponseTypeSchema, string>) => {
          if (params.row.gpuAllocatable === 0 || params.row.gpuAllocatable === undefined) {
            return <div className="w-[50%] h-[3px] bg-background-chip rounded" />;
          }
          const usage = params.row.gpuRequest || 0 / params.row.gpuAllocatable;

          return (
            <UsageAndRequestChart
              usage={usage * 100}
              request={usage * 100}
              tooltipData={{
                usage: params.row.gpuRequest || 0,
                request: params.row.gpuRequest || 0,
                allocatable: params.row.gpuAllocatable,
              }}
              elementToDisplay={[Elements.Request, Elements.Allocatable]}
              showMetricsTitles={false}
              showAllocatableBellow
            />
          );
        },
      },
    },
  ];
};

interface Props {
  isLoading: boolean;
  nodeStats: NodeStatsResponseTypeSchema[];
  nodeGroups: components["schemas"]["NodeGroupsNodeGroupInfo"][];
}

function NodesBreakdownTab({ nodeStats, isLoading, nodeGroups }: Props) {
  const [selectedNodeName, setSelectedNodeName] = React.useState<string | undefined>(undefined);

  const [selectedColumns, setSelectedColumns] = React.useState<Columns[]>([
    Columns.CpuRequestVsAllocatableDiff,
    Columns.CpuUsageVsAllocatableDiff,
    Columns.MemoryRequestVsAllocatableDiff,
    Columns.MemoryUsageVsAllocatableDiff,
  ]);

  const [pageSize, setPageSize] = useStateWithLocalStorage<number>({
    localStorageKey: NODE_BREAKDOWN_PAGE_SIZE_LOCAL_STORAGE_KEY,
    defaultValue: DEFAULT_PAGE_SIZE,
    valueFormatter: (value) => parseInt(value),
  });

  const [rows, setRows] = React.useState<NodeStatsResponseTypeSchema[]>([]);
  const [searchTerm] = useQueryParam("setSearchTerm", StringParam);

  React.useEffect(() => {
    let rowsToDisplay = nodeStats?.map((node) => ({
      ...node,
      cpuRequest: node.cpuRequest || 0,
      cpuAllocatable: node.cpuAllocatable || 0,
      cpuUsage: node.cpuUsage || 0,
      memoryRequest: node.memoryRequest || 0,
      memoryAllocatable: node.memoryAllocatable || 0,
      memoryUsage: node.memoryUsage || 0,
      gpuRequest: node.gpuRequest || 0,
      gpuAllocatable: node.gpuAllocatable || 0,
      availabilityZone: node.availabilityZone,
      cpuRequestVsAllocatableDiff: node.cpuRequest / node.cpuAllocatable,
      cpuUsageVsAllocatableDiff: node.cpuUsage / node.cpuAllocatable,
      memoryRequestVsAllocatableDiff: node.memoryRequest / node.memoryAllocatable,
      memoryUsageVsAllocatableDiff: node.memoryUsage / node.memoryAllocatable,
      gpuRequestVsAllocatableDiff:
        node.gpuAllocatable && node.gpuAllocatable > 0 ? (node.gpuRequest || 0) / node.gpuAllocatable : 0,
      maxPods: node.maxPods,
      currentRunningPods: node.currentRunningPods,
      currentDaemonSetPods: node.currentDaemonSetPods,
    }));

    if (searchTerm && searchTerm.length > 0) {
      rowsToDisplay = rowsToDisplay.filter((row) => row.name.includes(searchTerm));
    }

    rowsToDisplay = rowsToDisplay.sort((a, b) => {
      if (a.limitScaleDownReason && b.limitScaleDownReason) {
        return 0;
      }
      if (a.limitScaleDownReason) {
        return -1;
      }
      if (b.limitScaleDownReason) {
        return 1;
      }
      return 0;
    });

    setRows(rowsToDisplay);
  }, [nodeStats, searchTerm]);

  const hasNodeGroups = rows.filter((row) => row.nodeGroup && row.nodeGroup?.length > 0)?.length > 0;

  return (
    <div>
      <div className="flex">
        <div className="w-full flex gap-2 item flex-grow">
          <NodeFilterBar />
        </div>
        <div className="flex justify-end items-start gap-[8px] margin-top[9px]">
          <MultiSelect
            selected={selectedColumns}
            setSelected={setSelectedColumns as React.Dispatch<React.SetStateAction<(string | undefined)[]>>}
            options={COLUMNS_MENU_OPTIONS}
            className="top-[9px] w-[85px]"
            customIcon={<CustomColumnsFilterButton isFiltered={selectedColumns.length > 0} />}
          />
        </div>
      </div>
      <DataGrid
        sx={{
          ...getDataGridSx(undefined, HAS_NODE_OVERVIEW),
        }}
        rows={rows ?? []}
        columns={getColumns(hasNodeGroups, selectedColumns, nodeGroups)}
        onRowClick={(params: GridRowParams<NodeStatsResponseTypeSchema>) => {
          setSelectedNodeName(params.row.name);
        }}
        autoHeight={true}
        rowHeight={65}
        getRowId={(row: NodeStatsResponseTypeSchema) => row.name}
        loading={isLoading}
        disableSelectionOnClick
        getCellClassName={() => (HAS_NODE_OVERVIEW ? "cursor-pointer" : "")}
        pagination
        initialState={{
          pagination: {
            pageSize: DEFAULT_PAGE_SIZE,
          },
        }}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
          localStorage.setItem(NODE_BREAKDOWN_PAGE_SIZE_LOCAL_STORAGE_KEY, newPageSize.toString());
        }}
      />
      {HAS_EXPORT_TABLE_AS_CSV && (
        <div className="mt-[-35px] ml-[10px] z-50 relative w-fit">
          <ExportCSV<CSVExportType>
            filename="nodes.csv"
            columns={[
              "name",
              "nodeGroup",
              "cost",
              "instanceType",
              "isSpot",
              "availabilityZone",
              "cpuAllocatable",
              "cpuRequest",
              "cpuUsage",
              "cpuRequestVsAllocatableDiff",
              "cpuUsageVsAllocatableDiff",
              "memoryAllocatable",
              "memoryRequest",
              "memoryUsage",
              "memoryRequestVsAllocatableDiff",
              "memoryUsageVsAllocatableDiff",
              "maxPods",
              "currentRunningPods",
              "currentDaemonSetPods",
              "gpuAllocatable",
              "gpuRequest",
              "gpuRequestVsAllocatableDiff",
            ]}
            columnsToRound={["cost"]}
            data={
              rows.map((row) => {
                return { ...row, id: row.name };
              }) as CSVExportType[]
            }
            columnsToSum={[
              "cost",
              "cpuAllocatable",
              "cpuRequest",
              "cpuUsage",
              "cpuRequestVsAllocatableDiff",
              "cpuUsageVsAllocatableDiff",
              "memoryAllocatable",
              "memoryRequest",
              "memoryUsage",
              "memoryRequestVsAllocatableDiff",
              "memoryUsageVsAllocatableDiff",
              "maxPods",
              "currentRunningPods",
              "currentDaemonSetPods",
              "gpuAllocatable",
              "gpuRequest",
              "gpuRequestVsAllocatableDiff",
            ]}
            customColumnNames={{
              name: "Name",
              nodeGroup: "Group",
              cost: "Monthly Cost",
              instanceType: "Type",
              isSpot: "Life Cycle",
              availabilityZone: "Availability Zone",
              cpuAllocatable: "CPU Allocatable",
              cpuRequest: "CPU Request",
              cpuUsage: "CPU Usage",
              cpuRequestVsAllocatableDiff: "CPU - Request vs Allocatable (diff)",
              cpuUsageVsAllocatableDiff: "CPU - Usage vs Allocatable (diff)",
              memoryAllocatable: "Memory Allocatable",
              memoryRequest: "Memory Request",
              memoryUsage: "Memory Usage",
              memoryRequestVsAllocatableDiff: "Memory - Request vs Allocatable (diff)",
              memoryUsageVsAllocatableDiff: "Memory - Usage vs Allocatable (diff)",
              maxPods: "Max Pods",
              currentRunningPods: "Current Running Pods",
              currentDaemonSetPods: "Current DaemonSet Pods",
              gpuAllocatable: "GPU Allocatable",
              gpuRequest: "GPU Request",
              gpuRequestVsAllocatableDiff: "GPU - Request vs Allocatable (diff)",
            }}
          />
        </div>
      )}
      {HAS_NODE_OVERVIEW && (
        <NodeOverviewContainer selectedNodeName={selectedNodeName} setSelectedNodeName={setSelectedNodeName} />
      )}
    </div>
  );
}

export default NodesBreakdownTab;
