import Input from "../Input";
import { StringParam, useQueryParam } from "use-query-params";

const SearchNodeFilter = () => {
  const [searchTerm, setSearchTerm] = useQueryParam("setSearchTerm", StringParam);

  return (
    <Input
      placeholder="search..."
      onChange={(e) => {
        setSearchTerm(e.target.value);
      }}
      value={searchTerm ?? ""}
      borderColor="rgba(0, 0, 0, 0.87)"
      height="32px"
    />
  );
};

export default SearchNodeFilter;
