import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import CustomFilterChip from "../../../../components/CustomFilterChip";
import LabelsAndAnnotationsFilter from "../../../../components/WorkloadsAggregation/LabelsAndAnnotationsFilter";
import ColumnsFilter from "../ColumnsFilter";
import { Field } from "../utils";
import ClearAggregationFilters from "./ClearAggregationFilters";
import { FilterOptions, useMultiClusterUseQueryParam } from "./hooks/useAggregationFilters";

interface Props {
  selectedColumns: (string | undefined)[];
  setSelectedColumns: (selectedColumns: (string | undefined)[]) => void;
}

const AggregationFilters = ({ selectedColumns, setSelectedColumns }: Props) => {
  const [groupByNamespaceParams, setGroupByNamespaceParams] = useQueryParam(
    FilterOptions.GroupByNamespace,
    withDefault(BooleanParam, true)
  );
  const [multiClusterParams, setMultiClusterParams] = useMultiClusterUseQueryParam();

  return (
    <div className="w-full flex flex-col gap-2">
      <div className="flex gap-2 items-center">
        <CustomFilterChip
          label="clusters"
          onClick={() => {
            setMultiClusterParams((s) => !s);
          }}
          selected={!!multiClusterParams}
        />
        <CustomFilterChip
          label="namespaces"
          onClick={() => {
            setGroupByNamespaceParams((s) => !s);
          }}
          selected={!!groupByNamespaceParams}
        />
        <LabelsAndAnnotationsFilter
          labelsQueryKey={FilterOptions.GroupByLabels}
          annotationsQueryKey={FilterOptions.GroupByAnnotations}
        />
        <ColumnsFilter
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          options={[
            Field.clusterName,
            Field.totalCost,
            Field.savingsAvailable,
            Field.spot,
            Field.onDemand,
            Field.cpu,
            Field.memory,
            Field.replicas,
            Field.amountOfWorkloads,
          ]}
          customNames={{
            [Field.replicas]: "Number of Pods",
          }}
        />
      </div>
      <ClearAggregationFilters />
    </div>
  );
};

export default AggregationFilters;
