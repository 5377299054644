import { Typography } from "@mui/material";
import { NumberParam, QueryParamConfig, StringParam, useQueryParam, withDefault } from "use-query-params";
import ScaleopsIcon from "../../Icons/ScaleopsIcon";
import ChartsContainer from "./Charts/ChartsContainer";
import TablesContainer from "./Tables/TablesContainer";
import { TableType } from "./Tables/utils";
import RangePicker, { RangeOptions } from "../../components/RangePicker/RangePicker";
import { DateType, getFromDate } from "../Analytics/AnalyticsV2/utils";
import { useState } from "react";

const tableTypeConfig: QueryParamConfig<TableType, TableType> = {
  encode: (value) => value,
  decode: (value) => value as TableType,
};

const CostContainer = () => {
  const [selectedTable, setSelectedTable] = useQueryParam<TableType>(
    "selectedTable",
    withDefault(tableTypeConfig, TableType.Aggregation)
  );
  const [currentEpoch] = useState(Date.now());
  const [fromDate, setFromDate] = useQueryParam("fromDate", withDefault(NumberParam, getFromDate(7, currentEpoch)));
  const [toDate, setToDate] = useQueryParam("toDate", withDefault(NumberParam, currentEpoch));
  const [range, setRange] = useQueryParam("range", withDefault(StringParam, "7d"));
  const date: DateType = {
    from: fromDate,
    to: toDate,
    range: range,
  };
  const setDate = (date: DateType) => {
    setFromDate(date.from);
    setToDate(date.to);
    setRange(date.range);
  };

  return (
    <div className="p-4 flex flex-col gap-5">
      <div className="flex gap-3 border border-border rounded-lg p-5 bg-white">
        <div className="flex items-center">
          <ScaleopsIcon width={60} height={60} />
        </div>
        <div className="grow">
          <Typography variant="h6" fontWeight="bold">
            Cost report
          </Typography>
          <Typography variant="body2">
            <b>Explore your cost by workloads, namespaces and cluster.</b>
          </Typography>
        </div>
        <div className="flex items-center">
          <RangePicker date={date} setDate={setDate} rangeOptions={RangeOptions.MEDIUM} />
        </div>
      </div>
      <ChartsContainer selectedTable={selectedTable} />
      <TablesContainer selectedTable={selectedTable} setSelectedTable={setSelectedTable} />
    </div>
  );
};

export default CostContainer;
