import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Select,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  Checkbox,
  FormGroup,
  MenuItem,
} from "@mui/material";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "../../../components/Tooltip";
import { components } from "../../../api/schema";
import * as policyUtils from "../../../utils/policyUtils";
import "../../../theme";
import { FeatureEnabled } from "../../../utils/FeaturesHelper";
import * as componentUtils from "../../../components/componentUtils/overview";
import { numberFormatter } from "../../../utils/formatterUtils";
import { History } from "@mui/icons-material";
import { GetConfig } from "../../../utils/ConfigHelper";

function buildMeneHourItem(value: string, labelValue: string) {
  return (
    <MenuItem value={value}>
      <History style={{ float: "left" }} />
      <Typography style={{ marginLeft: "10px", float: "left" }}>{labelValue}</Typography>
    </MenuItem>
  );
}

// TODO refactor this component @barel22
export default function RightsizeTabContent(props: {
  errorsDict: { [errorType: string]: React.ReactNode };
  selectedPolicyToUpdate: components["schemas"]["V1alpha1Policy"];
}) {
  const {
    selectedPolicyToUpdate,
  }: { errorsDict: { [p: string]: React.ReactNode }; selectedPolicyToUpdate: components["schemas"]["V1alpha1Policy"] } =
    props;
  const { errorsDict } = props;
  const [isCpuSetLimit, setIsCpuSetLimit] = useState<boolean>(
    !policyUtils.policyCpuLimitsNoLimit(selectedPolicyToUpdate)
  );
  const [isMemorySetLimit, setIsMemorySetLimit] = useState<boolean>(
    !policyUtils.policyMemoryLimitsNoLimit(selectedPolicyToUpdate)
  );

  const [bootTime, setBootTime] = useState<boolean>(policyUtils.policyBootTime(selectedPolicyToUpdate));
  const [updateMode, setUpdateMode] = useState<string>(policyUtils.policyUpdateMode(selectedPolicyToUpdate));
  const [advancedSettings, setAdvancedSettings] = React.useState(false);

  const [historyGraphCpuWindow, setHistoryGraphCpuWindow] = useState(
    policyUtils.policyHistoryWindow(selectedPolicyToUpdate, "cpu")
  );
  const [historyGraphMemoryWindow, setHistoryGraphMemoryWindow] = useState(
    policyUtils.policyHistoryWindow(selectedPolicyToUpdate, "memory")
  );
  const [maxUnavailableValue, setMaxUnavailableValue] = React.useState<number>(
    policyUtils.policyMaxUnavailablePodsPercentage(selectedPolicyToUpdate)
  );

  const [cpuRequestsPercentile, setCpuRequestsPercentile] = React.useState<number>(
    policyUtils.policyCPUPercentile(selectedPolicyToUpdate)
  );
  const [cpuLimitsPercentile, setCpuLimitsPercentile] = React.useState<number>(
    policyUtils.policyCpuLimitsPercentile(selectedPolicyToUpdate)
  );
  const [memoryRequestsPercentile, setMemoryRequestsPercentile] = React.useState<number>(
    policyUtils.policyMemoryPercentile(selectedPolicyToUpdate)
  );
  const [memoryLimitsPercentile, setMemoryLimitsPercentile] = React.useState<number>(
    policyUtils.policyMemoryLimitsPercentile(selectedPolicyToUpdate)
  );

  const handleCpuPercentileRequestsSliderChange = (newValue: number) => {
    policyUtils.policyUpdateCpuPercentile(selectedPolicyToUpdate, newValue);
    setCpuRequestsPercentile(newValue);
  };

  const handleCpuPercentileLimitsSliderChange = (newValue: number) => {
    policyUtils.policyUpdateCpuLimitsPercentile(selectedPolicyToUpdate, newValue);
    setCpuLimitsPercentile(newValue);
  };

  const handleMemoryPercentileRequestsSliderChange = (newValue: number) => {
    policyUtils.policyUpdateMemoryPercentile(selectedPolicyToUpdate, newValue);
    setMemoryRequestsPercentile(newValue);
  };

  const handleMemoryPercentileLimitsSliderChange = (newValue: number) => {
    policyUtils.policyUpdateMemoryLimitsPercentile(selectedPolicyToUpdate, newValue);
    setMemoryLimitsPercentile(newValue);
  };

  const handleSliderChange = (newValue: number) => {
    policyUtils.policyUpdateMaxUnavailablePodsPercentage(selectedPolicyToUpdate, newValue);
    setMaxUnavailableValue(newValue);
  };

  const config = GetConfig();

  return (
    <div>
      {componentUtils.getPolicyConfiguration(
        "History window",
        "Define the number of days the recommendation should be based on.",
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <div style={{ paddingTop: "5px" }}>
            <Typography style={{ fontWeight: 500 }}>CPU</Typography>
            <FormControl sx={{ minWidth: 170 }} size="small">
              <Select
                id="demo-simple-select"
                value={historyGraphCpuWindow}
                onChange={(event) => {
                  policyUtils.policyUpdateHistoryWindow(selectedPolicyToUpdate, event.target.value, "cpu");
                  setHistoryGraphCpuWindow(event.target.value);
                }}
              >
                {buildMeneHourItem("672h", "1 month")}
                {buildMeneHourItem("336h", "2 weeks")}
                {buildMeneHourItem("168h", "7 days")}
                {buildMeneHourItem("96h", "4 days")}
                {buildMeneHourItem("48h", "2 days")}
                {buildMeneHourItem("24h", "1 day")}
                {buildMeneHourItem("12h", "12 hours")}
                {buildMeneHourItem("4h", "4 hours")}
                {buildMeneHourItem("1h", "1 hour")}
                {buildMeneHourItem("30m", "30 minutes")}
              </Select>
            </FormControl>
          </div>

          <Typography style={{ paddingTop: "35px", marginLeft: "10px", marginRight: "10px" }}>:</Typography>

          <div style={{ paddingTop: "5px" }}>
            <Typography style={{ fontWeight: 500 }}>Memory</Typography>
            <FormControl sx={{ minWidth: 170 }} size="small">
              <Select
                id="demo-simple-select"
                value={historyGraphMemoryWindow}
                onChange={(event) => {
                  policyUtils.policyUpdateHistoryWindow(selectedPolicyToUpdate, event.target.value, "memory");
                  setHistoryGraphMemoryWindow(event.target.value);
                }}
              >
                {buildMeneHourItem("672h", "1 month")}
                {buildMeneHourItem("336h", "2 weeks")}
                {buildMeneHourItem("168h", "7 days")}
                {buildMeneHourItem("96h", "4 days")}
                {buildMeneHourItem("48h", "2 days")}
                {buildMeneHourItem("24h", "1 day")}
                {buildMeneHourItem("12h", "12 hours")}
                {buildMeneHourItem("4h", "4 hours")}
                {buildMeneHourItem("1h", "1 hour")}
                {buildMeneHourItem("30m", "30 minutes")}
              </Select>
            </FormControl>
          </div>
        </div>
      )}
      {componentUtils.getPolicyConfiguration(
        "Requests headroom",
        "Define requests headroom for container resources.",
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <div style={{ paddingTop: "25px" }}>
            {componentUtils.getTextFieldForPolicyWindow(
              "CPU (%)",
              numberFormatter(policyUtils.policyCpuHeadroom(selectedPolicyToUpdate)),
              (value) => {
                policyUtils.policyUpdateCpuHeadroom(selectedPolicyToUpdate, numberFormatter(value));
              },
              errorsDict["cpuHeadroomError"]
            )}
          </div>
          <Typography style={{ paddingTop: "30px", marginLeft: "10px", marginRight: "10px" }}>:</Typography>
          <div style={{ paddingTop: "25px" }}>
            {componentUtils.getTextFieldForPolicyWindow(
              "Memory (%)",
              numberFormatter(policyUtils.policyMemoryHeadroom(selectedPolicyToUpdate)),
              (value) => {
                policyUtils.policyUpdateMemoryHeadroom(selectedPolicyToUpdate, numberFormatter(value));
              },
              errorsDict["memoryHeadroomError"]
            )}
          </div>
        </div>,
        "This value calculated by the resource request percentiles and can be editing on advanced tab"
      )}
      {componentUtils.getPolicyConfiguration(
        "Limits headroom",
        "Define limits headroom for container resources.",
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <div style={{ width: "min-content" }}>
            {componentUtils.getSwitchForPolicyWindow("Set CPU limit", isCpuSetLimit, setIsCpuSetLimit, (value) => {
              policyUtils.policyUpdateCpuLimitsNoLimit(selectedPolicyToUpdate, value);
              if (policyUtils.policyCpuLimitsHeadroom(selectedPolicyToUpdate) == 0) {
                // might be undefined
                policyUtils.policyUpdateCpuLimitsHeadroom(selectedPolicyToUpdate, 0);
              }
            })}
            {componentUtils.getTextFieldForPolicyWindow(
              "CPU (%)",
              numberFormatter(policyUtils.policyCpuLimitsHeadroom(selectedPolicyToUpdate)),
              (value) => {
                policyUtils.policyUpdateCpuLimitsHeadroom(selectedPolicyToUpdate, numberFormatter(value));
              },
              errorsDict["cpuLimitHeadroomError"],
              !isCpuSetLimit
            )}
          </div>
          <Typography style={{ paddingTop: "45px", marginLeft: "8px", marginRight: "10px" }}>:</Typography>
          <div style={{ width: "min-content" }}>
            <div style={{ width: "min-content" }}>
              {componentUtils.getSwitchForPolicyWindow(
                "Set Memory limit",
                isMemorySetLimit,
                setIsMemorySetLimit,
                (value) => {
                  policyUtils.policyUpdateMemoryLimitsNoLimit(selectedPolicyToUpdate, value);
                  if (policyUtils.policyMemoryLimitsHeadroom(selectedPolicyToUpdate) == 0) {
                    // might be undefined
                    policyUtils.policyUpdateMemoryLimitsHeadroom(selectedPolicyToUpdate, 0);
                  }
                }
              )}
              {componentUtils.getTextFieldForPolicyWindow(
                "Memory (%)",
                numberFormatter(policyUtils.policyMemoryLimitsHeadroom(selectedPolicyToUpdate)),
                (value) => {
                  policyUtils.policyUpdateMemoryLimitsHeadroom(selectedPolicyToUpdate, numberFormatter(value));
                },
                errorsDict["memoryLimitHeadroomError"],
                !isMemorySetLimit
              )}
            </div>
          </div>
        </div>,
        "This value calculated by the resource limit percentiles and can be editing on advanced tab"
      )}
      {componentUtils.getPolicyConfiguration(
        "Applying recommendation settings",
        "Define strategy to apply recommendations.",
        <div>
          <FormControl>
            <RadioGroup
              row
              key={updateMode.toString()}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue={updateMode}
              onChange={(event, value) => {
                setUpdateMode(value);
                policyUtils.policyUpdateUpdateMode(selectedPolicyToUpdate, value);
              }}
            >
              <FormControlLabel
                value="Ongoing"
                control={<Radio />}
                label={<Typography fontSize="small">Rollout</Typography>}
              />
              <FormControlLabel
                value="OnCreate"
                control={<Radio size="small" />}
                label={<Typography fontSize="small">Upon Pod Creation</Typography>}
              />
              {FeatureEnabled("AllowInPlace") && (
                <FormControlLabel
                  value="Inplace"
                  control={<Radio size="small" />}
                  label={<Typography fontSize="small">Inplace</Typography>}
                />
              )}
              {!FeatureEnabled("AllowInPlace") && (
                <Tooltip
                  title={`To enable this feature go to: https://docs.scaleops.com/docs/operational/inplace/${
                    config.token ? "?token=" + config.token : ""
                  }`}
                >
                  <FormControlLabel
                    value="Inplace"
                    control={<Radio size="small" />}
                    label={<Typography fontSize="small">Inplace</Typography>}
                    disabled={true}
                  />
                </Tooltip>
              )}
            </RadioGroup>
          </FormControl>
          <div style={{ paddingBottom: "10px" }}>
            <FormControlLabel
              control={
                <Switch
                  key={updateMode.toString()}
                  disabled={updateMode !== "Ongoing" && updateMode !== "Inplace"}
                  color="secondary"
                  checked={bootTime}
                  onChange={(value, checked) => {
                    policyUtils.policyUpdateBootTime(selectedPolicyToUpdate, checked);
                    setBootTime(checked);
                  }}
                />
              }
              label={
                <div style={{ display: "flax", justifyContent: "space-between" }}>
                  <Typography fontSize="small" style={{ float: "left" }}>
                    Depends on Boot Time
                  </Typography>
                  <Tooltip title="Update depends on whole pod lifecycle data for automatically applying changes">
                    <InfoOutlinedIcon style={{ fontSize: "small", marginLeft: "2px", marginBottom: "5px" }} />
                  </Tooltip>
                </div>
              }
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            {componentUtils.getTextFieldForPolicyWindow(
              "Min Replicas",
              numberFormatter(policyUtils.policyRolloutMinReplicas(selectedPolicyToUpdate)),
              (value) => {
                policyUtils.policyUpdateRolloutMinReplicas(selectedPolicyToUpdate, numberFormatter(value));
              },
              errorsDict["minReplicas"],
              updateMode !== "Ongoing" && updateMode !== "Inplace",
              "110px",
              "1"
            )}
            <div style={{ paddingLeft: "30px" }}>
              {componentUtils.getSlider(
                "Max Unavailable Pods",
                maxUnavailableValue,
                (value) => {
                  handleSliderChange(value);
                },
                updateMode !== "Ongoing" && updateMode !== "Inplace"
              )}
            </div>
          </div>
        </div>
      )}
      <div>
        <Button
          variant="text"
          onClick={() => {
            setAdvancedSettings(!advancedSettings);
          }}
          disableRipple
          style={{ backgroundColor: "transparent", textTransform: "none", paddingLeft: "0px" }}
          endIcon={advancedSettings ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        >
          Advanced
        </Button>
      </div>
      {advancedSettings && (
        <div>
          {componentUtils.getPolicyConfiguration(
            "Histogram Percentile Configuration",
            "Define how close should scaleops be to the usage that measured.",
            <div>
              {componentUtils.getSlider(
                "CPU Request",
                cpuRequestsPercentile,
                (value) => {
                  handleCpuPercentileRequestsSliderChange(value);
                },
                false,
                70,
                1,
                100
              )}
              {componentUtils.getSlider(
                "CPU Limits",
                cpuLimitsPercentile,
                (value) => {
                  handleCpuPercentileLimitsSliderChange(value);
                },
                !isCpuSetLimit,
                70,
                1,
                100
              )}
              {componentUtils.getSlider(
                "Memory Request",
                memoryRequestsPercentile,
                (value) => {
                  handleMemoryPercentileRequestsSliderChange(value);
                },
                false,
                70,
                1,
                100
              )}
              {componentUtils.getSlider(
                "Memory Limits",
                memoryLimitsPercentile,
                (value) => {
                  handleMemoryPercentileLimitsSliderChange(value);
                },
                !isMemorySetLimit,
                70,
                1,
                100
              )}
            </div>
          )}
          {componentUtils.getPolicyConfiguration(
            "Resource Boundaries",
            "Determine the max and min values of CPU and memory, ScaleOps allows to recommend.",
            <div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div style={{ paddingTop: "25px" }}>
                  {componentUtils.getTextFieldForPolicyWindow(
                    "Min CPU",
                    numberFormatter(policyUtils.policyCpuMinAllowed(selectedPolicyToUpdate) ?? ""),
                    (value) => {
                      policyUtils.policyUpdateCpuMinAllowed(selectedPolicyToUpdate, numberFormatter(value));
                    },
                    errorsDict["cpuMinAllowedError"]
                  )}
                </div>
                <Typography style={{ paddingTop: "30px", marginLeft: "10px", marginRight: "10px", fontWeight: 600 }}>
                  to
                </Typography>
                <div style={{ paddingTop: "25px" }}>
                  {componentUtils.getTextFieldForPolicyWindow(
                    "Max CPU",
                    numberFormatter(policyUtils.policyCpuMaxAllowed(selectedPolicyToUpdate) ?? ""),
                    (value) => {
                      policyUtils.policyUpdateCpuMaxAllowed(selectedPolicyToUpdate, numberFormatter(value));
                    },
                    errorsDict["cpuMaxAllowedError"]
                  )}
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div style={{ paddingTop: "25px" }}>
                  {componentUtils.getTextFieldForPolicyWindow(
                    "Min Memory (GiB)",
                    numberFormatter(policyUtils.policyMemoryMinAllowed(selectedPolicyToUpdate) ?? ""),
                    (value) => {
                      policyUtils.policyUpdateMemoryMinAllowed(selectedPolicyToUpdate, numberFormatter(value));
                    },
                    errorsDict["memoryMinAllowedError"]
                  )}
                </div>
                <Typography style={{ paddingTop: "30px", marginLeft: "10px", marginRight: "10px", fontWeight: 600 }}>
                  to
                </Typography>
                <div style={{ paddingTop: "25px" }}>
                  {componentUtils.getTextFieldForPolicyWindow(
                    "Max Memory (GiB)",
                    numberFormatter(policyUtils.policyMemoryMaxAllowed(selectedPolicyToUpdate) ?? ""),
                    (value) => {
                      policyUtils.policyUpdateMemoryMaxAllowed(selectedPolicyToUpdate, numberFormatter(value));
                    },
                    errorsDict["memoryMaxAllowedError"]
                  )}
                </div>
              </div>
            </div>
          )}
          {componentUtils.getPolicyConfiguration(
            "Stabilization Window before Rollout",
            "Define the duration the pod  should be live before rolling.",
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ paddingTop: "25px" }}>
                {componentUtils.getTextFieldForPolicyWindow(
                  "Scale Down (H.)",
                  Math.round((policyUtils.policyStabilizationScaleDownWindow(selectedPolicyToUpdate) / 60 / 60) * 100) /
                    100,
                  (value) => {
                    policyUtils.policyUpdateStabilizationWindowSecondsScaleDown(
                      selectedPolicyToUpdate,
                      Number(value) * 60 * 60
                    );
                  },
                  errorsDict["stabilizationWindowError"],
                  updateMode !== "Ongoing" && updateMode !== "Inplace"
                )}
              </div>
              <Typography style={{ paddingTop: "30px", marginLeft: "10px", marginRight: "10px" }}>:</Typography>
              <div style={{ paddingTop: "25px" }}>
                {componentUtils.getTextFieldForPolicyWindow(
                  "Scale Up (H.)",
                  Math.round((policyUtils.policyStabilizationScaleUpWindow(selectedPolicyToUpdate) / 60 / 60) * 100) /
                    100,
                  (value) => {
                    policyUtils.policyUpdateStabilizationWindowSecondsScaleUp(
                      selectedPolicyToUpdate,
                      Number(value) * 60 * 60
                    );
                  },
                  errorsDict["stabilizationWindowError"],
                  updateMode !== "Ongoing" && updateMode !== "Inplace"
                )}
              </div>
            </div>
          )}
          {componentUtils.getPolicyConfiguration(
            "Allowed Rollout Period ",
            "Determine when scaleops allows to optimize workloads.",
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ paddingTop: "25px" }}>
                {componentUtils.getTextFieldForPolicyWindow(
                  "Scale Down (m h d m d)",
                  policyUtils.policyEvictionScheduleScaleDown(selectedPolicyToUpdate),
                  (value) => {
                    policyUtils.policyUpdateEvictionScheduleScaleDown(selectedPolicyToUpdate, value.toString());
                  },
                  errorsDict["stabilizationWindowError"],
                  updateMode !== "Ongoing" && updateMode !== "Inplace"
                )}
              </div>
              <Typography style={{ paddingTop: "30px", marginLeft: "10px", marginRight: "10px" }}>:</Typography>
              <div style={{ paddingTop: "25px" }}>
                {componentUtils.getTextFieldForPolicyWindow(
                  "Scale Up (m h d m d)",
                  policyUtils.policyEvictionScheduleScaleUp(selectedPolicyToUpdate),
                  (value) => {
                    policyUtils.policyUpdateEvictionScheduleScaleUp(selectedPolicyToUpdate, value.toString());
                  },
                  errorsDict["stabilizationWindowError"],
                  updateMode !== "Ongoing" && updateMode !== "Inplace"
                )}
              </div>
            </div>
          )}
          {componentUtils.getPolicyConfiguration(
            "Change Thresholds",
            "Define the thresholds by which ScaleOps would apply changes to the workload.",
            <div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div style={{ paddingTop: "25px" }}>
                  {componentUtils.getTextFieldForPolicyWindow(
                    "Scale Up (%)",
                    policyUtils.policyMinChangeScaleUpCpu(selectedPolicyToUpdate) * 100,
                    (value) => {
                      policyUtils.policyUpdateMinChangeScaleUpCpu(selectedPolicyToUpdate, Number(value) / 100);
                      policyUtils.policyUpdateMinChangeScaleUpMemory(selectedPolicyToUpdate, Number(value) / 100);
                    },
                    errorsDict["minChangeScaleUpCpuError"]
                  )}
                </div>
                <Typography style={{ paddingTop: "30px", marginLeft: "10px", marginRight: "10px" }}> </Typography>
                <div style={{ paddingTop: "25px" }}>
                  {componentUtils.getTextFieldForPolicyWindow(
                    "Scale Down (%)",
                    policyUtils.policyMinChangeScaleDownCpu(selectedPolicyToUpdate) * 100,
                    (value) => {
                      policyUtils.policyUpdateMinChangeScaleDownCpu(selectedPolicyToUpdate, Number(value) / 100);
                      policyUtils.policyUpdateMinChangeScaleDownMemory(selectedPolicyToUpdate, Number(value) / 100);
                    },
                    errorsDict["minChangeScaleDownCpuError"]
                  )}
                </div>
              </div>
            </div>
          )}
          {componentUtils.getPolicyConfiguration(
            "Required Window Coverage",
            "Define the percentage of data points in history window that is required for applying changes",
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ paddingTop: "25px" }}>
                {componentUtils.getTextFieldForPolicyWindow(
                  "Window Coverage (%)",
                  policyUtils.policyRequiredHistoryCoveragePercentage(selectedPolicyToUpdate),
                  (value) => {
                    policyUtils.policyUpdateRequiredHistoryCoveragePercentage(selectedPolicyToUpdate, Number(value));
                  },
                  errorsDict["stabilizationWindowError"],
                  updateMode !== "Ongoing" && updateMode !== "Inplace",
                  "150px",
                  "1"
                )}
              </div>
            </div>,
            "Low coverage will delay ScaleOps from applying recommendation in Auto mode and creating github PRs"
          )}
          {componentUtils.getPolicyConfiguration(
            "JAVA Applications Environment Overrides",
            "Propagate and override JAVA_TOOL_OPTIONS and _JAVA_OPTIONS environment Xms,Xmx flags with ScaleOps request and limits recommendation",
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ paddingTop: "25px" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={policyUtils.policyJvmEnvXmsMxs(selectedPolicyToUpdate)}
                        onChange={(event, checked) => {
                          policyUtils.policyUpdateJvmEnvXms(selectedPolicyToUpdate, checked);
                        }}
                      />
                    }
                    label="Override"
                  />
                </FormGroup>
              </div>
            </div>,
            "ScaleOps will edit the overriden flags if exists or add them otherwise"
          )}
          {componentUtils.getPolicyConfiguration(
            "Zero Downtime Rollout",
            "For workloads with 1 replica and 0 downtime requirement, allow smart rollout",
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ paddingTop: "25px" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={policyUtils.policyAllowRollingUpdate(selectedPolicyToUpdate)}
                        onChange={(event, checked) => {
                          policyUtils.policyUpdateAllowRollingUpdate(selectedPolicyToUpdate, checked);
                        }}
                      />
                    }
                    label="Enable"
                  />
                </FormGroup>
              </div>
            </div>,
            "ScaleOps will create new pods before evicting old ones"
          )}
          {componentUtils.getPolicyConfiguration(
            "HPA",
            "Allow ScaleOps to manage the HPA of workloads in Auto",
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ paddingTop: "25px" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={policyUtils.policyManageHPA(selectedPolicyToUpdate)}
                        onChange={(event, checked) => {
                          policyUtils.policyUpdateManageHPA(selectedPolicyToUpdate, checked);
                        }}
                      />
                    }
                    label="Enable"
                  />
                </FormGroup>
              </div>
            </div>,
            "ScaleOps will update the resource metrics of type CPU / Memory from Utilization to AverageValue in order to ensure intended threshold"
          )}
        </div>
      )}
    </div>
  );
}
