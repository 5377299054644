import { Typography } from "@mui/material";
import { useEffect } from "react";
import Tooltip from "../components/Tooltip";
import AlertIcon from "../Icons/AlertIcon";
import AuditIcon from "../Icons/AuditIcon";
import BasicSettingsIcon from "../Icons/BasicSettingsIcon";
import DocsIcon from "../Icons/DocsIcon";
import DollarIcon from "../Icons/DollarIcon";
import FullLogo from "../Icons/FullLogo";
import InsightIcon from "../Icons/InsightIcon";
import LightingIcon from "../Icons/LightingIcon";
import LogoutIcon from "../Icons/LogoutIcon";
import OverviewIcon from "../Icons/OverviewIcon";
import PlainCubeIcon from "../Icons/PlainCubeIcon";
import PolicyIcon from "../Icons/PolicyIcon";
import RecommendationIcon from "../Icons/RecommendationIcon";
import { useMainContext } from "../MainContext";
import { HAS_MULTILEVEL_ANALYTICS } from "../pages/Analytics/AnalyticsV2/AnalyticsContainer";
import { GetConfig } from "../utils/ConfigHelper";
import { FeatureEnabled } from "../utils/FeaturesHelper";
import HealthCheckStatus from "./HealthCheckStatus";
import MainMenuItem, { MainMenuItemSize } from "./MainMenuItem";
import MultiClusterMenu from "./MultiClusterMenu";
import MultiMenu from "./MultiMenu";
import SelectCluster from "./SelectCluster";
import UpgradeVersion from "./UpgradeVersion";
import UserRole from "./UserRole";

const HAS_QUICK_ACTION = false;
export const MAIN_MENU_WIDTH = 205;

const MainMenu = () => {
  const config = GetConfig();
  const version = FeatureEnabled("DemoVersion") ? "demo" : config.version || "";
  const { currentCluster } = useMainContext();

  useEffect(() => {
    const sessionCurrentCluster = sessionStorage.getItem("currentCluster");
    if (sessionCurrentCluster !== currentCluster) {
      sessionStorage.setItem("currentCluster", currentCluster ?? "");
    }
  }, [currentCluster]);

  const logout = () => {
    localStorage.removeItem("__scaleops_token");
    window.location.href = "/login";
  };

  return (
    <div
      className={`fixed h-[100vh] p-[20px] bg-mainMenuBackground top-0 flex flex-col text-white overflow-y-auto`}
      style={{ zIndex: 999, width: MAIN_MENU_WIDTH }}
    >
      <div className="min-w-[159px] min-h-[33px]">
        <FullLogo />
      </div>
      <div className="grow flex flex-col gap-4 mt-10">
        <div className="flex flex-col gap-5 mt-2">
          <SelectCluster />
          <div className="flex flex-col">
            <HealthCheckStatus />
            <UserRole />
          </div>
          <MainMenuItem icon={<OverviewIcon />} title="Overview" to="/" />
          <MainMenuItem icon={<PolicyIcon />} title="Policies" to="/policy" />
          <MainMenuItem icon={<PlainCubeIcon />} title="Nodes" to="/nodes" />
          {/*<MainMenuItem icon={<AlertIcon />} title="Alerts" to="/alerts" />*/}
          {HAS_QUICK_ACTION && (
            <MainMenuItem icon={<LightingIcon />} title="Quick actions" to="/dashboards/insights/" />
          )}
          {!HAS_MULTILEVEL_ANALYTICS && (
            <>
              <MainMenuItem icon={<InsightIcon />} title="Troubleshoot" to="/dashboards/insight" />
              <MainMenuItem icon={<DollarIcon />} title="Savings result" to="/reports" />
              <MainMenuItem icon={<RecommendationIcon />} title="Analytics" to="/dashboards/analytics" />
            </>
          )}
          {HAS_MULTILEVEL_ANALYTICS && (
            <MultiMenu
              baseUrl={{ name: "Analytics", url: "/dashboards/analytics/general" }}
              urls={[
                { name: "General", url: "/dashboards/analytics/general" },
                { name: "Troubleshoot", url: "/dashboards/analytics/insight" },
              ]}
              icon={<RecommendationIcon />}
              title="Analytics"
              MainMenuItemSize={MainMenuItemSize.Small}
              ItemsWrapperClassName="ml-[25px]"
            />
          )}
          <MainMenuItem icon={<AuditIcon />} title="Audits" to="/audits" />
        </div>
        <hr className="border-text-lightBlack" />
        <MainMenuItem title="Alerts" icon={<AlertIcon />} to="/multiCluster/alerts" />
        <MainMenuItem title="Cost report" icon={<DollarIcon />} to="/multiCluster/cost" />
        <MultiClusterMenu />
      </div>
      <div className="h-fit">
        <div className="py-2 flex flex-col gap-5">
          <MainMenuItem icon={<BasicSettingsIcon />} title="Settings" to="/settings" />
          <MainMenuItem
            icon={<DocsIcon />}
            title="Docs"
            to={`https://docs.scaleops.com/docs${config.token ? "?token=" + config.token : ""}`}
            isExternalLink
          />
          {config?.ssoConf?.provider && config.ssoConf?.provider === "simpleAuth" && (
            <button
              className="flex justify-start items-center gap-2 rounded-lg px-4 py-1 text-white hover:bg-mainMenuBackgroundHover text-[14px]"
              onClick={logout}
              id="log-out"
            >
              <LogoutIcon width={17} height={17} className="ml-[2px]" />
              <span className="ml-[-2px]">Log out</span>
            </button>
          )}
          <div className="flex gap-2 items-center">
            <Tooltip title={version} className="truncate cursor-default w-fit max-w-[100%] pl-[17px]">
              <Typography variant="caption" fontWeight={400}>
                {version}
              </Typography>
            </Tooltip>
            <UpgradeVersion />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;
