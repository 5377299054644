import clsx from "clsx";
import { components } from "../../../api/schema";
import HAPWorkload from "../../../components/WorkloadStatusByNamespace/HAPWorkload";
import { METRIC_BOX_CLASS_NAME } from "./utils";

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  runningReplicas?: number;
  replicas?: number;
  className?: string;
}

const NumberOfReplicas = ({ runningReplicas, replicas, className, selectedWorkload }: Props) => {
  const hasData = runningReplicas !== undefined || replicas !== undefined;

  return (
    <div
      className={clsx("px-0 py-0 flex justify-center items-center gap-1", className, METRIC_BOX_CLASS_NAME, {
        "bg-guideline-lightGray animate-pulse": !hasData,
      })}
    >
      <HAPWorkload workload={selectedWorkload} iconSize={13} className="w-full h-full">
        {hasData && `Replicas: ${runningReplicas ?? ""} / ${replicas ?? ""}`}
      </HAPWorkload>
    </div>
  );
};

export default NumberOfReplicas;
