import { memo } from "react";
import { useWorkloadsContext } from "../../../WorkloadsContext";
import { components } from "../../../api/schema";
import Button from "../../../components/Button";
import Tooltip from "../../../components/Tooltip";
import YouHaveReadOnlyAccess from "../../../components/YouHaveReadOnlyAccess";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import { Policy } from "./PolicyTuning";
import useUpdateRecommendation from "./mutation/useUpdateRecommendation";
import { Typography } from "@mui/material";

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  selectedPolicy: Policy | undefined;
}

const SaveButtonContainer = memo(({ selectedWorkload, selectedPolicy }: Props) => {
  const isEditable = selectedWorkload?.isEditable;
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const updateRecommendation = useUpdateRecommendation();

  const { updateOverriddenWorkloads } = useWorkloadsContext();

  const handleSave = () => {
    if (selectedPolicy?.name == selectedPolicy?.displayName) {
      updateOverriddenWorkloads({
        ids: [selectedWorkload.id],
        props: {
          policyName: selectedPolicy?.name,
        },
      });
      updateRecommendation.mutate({
        name: `${selectedWorkload.type}-${selectedWorkload.workloadName}`.toLowerCase(),
        namespace: selectedWorkload.namespace,
        policyName: String(selectedPolicy?.displayName),
      });
    }
  };

  const tooltipContent = isReadyOnlyFrontEnd ? <YouHaveReadOnlyAccess /> : "Apply and save changes";

  return (
    <Tooltip
      title={
        isEditable ? (
          tooltipContent
        ) : (
          <Typography variant="caption">
            You don't have <b>permissions</b> to save this workload.
          </Typography>
        )
      }
      maxWidth={500}
    >
      <Button label="Save" onClick={handleSave} disabled={isReadyOnlyFrontEnd || !isEditable} />
    </Tooltip>
  );
});

export default SaveButtonContainer;
