import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { GetRebalanceStatus, GetRebalanceStatusResponse, RebalanceSpec, SetRebalanceSpec } from "../../../api/fetcher";
import { TOAST_SETTINGS } from "../../../pages/Roles/mutations/utils";
import { ConsolidationState } from "./utils";

const setRebalanceSpec = SetRebalanceSpec();
const getRebalanceStatus = GetRebalanceStatus();

const useSetRebalanceSpec = () => {
  const queryClient = useQueryClient();

  return useMutation((spec: RebalanceSpec) => setRebalanceSpec.queryFn(spec), {
    onMutate: (spec: RebalanceSpec) => {
      queryClient.setQueryData([getRebalanceStatus.queryKey], (oldData: GetRebalanceStatusResponse | undefined) => {
        return {
          ...oldData,
          status: {
            ...oldData?.status,
            state: ConsolidationState.Pending,
          },
          spec: spec,
        };
      });
    },
    onError: () => {
      toast.error(`Error while setting rebalance plan`, TOAST_SETTINGS);
    },
    onSuccess: () => {
      toast.success("Rebalance plan was modified successfully", TOAST_SETTINGS);
    },
    onSettled: () => {
      queryClient.invalidateQueries([getRebalanceStatus.queryKey]);
    },
  });
};

export default useSetRebalanceSpec;
