import { ListItemIcon, Typography } from "@mui/material";
import BulkAutomationIcon from "../../Icons/BulkAutomationIcon";

const AutomateMenuItemDiv = () => (
  <div className="flex gap-2 justify-start items-center h-[28px] bg-guideline-darkGreen w-full rounded-lg text-white pl-[22px] hover:bg-guideline-darkerGreen scaleopsShadow hover:shadow-none">
    <ListItemIcon>
      <BulkAutomationIcon className="text-white" />
    </ListItemIcon>
    <Typography variant="body2" fontWeight={700}>
      Automate
    </Typography>
  </div>
);

export default AutomateMenuItemDiv;
