import dayjs from "dayjs";
import numeral from "numeral";
import prettyBytes from "pretty-bytes";

export const DAY_MONTH_FORMAT = "MMM DD";
export const DEFAULT_DATE_FORMAT = "MMM DD, YYYY";
export const DEFAULT_TIME_FORMAT = "HH:mm";
export const DEFAULT_DATE_TIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;

export const getTimeFormatFromEpochAndPeriodInHours = (tick: number, selectedViewPeriod: string | number) => {
  const viewPeriod = Number(selectedViewPeriod);
  return dayjs
    .unix(Number(tick))
    .format(`${viewPeriod > 13 ? DAY_MONTH_FORMAT : ""} ${viewPeriod < 336 ? DEFAULT_TIME_FORMAT : ""}`);
};

export enum TimeStandard {
  UTC = "UTC",
  LOCAL = "Local time",
}

export function CpuFormatter() {
  const cpuFormatter = new Intl.NumberFormat("en-Us", {
    maximumFractionDigits: 3,
  });
  return cpuFormatter;
}

export function CpuReportFormatter() {
  return new Intl.NumberFormat("en-Us", {
    maximumFractionDigits: 3,
  });
}

export function CpuReportFormatterDigits0() {
  return new Intl.NumberFormat("en-Us", {
    maximumFractionDigits: 0,
  });
}

export function CurrencyFormatter() {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
}

function memoryFormatter() {
  const format = (value?: number): string => {
    return prettyBytes(value || 0.0, { bits: false, binary: true, maximumFractionDigits: 2 });
  };
  return {
    format,
  };
}

export function MemoryFormatter(): { format: (value?: number) => string } {
  return memoryFormatter();
}

export const numberFormatter = (value: number | string, hasMetricPrefix?: boolean, numberOfDecimalPoints?: number) => {
  let num: number | string = Number(value);

  if (hasMetricPrefix) {
    num = num > 1000 ? numeral(value).format("0,0.00[0]a") : value;
  }

  if (typeof num === "number" && num % 1 !== 0) {
    return Number(num.toFixed(numberOfDecimalPoints ?? 3));
  }

  return Number(num);
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const camelCaseToSnakeSpace = (str: string) => {
  return str.replace(/([A-Z])/g, " $1").trim();
};

export const spacedCamelCase = (str: string) => {
  return str.replace(/([A-Z])/g, " $1").toLowerCase();
};

export const customNumberFormatter = (value: number, aThreshold = 10000) => {
  const absValue = Math.abs(value);

  let returnValue: number | string;

  switch (true) {
    case absValue < 10 && absValue > 0:
      returnValue = Math.round(value * 100) / 100;
      break;
    case absValue >= 10 && absValue < aThreshold:
      returnValue = numeral(value).format("0,0");
      break;
    default:
      returnValue = numeral(value).format("0a").toLocaleUpperCase();
  }

  return returnValue;
};

export const roundedOrTwoDecimal = (value: number | undefined | null | React.ReactNode) => {
  let displayValue;

  switch (true) {
    case !Number.isNaN(Number(value)) && Number(value) % 1 === 0:
      displayValue = numeral(value).format("0,0");
      break;
    case !Number.isNaN(Number(value)) && Number(value) % 1 !== 0:
      displayValue = numeral(value).format("0,0.00");
      break;
    default:
      displayValue = value;
  }

  return displayValue;
};

export const currencyFormatter = (value: number) => {
  const absValue = Math.abs(value);
  if (!absValue || absValue < 0.01) return "$0";
  return absValue >= 0.01 && absValue < 10 && absValue % 1 !== 0
    ? numeral(value).format("$0,0.00")
    : numeral(value).format("$0,0");
};
