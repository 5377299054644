import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  GetCostBreakdown,
  GetCostBreakdownAggregations,
  GetCostBreakdownAggregationsResponse,
  GetCostBreakdownResponse,
} from "../../../api/fetcher";
import GraphCircularLoader from "../../../components/GraphCircularLoader";
import useAggregationFilters from "../Tables/Aggregation/hooks/useAggregationFilters";
import { TableType } from "../Tables/utils";
import useWorkloadsFilters from "../Tables/Workloads/hooks/useWorkloadsFilters";
import EntireWindowChart from "./EntireWindowChart";
import { EntireWindowData } from "./utils";

const OTHERS = "Others";
const TOP_K = 9;

const { queryKey, queryFn } = GetCostBreakdown();
const { queryKey: aggregationQueryKey, queryFn: aggregationQueryFn } = GetCostBreakdownAggregations();
interface Props {
  selectedTable: TableType;
}

const EntireWindowChartContainer = ({ selectedTable }: Props) => {
  const workloadsFilters = useWorkloadsFilters();
  const workloadsFiltersLength = Object.keys(workloadsFilters).length;
  const aggregationFilters = useAggregationFilters();
  const aggregationFiltersLength = Object.keys(aggregationFilters).length;

  const [graphData, setGraphData] = useState<EntireWindowData[]>([]);

  const { data, isLoading, error } = useQuery<GetCostBreakdownResponse, Error>({
    queryKey: ["entirewindow", queryKey, workloadsFilters, TOP_K],
    queryFn: () =>
      queryFn({
        ...workloadsFilters,
        topk: TOP_K,
        multiCluster: true,
      }),
    enabled: selectedTable === TableType.Workloads && !!workloadsFiltersLength,
  });

  const {
    data: aggregationData,
    isLoading: aggregationIsLoading,
    error: aggregationError,
  } = useQuery<GetCostBreakdownAggregationsResponse, Error>({
    queryKey: ["entirewindow", aggregationQueryKey, aggregationFilters, TOP_K],
    queryFn: () =>
      aggregationQueryFn({
        ...aggregationFilters,
        topk: TOP_K,
        multiCluster: true,
      }),
    enabled: selectedTable === TableType.Aggregation && !!aggregationFiltersLength,
  });

  useEffect(() => {
    if (data || aggregationData) {
      let rawData;
      switch (selectedTable) {
        case TableType.Workloads:
          rawData = data?.costs?.[0]?.costs ?? [];
          break;
        case TableType.Aggregation:
          rawData = aggregationData?.costs?.[0]?.costs ?? [];
          break;
        default:
          break;
      }

      if (rawData) {
        let graphData = rawData.map((d) => ({
          name: String(d.id),
          cost: d.value ? Number(d.value) : 0,
        }));

        graphData = graphData.sort((a, b) => {
          if (a.name === "timestamp") {
            return -1;
          } else if (b.name === "timestamp") {
            return 1;
          } else if (a.name === OTHERS) {
            return 1;
          } else if (b.name === OTHERS) {
            return -1;
          } else {
            return b.cost - a.cost;
          }
        });

        setGraphData(graphData);
      }
    }
  }, [data, aggregationData, selectedTable]);

  if (error || aggregationError) {
    console.log("Error fetching costs", error || aggregationError);
  }

  return (
    <div className="relative flex items-center align-center w-full h-full">
      {(isLoading && selectedTable === TableType.Workloads) ||
      (aggregationIsLoading && selectedTable === TableType.Aggregation) ? (
        <GraphCircularLoader />
      ) : null}
      <EntireWindowChart data={graphData} />
    </div>
  );
};

export default EntireWindowChartContainer;
