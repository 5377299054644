import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Alert, AlertProps, Checkbox, Menu, MenuItem, Paper, Snackbar, Switch } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
  GridRowsProp,
  GridSortDirection,
} from "@mui/x-data-grid";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import { ApiResponse } from "openapi-typescript-fetch/dist/cjs/types";
import pluralize from "pluralize";
import prettyBytes from "pretty-bytes";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { toast } from "react-toastify";
import { ArrayParam, BooleanParam, StringParam, useQueryParam } from "use-query-params";
import { useWorkloadsContext } from "../../WorkloadsContext";
import { ScaleOps } from "../../api/api";
import {
  getConfig,
  GetConfigResponse,
  getDashboardByNamespace,
  GetDashboardByNamespaceResponse,
} from "../../api/fetcher";
import { components } from "../../api/schema";
import PolicyTuning from "../../pages/Overview/PolicyTuning/PolicyTuning";
import useResetRecommendation from "../../pages/Overview/PolicyTuning/mutation/useResetRecommendation";
import useUpdateAutomation from "../../pages/Overview/PolicyTuning/mutation/useUpdateAutomation";
import { FeatureEnabled, IsBigCluster } from "../../utils/FeaturesHelper";
import { ApplyRecommendation } from "../../utils/configFetcherUtils";
import * as formatterUtils from "../../utils/formatterUtils";
import { FOOTER_HEIGHT, getDataGridSx, HEADER_HEIGHT, ROW_HEIGHT, SWITCH_SX } from "../../utils/styleUtils";
import DefaultFallback from "../DefaultFallback";
import { default as CustomTooltip, default as Tooltip } from "../Tooltip";
import WarningPopup from "../warningPopup/WarningPopup";
import EditToolbar, {
  enableFilterByUrlParam,
  ExtendedOverviewTableColumns,
  FilterByUrlParam,
  OverviewTableColumns,
} from "./BulkPolicyEditorToolbar";
import GitConfigPopup from "./GitConfigPopup";
// import useUpdateAutomationExclude from "./useUpdateAutomationExclude";
import CheckIcon from "../../Icons/CheckIcon";
import CreativeInfoIcon from "../../Icons/CreativeInfoIcon";
import InfoIcon from "../../Icons/InfoIcon";
import LockIcon from "../../Icons/LockIcon";
import RightArrowIcon from "../../Icons/RightArrowIcon";
import ScaleopsIcon from "../../Icons/ScaleopsIcon";
import SelectedCheckBoxIcon from "../../Icons/SelectedCheckBoxIcon";
import WarningIcon from "../../Icons/WarningIcon";
import WasteIcon from "../../Icons/WasteIcon";
import useGetApi from "../../api/useGetApi";
import { SCALEOPS_COLORS } from "../../colors";
import { isGitSyncEnabled } from "../../utils/configUtils";
import useIsReadOnlyFrontEnd from "../../utils/useIsReadyOnlyFrontEnd";
import useStateWithLocalStorage from "../../utils/useStateWithLocalStorage";
import useStateWithSessionStorage from "../../utils/useStateWithSessionStorage";
import ScaleOpsSpan from "../ScaleOpsSpan";
import ValueChip from "../ValueChip";
import YouHaveReadOnlyAccess from "../YouHaveReadOnlyAccess";
import useWindowSize from "../useWindowSize";
import AutomateYourFirstWorkloadTooltip from "./AnimateYourFirstWorkloadTooltip";
import AutomationTooltip from "./AutomationTooltip";
import ExploreYourWorkloadMessage from "./ExploreYourWorkloadMessage";
import HAPWorkload from "./HAPWorkload";
import NodeActionRecommendation from "./NodeActionRecommendation";
import OverviewExportCSVButton from "./OverviewExportCSVButton";
import PolicyCell from "./PolicyCell";
import { getWorkloadType, OverviewTableFields, WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "./overviewUtils";
import useAttachPolicyToWorkload from "./useAttachPolicyToWorkload";
import useFilterQueryParams, { FilterParamObject } from "./useFilterQueryParams";
import { useUpdateNamespaceInBulk } from "./useUpdatePolicyByNamespace";
import { roundToTwoDecimal } from "../autoscalers/nodesCostUtils";

dayjs.extend(dayjsPluginUTC);

const OVERVIEW_PAGE_SIZE_LOCAL_STORAGE_KEY = "overviewPageSize";
const ENABLE_FIRST_ANIMATION_CTA_FF = true;
const HAS_NODE_ACTIONS_RECOMMENDATION_FF = false;
const ACTIVE_TOGGLE_PING_SIZE = 10;
const HAS_OVERRIDE_WORKLOADS_ENABLED = false;
const SELECTED_COLUMNS_LOCAL_STORAGE_KEY = "selectedOverviewPageColumns";
const ASC = "asc";
const DESC = "desc";
const STOP_ANIMATION_THRESHOLD = 200;
const FOUR_DAYS_IN_SEC = 4 * 24 * 60 * 60;
const MAX_CLICKS_TO_SHOW_ROW_PULSE = 3;
const ENABLE_HPA_RECOMMENDATION = enableFilterByUrlParam(FilterByUrlParam.ENABLE_HPA_RECOMMENDATION);

const { queryKey: getConfigQueryKey, queryFn: getConfigQueryFn } = getConfig();

const INITIAL_SORT_MODEL = [
  {
    field: String(OverviewTableFields.SavingsAvailable),
    sort: DESC as GridSortDirection,
  },
];

const numericSort = (
  workLoadsToSet: components["schemas"]["UtilsWorkload"][],
  sortDirection: string,
  property: keyof components["schemas"]["UtilsWorkload"],
  shouldShowInactiveLast?: boolean
) =>
  workLoadsToSet.sort((a, b) => {
    const aValue = Number(a[property]);
    const bValue = Number(b[property]);
    if (shouldShowInactiveLast && a.isReadyRecommendation !== b.isReadyRecommendation) {
      return a.isReadyRecommendation ? -1 : 1;
    }
    if (aValue < bValue) {
      return sortDirection === ASC ? -1 : 1;
    } else if (aValue > bValue) {
      return sortDirection === ASC ? 1 : -1;
    } else {
      return -2;
    }
  });

const setInitialSelectedColumns = ({
  localStorageSelectedColumns,
  initialColumns,
  availableColumns,
}: {
  localStorageSelectedColumns: string[];
  initialColumns: string[];
  availableColumns: string[];
}) =>
  localStorageSelectedColumns &&
    localStorageSelectedColumns.length > 0 &&
    localStorageSelectedColumns.every((c) => availableColumns.includes(c as OverviewTableColumns))
    ? localStorageSelectedColumns
    : initialColumns;

const WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE = (
  <>
    Click to open <b className="text-primary-purpleBlue">Workload Overview</b>.
    <br />
    <br />
    Examine workload <b>resource allocation and events</b> for a comprehensive view.
  </>
);
const WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY = 1000;

const getLowerCaseWorkloadName = (workload: components["schemas"]["UtilsWorkload"]) => {
  return `${workload.namespace}/${workload.workloadName}`.toLowerCase();
};

export const getDisplayWorkloadName = (name: string, hideSuffix?: boolean) =>
  name.replace(/^scaleops-rollout-/, "").replace(hideSuffix ? /-(?!.*-).*$/ : "", "");

const initialColumns = !ENABLE_HPA_RECOMMENDATION
  ? [
    // OverviewTableColumns.Workload,
    OverviewTableColumns.SavingsAvailable,
    // OverviewTableColumns.ActiveSavings,
    OverviewTableColumns.CpuRequests,
    // OverviewTableColumns.OriginalCpuRequests,
    OverviewTableColumns.MemoryRequests,
    // OverviewTableColumns.OriginalMemoryRequests,
    OverviewTableColumns.Replicas,
    OverviewTableColumns.Policy,
    // OverviewTableColumns.Actions,
    // OverviewTableColumns.Automated,
  ]
  : [
    OverviewTableColumns.CpuRequests,
    ExtendedOverviewTableColumns.IsIdleStable,
    ExtendedOverviewTableColumns.MinReplicasDiff,
    ExtendedOverviewTableColumns.PredictableSavings,
    ExtendedOverviewTableColumns.PredictionErrorScore,
    ExtendedOverviewTableColumns.ReplicasActiveSavings,
    ExtendedOverviewTableColumns.ReplicasAvailableSavings,
    ExtendedOverviewTableColumns.ReplicasDiff,
    ExtendedOverviewTableColumns.StableSavings,
    ExtendedOverviewTableColumns.PredictableSavings,
  ];

const getPercentageOfOptimizedReplicas = (
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>
) => (params.row.replicas ? Math.round((params.row?.optimizedReplicas ?? 0 / params.row.replicas) * 100) : 0);

const availableColumns = ENABLE_HPA_RECOMMENDATION
  ? Object.values(ExtendedOverviewTableColumns)
  : Object.values(OverviewTableColumns);

interface DashOverUnderProvisioningFilters {
  overProvisioned?: boolean;
  underProvisioned?: boolean;
  priorityClassName?: string;
  optimizationGap?: string;
  namespace?: string;
  labels?: string;
}

type DashWorkloadWithIssues = components["schemas"]["UtilsWorkload"] & {
  issues?: components["schemas"]["DashIssues"];
} & DashOverUnderProvisioningFilters;

export interface WorkloadTableFilter {
  type: string;
  valueName: string;
}

export interface WorkloadTableFilterToHandle {
  type: string;
  namespaces: string[];
  labels: string[];
  annotations: string[];
  priorityClass: string[];
  optimizationGaps: string[];
  hpaPredictable: string[];
}

interface Props {
  setDisableAnimation: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function WorkloadStatusByNamespaces({ setDisableAnimation }: Props) {
  const api = useGetApi();
  const windowSize = useWindowSize();
  const dashboardByNamespace = getDashboardByNamespace();
  const isBigCluster = IsBigCluster();

  const {
    overriddenWorkloads,
    overriddenWorkloadsIds,
    setSelectedNamespaceIds,
    excludeFromNamespaceAutomationIds,
    setExcludeFromNamespaceAutomationIds,
    updateOverriddenWorkloads,
    updateOverriddenWorkloadsByUpdateFunction,
    deleteOverriddenWorkloadsProperties,
  } = useWorkloadsContext();

  const isReadOnlyFrontEnd = useIsReadOnlyFrontEnd();

  // const updatePolicyByNamespace = useUpdatePolicyByNamespace();
  const updateNamespaceBulk = useUpdateNamespaceInBulk();

  const originalOrder = useRef<(string | undefined)[]>([]);

  const [lastTimeWorkloadRowWasClicked, setLastTimeWorkloadRowWasClicked] = useStateWithLocalStorage<number>({
    localStorageKey: "lastTimeWorkloadRowWasClicked",
    defaultValue: 0,
  });

  const [workloadRowClickCount, setWorkloadRowClickCount] = useStateWithLocalStorage<number>({
    localStorageKey: "workloadRowClickCount",
    defaultValue: 0,
  });

  const [didUserAutomateFirstWorkload, setDidUserAutomateFirstWorkload] = useStateWithSessionStorage<boolean>({
    sessionStorageKey: "didUserAutomateFirstWorkload",
    defaultValue: false,
    valueFormatter: (value) => value === "true",
  });

  const [selectAllOnInitialLoading, setSelectAllOnInitialLoading] = useQueryParam(
    "selectAllOnInitialLoading",
    BooleanParam
  );

  const [highlightedSelection, setHighlightedSelection] = useState<string | undefined>();
  const [searchTerms] = useQueryParam("searchTerms", ArrayParam);
  const [currentSearchTerm] = useQueryParam("currentSearchTerm", StringParam);
  const [namespacesParams] = useQueryParam("namespaces", ArrayParam);
  const [isNamespacesExclude] = useQueryParam("isNamespacesExclude", BooleanParam);
  const [policiesParams] = useQueryParam("policy", ArrayParam);
  const [isAutomated] = useQueryParam("isAutomated", BooleanParam);
  const [isUnAutomated] = useQueryParam("isUnAutomated", BooleanParam);
  const [hasMinReplicasSavings] = useQueryParam("hasMinReplicasSavings", BooleanParam);
  const [hasHpa] = useQueryParam("hasHpa", BooleanParam);
  const [isHpaPredictable] = useQueryParam("isHpaPredictable", BooleanParam);
  const [isOverProvisioned] = useQueryParam("overProvisioned", BooleanParam);
  const [isUnderProvisioned] = useQueryParam("underProvisioned", BooleanParam);
  const [isPdb] = useQueryParam("isPdb", BooleanParam);
  const [outOfMemory] = useQueryParam("outOfMemory", BooleanParam);
  const [priorityClassNamesParams] = useQueryParam("priorityClassNames", ArrayParam);
  const [optimizationGapsParams] = useQueryParam("optimizationGaps", ArrayParam);
  const [availableSavingsFilter] = useQueryParam("availableSavingsFilter", StringParam);
  const [selectedLabelNames] = useQueryParam("labels", ArrayParam);
  const [typesParams] = useQueryParam("types", ArrayParam);
  const [sortField, setSortField] = useQueryParam("sortField", StringParam);
  const [sortDirection, setSortDirection] = useQueryParam("sortDirection", StringParam);
  const [isInitialSortModel, setIsInitialSortModel] = useState<boolean>(true);

  const [namespaceAnnotationParam] = useQueryParam("namespaceAnnotation", ArrayParam);

  const [namespaceAnnotationLogical] = useQueryParam("namespaceAnnotationLogical", StringParam);
  const [namespaceAnnotationExclude] = useQueryParam("isNamespaceAnnotationExclude", BooleanParam);
  const [openFirstRowWorkloadOverview, setOpenFirstRowWorkloadOverview] = useQueryParam(
    "openFirstRowWorkloadOverview",
    BooleanParam
  );

  const resetRecommendation = useResetRecommendation();

  const attachPolicyToWorkload = useAttachPolicyToWorkload();

  const localStorageSelectedColumns = JSON.parse(
    localStorage.getItem(SELECTED_COLUMNS_LOCAL_STORAGE_KEY) as string
  ) as string[];
  const [selectedColumns, setSelectedColumns] = useState<(string | undefined)[]>(
    setInitialSelectedColumns({
      localStorageSelectedColumns,
      initialColumns,
      availableColumns,
    })
  );

  const defaultWorkloadsFetchIntervalMs = 12000;
  const demoVersionWorkloadsFetchIntervalMs = 5000;

  const [workloadsLabels, setWorkloadsLabels] = useState<string[]>([]);
  const [workloadsAnnotations, setWorkloadsAnnotations] = useState<string[]>([]);
  const [namespaceAnnotations, setNamespaceAnnotations] = useState<string[]>([]);

  const [rows, setRows] = useState<GridRowsProp<components["schemas"]["UtilsWorkload"]>>([]);
  const [selectAllRowsOnInitialLoad, setSelectAllRowsOnInitialLoad] = useQueryParam(
    "selectAllRowsOnInitialLoad",
    BooleanParam
  );

  const [firstRowId, setFirstRowId] = useState<string | undefined>();
  const [rowsWasInitialized, setRowsWasInitialized] = useState<boolean>(false);
  const [allRows, setAllRows] = useState<GridRowsProp<components["schemas"]["UtilsWorkload"]>>([]);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>({
    localStorageKey: OVERVIEW_PAGE_SIZE_LOCAL_STORAGE_KEY,
    defaultValue: 25,
    valueFormatter: (value) => parseInt(value),
  });

  const [rowCount, setRowCount] = useState<number>(0);

  const [policies, setPolicies] = useState<components["schemas"]["V1alpha1Policy"][]>([]);

  const [policyNames, setPolicyNames] = useState<string[]>([]);

  const [selectedRows, setSelectedRows] = useState<components["schemas"]["UtilsWorkload"][] | undefined>(undefined);

  const [gitWorkflow, setGitWorkflow] = useState<components["schemas"]["UtilsWorkload"] | undefined>();

  const [snackbar, setSnackbar] = useState<(Pick<AlertProps, "severity"> & { children: { message?: string } }) | null>(
    null
  );
  const handleCloseSnackbar = () => setSnackbar(null);

  const [namespacesForFilter, setNamespacesForFilter] = useState<string[]>([]);
  const [typesForFilter, setTypesForFilter] = useState<string[]>([]);
  const [priorityClassNames, setPriorityClassNames] = useState<string[]>([]);
  const [optimizationGaps, setOptimizationGaps] = useState<string[]>([]);

  const [workloadFetchIntervalMs, setWorkloadFetchIntervalMs] = useState<number>(defaultWorkloadsFetchIntervalMs);

  const [isPolicyTuningDialogOpen, setIsPolicyTuningDialogOpen] = useState<boolean>(false);

  const [selectedWorkloadOverviewId, setSelectedWorkloadOverviewId] = useQueryParam(
    "selectedWorkloadOverviewId",
    StringParam
  );
  const [selectedWorkload, setSelectedWorkload] = useState<components["schemas"]["UtilsWorkload"] | undefined>(
    undefined
  );

  const [hideSuffix, setHideSuffix] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const filterQueryParams: FilterParamObject = useFilterQueryParams();

  const { data: configData } = useQuery<GetConfigResponse, Error>({
    queryKey: [getConfigQueryKey],
    queryFn: getConfigQueryFn,
  });

  const wasInstalledInTheLast3Days =
    !!configData?.installDate && dayjs().diff(dayjs(configData.installDate), "day") <= 3;

  const { data, isLoading } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey, filterQueryParams],
    queryFn: () => dashboardByNamespace.queryFn(filterQueryParams),
    refetchInterval: workloadFetchIntervalMs,
  });

  useEffect(() => {
    if (selectedWorkloadOverviewId && selectedWorkloadOverviewId !== selectedWorkload?.id) {
      const selectedWorkload = data?.workloads?.find((wl) => wl.id === selectedWorkloadOverviewId);
      if (selectedWorkload) {
        setSelectedWorkload(selectedWorkload);
        setIsPolicyTuningDialogOpen(true);
      }
    }
  }, [selectedWorkloadOverviewId, rows]);

  useEffect(() => {
    if (openFirstRowWorkloadOverview && rows && rows.length > 0 && rows[0].id) {
      setOpenFirstRowWorkloadOverview(false);
      setSelectedWorkloadOverviewId(rows[0].id);
    }
  }, [rows, openFirstRowWorkloadOverview]);

  useEffect(() => {
    const hideReadOnlyToaster = localStorage.getItem("hideReadOnlyToaster");

    if (
      wasInstalledInTheLast3Days &&
      data?.totalNamespaceSummary?.automatedWorkloads === 0 &&
      !hideReadOnlyToaster &&
      !didUserAutomateFirstWorkload
    ) {
      localStorage.setItem("hideReadOnlyToaster", "true");

      toast.success(
        <div className="flex flex-col gap-2 relative">
          <Typography variant="body2" className="flex gap-1 justify-center text-black" fontWeight={700}>
            <ScaleopsIcon
              width={30}
              height={30}
              className="hide info-icon-animation fixed top-[-8px] left-[-8px]"
              style={{ zIndex: 99999 }}
            />
            <span className="text-guideline-darkPurple flex gap-1">ScaleOps</span> is running in{" "}
            <span className="text-guideline-darkPurple flex gap-1">Read-Only</span>
          </Typography>
          <Typography variant="caption" className="text-black">
            <div className="text-center">
              Explore and discover the full potentials of your cluster.
              <br />
              <br />
              To activate the optimization, <br />
              <b>start automating your workloads</b>.
            </div>
          </Typography>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000,
          icon: false,
          style: {
            background: "#F5F5F5",
            width: 450,
          },
        }
      );
    }
  }, [wasInstalledInTheLast3Days, data, didUserAutomateFirstWorkload]);

  useEffect(() => {
    if (selectedRows) {
      const selectedRowsToSet = selectedRows.filter((row) => {
        return data?.workloads?.find((wl) => wl.id === row.id) !== undefined;
      });

      setSelectedRows(selectedRowsToSet);
    }
  }, [data?.workloads]);

  useEffect(() => {
    setSelectedRows([]);
  }, [currentSearchTerm, searchTerms]);

  useEffect(() => {
    const filterQueryParamsKeys = Object.keys(filterQueryParams);
    if (selectAllOnInitialLoading && allRows && allRows.length > 0 && filterQueryParamsKeys.length > 0) {
      setSelectedRows(allRows as components["schemas"]["UtilsWorkload"][]);
      setSelectAllOnInitialLoading(false);
    }
  }, [allRows, filterQueryParams]);

  useEffect(() => {
    setSelectedRows([]);
  }, [filterQueryParams]);

  useEffect(() => {
    if (selectedColumns) {
      localStorage.setItem(SELECTED_COLUMNS_LOCAL_STORAGE_KEY, JSON.stringify(selectedColumns));
    }
  }, [selectedColumns]);

  useEffect(() => {
    api
      .getFetcher()
      .path("/api/v1/labels/")
      .method("get")
      .create()({})
      .then((response) => {
        if (response.ok) {
          if (response.data.priorityClasses !== undefined) {
            setPriorityClassNames(response.data.priorityClasses);
          }
          if (response.data.optimizationEvents !== undefined) {
            setOptimizationGaps(response.data.optimizationEvents);
          }
          if (response.data.labels !== undefined) {
            const labels = [...response.data.labels, ...(response.data?.ownerLabels ?? [])];
            let uniqueLabels = [...new Set(labels)];

            const keyAndValueCombinationsToExclude = ["app.kubernetes.io/instance=scaleops"];

            uniqueLabels = uniqueLabels.filter((label) => {
              if (keyAndValueCombinationsToExclude.includes(label)) {
                return false;
              }
              return true;
            });

            setWorkloadsLabels(uniqueLabels);
          }
          if (response.data.annotations !== undefined) {
            const ownerAnnotations = response.data?.ownerAnnotations ?? [];
            const annotations = [...response.data.annotations, ...ownerAnnotations];
            let uniqueAnnotations = [...new Set(annotations)];

            const keyAndValueCombinationsToExclude = [
              "app.kubernetes.io/instance=scaleops",
              "app.kubernetes.io/managed-by=Helm",
              "app.kubernetes.io/name=scaleops",
            ];

            const keysToExclude = [
              "kubectl.kubernetes.io/last-applied-configuration",
              "deployment.kubernetes.io/revision",
              "kubectl.kubernetes.io/restartedAt",
              "scaleops.sh/post39",
            ];

            uniqueAnnotations = uniqueAnnotations.filter((annotation: string) => {
              const key = annotation.split("=")[0];

              if (keyAndValueCombinationsToExclude.includes(annotation)) {
                return false;
              }

              return !keysToExclude.includes(key);
            });

            setWorkloadsAnnotations(uniqueAnnotations);
          }
          if (response.data.types !== undefined) {
            setTypesForFilter(response.data.types);
          }
        }
      })
      .catch((error) => console.error(error));
  }, [api]);

  // const updateNamespaceAutomation = useUpdateNamespaceAutomation();

  const [namespaceObjects, setNamespaceObjects] = useState<components["schemas"]["V1Namespace"][]>([]);

  useEffect(() => {
    api
      .getFetcher()
      .path("/api/v1/namespaces/")
      .method("get")
      .create()({})
      .then((response) => {
        if (response.ok) {
          if (response.data.namespaces != undefined) {
            setNamespaceObjects(response.data.namespaces);
            setNamespacesForFilter(
              response.data.namespaces
                .filter((ns) => ns !== undefined)
                .map((ns: components["schemas"]["V1Namespace"]) => ns.metadata?.name ?? "")
                .filter((ns) => ns != "")
            );
          }
        }
      })
      .catch((error) => console.error(error));

    if (FeatureEnabled("DemoVersion")) {
      setWorkloadFetchIntervalMs(demoVersionWorkloadsFetchIntervalMs);
    }
  }, [api]);

  useEffect(() => {
    api
      .getFetcher()
      .path("/api/v1/namespaces/annotations")
      .method("get")
      .create()({})
      .then((response) => {
        setNamespaceAnnotations(response?.data?.annotations || []);
      })
      .catch((error) => console.error(error));
  }, [api]);

  const handleProcessRowUpdateError = useCallback((message: string) => {
    setSnackbar({ children: { message }, severity: "error" });
  }, []);

  const handleProcessRowUpdateSuccess = useCallback((message: string | React.ReactNode) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  }, []);

  const cpuFormatter = formatterUtils.CpuFormatter();

  useEffect(() => {
    // TODO - reffer that fact that workloads are now already filtered in backend. namespacesParams can be ignored
    if (namespacesParams && namespacesParams.length === 1) {
      setSelectedNamespaceIds(
        data?.workloads?.filter((w) => w.namespace === namespacesParams[0]).map((w) => w.id) ?? []
      );
    }
  }, [namespacesParams, data]);

  useEffect(() => {
    if (data) {
      setExcludeFromNamespaceAutomationIds(
        data.workloads?.filter((w) => w.isAutomationExcluded || !w.isReadyRecommendation).map((w) => w.id) ?? []
      );
    }
  }, [data]);

  const fetchWorkloads = () => {
    queryClient.invalidateQueries([dashboardByNamespace.queryKey, filterQueryParams]);
  };

  const fetchWorkloadsProp = useCallback(fetchWorkloads, []);

  const updateAutomation = useUpdateAutomation((usAutomated: boolean) => {
    handleProcessRowUpdateSuccess(
      <span className={"flex"}>
        Workload was {usAutomated ? "automated" : "unautomated"} successfully
        {usAutomated && <ScaleopsIcon width={13} height={13} className="absolute left-[135px] top-[44px]" />}
      </span>
    );
  });

  const fetchAndSetNewPolicies = () => {
    api
      .getFetcher()
      .path("/api/v1/policies")
      .method("get")
      .create()({})
      .then((response) => {
        if (response.ok) {
          if (response.data.policies != undefined) {
            setPolicies(response.data.policies);
          }
        }
      })
      .catch((error) => console.error(error));
  };

  const processRowUpdate = (newRow: components["schemas"]["UtilsWorkload"]): components["schemas"]["UtilsWorkload"] => {
    void updatePolicy(newRow.policyName, newRow.workloadName, newRow.type, newRow.namespace);
    return newRow;
  };

  const updatePolicy = (
    policyName: string | undefined,
    workloadName: string | undefined,
    workloadType: string | undefined,
    namespace: string | undefined
  ): Promise<void> => {
    if (policyName != undefined && workloadName != undefined && workloadType != undefined && namespace != undefined) {
      return api
        .getFetcher()
        .path("/api/v1/policy/{name}/workload/attach")
        .method("patch")
        .create()({
          name: policyName,
          target: workloadType?.toLowerCase() + "-" + workloadName,
          namespaceName: namespace,
        })
        .then(() => {
          fetchWorkloads();
        })
        .catch((error) => console.error(error));
    } else {
      return Promise.resolve();
    }
  };

  const getNamespaceIdsAndNamespaceNames = (annotations: components["schemas"]["UtilsNamespaceAnnotationFilter"]) => {
    let namespaceIds;
    let namespacesNames;

    const namespaceForFilter =
      annotations.annotations && annotations.annotations.length > 0
        ? getNamespacesFromAnnotations(annotations)
        : namespacesParams ?? [];

    const namespaces =
      namespacesParams && namespacesParams.length > 0
        ? namespacesParams.filter((ns) => ns && namespaceForFilter.includes(ns))
        : namespaceForFilter;

    switch (!!isNamespacesExclude) {
      case true:
        namespaceIds = data?.workloads?.filter((wl) => !namespaces?.includes(wl.namespace)).map((wl) => wl.id);
        namespacesNames = namespacesForFilter.filter((ns) => !namespaces?.includes(ns));
        break;
      default:
        namespaceIds = data?.workloads?.filter((wl) => namespaces?.includes(wl.namespace)).map((wl) => wl.id);
        namespacesNames = namespaces;
    }

    namespaceIds = namespaceIds?.filter((id) => !excludeFromNamespaceAutomationIds.includes(id)) ?? [];

    return { namespaceIds, namespacesNames };
  };

  const getNamespacesFromAnnotations = (annotations: components["schemas"]["UtilsNamespaceAnnotationFilter"]) => {
    const operator = annotations?.operator || "OR";
    return namespaceObjects
      .filter((ns) => {
        for (const annotation of annotations.annotations || []) {
          const [key, value] = annotation.split("=");
          const match = ns?.metadata?.annotations?.[key] === value;
          switch (operator) {
            case "And":
            case "AND":
            case "and": {
              if (!match) {
                return annotations.exclude;
              }
              break;
            }
            case "Or":
            case "or":
            case "OR": {
              if (match) {
                return !annotations.exclude;
              }
            }
          }
        }
        return annotations.exclude;
      })
      .map((ns) => ns?.metadata?.name);
  };

  const attachPolicyToNamespace = (policyName: string) => {
    const annotations = {
      annotations: namespaceAnnotationParam as string[],
      opeartor: (namespaceAnnotationLogical || "OR") as "OR" | "or" | "Or" | "AND" | "and" | "And",
      exclude: !!namespaceAnnotationExclude,
    };
    const { namespaceIds } = getNamespaceIdsAndNamespaceNames(annotations);
    const Input = {
      namespaces: namespacesParams as string[],
      annotations: annotations,
      policy: policyName,
    };

    updateNamespaceBulk.mutate(Input);

    if (namespaceIds.length > 0) {
      updateOverriddenWorkloads({
        ids: namespaceIds,
        props: { policyName },
      });
    }
  };

  const automateByFilter = (state: boolean): void => {
    const annotations = {
      annotations: namespaceAnnotationParam as string[],
      opeartor: (namespaceAnnotationLogical || "OR") as "OR" | "or" | "Or" | "AND" | "and" | "And",
      exclude: !!namespaceAnnotationExclude,
    };
    const { namespaceIds } = getNamespaceIdsAndNamespaceNames(annotations);
    const Input = {
      namespaces: namespacesParams as string[],
      annotations: annotations,
      optimize: state,
    };

    updateNamespaceBulk.mutate(Input);

    if (namespaceIds.length > 0) {
      updateOverriddenWorkloads({
        ids: namespaceIds,
        props: { auto: state },
      });
    }
  };

  useEffect(() => {
    const potentialPolicyNames: string[] = [];
    policies.forEach((policy: components["schemas"]["V1alpha1Policy"]) => {
      if (policy.metadata?.name != undefined) {
        potentialPolicyNames.push(policy.metadata?.name);
      }
    });
    setPolicyNames(potentialPolicyNames);
  }, [policies]);

  useEffect(() => {
    fetchAndSetNewPolicies();
    fetchWorkloads();
    const interval = setInterval(() => {
      fetchWorkloads();
    }, 1000 * 20);
    return clearInterval(interval);
  }, [api]);

  const isUnallocatedRow = (wlrow: components["schemas"]["UtilsWorkload"]) => {
    if (wlrow.workloadName == "__unallocated__") return true;
    return false;
  };

  const updateAutoInBulk = (
    checked: boolean,
    workloads: {
      name: string;
      type: string;
      namespace: string;
      auto: boolean;
      id: string;
      isAutomationExcluded: boolean | undefined;
    }[]
  ) => {
    const uniqueIds = workloads.filter((wl) => !wl?.isAutomationExcluded).map((wl) => wl.id);

    queryClient.setQueryData(
      [dashboardByNamespace.queryKey, filterQueryParams],
      (oldData: GetDashboardByNamespaceResponse | undefined) => {
        return {
          ...oldData,
          workloads: oldData?.workloads?.map((wl) => {
            if (uniqueIds?.includes(wl.id)) {
              return { ...wl, auto: checked };
            }
            return wl;
          }),
        };
      }
    );

    updateOverriddenWorkloads({
      ids: uniqueIds,
      props: { auto: checked },
    });

    const api = ScaleOps();

    return api
      .getFetcher()
      .path("/api/v1/auto/bulk")
      .method("post")
      .create()({
        workloads: workloads,
        state: checked,
      })
      .then(() => {
        toast.success("Auto updated in bulk", {
          position: "top-center",
        });
        return;
      })
      .catch((error) => {
        console.error(error);
        toast.error(`Failed to update auto in bulk`, {
          position: "top-center",
        });
        uniqueIds.forEach((id) => {
          const previousAutoValue = workloads.find((wl) => wl.id === id)?.auto;
          updateOverriddenWorkloads({
            ids: [id],
            props: { auto: previousAutoValue },
          });
        });
      })
      .finally(() => {
        queryClient.invalidateQueries([dashboardByNamespace.queryKey, filterQueryParams]);
      });
  };

  const applyInBulk = (workloads: { name: string; type: string; namespace: string; muttype: string }[]): void => {
    const api = ScaleOps();

    api
      .getFetcher()
      .path("/api/v1/recommendation/apply/bulk")
      .method("patch")
      .create()({
        workloads: workloads,
      })
      .then((response: ApiResponse<components["schemas"]["RecommendationsApplyRecommendationsResponse"]>) => {
        handleProcessRowUpdateSuccess(
          response.ok
            ? `Successfully Applied recommendations to workloads`
            : `Failed to apply recommendations to workloads`
        );
      })
      .catch((error) => {
        console.error(error);
        handleProcessRowUpdateError("Failed to apply recommendations to workloads");
      })
      .finally(() => {
        queryClient.invalidateQueries([dashboardByNamespace.queryKey, filterQueryParams]);
      });
  };

  const restartInBulk = (workloads: { name: string; type: string; namespace: string }[]): Promise<void> => {
    const request: components["schemas"]["RolloutInput"] = {
      workloads: workloads,
    };
    return api
      .getFetcher()
      .path("/api/v1/recommendation/rollout")
      .method("post")
      .create()(request)
      .then(() => {
        toast.success("Successfully rolled out workloads", {
          position: "top-center",
        });
      })
      .catch((reason) => {
        toast.error("Failed to rollout workloads", {
          position: "top-center",
        });
        console.error(reason);
      });
  };
  const restorePolicyInBulk = (
    workloads: { name: string; type: string; namespace: string; id: string }[]
  ): Promise<void> => {
    const request: components["schemas"]["BulkRestorePolicyInput"] = {
      workloads: workloads,
    };

    return api
      .getFetcher()
      .path("/api/v1/policy/restore-policy/bulk")
      .method("post")
      .create()(request)
      .then(() => {
        // deleteOverriddenWorkloadsProperties({
        //   ids: workloads.map((wl) => wl.id),
        //   propertyNames: ["policyName"],
        // });
        updateOverriddenWorkloadsByUpdateFunction({
          ids: workloads.map((wl) => wl.id),
          updateFunction: (workload) => {
            const smartPolicyName = data?.workloads?.find((wl) => wl.id === workload.id)?.smartPolicyName;
            return { ...workload, policyName: smartPolicyName || "production" };
          },
        });
        toast.success("Successfully restored policy", {
          position: "top-center",
        });
        queryClient.invalidateQueries([dashboardByNamespace.queryKey, filterQueryParams]);
      })
      .catch((reason) => {
        toast.error("Failed to restore policy", {
          position: "top-center",
        });
        console.error(reason);
      });
  };
  const attachPolicyInBulk = (
    policyName: string,
    workloads: { name: string; type: string; namespace: string; id: string; rollbackPolicyName: string }[]
  ): Promise<void> => {
    const uniqueIds = workloads.map((wl) => wl.id);

    updateOverriddenWorkloads({
      ids: uniqueIds,
      props: { policyName: policyName },
    });

    queryClient.setQueryData(
      [dashboardByNamespace.queryKey, filterQueryParams],
      (oldData: GetDashboardByNamespaceResponse | undefined) => {
        return {
          ...oldData,
          workloads: oldData?.workloads?.map((wl) => {
            if (uniqueIds?.includes(wl.id)) {
              return { ...wl, policyName: policyName };
            }
            return wl;
          }),
        };
      }
    );

    const api = ScaleOps();

    if (policyName !== "") {
      return api
        .getFetcher()
        .path("/api/v1/policy/{name}/workload/attach/bulk")
        .method("patch")
        .create()({
          name: policyName,
          workloads: workloads,
        })
        .then(() => {
          toast.success("Successfully attached policy to workloads", {
            position: "top-center",
          });
        })
        .catch((error: Error & { reason?: string }) => {
          workloads.forEach((wl) => {
            const previousPolicyName = wl.rollbackPolicyName;
            updateOverriddenWorkloads({
              ids: [wl.id],
              props: { policyName: previousPolicyName },
            });
          });
          const forbidden = error?.reason && error.reason === "Forbidden" ? " (Forbidden)" : "";
          toast.error(`Failed to attach policy to workloads${forbidden}`);
          console.log(` Error: ${error.message}`);
        })
        .finally(() => {
          queryClient.invalidateQueries([dashboardByNamespace.queryKey, filterQueryParams]);
        });
    } else {
      handleProcessRowUpdateError("No policy has set");
      return Promise.resolve();
    }
  };

  function patchWorkload(row: GridRowModel<components["schemas"]["UtilsWorkload"]>, recommendationType = "rightSize") {
    ApplyRecommendation(row.namespace, row.workloadName, row.type, recommendationType)
      .then((response) => {
        handleProcessRowUpdateSuccess(
          response.ok ? `Successfully Patch ${row.workloadName}` : `Failed to Patch ${row.workloadName}`
        );
      })
      .catch((error) => {
        console.error(error);
        handleProcessRowUpdateError("Failed to patch workload");
      });
  }

  const stopPropagationFnc = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const getWorkloadActionMenu = (row: components["schemas"]["UtilsWorkload"], type?: string) => {
    // const updateAutomationExclude = useUpdateAutomationExclude();

    if (isUnallocatedRow(row)) return <div></div>;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleActionClick = (
      row: GridRowModel<components["schemas"]["UtilsWorkload"]>,
      event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>
    ) => {
      if (event.currentTarget.innerText === "Apply recommendation") {
        patchWorkload(row);
      }

      if (event.currentTarget.innerText === "Auto update in Git") {
        setGitWorkflow(row);
      }

      if (event.currentTarget.innerText === "Revert recommendation") {
        patchWorkload(row, "undoRightSize");
      }

      if (event.currentTarget.innerText === "Rollout workload") {
        restartInBulk([{ name: row.workloadName, type: row.type, namespace: row.namespace }]).then(() =>
          console.log("")
        );
      }
      if (event.currentTarget.innerText === "Restore to suggested policy") {
        deleteOverriddenWorkloadsProperties({
          ids: [row.id],
          propertyNames: ["policyName"],
        });
        const updateValue = async () => {
          attachPolicyToWorkload.mutate({
            name: row.smartPolicyName || "production",
            target: `${row.type}-${row.workloadName}`.toLocaleLowerCase(),
            namespaceName: row.namespace,
            id: row.id,
            oldPolicyName: row.policyName,
          });

          await resetRecommendation.mutateAsync({
            name: `${row.type.toLowerCase()}-${row.workloadName}`,
            namespace: row.namespace,
            id: row.id,
            dontShowToast: true,
          });
        };
        updateValue();
      }

      setAnchorEl(null);
    };

    const showExtensiveOptions =
      type &&
      ["Deployment", "DaemonSet", "StatefulSet", "DeploymentConfig", "flink"]
        .map((s) => s.toLocaleLowerCase())
        .includes(type.toLocaleLowerCase());

    const optionsToShow = [
      FeatureEnabled("DemoVersion") ? "Apply recommendation" : undefined,
      FeatureEnabled("DemoVersion") ? "Revert recommendation" : undefined,
      showExtensiveOptions ? "Rollout workload" : undefined,
      showExtensiveOptions && isGitSyncEnabled() ? "Auto update in Git" : undefined,
      "Restore to suggested policy",
    ].filter((option) => option !== undefined);

    if (!optionsToShow || optionsToShow.length === 0) return null;

    const disableMenuOpen = row.isPrivileged == false || row.isReadyRecommendation == false;

    return (
      <div className="flex items-center justify-center w-full h-full" onClick={stopPropagationFnc}>
        <div className="flex">
          <div className="w-fit opacity-40 hover:opacity-100">
            <div
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                if (disableMenuOpen) return false;
                handleClick(e);
              }}
              className={clsx({
                "text-text-disable cursor-not-allowed": disableMenuOpen,
              })}
            >
              <MoreVertIcon />
            </div>
          </div>
        </div>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
        >
          {isReadOnlyFrontEnd && <YouHaveReadOnlyAccess className="my-2 mx-3" chipDesign />}
          {optionsToShow.map((option) => (
            <MenuItem
              key={option}
              disabled={isReadOnlyFrontEnd || (option == "Apply recommendation" && !row.isEditable)}
              onClick={(event) => handleActionClick(row, event)}
            >
              <Typography variant="body2">{option}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "__check__",
      width: 50,
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      renderHeader: () => {
        return (
          <Checkbox
            checked={selectedRows?.length === allRows?.length && rows?.length > 0}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRows(allRows as components["schemas"]["UtilsWorkload"][]);
              } else {
                setSelectedRows([]);
              }
            }}
            sx={{
              "& .MuiSvgIcon-root": {
                color: SCALEOPS_COLORS.primary.purpleBlue,
              },
            }}
            checkedIcon={<SelectedCheckBoxIcon checked />}
            icon={<SelectedCheckBoxIcon />}
          />
        );
      },
      renderCell: (
        params: GridRenderCellParams<
          components["schemas"]["DashIssues"],
          components["schemas"]["UtilsWorkload"],
          components["schemas"]["DashIssues"]
        >
      ) => {
        const checked = selectedRows?.find((row) => row.id === params.row.id) ? true : false;

        return (
          <div
            className={clsx("w-full flex justify-center", {
              "opacity-30 hover:opacity-100": !checked,
            })}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Checkbox
              checked={checked}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedRows([...(selectedRows ?? []), params.row]);
                } else {
                  setSelectedRows(selectedRows?.filter((row) => row.id !== params.row.id));
                }
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  color: SCALEOPS_COLORS.primary.purpleBlue,
                },
              }}
              checkedIcon={<SelectedCheckBoxIcon checked />}
              icon={<SelectedCheckBoxIcon />}
            />
          </div>
        );
      },
    },
    {
      field: OverviewTableFields.Issues,
      hide: true,
      headerName: "",
      width: 50,
      type: "string",
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (
        params: GridRenderCellParams<
          components["schemas"]["DashIssues"],
          components["schemas"]["UtilsWorkload"],
          components["schemas"]["DashIssues"]
        >
      ) => {
        const shouldShowWarningInNonDemoEnv =
          !FeatureEnabled("DemoVersion") &&
          params.row.oomLastTimestamp &&
          params.row.oomLastTimestamp.length > 0 &&
          params.row.oomCountLast24h &&
          params.row.oomCountLast24h > 0;

        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {FeatureEnabled("DemoVersion") && params.value && <WarningPopup warnings={params.value} />}
            {shouldShowWarningInNonDemoEnv ? (
              <CustomTooltip
                title={
                  <Typography variant="body2">
                    <b>Last out of memory: </b> {dayjs.utc(params.row.oomLastTimestamp).format("YYYY-MM-DD HH:mm:ss")}
                    <br />
                    <b>Number of out of memory events in the last 24 hours: </b>
                    {params.row.oomCountLast24h}
                  </Typography>
                }
                maxWidth={1200}
              >
                <WarningIcon />
              </CustomTooltip>
            ) : null}
            {params.row.eventReason != undefined && params.row.auto && params.row.eventReason !== "" && (
              <Tooltip title={<>{params.row.eventReason}</>} maxWidth={500}>
                <InfoIcon />
              </Tooltip>
            )}
            {params.row.workloadErrors && params.row.workloadErrors?.length > 0 && (
              <CustomTooltip
                title={
                  <Typography variant="body2">
                    <b>Workload isn't running but consuming resources. Reason:</b>
                    <br />
                    <ul>
                      {params.row.workloadErrors?.map((e) => (
                        <li>{e}</li>
                      ))}
                    </ul>
                  </Typography>
                }
                maxWidth={500}
              >
                <WarningIcon />
              </CustomTooltip>
            )}
          </div>
        );
      },
    },
    {
      field: OverviewTableFields.Workload,
      headerName: "Workload",
      flex: 3,
      minWidth: 150,
      type: "string",
      align: "left",
      headerAlign: "center",
      cellClassName: "workloadCellContent",
      disableColumnMenu: true,
      sortable: true,
      renderCell: (
        params: GridRenderCellParams<
          components["schemas"]["DashIssues"],
          components["schemas"]["UtilsWorkload"],
          components["schemas"]["DashIssues"]
        >
      ) => {
        const userClickOnWorkloadRowAtTheLastFourDays =
          new Date().getTime() / 1000 - lastTimeWorkloadRowWasClicked < FOUR_DAYS_IN_SEC;
        const userClickedMoreThanThreeTimes = Number(workloadRowClickCount) > MAX_CLICKS_TO_SHOW_ROW_PULSE;
        const showClickRowMessage =
          ENABLE_FIRST_ANIMATION_CTA_FF &&
          params.row.isReadyRecommendation &&
          !userClickOnWorkloadRowAtTheLastFourDays &&
          !userClickedMoreThanThreeTimes;
        const showAnimatedPingDote = showClickRowMessage && params.row.id === firstRowId;
        const savingAvailable = Math.round(params.row.savingsAvailable * 100) / 100;

        const tooltipType = getWorkloadType(params.row);

        const hasWorkloadErrorsWarning =
          params.row.namespace != "scaleops-system" &&
          params.row.workloadErrors &&
          params.row.workloadErrors?.length > 0;

        const hasOOMWarning = !FeatureEnabled("DemoVersion") &&
          params.row.oomLastTimestamp &&
          params.row.oomLastTimestamp.length > 0 &&
          params.row.oomCountLast24h &&
          params.row.oomCountLast24h > 0 && (
            <div>
              <p className="flex gap-2 items-center">
                <RightArrowIcon width={8} height={8} />
                Out of memory
              </p>
              <ul className="pl-8 list-disc text-[10px]">
                <li>
                  <b>Last out of memory:</b> {dayjs.utc(params.row.oomLastTimestamp).format("YYYY-MM-DD HH:mm:ss")}
                </li>
                <li>
                  <b>OOM events in the last 24 hours:</b> {params.row.oomCountLast24h}
                </li>
              </ul>
            </div>
          );

        const totalNumberOfWarnings = (params.row.workloadErrors?.length ?? 0) + (hasOOMWarning ? 1 : 0);

        const workloadErrorsWarnings =
          hasWorkloadErrorsWarning || hasOOMWarning ? (
            <CustomTooltip
              title={
                <div className="flex flex-col gap-4">
                  <div>
                    <Typography
                      variant="body2"
                      fontWeight={700}
                      className="bg-guideline-lightPurple px-1 py-0.5 rounded-lg flex items-center gap-1 w-full min-w-[200px]"
                    >
                      <WarningIcon width={14} height={14} /> {pluralize("Warning", totalNumberOfWarnings)}
                    </Typography>
                    <ul className="pl-4 pt-2">
                      {hasOOMWarning ? <li>{hasOOMWarning}</li> : null}
                      {params.row.workloadErrors?.map((e) => (
                        <li className="flex gap-2 items-center py-[2px]">
                          <RightArrowIcon width={8} height={8} />
                          <p>{e}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              }
              maxWidth={500}
            >
              <WarningIcon width={14} height={14} />
            </CustomTooltip>
          ) : null;

        const demoWarning =
          FeatureEnabled("DemoVersion") && params.value ? <WarningPopup warnings={params.value} /> : null;

        const bufferTimer = dayjs.utc().subtract(15, "seconds").unix();
        const isAutomationTimeOlderThanBuffer = dayjs.utc(params.row.automationTime).unix() < bufferTimer;

        let percentageOfOptimizedReplicas = params.row.replicas
          ? Math.round(((params.row?.optimizedReplicas ?? 0) / params.row.replicas) * 100)
          : 0;

        switch (true) {
          case percentageOfOptimizedReplicas > 100:
            percentageOfOptimizedReplicas = 100;
            break;
          case percentageOfOptimizedReplicas < 0:
            percentageOfOptimizedReplicas = 0;
            break;
          default:
            break;
        }

        const numberOfUnOptimizedPods = (params.row.replicas ?? 0) - (params.row.optimizedReplicas ?? 0);

        const eventReasonWarning =
          isAutomationTimeOlderThanBuffer &&
            params.row.auto &&
            ((params.row.eventReason !== "" && params.row.eventReason !== undefined) || params.row?.rolloutInProgress) &&
            params.row.savingsAvailable &&
            params.row.savingsAvailable !== 0 ? (
            <Tooltip
              title={
                <div>
                  {percentageOfOptimizedReplicas > -1 && (
                    <b className="text-guideline-darkGreen">{percentageOfOptimizedReplicas}% are optimized</b>
                  )}
                  {params.row?.rolloutInProgress ? (
                    <p className="text-[.625rem] bg-guideline-darkGray rounded-sm px-2">Workload rollout in progress</p>
                  ) : (
                    <div>
                      {!!numberOfUnOptimizedPods && (
                        <p>
                          {numberOfUnOptimizedPods} {pluralize("pod", numberOfUnOptimizedPods)}{" "}
                          {pluralize("is", numberOfUnOptimizedPods)} not optimized due to:
                        </p>
                      )}
                      {params.row.eventReason && (
                        <p className="italic text-[.625rem] bg-guideline-darkGray px-2 py-3 rounded-sm">
                          {params.row.eventReason}
                        </p>
                      )}
                      {params.row.eventAction && (
                        <p className="text-[.625rem] bg-guideline-darkGray px-2 pb-3 rounded-sm">
                          <b>{params.row.eventAction}</b>
                        </p>
                      )}
                    </div>
                  )}
                </div>
              }
              maxWidth={500}
            >
              <CreativeInfoIcon width={14} height={14} />
            </Tooltip>
          ) : null;

        const wasterResourcesWarning =
          params.row.wastedResources && params.row.wastedResources.length > 0 ? (
            <CustomTooltip
              title={
                <div>
                  <b>Wasted resources</b>
                  <br />
                  <Typography variant="caption" className="">
                    Not running pods waste resources due to:
                  </Typography>
                  <ul className="list-disc pl-4">
                    {params.row.wastedResources.map((e) => {
                      return <li>{e}</li>;
                    })}
                  </ul>
                </div>
              }
              maxWidth={500}
            >
              <WasteIcon width={17} height={17} />
            </CustomTooltip>
          ) : null;

        const workloadName = getDisplayWorkloadName(params.row.workloadName, params.row.hideSuffix);

        return (
          <Tooltip
            title={<ExploreYourWorkloadMessage />}
            maxWidth={500}
            disabled={!showAnimatedPingDote}
            isTourGuideTooltip
            className="w-full"
          >
            <div className="flex w-full justify-center items-center">
              <div className="flex gap-1 relative max-w-full w-[92%]">
                <div className="w-fit flex gap-[.125rem] items-center justify-start">
                  {showAnimatedPingDote && (
                    <div className="w-2 h-2 mx-1 relative">
                      <div className="animate-ping absolute inline-flex w-2 h-2 rounded-full bg-main-blue opacity-50 left-0 top-0" />
                      <div className="absolute inline-flex w-2 h-2 rounded-full bg-main-blue opacity-75 left-0 top-0" />
                    </div>
                  )}
                  {params.row.auto && savingAvailable === 0 && <CheckIcon className="text-main-green" />}
                  {workloadErrorsWarnings}
                  {wasterResourcesWarning}
                  {demoWarning}
                  {eventReasonWarning}
                </div>
                <Typography
                  variant="body2"
                  sx={{ whiteSpace: "normal" }}
                  fontWeight={500}
                  className="line-clamp-2 w-full flex items-center"
                  data-testid="workload-name"
                  style={{ height: ROW_HEIGHT }}
                >
                  <Tooltip
                    title={
                      <div className={"flex flex-col gap-0.5"}>
                        <div>
                          {tooltipType}: {params.row.namespace}/{workloadName}
                        </div>
                        {
                          <div className="flex gap-1" style={{ color: SCALEOPS_COLORS.primary.purpleBlue }}>
                            <ScaleopsIcon width={10} height={10} className="mt-0.5" />{" "}
                            <small>Explore workload overview</small>
                          </div>
                        }
                      </div>
                    }
                    maxWidth={500}
                    disabled={showAnimatedPingDote}
                    className="h-full flex items-center"
                  >
                    {params.row.namespace}/{workloadName}
                  </Tooltip>
                </Typography>
              </div>
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: OverviewTableFields.TotalCost,
      headerName: "Total Cost",
      renderHeader: (params) => (
        <Typography variant="body2" fontWeight={700} sx={{ whiteSpace: "normal ", wordBreak: "break-word" }}>
          {params.colDef.headerName}
          <br />
          <span className="text-text-darkGray text-[.625rem]">(monthly)</span>
        </Typography>
      ),
      hide: !selectedColumns.includes(OverviewTableColumns.TotalCost),
      flex: 1.4,
      minWidth: 100,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "fullCellTooltipWrapper",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return <div></div>;
        if (params.row.isReadyRecommendation == false) {
          return (
            <Typography fontWeight={410} fontSize={"small"} fontFamily={"Poppins"} fontStyle={"oblique"}>
              inactive
            </Typography>
          );
        }
        return (
          <Typography variant="body2" className={clsx("fullCellTooltipContent")} fontWeight={500}>
            <Tooltip
              title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
              maxWidth={400}
              className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
              enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
            >
              {params.row.totalCost == undefined || params.row.totalCost < 0
                ? formatterUtils.currencyFormatter(0)
                : formatterUtils.currencyFormatter(params.row.totalCost)}
            </Tooltip>
          </Typography>
        );
      },
    },
    {
      field: OverviewTableFields.SavingsAvailable,
      sortComparator: (a, b) => {
        if (a === -Infinity) return NaN;
        return a - b;
      },
      headerName: "Savings Available",
      renderHeader: (params) => (
        <Typography variant="body2" fontWeight={700} sx={{ whiteSpace: "normal ", wordBreak: "break-word" }}>
          {params.colDef.headerName}
          <br />
          <span className="text-text-darkGray text-[.625rem]">(monthly)</span>
        </Typography>
      ),
      hide: !selectedColumns.includes(OverviewTableColumns.SavingsAvailable),
      flex: 1.4,
      minWidth: 140,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "fullCellTooltipWrapper",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return <div></div>;
        if (params.row.isReadyRecommendation == false) {
          return (
            <Typography fontWeight={410} fontSize={"small"} fontFamily={"Poppins"} fontStyle={"oblique"}>
              inactive
            </Typography>
          );
        }

        const displayValue =
          params.row.savingsAvailable == undefined ||
            (params.row.savingsAvailable < 0 && params.row.savingsAvailable > -1)
            ? formatterUtils.currencyFormatter(0)
            : formatterUtils.currencyFormatter(params.row.savingsAvailable);

        return (
          <Typography
            variant="body2"
            className={clsx("fullCellTooltipContent", {
              "text-guideline-darkGreen":
                displayValue !== "$0" && params.row.savingsAvailable && params.row.savingsAvailable > -1,
            })}
            fontWeight={500}
          >
            <Tooltip
              title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
              maxWidth={400}
              className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
              enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
            >
              {displayValue}
            </Tooltip>
          </Typography>
        );
      },
    },
    {
      field: OverviewTableFields.ReplicasAvailableSavings,
      sortComparator: (a, b) => {
        if (a === -Infinity) return NaN;
        return a - b;
      },
      headerName: "Replicas Avail. Savings",
      renderHeader: (params) => (
        <Typography variant="body2" fontWeight={700} sx={{ whiteSpace: "normal ", wordBreak: "break-word" }}>
          {params.colDef.headerName}
          <br />
          <span className="text-text-darkGray text-[.625rem]">(monthly)</span>
        </Typography>
      ),
      hide:
        !ENABLE_HPA_RECOMMENDATION || !selectedColumns.includes(ExtendedOverviewTableColumns.ReplicasAvailableSavings),
      flex: 1.4,
      minWidth: 140,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "fullCellTooltipWrapper",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return <div></div>;
        if (params.row.isReadyRecommendation == false) {
          return (
            <Typography fontWeight={410} fontSize={"small"} fontFamily={"Poppins"} fontStyle={"oblique"}>
              inactive
            </Typography>
          );
        }

        const displayValue =
          params.row.replicasAvailableSavings == undefined ||
            (params.row.replicasAvailableSavings < 0 && params.row.replicasAvailableSavings > -1)
            ? formatterUtils.currencyFormatter(0)
            : formatterUtils.currencyFormatter(params.row.replicasAvailableSavings);

        return (
          <Typography
            variant="body2"
            className={clsx("fullCellTooltipContent", {
              "text-guideline-darkGreen":
                displayValue !== "$0" &&
                params.row.replicasAvailableSavings &&
                params.row.replicasAvailableSavings > -1,
            })}
            fontWeight={500}
          >
            <Tooltip
              title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
              maxWidth={400}
              className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
              enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
            >
              {displayValue}
            </Tooltip>
          </Typography>
        );
      },
    },
    {
      field: OverviewTableFields.PredictableSavings,
      sortComparator: (a, b) => {
        if (a === -Infinity) return NaN;
        return a - b;
      },
      headerName: "Predictable Avail. Savings",
      renderHeader: (params) => (
        <Typography variant="body2" fontWeight={700} sx={{ whiteSpace: "normal ", wordBreak: "break-word" }}>
          {params.colDef.headerName}
          <br />
          <span className="text-text-darkGray text-[.625rem]">(monthly)</span>
        </Typography>
      ),
      hide: !ENABLE_HPA_RECOMMENDATION || !selectedColumns.includes(ExtendedOverviewTableColumns.PredictableSavings),
      flex: 1.4,
      minWidth: 140,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "fullCellTooltipWrapper",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return <div></div>;
        if (params.row.isReadyRecommendation == false) {
          return (
            <Typography fontWeight={410} fontSize={"small"} fontFamily={"Poppins"} fontStyle={"oblique"}>
              inactive
            </Typography>
          );
        }

        const displayValue =
          params.row.replicasPredictableAvailableSavings == undefined ||
            (params.row.replicasPredictableAvailableSavings < 0 && params.row.replicasPredictableAvailableSavings > -1)
            ? formatterUtils.currencyFormatter(0)
            : formatterUtils.currencyFormatter(params.row.replicasPredictableAvailableSavings);

        return (
          <Typography
            variant="body2"
            className={clsx("fullCellTooltipContent", {
              "text-guideline-darkGreen":
                displayValue !== "$0" &&
                params.row.replicasPredictableAvailableSavings &&
                params.row.replicasPredictableAvailableSavings > -1,
            })}
            fontWeight={500}
          >
            <Tooltip
              title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
              maxWidth={400}
              className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
              enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
            >
              {displayValue}
            </Tooltip>
          </Typography>
        );
      },
    },
    {
      field: OverviewTableFields.StableSavings,
      sortComparator: (a, b) => {
        if (a === -Infinity) return NaN;
        return a - b;
      },
      headerName: "Stable Avail. Savings",
      renderHeader: (params) => (
        <Typography variant="body2" fontWeight={700} sx={{ whiteSpace: "normal ", wordBreak: "break-word" }}>
          {params.colDef.headerName}
          <br />
          <span className="text-text-darkGray text-[.625rem]">(monthly)</span>
        </Typography>
      ),
      hide: !ENABLE_HPA_RECOMMENDATION || !selectedColumns.includes(ExtendedOverviewTableColumns.StableSavings),
      flex: 1.4,
      minWidth: 140,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "fullCellTooltipWrapper",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return <div></div>;
        if (params.row.isReadyRecommendation == false) {
          return (
            <Typography fontWeight={410} fontSize={"small"} fontFamily={"Poppins"} fontStyle={"oblique"}>
              inactive
            </Typography>
          );
        }

        const displayValue =
          params.row.replicasStableAvailableSavings == undefined ||
            (params.row.replicasStableAvailableSavings < 0 && params.row.replicasStableAvailableSavings > -1)
            ? formatterUtils.currencyFormatter(0)
            : formatterUtils.currencyFormatter(params.row.replicasStableAvailableSavings);

        return (
          <Typography
            variant="body2"
            className={clsx("fullCellTooltipContent", {
              "text-guideline-darkGreen":
                displayValue !== "$0" &&
                params.row.replicasStableAvailableSavings &&
                params.row.replicasStableAvailableSavings > -1,
            })}
            fontWeight={500}
          >
            <Tooltip
              title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
              maxWidth={400}
              className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
              enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
            >
              {displayValue}
            </Tooltip>
          </Typography>
        );
      },
    },
    {
      field: OverviewTableFields.ActiveSavings,
      headerName: "Active Savings",
      renderHeader: (params) => (
        <Typography variant="body2" fontWeight={700} sx={{ whiteSpace: "normal ", wordBreak: "break-word" }}>
          {params.colDef.headerName}
          <br />
          <span className="text-text-darkGray text-[.625rem]">(monthly)</span>
        </Typography>
      ),
      hide: !selectedColumns.includes(OverviewTableColumns.ActiveSavings),
      flex: 1.4,
      minWidth: 130,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "fullCellTooltipWrapper",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>) => {
        const displayValue =
          params.row.activeSavings == undefined || (params.row.activeSavings < 0 && params.row.activeSavings > -1)
            ? formatterUtils.currencyFormatter(0)
            : formatterUtils.currencyFormatter(params.row.activeSavings);

        if (isUnallocatedRow(params.row)) return <div></div>;
        if (params.row.isReadyRecommendation == false) {
          return (
            <Typography fontWeight={410} fontSize={"small"} fontFamily={"Poppins"} fontStyle={"oblique"}>
              inactive
            </Typography>
          );
        }
        return (
          <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
            <Tooltip
              title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
              maxWidth={400}
              className={clsx(WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME, {
                "text-guideline-darkGreen":
                  displayValue !== "$0" && params.row.activeSavings && params.row.activeSavings > -1,
              })}
              enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
            >
              {displayValue}
            </Tooltip>
          </Typography>
        );
      },
    },
    {
      field: OverviewTableFields.ReplicasActiveSavings,
      headerName: "Active Savings",
      renderHeader: (params) => (
        <Typography variant="body2" fontWeight={700} sx={{ whiteSpace: "normal ", wordBreak: "break-word" }}>
          {params.colDef.headerName}
          <br />
          <span className="text-text-darkGray text-[.625rem]">(monthly)</span>
        </Typography>
      ),
      hide: !ENABLE_HPA_RECOMMENDATION || !selectedColumns.includes(ExtendedOverviewTableColumns.ReplicasActiveSavings),
      flex: 1.4,
      minWidth: 130,
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "fullCellTooltipWrapper",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return <div></div>;
        if (params.row.isReadyRecommendation == false) {
          return (
            <Typography fontWeight={410} fontSize={"small"} fontFamily={"Poppins"} fontStyle={"oblique"}>
              inactive
            </Typography>
          );
        }
        return (
          <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
            <Tooltip
              title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
              maxWidth={400}
              className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
              enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
            >
              {params.row.replicasActiveSavings == undefined || !params.row.scaleOutAuto
                ? formatterUtils.currencyFormatter(0)
                : formatterUtils.currencyFormatter(params.row.replicasActiveSavings)}
            </Tooltip>
          </Typography>
        );
      },
    },
    {
      field: OverviewTableFields.CPUDiff,
      headerName: "CPU Request",
      hide: !selectedColumns.includes(OverviewTableColumns.CpuRequests),
      flex: 1.5,
      minWidth: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => {
        const percentageOfOptimizedReplicas = getPercentageOfOptimizedReplicas(params);
        let cpuRequestsDisplayValue = cpuFormatter.format(params.row.cpuRequests / 1000);
        cpuRequestsDisplayValue = Number.isNaN(cpuRequestsDisplayValue) ? "0" : cpuRequestsDisplayValue;
        let cpuRecommendedDisplayValue = cpuFormatter.format(params.row.cpuRecommended / 1000);
        cpuRecommendedDisplayValue = Number.isNaN(cpuRecommendedDisplayValue) ? "0" : cpuRecommendedDisplayValue;

        let cpuDiff =
          (params.row.ownerCpuRequests / 1000 - params.row.cpuRecommended / 1000) *
          (params.row?.optimizedReplicas ?? 0);

        cpuDiff = Number(cpuDiff);

        if (Number.isNaN(cpuDiff)) cpuDiff = 0;

        const showValueChip =
          params.row.namespace != "scaleops-system" && params.row.auto && !Number.isNaN(cpuDiff) && cpuDiff >= 0.01;

        const valueChipTooltipContent = showValueChip ? (
          <>
            <ScaleOpsSpan className="mt-[4px]" /> has saved{" "}
            <b className="text-guideline-darkGreen">{formatterUtils.customNumberFormatter(Math.abs(cpuDiff))}</b> cores
            for this workload.
          </>
        ) : undefined;

        const valueChip = (
          <ValueChip
            value={cpuDiff}
            renderValue={(value) => formatterUtils.customNumberFormatter(Math.abs(value))}
            hide={!showValueChip}
            tooltipContent={valueChipTooltipContent}
          />
        );
        if (isUnallocatedRow(params.row)) return null;

        return (
          <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
            <Tooltip
              title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
              maxWidth={400}
              className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
              enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
              disabled={showValueChip}
            >
              <div className="flex justify-end items-center gap-[.625rem]">
                <span
                  className={clsx({
                    "text-main-red":
                      !(percentageOfOptimizedReplicas > 50 && params.row.auto) &&
                      params.row.cpuRecommended > params.row.cpuRequests * 1.1,
                  })}
                >
                  {percentageOfOptimizedReplicas > 50 && params.row.auto
                    ? cpuRecommendedDisplayValue
                    : cpuRequestsDisplayValue}
                </span>
                {params.row.isReadyRecommendation == true && (
                  <>
                    <RightArrowIcon width={10} height={10} />
                    <span className="text-guideline-darkGreen">{cpuRecommendedDisplayValue}</span>
                  </>
                )}
              </div>
              <div className="absolute bottom-1.5">{valueChip}</div>
            </Tooltip>
          </Typography>
        );
      },
    },
    {
      field: OverviewTableFields.OriginalCPURequest,
      headerName: "Original CPU Request",
      hide: !selectedColumns.includes(OverviewTableColumns.OriginalCpuRequests),
      flex: 1.5,
      minWidth: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return <div></div>;

        const percentageOfOptimizedReplicas = getPercentageOfOptimizedReplicas(params);
        let cpuRequestsDisplayValue = cpuFormatter.format(params.row.cpuRequests / 1000);
        cpuRequestsDisplayValue = Number.isNaN(cpuRequestsDisplayValue) ? "0" : cpuRequestsDisplayValue;
        let cpuRecommendedDisplayValue = cpuFormatter.format(params.row.cpuRecommended / 1000);
        cpuRecommendedDisplayValue = Number.isNaN(cpuRecommendedDisplayValue) ? "0" : cpuRecommendedDisplayValue;

        const currentDisplayValue = params.row.ownerCpuRequests
          ? cpuFormatter.format(params.row.ownerCpuRequests / 1000)
          : 0;
        const recommendedDisplayValue = cpuFormatter.format(params.row.cpuRecommended / 1000);

        return (
          <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
            <Tooltip
              title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
              maxWidth={400}
              className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
              enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
            >
              <div className="flex justify-end items-center gap-[.625rem]">
                <span>{currentDisplayValue}</span>
                {recommendedDisplayValue !== undefined && (
                  <>
                    <RightArrowIcon width={10} height={10} />
                    <span className="text-guideline-darkGreen">
                      {percentageOfOptimizedReplicas > 50 && params.row.auto
                        ? cpuRecommendedDisplayValue
                        : cpuRequestsDisplayValue}
                    </span>
                  </>
                )}
              </div>
            </Tooltip>
          </Typography>
        );
      },
    },
    {
      field: OverviewTableFields.MemoryDiff,
      headerName: "Memory Request",
      hide: !selectedColumns.includes(OverviewTableColumns.MemoryRequests),
      flex: 2,
      minWidth: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return <div></div>;

        const percentageOfOptimizedReplicas = getPercentageOfOptimizedReplicas(params);
        const memoryRequestsDisplayValue = prettyBytes(params.row.memRequests || 0.0, { bits: false, binary: true });

        const recommendedDisplayValue = prettyBytes(params.row.memRecommended || 0.0, {
          bits: false,
          binary: true,
        });

        let memoryDiff =
          (params.row.ownerMemoryRequests - params.row.memRecommended) * (params.row?.optimizedReplicas ?? 0);

        memoryDiff = Number(memoryDiff);

        if (Number.isNaN(memoryDiff)) memoryDiff = 0;

        const shouldValueChip =
          params.row.namespace != "scaleops-system" && params.row.auto && !Number.isNaN(memoryDiff) && memoryDiff >= 1;

        const valueChipTooltipContent = shouldValueChip ? (
          <>
            <ScaleOpsSpan className="mt-[4px]" /> has saved{" "}
            <b className="text-guideline-darkGreen">
              {prettyBytes(Math.abs(memoryDiff), {
                bits: false,
                binary: true,
              })}
            </b>{" "}
            of memory for this workload.
          </>
        ) : undefined;

        const valueChip = (
          <ValueChip
            value={memoryDiff}
            renderValue={(value) =>
              prettyBytes(Math.abs(value), {
                bits: false,
                binary: true,
              })
            }
            hide={!shouldValueChip}
            tooltipContent={valueChipTooltipContent}
          />
        );

        return (
          <div className="fullCellTooltipContent">
            <Tooltip
              title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
              maxWidth={400}
              className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
              enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
              disabled={shouldValueChip}
            >
              <div className="flex justify-center items-center gap-[.875rem]">
                <Typography
                  variant="body2"
                  fontWeight={500}
                  className={clsx({
                    "text-main-red":
                      !(percentageOfOptimizedReplicas > 50 && params.row.auto) &&
                      params.row.memRecommended > params.row.memRequests * 1.1,
                  })}
                >
                  {percentageOfOptimizedReplicas > 50 && params.row.auto
                    ? recommendedDisplayValue
                    : memoryRequestsDisplayValue}
                </Typography>
                {params.row.isReadyRecommendation == true && (
                  <>
                    <RightArrowIcon width={10} height={10} />
                    <Typography variant="body2" fontWeight={500} className="text-guideline-darkGreen">
                      {recommendedDisplayValue}
                    </Typography>
                  </>
                )}
              </div>
              <div className="absolute bottom-1.5">{valueChip}</div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: OverviewTableFields.OriginalMemoryRequest,
      headerName: "Original Memory Request",
      hide: !selectedColumns.includes(OverviewTableColumns.OriginalMemoryRequests),
      flex: 1.5,
      minWidth: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return <div></div>;
        const originalMemoryRequest = Number(params.row.ownerMemoryRequests);

        const percentageOfOptimizedReplicas = getPercentageOfOptimizedReplicas(params);

        const memoryRequestsRecommendedDisplayValue = prettyBytes(params.row.memRecommended || 0.0, {
          bits: false,
          binary: true,
        });

        const currentDisplayValue = prettyBytes(originalMemoryRequest || 0.0, { bits: false, binary: true });

        const memoryRequestsDisplayValue = prettyBytes(params.row.memRequests || 0.0, { bits: false, binary: true });

        const recommendedDisplayValue = prettyBytes(params.row.memRecommended || 0.0, {
          bits: false,
          binary: true,
        });

        return (
          <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
            <Tooltip
              title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
              maxWidth={400}
              className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
              enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
            >
              <div className="flex justify-center items-center gap-[.625rem]">
                <span
                  className={clsx({
                    "text-main-red":
                      !(percentageOfOptimizedReplicas > 50 && params.row.auto) &&
                      currentDisplayValue !== recommendedDisplayValue,
                  })}
                >
                  {currentDisplayValue}
                </span>
                {(memoryRequestsRecommendedDisplayValue || memoryRequestsDisplayValue) && (
                  <>
                    <RightArrowIcon width={10} height={10} />
                    <span className="text-guideline-darkGreen">
                      {percentageOfOptimizedReplicas > 50 && params.row.auto
                        ? recommendedDisplayValue
                        : memoryRequestsDisplayValue}
                    </span>
                  </>
                )}
              </div>
            </Tooltip>
          </Typography>
        );
      },
    },
    {
      field: OverviewTableFields.ReplicasDiff,
      headerName: "Replicas Recommendation",
      hide: !ENABLE_HPA_RECOMMENDATION || !selectedColumns.includes(ExtendedOverviewTableColumns.ReplicasDiff),
      flex: 1.5,
      minWidth: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return null;
        return (
          <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
            <Tooltip
              title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
              maxWidth={400}
              className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
              enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
            >
              <div className="flex justify-end items-center gap-[.625rem]">
                <span>{params.row.desiredReplicas ?? params.row.replicas}</span>
                {params.row.isReadyRecommendation == true && (
                  <>
                    <RightArrowIcon width={10} height={10} />
                    <span className="text-guideline-darkGreen">
                      {params.row.recommendedReplicas ?? params.row.desiredReplicas ?? params.row.replicas}
                    </span>
                  </>
                )}
              </div>
            </Tooltip>
          </Typography>
        );
      },
    },
    {
      field: OverviewTableFields.MinReplicasDiff,
      headerName: "Min Replicas Recommendation",
      hide: !ENABLE_HPA_RECOMMENDATION || !selectedColumns.includes(ExtendedOverviewTableColumns.MinReplicasDiff),
      flex: 1.5,
      minWidth: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return null;
        return (
          <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
            <Tooltip
              title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
              maxWidth={400}
              className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
              enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
            >
              <div className="flex justify-end items-center gap-[.625rem]">
                <span>{params.row.minReplicas ?? params.row.replicas}</span>
                {params.row.isReadyRecommendation == true && (
                  <>
                    <RightArrowIcon width={10} height={10} />
                    <span className="text-guideline-darkGreen">
                      {params.row.recommendedMinReplicas ?? params.row.minReplicas ?? params.row.replicas}
                    </span>
                  </>
                )}
              </div>
            </Tooltip>
          </Typography>
        );
      },
    },
    {
      field: OverviewTableFields.Replicas,
      minWidth: 110,
      sortable: true,
      headerName: "Replicas",
      hide: !selectedColumns.includes(OverviewTableColumns.Replicas),
      flex: 1.2,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return <div></div>;
        return (
          <div className="flex relative">
            <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
              <Tooltip
                title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
                maxWidth={400}
                className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
                enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
              >
                {(params.row.readyReplicas ?? params.row.runningReplicas) || 0} / {params.row.replicas}
              </Tooltip>
            </Typography>
            <HAPWorkload workload={params.row} className="absolute right-[-1.25rem] top-[.1875rem]" />
          </div>
        );
      },
    },
    {
      field: OverviewTableFields.PredictionErrorScore,
      minWidth: 150,
      sortable: true,
      headerName: "Prediction Error Score",
      hide: !ENABLE_HPA_RECOMMENDATION || !selectedColumns.includes(ExtendedOverviewTableColumns.PredictionErrorScore),
      flex: 1.2,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return <div></div>;
        return (
          <div className="flex relative">
            <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
              <Tooltip
                title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
                maxWidth={400}
                className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
                enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
              >
                {params.row.predictionErrorScore
                  ? params.row.predictionErrorScore >= 0
                    ? `${roundToTwoDecimal(params.row.predictionErrorScore * 100)}%`
                    : "Unpredictable"
                  : "N/A"}
              </Tooltip>
            </Typography>
          </div>
        );
      },
    },
    {
      field: OverviewTableFields.IsIdleStable,
      minWidth: 150,
      sortable: true,
      headerName: "Stable Trigger",
      hide: !ENABLE_HPA_RECOMMENDATION || !selectedColumns.includes(ExtendedOverviewTableColumns.IsIdleStable),
      flex: 1.2,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      getApplyQuickFilterFn: undefined,
      cellClassName: "fullCellTooltipWrapper",
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => {
        if (isUnallocatedRow(params.row)) return <div></div>;
        return (
          <div className="flex relative">
            <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
              <Tooltip
                title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
                maxWidth={400}
                className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
                enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
              >
                {params.row.isIdleStable ? "Yes" : "No"}
              </Tooltip>
            </Typography>
          </div>
        );
      },
    },
    {
      field: OverviewTableFields.PolicyName,
      sortable: true,
      headerName: "Policy",
      cellClassName: "removePadding",
      hide: !selectedColumns.includes(OverviewTableColumns.Policy),
      flex: 1.5,
      minWidth: 220,
      valueOptions: policyNames,
      editable: false,
      align: "left",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>) => (
        <PolicyCell
          params={params}
          overriddenWorkloadsIds={overriddenWorkloadsIds}
          isReadOnlyFrontEnd={isReadOnlyFrontEnd}
          policyNames={policyNames}
        />
      ),
      getApplyQuickFilterFn: undefined,
    },
    {
      field: OverviewTableFields.Automated,
      headerName: "Automated",
      width: 130,
      type: "boolean",
      cellClassName: "border-r border-strongBorder removePadding",
      align: "center",
      headerAlign: "center",
      headerClassName: "workloadCellContent",
      disableColumnMenu: true,
      sortable: true,
      getApplyQuickFilterFn: undefined,
      renderCell: (params: GridRenderCellParams<boolean, components["schemas"]["UtilsWorkload"], boolean>) => {
        const isDisabled =
          isReadOnlyFrontEnd ||
          params.row.isPrivileged == false ||
          params.row.isReadyRecommendation == false ||
          !params.row.isEditable;

        const isFirstRowAndZeroPercentAutomation =
          ENABLE_FIRST_ANIMATION_CTA_FF &&
          wasInstalledInTheLast3Days &&
          params.row.isReadyRecommendation &&
          params.row.id === firstRowId &&
          !params.row.auto &&
          data?.totalNamespaceSummary?.automatedWorkloads === 0 &&
          !didUserAutomateFirstWorkload &&
          !isDisabled;

        const isAutomationExcluded = params.row.isAutomationExcluded;

        const onChange = (_: React.ChangeEvent<HTMLInputElement> | undefined, checked: boolean) => {
          setDidUserAutomateFirstWorkload(true);
          updateAutomation.mutate({
            id: params.row.id,
            namespace: params.row.namespace,
            workloadName: params.row.workloadName,
            workloadType: params.row.type,
            state: checked,
          });
        };

        const isEditable = params.row.isEditable;

        let tooltipContent = <></>;

        switch (true) {
          case isFirstRowAndZeroPercentAutomation:
            tooltipContent = <AutomateYourFirstWorkloadTooltip />;
            break;
          case !isEditable:
            tooltipContent = (
              <Typography variant="caption">
                You don't have <b>permission to automate</b> this workload.
              </Typography>
            );
            break;
          default:
            tooltipContent = (
              <AutomationTooltip
                details={params.row?.rollingStrategyDetails}
                replicas={params.row?.replicas}
                hasHap={!!params.row?.hpa}
                hasVpa={!!params.row?.hasVpa}
              />
            );
            break;
        }

        if (isUnallocatedRow(params.row)) return <div></div>;
        return (
          <div onClick={stopPropagationFnc} className="w-full h-full flex justify-center align-center">
            <CustomTooltip
              title={
                !params.row.isPrivileged ? "Actions for unlimited workloads are limited to ScaleOps full version" : ""
              }
              disabled={params.row.isPrivileged}
            >
              <div className="flex justify-end w-full items-center">
                <div className="flex gap-2 items-center justify-center">
                  {isAutomationExcluded ? (
                    <CustomTooltip
                      title={
                        <>
                          This workload is <b>excluded</b> from <b>namespace automation</b>.
                        </>
                      }
                      placement="left"
                      maxWidth={300}
                    >
                      <div className="block">
                        <div className="opacity-30">
                          <Switch sx={{ float: "center" }} checked={params.row.auto} />
                        </div>
                        <div className="w-full flex justify-center items-center mt-[-2rem] h-[1.875rem] black">
                          <LockIcon />
                        </div>
                      </div>
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip
                      title={tooltipContent}
                      placement="left"
                      maxWidth={800}
                      isTourGuideTooltip={isFirstRowAndZeroPercentAutomation}
                    >
                      <div className="relative">
                        <Switch
                          data-testid="workload-automation-switch"
                          sx={{ ...SWITCH_SX(), cursor: isDisabled ? "not-allowed" : "pointer" }}
                          disabled={isDisabled}
                          checked={params.row.auto}
                          onChange={onChange}
                        />
                        {isFirstRowAndZeroPercentAutomation && (
                          <div
                            className="absolute top-[.5625rem] left-[.5625rem] w-[1.25rem] h-[1.25rem] rounded-full flex items-center justify-center pt-[.0313rem] pl-[.0313rem]"
                            onClick={() => {
                              onChange(undefined, true);
                            }}
                          >
                            <span
                              className="custom-animate-ping duration-1050 absolute inline-flex rounded-full bg-main-blue opacity-50"
                              style={{
                                width: ACTIVE_TOGGLE_PING_SIZE * 2,
                                height: ACTIVE_TOGGLE_PING_SIZE * 2,
                              }}
                            />
                            <span
                              className="absolute inline-flex rounded-full bg-main-blue opacity-75"
                              style={{
                                width: ACTIVE_TOGGLE_PING_SIZE,
                                height: ACTIVE_TOGGLE_PING_SIZE,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </CustomTooltip>
                  )}
                </div>
                {isEditable ? (
                  <div className="w-[2.5rem] h-[2.5rem] flex">{getWorkloadActionMenu(params.row, params.row.type)}</div>
                ) : (
                  <div className="w-[31px] flex justify-center opacity-10 hover:cursor-not-allowed">
                    <MoreVertIcon />
                  </div>
                )}
              </div>
            </CustomTooltip>
          </div>
        );
      },
    },
    { field: OverviewTableFields.OverProvisioned, type: "boolean", hide: true },
    { field: OverviewTableFields.UnderProvisioned, type: "boolean", hide: true },
  ];

  const workloadsAndIssues = () => {
    let workLoadsToSet =
      data?.workloads?.map((workload: components["schemas"]["UtilsWorkload"]) => {
        const wlWithIssues: DashWorkloadWithIssues = {
          ...workload,
        };
        return wlWithIssues;
      }) ?? [];

    workLoadsToSet = workLoadsToSet.map((w) => ({
      ...w,
      savingsAvailable: w.isReadyRecommendation ? w.savingsAvailable : -Infinity,
    }));

    /**
     * Override workloads with overridden workloads / SuperOptimistic updater mode / forced state
     * Note: use the OVERRIDE_WORKLOADS_ENABLED env var to enable this feature
     */
    workLoadsToSet = workLoadsToSet.map((wl) => {
      if (HAS_OVERRIDE_WORKLOADS_ENABLED) return wl;
      const overriddenWorkload = overriddenWorkloads.find((ow) => ow.id === wl.id);
      if (overriddenWorkload) {
        return {
          ...wl,
          ...overriddenWorkload,
        };
      }
      return wl;
    });

    workLoadsToSet.map((wl) => ({
      ...wl,
      activeSavings: wl.auto ? wl.activeSavings : 0,
    }));

    /**
     * Filter by search terms (currentSearchTerm and searchTerms)
     */

    if (currentSearchTerm && currentSearchTerm.length > 0) {
      workLoadsToSet = workLoadsToSet.filter((wl) => {
        const workloadName = getLowerCaseWorkloadName(wl);
        return workloadName.includes(currentSearchTerm);
      });
    }

    if (searchTerms && searchTerms.length > 0) {
      workLoadsToSet = workLoadsToSet.filter((wl) => {
        const workloadName = getLowerCaseWorkloadName(wl);
        return searchTerms.some((term) => {
          const lowerCaseTerm = term ? term.toLowerCase() : "";
          return workloadName.includes(lowerCaseTerm);
        });
      });
    }

    workLoadsToSet = workLoadsToSet.map((wl) => {
      wl.totalCost = wl.totalCost ?? 0;
      return wl;
    });

    /** ** ** ** ** ** ** ** ** ** **
     *
     * The column sort behavior
     *
     ** ** ** ** ** ** ** ** ** ** **/

    if (sortField && sortDirection) {
      switch (true) {
        case sortField === OverviewTableFields.Workload:
          workLoadsToSet.sort((a, b) => {
            const workloadNameA = `${a.namespace}/${a.workloadName.replace(/^scaleops-rollout-/, "")}`;
            const workloadNameB = `${b.namespace}/${b.workloadName.replace(/^scaleops-rollout-/, "")}`;
            if (workloadNameA < workloadNameB) {
              return sortDirection === ASC ? -1 : 1;
            } else if (workloadNameA > workloadNameB) {
              return sortDirection === ASC ? 1 : -1;
            } else {
              return 0;
            }
          });
          break;
        case sortField === OverviewTableFields.TotalCost:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "totalCost", true);
          break;
        case sortField === OverviewTableFields.SavingsAvailable:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "savingsAvailable", true);
          break;
        case sortField === OverviewTableFields.ActiveSavings:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "activeSavings");
          break;
        case sortField === OverviewTableFields.CPUDiff:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "cpuRequests");
          break;
        case sortField === OverviewTableFields.OriginalCPURequest:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "ownerCpuRequests");
          break;
        case sortField === OverviewTableFields.MemoryDiff:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "memRequests");
          break;
        case sortField === OverviewTableFields.OriginalMemoryRequest:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "ownerMemoryRequests");
          break;
        case sortField === OverviewTableFields.Replicas:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "runningReplicas");
          break;
        case sortField === OverviewTableFields.PolicyName:
          workLoadsToSet.sort((a, b) => {
            if (a.policyName < b.policyName) {
              return sortDirection === ASC ? -1 : 1;
            } else if (a.policyName > b.policyName) {
              return sortDirection === ASC ? 1 : -1;
            } else {
              return 0;
            }
          });
          break;
        case sortField === OverviewTableFields.Automated:
          workLoadsToSet.sort((a, b) => {
            if (a.auto && !b.auto) {
              return sortDirection === ASC ? -1 : 1;
            } else if (!a.auto && b.auto) {
              return sortDirection === ASC ? 1 : -1;
            } else {
              return 0;
            }
          });
          break;
        case sortField === OverviewTableFields.ReplicasAvailableSavings:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "replicasAvailableSavings", true);
          break;
        case sortField === OverviewTableFields.PredictableSavings:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "replicasPredictableAvailableSavings", true);
          break;
        case sortField === OverviewTableFields.StableSavings:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "replicasStableAvailableSavings", true);
          break;
        case sortField === OverviewTableFields.ReplicasActiveSavings:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "replicasActiveSavings", true);
          break;
        case sortField === OverviewTableFields.ReplicasDiff:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "recommendedReplicas");
          break;
        case sortField === OverviewTableFields.MinReplicasDiff:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "recommendedMinReplicas");
          break;
        case sortField === OverviewTableFields.PredictionErrorScore:
          workLoadsToSet = numericSort(workLoadsToSet, sortDirection, "predictionErrorScore");
          break;
        case sortField === OverviewTableFields.IsIdleStable:
          workLoadsToSet.sort((a, b) => {
            if (a.isIdleStable && !b.isIdleStable) {
              return sortDirection === ASC ? -1 : 1;
            } else if (!a.isIdleStable && b.isIdleStable) {
              return sortDirection === ASC ? 1 : -1;
            } else {
              return 0;
            }
          });
          break;

        default:
          break;
      }

      setRowCount(workLoadsToSet.length);
      return workLoadsToSet;
    }

    /** ** ** ** ** ** ** ** ** ** **
     *
     * The default sort behavior
     *
     ** ** ** ** ** ** ** ** ** ** **/

    /*
     * sort by show "namespace": "scaleops-system" last
     */
    const SCALEOPS_SYSTEM_NAMESPACE = "scaleops-system";
    workLoadsToSet.sort((a, b) => {
      if (a.namespace == SCALEOPS_SYSTEM_NAMESPACE && b.namespace != SCALEOPS_SYSTEM_NAMESPACE) {
        return 1;
      } else if (a.namespace != SCALEOPS_SYSTEM_NAMESPACE && b.namespace == SCALEOPS_SYSTEM_NAMESPACE) {
        return -1;
      } else {
        return 0;
      }
    });

    /**
     * Sort by savings available
     */
    workLoadsToSet.sort((a, b) => {
      if (a.savingsAvailable > b.savingsAvailable) {
        return -1;
      } else if (a.savingsAvailable < b.savingsAvailable) {
        return 1;
      } else {
        return 0;
      }
    });

    /**
     * Sort by automated
     */
    workLoadsToSet.sort((a, b) => {
      if (a.auto == true && b.auto == false) {
        return 1;
      } else if (a.auto == false && b.auto == true) {
        return -1;
      } else {
        return 0;
      }
    });

    /**
     * Sort workloads by recommendation readiness (inactive are at the bottom) @barel22
     */
    workLoadsToSet.sort((a, b) => {
      if (a.isReadyRecommendation == false && b.isReadyRecommendation == true) {
        return 1;
      } else if (a.isReadyRecommendation == true && b.isReadyRecommendation == false) {
        return -1;
      } else {
        return 0;
      }
    });

    /**
     * Sort by original order
     */
    if (originalOrder.current?.length === 0) {
      originalOrder.current = workLoadsToSet.map((wl) => wl.id);
    }

    /**
     * Sort by original order
     */
    if (originalOrder.current?.length > 0) {
      workLoadsToSet.sort((a, b) => {
        const aIndex = originalOrder.current.indexOf(a.id);
        const bIndex = originalOrder.current.indexOf(b.id);
        if (aIndex < bIndex) {
          return -1;
        } else if (aIndex > bIndex) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    /**
     * Show params.row.isAutomationExcluded = true workloads last
     */

    workLoadsToSet.sort((a, b) => {
      if (a.isAutomationExcluded == false && b.isAutomationExcluded == true) {
        return -1;
      } else if (a.isAutomationExcluded == true && b.isAutomationExcluded == false) {
        return 1;
      } else {
        return 0;
      }
    });

    /**
     * Show isRecommendationReady = false workloads last
     */
    workLoadsToSet.sort((a, b) => {
      if (a.isReadyRecommendation == false && b.isReadyRecommendation == true) {
        return 1;
      } else if (a.isReadyRecommendation == true && b.isReadyRecommendation == false) {
        return -1;
      } else {
        return 0;
      }
    });

    setRowCount(workLoadsToSet.length);
    return workLoadsToSet;
  };

  useEffect(() => {
    const sliceStart = page * pageSize;
    const sliceEnd = sliceStart + pageSize;
    setDisableAnimation((data?.workloads?.length ?? 0) > STOP_ANIMATION_THRESHOLD ? true : false);
    const wl = workloadsAndIssues();
    setAllRows(wl);
    const rowsToSet = wl.slice(sliceStart, sliceEnd);
    if (selectAllRowsOnInitialLoad && rowsToSet.length > 0) {
      setSelectedRows(rowsToSet);
      setSelectAllRowsOnInitialLoad(false);
    }
    setRows(rowsToSet);
    setFirstRowId(rowsToSet[0]?.id);
  }, [
    data,
    currentSearchTerm,
    searchTerms,
    overriddenWorkloads,
    originalOrder,
    page,
    pageSize,
    sortField,
    sortDirection,
  ]);

  const [showAllocatable, setShowAllocatable] = useState<boolean>(!FeatureEnabled("DemoVersion"));

  const updateShowAllocatable = (value: boolean) => {
    if (showAllocatable !== value && !isBigCluster) {
      setShowAllocatable(value);
    }
  };
  useEffect(() => {
    if (FeatureEnabled("DemoVersion")) {
      return;
    }
    if (namespacesParams?.length !== undefined && namespacesParams?.length > 0) {
      updateShowAllocatable(false);
      return;
    }
    if (policiesParams?.length !== undefined && policiesParams?.length > 0) {
      updateShowAllocatable(false);
      return;
    }
    if (priorityClassNamesParams?.length !== undefined && priorityClassNamesParams?.length > 0) {
      updateShowAllocatable(false);
      return;
    }
    if (optimizationGapsParams?.length !== undefined && optimizationGapsParams?.length > 0) {
      updateShowAllocatable(false);
      return;
    }
    if (selectedLabelNames?.length !== undefined && selectedLabelNames?.length > 0) {
      updateShowAllocatable(false);
      return;
    }
    if (typesParams?.length !== undefined && typesParams?.length > 0) {
      updateShowAllocatable(false);
      return;
    }
    if (isAutomated !== undefined && isAutomated !== null && isAutomated) {
      updateShowAllocatable(false);
      return;
    }
    if (hasMinReplicasSavings !== undefined && hasMinReplicasSavings !== null && hasMinReplicasSavings) {
      updateShowAllocatable(false);
      return;
    }
    if (hasHpa !== undefined && hasHpa !== null && hasHpa) {
      updateShowAllocatable(false);
      return;
    }
    if (isHpaPredictable !== undefined && isHpaPredictable !== null && isHpaPredictable) {
      updateShowAllocatable(false);
      return;
    }
    if (isUnAutomated !== undefined && isUnAutomated !== null && isUnAutomated) {
      updateShowAllocatable(false);
      return;
    }
    if (isOverProvisioned !== undefined && isOverProvisioned !== null && isOverProvisioned) {
      updateShowAllocatable(false);
      return;
    }
    if (isUnderProvisioned !== undefined && isUnderProvisioned !== null && isUnderProvisioned) {
      updateShowAllocatable(false);
      return;
    }
    if (isPdb !== undefined && isPdb !== null && isPdb) {
      updateShowAllocatable(false);
      return;
    }
    if (outOfMemory !== undefined && outOfMemory !== null && outOfMemory) {
      updateShowAllocatable(false);
      return;
    }
    if (availableSavingsFilter !== undefined && availableSavingsFilter !== null && availableSavingsFilter) {
      updateShowAllocatable(false);
      return;
    }
    updateShowAllocatable(!FeatureEnabled("DemoVersion"));
  }, [
    namespacesParams,
    policiesParams,
    priorityClassNamesParams,
    optimizationGapsParams,
    selectedLabelNames,
    typesParams,
    isAutomated,
    hasMinReplicasSavings,
    hasHpa,
    isHpaPredictable,
    isUnAutomated,
    isOverProvisioned,
    isUnderProvisioned,
    isPdb,
    outOfMemory,
    availableSavingsFilter,
  ]);

  useEffect(() => {
    if (!isLoading && !rowsWasInitialized) {
      setRowsWasInitialized(true);
    }
  }, [rows]);

  const totalNumberOfPages = Math.ceil(rows.length / pageSize);
  const isPageLastPage = page === totalNumberOfPages - 1 && rows.length > pageSize;

  const isTableLoading = isLoading || !rowsWasInitialized;

  return (
    <div className="flex flex-col gap-3">
      {selectedWorkload && (
        <ErrorBoundary fallback={<DefaultFallback message="An error occurred while opening Policy Tuning" />}>
          <PolicyTuning
            selectedWorkload={selectedWorkload}
            isOpen={isPolicyTuningDialogOpen}
            onClose={() => {
              setSelectedWorkload(undefined);
              setSelectedWorkloadOverviewId(undefined);
              setHideSuffix(false);
              setIsPolicyTuningDialogOpen(false);
            }}
            fetchWorkloads={fetchWorkloadsProp}
            hideWorkloadSuffix={hideSuffix}
          />
        </ErrorBoundary>
      )}
      {HAS_NODE_ACTIONS_RECOMMENDATION_FF && (
        <NodeActionRecommendation
          totalAutomatedWorkloads={data?.totalNamespaceSummary?.automatedWorkloads || 0}
          totalUnAutomatedWorkloads={data?.totalNamespaceSummary?.unautomatedWorkloads || 0}
        />
      )}
      <div style={{ paddingTop: ".375rem" }}>
        <Paper
          sx={{
            width: "100%",
            boxShadow: "none",
            border: "none",
            borderTopLeftRadius: "0rem",
          }}
          className="p-[1.25rem] relative"
        >
          <EditToolbar
            canEditAllWorkloads={data?.metadata?.canEditAllWorkloads}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            fetchWorkloads={fetchWorkloads}
            updateAutoInBulk={updateAutoInBulk}
            applyInBulk={applyInBulk}
            namespaces={namespacesForFilter}
            workloadsLabels={workloadsLabels}
            workloadsAnnotations={workloadsAnnotations}
            types={typesForFilter}
            priorityClassNames={priorityClassNames}
            optimizationGaps={optimizationGaps}
            hpaPredictable={["predictable", "idleStable"]}
            automateByFilter={automateByFilter}
            policies={policies}
            attachPolicyToNamespace={attachPolicyToNamespace}
            attachPolicyInBulk={attachPolicyInBulk}
            restartInBulk={restartInBulk}
            restorePolicyInBulk={restorePolicyInBulk}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            availableColumns={availableColumns}
            namespaceAnnotations={namespaceAnnotations}
            numberOfRows={allRows.length}
          />
          <DataGrid
            key={`${String(windowSize.width)}-${String(windowSize.height)}`}
            pagination={true}
            headerHeight={HEADER_HEIGHT}
            autoHeight={allRows.length <= pageSize}
            rowHeight={ROW_HEIGHT}
            checkboxSelection
            sx={{
              ...getDataGridSx(true),
              width: "100%",
              border: isPageLastPage ? undefined : "none",
              height: pageSize * ROW_HEIGHT + HEADER_HEIGHT + FOOTER_HEIGHT,
              "& .MuiDataGrid-cellCheckbox": {
                borderLeft: ".0625rem solid #c4c4c4",
              },
              "& .workloadCellContent": {
                padding: "0rem",
              },
              "& NaNpxovePadding.MuiDataGrid-cell": {
                padding: "0rem",
              },
              "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                opacity: isLoading ? "50%" : "100%",
                cursor: isLoading ? "not-allowed" : "pointer",
              },
            }}
            columns={columns}
            isCellEditable={(params) => {
              return !!params.row.isEditable;
            }}
            rows={rows}
            initialState={{
              pagination: {
                pageSize: pageSize,
              },
            }}
            pageSize={pageSize}
            onPageChange={(newPage) => {
              setPage(newPage);
            }}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
            }}
            loading={isTableLoading}
            experimentalFeatures={{ newEditingApi: true }}
            paginationMode="server"
            sortingMode="server"
            onSortModelChange={(x) => {
              if (isInitialSortModel) setIsInitialSortModel(false);
              setSortField(x[0]?.field);
              setSortDirection(x[0]?.sort);
            }}
            sortModel={
              isInitialSortModel && !sortField && !sortDirection
                ? INITIAL_SORT_MODEL
                : [
                  {
                    field: String(sortField),
                    sort: String(sortDirection) as GridSortDirection,
                  },
                ]
            }
            rowCount={rowCount}
            style={{ borderRadius: ".3125rem" }}
            processRowUpdate={processRowUpdate}
            disableSelectionOnClick
            onRowClick={(params: GridRowParams<components["schemas"]["UtilsWorkload"]>) => {
              setLastTimeWorkloadRowWasClicked(new Date().getTime() / 1000);
              setWorkloadRowClickCount(Number(workloadRowClickCount) + 1);
              const selection = window.getSelection();
              const selectedText = selection?.toString();
              if (highlightedSelection !== selectedText) {
                setHighlightedSelection(selectedText);
              }

              if (
                params.row.isReadyRecommendation &&
                (!selectedText?.length || selectedText.length === 0 || selectedText === highlightedSelection)
              ) {
                setSelectedWorkloadOverviewId(params.row.id);
                setHideSuffix(!!params.row.hideSuffix);
                setIsPolicyTuningDialogOpen(true);
              }
            }}
            getRowClassName={(params) =>
              clsx({
                automatedRow: params.row.auto === true,
                "cursor-pointer group": params.row.isReadyRecommendation,
              })
            }
            keepNonExistentRowsSelected
            onSelectionModelChange={(selectedIds) => {
              switch (true) {
                case isLoading:
                  break;
                case Number(selectedIds.length) === pageSize && selectedRows && allRows.length !== selectedRows.length:
                  setSelectedRows([...allRows]);
                  break;
                case Number(selectedIds.length) === allRows.length - pageSize && selectedIds.length !== 1:
                  setSelectedRows([]);
                  break;
                case Number(selectedIds.length) === 1:
                  setSelectedRows(allRows.filter((row) => row.id === selectedIds[0]));
                  break;
                default:
                  setSelectedRows(allRows.filter((row) => selectedIds.includes(row.id)));
                  break;
              }
            }}
            selectionModel={selectedRows?.map((row) => row.id) ?? []}
            hideFooterSelectedRowCount
          />
          {selectedRows && selectedRows.length > 0 && (
            <Typography variant="body2" className="w-fit left-[1.0625rem] top-[-2.25rem] relative">
              {selectedRows.length} {selectedRows.length === 1 ? "workload" : "workloads"} selected
            </Typography>
          )}
          <OverviewExportCSVButton
            csvData={[...(allRows ?? [])]}
            selectedColumns={selectedColumns}
            hideExport={(selectedRows && selectedRows.length !== 0) || !rows || rows.length === 0}
          />
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert severity={snackbar?.severity} onClose={handleCloseSnackbar}>
                {snackbar?.children?.message}
              </Alert>
            </Snackbar>
          )}
          <GitConfigPopup id={gitWorkflow?.workloadName} workload={gitWorkflow} setWorkload={setGitWorkflow} />
        </Paper>
      </div>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert severity={snackbar?.severity} onClose={handleCloseSnackbar}>
            {snackbar?.children?.message}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export interface policyDiaglogAutoToggleParams {
  isFreeDistLimited: boolean;
  isAutoCheck: boolean;
  setIsAutoCheck: React.Dispatch<React.SetStateAction<boolean>>;
  isAutoSideEffectCb: (check: boolean) => void;
}
