import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { StringParam, useQueryParam } from "use-query-params";
import {
  GetPolicyTuningDiagnostics,
  GetPolicyTuningDiagnosticsParams,
  GetPolicyTuningDiagnosticsResponse,
} from "../../../../api/fetcher";
import { SELECTED_CONTAINER_KEY } from "../ContainerFilter";
import DiagnosticsChart from "./DiagnosticsChart";
import { EventPoint, EventType } from "./utils";

const { queryFn, queryKey } = GetPolicyTuningDiagnostics();

const DiagnosticsContainer = ({
  name,
  namespace,
  endDate,
  period,
  startDate,
  selectedViewPeriod,
  emptyEventArray,
  forcedIsLoading,
}: GetPolicyTuningDiagnosticsParams & {
  selectedViewPeriod: string;
  emptyEventArray: EventPoint[] | undefined;
  forcedIsLoading?: boolean;
}) => {
  const [selectedContainer] = useQueryParam(SELECTED_CONTAINER_KEY, StringParam);
  const [parsedData, setParsedData] = useState<EventPoint[] | undefined>(undefined);

  const { data, isLoading } = useQuery<GetPolicyTuningDiagnosticsResponse, Error>({
    queryKey: [queryKey, name, namespace, endDate, period, startDate, selectedContainer, emptyEventArray],
    queryFn: () => {
      return queryFn({ name, namespace, endDate, period, startDate, container: selectedContainer });
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const isParseDataOutOfSync = () => {
    return (
      emptyEventArray?.length !== parsedData?.length ||
      (emptyEventArray?.length &&
        parsedData?.length &&
        (emptyEventArray[0]?.timestamp !== parsedData[0]?.timestamp ||
          emptyEventArray[emptyEventArray.length - 1]?.timestamp !== parsedData[parsedData.length - 1]?.timestamp))
    );
  };
  useEffect(() => {
    const parsedDataContent = JSON.parse(JSON.stringify(emptyEventArray ?? "")) as EventPoint[];

    if (data?.diagnosticEventsSeries) {
      data.diagnosticEventsSeries.forEach((event, index) => {
        const eventEpochTimestamp = dayjs(event.timestamp).unix() * 1000;
        const nextEventEpochTimestamp = data.diagnosticEventsSeries
          ? dayjs(data.diagnosticEventsSeries[index + 1]?.timestamp).unix() * 1000
          : 0;
        const parsedDataIndex = parsedDataContent
          ? parsedDataContent?.findIndex((item) => {
              const currentEventTimestamp = Number(item.timestamp) * 1000;
              return currentEventTimestamp >= eventEpochTimestamp && currentEventTimestamp < nextEventEpochTimestamp;
            })
          : -1;

        if (parsedDataIndex > -1) {
          parsedDataContent[parsedDataIndex] = {
            ...parsedDataContent[parsedDataIndex],
            [EventType.AUTO]: event.auto || parsedDataContent[parsedDataIndex][EventType.AUTO] ? 1 : undefined,
            [EventType.AUTO_HEALING]:
              (event.autoHealing ?? 0) + (parsedDataContent[parsedDataIndex][EventType.AUTO_HEALING] ?? 0),
            [EventType.CPU_THROTTLING]:
              (event.cpuThrottling ?? 0) + (parsedDataContent[parsedDataIndex][EventType.CPU_THROTTLING] ?? 0),
            [EventType.EVICTION]: (event.eviction ?? 0) + (parsedDataContent[parsedDataIndex][EventType.EVICTION] ?? 0),
            [EventType.HIGH_UTILIZATION_NODES]:
              (event.highUtilizationNodes ?? 0) +
              (parsedDataContent[parsedDataIndex][EventType.HIGH_UTILIZATION_NODES] ?? 0),
            [EventType.OOM_EVENT]:
              (event.oomEvent ?? 0) + (parsedDataContent[parsedDataIndex][EventType.OOM_EVENT] ?? 0),
            [EventType.OOM_KUBELET]:
              (event.oomKubelet ?? 0) + (parsedDataContent[parsedDataIndex][EventType.OOM_KUBELET] ?? 0),
            [EventType.OOM_LIMIT]:
              (event.oomLimit ?? 0) + (parsedDataContent[parsedDataIndex][EventType.OOM_LIMIT] ?? 0),
            [EventType.OOM_NODE]: (event.oomNode ?? 0) + (parsedDataContent[parsedDataIndex][EventType.OOM_NODE] ?? 0),
            [EventType.CPU_FAST_REACTION]:
              (event.cpuFastReaction ?? 0) + (parsedDataContent[parsedDataIndex][EventType.CPU_FAST_REACTION] ?? 0),
            [EventType.MEMORY_FAST_REACTION]:
              (event.memoryFastReaction ?? 0) +
              (parsedDataContent[parsedDataIndex][EventType.MEMORY_FAST_REACTION] ?? 0),
            [EventType.FAST_REACTION]:
              (event.cpuFastReaction ?? 0) +
              (event.memoryFastReaction ?? 0) +
              (parsedDataContent[parsedDataIndex][EventType.FAST_REACTION] ?? 0),
            [EventType.IMAGE_CHANGE]:
              (event.imageChange ?? 0) + (parsedDataContent[parsedDataIndex][EventType.IMAGE_CHANGE] ?? 0),
          };
        }
      });
    }
    if ((data?.diagnosticEventsSeries && emptyEventArray && !parsedData) || (data && isParseDataOutOfSync()))
      setParsedData(parsedDataContent);
  }, [
    data,
    emptyEventArray && emptyEventArray[0]?.timestamp,
    emptyEventArray && emptyEventArray[emptyEventArray.length - 1]?.timestamp,
  ]);

  return (
    <DiagnosticsChart
      data={parsedData}
      isLoading={isLoading || forcedIsLoading}
      selectedViewPeriod={selectedViewPeriod}
      includedElements={[
        EventType.AUTO,
        EventType.FAST_REACTION,
        EventType.AUTO_HEALING,
        EventType.EVICTION,
        EventType.CPU_THROTTLING,
        EventType.HIGH_UTILIZATION_NODES,
        EventType.OOM_LIMIT,
        EventType.OOM_NODE,
        EventType.IMAGE_CHANGE,
      ]}
    />
  );
};

export default DiagnosticsContainer;
