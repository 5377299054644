export const unEvictableWorkloadsInfo = (
  <>
    <b>Unevictable workloads</b> have <b>annotation</b> that prevents autoscaler eviction,
    <br />
    or are associated with <b>PDB</b> (Pod Disruption Budgets).
  </>
);

export const blockedNodesInfo = (
  <>
    <b>Blocked nodes</b> have at least one <b>unevictable workloads</b>.
  </>
);
