import { chartStyle } from "../../../../utils/styleUtils";

export const SYNC_ID = "analyticsPageSyncId";

export enum GraphLine {
  Usage = "usage",
  UsageUnknown = "usageUnknownOwner",
  RequestsOrigin = "requestsOrigin",
  Requests = "requests",
  RequestsUnknown = "requestsUnknownOwner",
  Recommendation = "recommendation",
  Allocatable = "allocatable",
}

export const graphLineNames = {
  [GraphLine.Usage]: "usage",
  [GraphLine.UsageUnknown]: "usage",
  [GraphLine.Requests]: "total requests",
  [GraphLine.RequestsUnknown]: "requests",
  [GraphLine.RequestsOrigin]: "original requests",
  [GraphLine.Recommendation]: "recommendation",
  [GraphLine.Allocatable]: "total allocatable",
};

export const LineStyle = {
  [GraphLine.Usage]: {
    type: chartStyle.usage.type,
    fill: chartStyle.usage.fill,
    fillOpacity: chartStyle.usage.fillOpacity,
    stroke: chartStyle.usage.stroke,
    strokeWidth: chartStyle.usage.strokeWidth,
  },
  [GraphLine.UsageUnknown]: {
    type: chartStyle.usageUnknown.type,
    fill: chartStyle.usageUnknown.fill,
    fillOpacity: chartStyle.usageUnknown.fillOpacity,
    stroke: chartStyle.usageUnknown.stroke,
    strokeWidth: chartStyle.usageUnknown.strokeWidth,
  },
  [GraphLine.Requests]: {
    type: chartStyle.request.type,
    fill: "white",
    fillOpacity: 0,
    stroke: chartStyle.request.stroke,
    strokeWidth: chartStyle.request.strokeWidth,
  },
  [GraphLine.RequestsUnknown]: {
    type: chartStyle.requestUnknown.type,
    fill: "white",
    fillOpacity: 0,
    stroke: chartStyle.requestUnknown.stroke,
    strokeWidth: chartStyle.requestUnknown.strokeWidth,
  },
  [GraphLine.RequestsOrigin]: {
    type: chartStyle.RequestsOrigin.type,
    fill: chartStyle.RequestsOrigin.fill,
    fillOpacity: chartStyle.RequestsOrigin.fillOpacity,
    stroke: chartStyle.RequestsOrigin.stroke,
    strokeWidth: chartStyle.RequestsOrigin.strokeWidth,
  },
  [GraphLine.Recommendation]: {
    type: chartStyle.Recommendation.type,
    fill: chartStyle.Recommendation.fill,
    fillOpacity: chartStyle.Recommendation.fillOpacity,
    stroke: chartStyle.Recommendation.stroke,
    strokeWidth: chartStyle.Recommendation.strokeWidth,
  },
  [GraphLine.Allocatable]: {
    type: chartStyle.Allocatable.type,
    fill: chartStyle.Allocatable.fill,
    fillOpacity: chartStyle.Allocatable.fillOpacity,
    stroke: chartStyle.Allocatable.stroke,
    strokeWidth: chartStyle.Allocatable.strokeWidth,
  },
};

export enum ResourceType {
  CPU = "cpu",
  Memory = "memory",
}
