import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import { SCALEOPS_COLORS } from "../../../../colors";
import CircleArrowUpWithFillIcon from "../../../../Icons/CircleArrowUpWithFillIcon";
import FastReactionIcon from "../../../../Icons/FastReactionIcon";
import HealingIcon from "../../../../Icons/HealingIcon";
import OOMIcon from "../../../../Icons/OOMIcon";
import StartsIcon from "../../../../Icons/StartsIcon";
import ThrottlingIcon from "../../../../Icons/ThrottlingIcon";
import TrendIcon from "../../../../Icons/TrendIcon";

dayjs.extend(dayjsPluginUTC);

export enum EventType {
  AUTO_HEALING = "autoHealing",
  CPU_THROTTLING = "cpuThrottling",
  EVICTION = "eviction",
  HIGH_UTILIZATION_NODES = "highUtilizationNodes",
  OOM_EVENT = "oomEvent",
  OOM_KUBELET = "oomKubelet",
  OOM_LIMIT = "oomLimit",
  OOM_NODE = "oomNode",
  HIGH_CPU_UTILIZATION = "highCpuUtilization",
  HIGH_MEMORY_UTILIZATION = "highMemoryUtilization",
  NODE_MEMORY_PRESSURE_EVENT = "nodeMemoryPressureEvent",
  CPU_FAST_REACTION = "cpuFastReaction",
  MEMORY_FAST_REACTION = "memoryFastReaction",
  FAST_REACTION = "fastReaction",
  AUTO = "auto",
  IMAGE_CHANGE = "imageChange",
}

export enum EventName {
  memoryFastReaction = "Memory fast reaction",
  cpuFastReaction = "CPU fast reaction",
  fastReaction = "Fast reaction",
  cpuThrottling = "CPU throttling",
  eviction = "Eviction",
  highUtilizationNodes = "CPU stressed nodes",
  oomEvent = "OOM event",
  oomKubelet = "OOM kubelet",
  oomLimit = "OOM limit",
  oomNode = "OOM node",
  autoHealing = "Auto-healing",
  highCpuUtilization = "CPU stress node",
  highMemoryUtilization = "High memory utilization",
  nodeMemoryPressureEvent = "Node memory pressure event",
  auto = "Automated",
  imageChange = "Image changed",
}

export type EventPoint = {
  autoHealing?: number | null;
  cpuThrottling?: number | null;
  eviction?: number | null;
  highUtilizationNodes?: number | null;
  oomEvent?: number | null;
  oomKubelet?: number | null;
  oomLimit?: number | null;
  oomNode?: number | null;
  timestamp?: string | number;
  timestampEnd?: string | number;
  highCpuUtilization?: number | null;
  highMemoryUtilization?: number | null;
  nodeMemoryPressureEvent?: number | null;
  cpuFastReaction?: number | null;
  memoryFastReaction?: number | null;
  fastReaction?: number | null;
  auto?: number | null;
  imageChange?: number | null;
};

export type RawData =
  | {
      cpuThrottling: number;
      autoHealing: number;
      eviction: number;
      highUtilizationNodes: number;
      oomEvent: number;
      oomKubelet: number;
      oomLimit: number;
      oomNode: number;
      totalNumberOfEvents?: number;
      timestamp: string;
      cpuFastReaction: number;
      memoryFastReaction: number;
      auto: number;
      timestampEnd?: string;
    }[];

const emptyPoint = {
  autoHealing: 0,
  cpuThrottling: 0,
  eviction: 0,
  highUtilizationNodes: 0,
  oomEvent: 0,
  oomKubelet: 0,
  oomLimit: 0,
  oomNode: 0,
  cpuFastReaction: 0,
  memoryFastReaction: 0,
  auto: 0,
};

export const groupRawDataByMinutesInterval = (
  rawData: RawData,
  minutesInterval: number,
  startDate: string | undefined,
  endDate: string | undefined,
  shouldAddDates = true
) => {
  let response: RawData = [];
  const baseTime = dayjs.utc(startDate).unix();
  const endTime = dayjs.utc(endDate).unix();
  minutesInterval = minutesInterval * 60;

  if (shouldAddDates) {
    let timestampToAdd = baseTime;
    while (timestampToAdd <= endTime) {
      const timestamp = dayjs(timestampToAdd * 1000)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      const timestampEnd = dayjs(timestampToAdd * 1000)
        .add(minutesInterval, "second")
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      response = [
        ...response,
        {
          ...emptyPoint,
          timestamp,
          timestampEnd,
        },
      ];
      timestampToAdd += minutesInterval;
    }
  }

  rawData.forEach((point) => {
    const pointTimestamp = dayjs(point.timestamp);

    const existingPoint = response.find((el) => {
      return (
        (dayjs(pointTimestamp).isAfter(dayjs.utc(el.timestamp)) ||
          dayjs.utc(pointTimestamp).isSame(dayjs.utc(el.timestamp))) &&
        (dayjs(pointTimestamp).isBefore(dayjs.utc(el.timestampEnd)) ||
          dayjs.utc(pointTimestamp).isSame(dayjs.utc(el.timestampEnd)))
      );
    });

    if (existingPoint) {
      existingPoint.autoHealing += point.autoHealing;
      existingPoint.cpuThrottling += point.cpuThrottling;
      existingPoint.eviction += point.eviction;
      existingPoint.highUtilizationNodes += point.highUtilizationNodes;
      existingPoint.oomEvent += point.oomEvent;
      existingPoint.oomKubelet += point.oomKubelet;
      existingPoint.oomLimit += point.oomLimit;
      existingPoint.oomNode += point.oomNode;
      existingPoint.cpuFastReaction += point.cpuFastReaction;
      existingPoint.memoryFastReaction += point.memoryFastReaction;
      existingPoint.auto += point.auto;

      response = response.map((el) => {
        if (dayjs(el.timestamp).isSame(existingPoint.timestamp)) {
          return existingPoint;
        }
        return el;
      });
    }
  });

  return response.sort((first, second) => {
    return dayjs(first.timestamp).diff(dayjs(second.timestamp));
  });
};

export type ParsedData = {
  autoHealing?: number | null;
  cpuThrottling?: number | null;
  eviction?: number | null;
  highUtilizationNodes?: number | null;
  oomEvent?: number | null;
  oomKubelet?: number | null;
  oomLimit?: number | null;
  oomNode?: number | null;
  timestamp?: string | number;
  timestampEnd?: string | number;
  highCpuUtilization?: number | null;
  highMemoryUtilization?: number | null;
  nodeMemoryPressureEvent?: number | null;
  cpuFastReaction?: number | null;
  memoryFastReaction?: number | null;
  auto?: number | null;
  imageChange?: number | null;
}[];

interface GetParsedData {
  rawData: EventPoint[];
}

export const getParsedData = ({ rawData }: GetParsedData): ParsedData | undefined => {
  if (!rawData) return undefined;

  const parsedData = rawData.map((point) => {
    return {
      ...point,
      timestamp: dayjs(point.timestamp).utc().format("DD/MMM/YYYY HH:mm"),
      timestampEnd: dayjs(point.timestampEnd).utc().format("DD/MMM/YYYY HH:mm"),
    };
  });

  return parsedData;
};

export const EVENT_COLORS = {
  eviction: "#9BCDD2",
  autoHealing: "rgba(45,174,16,0.35)",
  cpuThrottling: SCALEOPS_COLORS.events.cpuThrottling,
  highUtilizationNodes: SCALEOPS_COLORS.guideline.lessDarkPurple,
  oomEvent: "#fac2c2",
  oomKubelet: SCALEOPS_COLORS.events.oomKubelet,
  oomLimit: SCALEOPS_COLORS.events.oomLimit,
  oomNode: SCALEOPS_COLORS.main.burgundy,
  highCpuUtilization: SCALEOPS_COLORS.guideline.lessDarkPurple,
  highMemoryUtilization: "#9BCDD2",
  nodeMemoryPressureEvent: "#fac2c2",
  cpuFastReaction: SCALEOPS_COLORS.events.cpuFastReaction,
  memoryFastReaction: SCALEOPS_COLORS.events.memoryFastReaction,
  fastReaction: SCALEOPS_COLORS.events.fastReaction,
  auto: SCALEOPS_COLORS.main.green,
  imageChange: "#9d6f96",
};

export const Y_OFFSET = 25;
export const ICON_SIZE = 20;
const ICON_OPACITY = 0.8;

export const DiagnosticIcons: Record<EventType, (x: number, y: number) => JSX.Element | null> = {
  [EventType.AUTO]: () => null,
  [EventType.AUTO_HEALING]: (x: number, y: number) => (
    <HealingIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.autoHealing}
    />
  ),
  [EventType.CPU_THROTTLING]: (x: number, y: number) => (
    <ThrottlingIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.cpuThrottling}
    />
  ),
  [EventType.CPU_FAST_REACTION]: (x: number, y: number) => (
    <FastReactionIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.cpuFastReaction}
    />
  ),
  [EventType.MEMORY_FAST_REACTION]: (x: number, y: number) => (
    <FastReactionIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.cpuFastReaction}
    />
  ),
  [EventType.FAST_REACTION]: (x: number, y: number) => (
    <FastReactionIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.fastReaction}
    />
  ),
  [EventType.EVICTION]: (x: number, y: number) => (
    <StartsIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.eviction}
    />
  ),
  [EventType.HIGH_UTILIZATION_NODES]: (x: number, y: number) => (
    <TrendIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.highUtilizationNodes}
    />
  ),
  // oom events
  [EventType.OOM_EVENT]: (x: number, y: number) => (
    <OOMIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.oomEvent}
    />
  ),
  [EventType.OOM_KUBELET]: (x: number, y: number) => (
    <OOMIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.oomKubelet}
    />
  ),
  [EventType.OOM_LIMIT]: (x: number, y: number) => (
    <OOMIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.oomLimit}
    />
  ),
  [EventType.OOM_NODE]: (x: number, y: number) => (
    <OOMIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.oomNode}
    />
  ),
  [EventType.HIGH_CPU_UTILIZATION]: (x: number, y: number) => (
    <TrendIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.highCpuUtilization}
    />
  ),
  [EventType.HIGH_MEMORY_UTILIZATION]: (x: number, y: number) => (
    <TrendIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.highMemoryUtilization}
    />
  ),
  [EventType.NODE_MEMORY_PRESSURE_EVENT]: (x: number, y: number) => (
    <OOMIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.nodeMemoryPressureEvent}
    />
  ),
  [EventType.IMAGE_CHANGE]: (x: number, y: number) => (
    <CircleArrowUpWithFillIcon
      x={x}
      y={y - Y_OFFSET}
      width={ICON_SIZE}
      height={ICON_SIZE}
      opacity={ICON_OPACITY}
      fill={EVENT_COLORS.imageChange}
    />
  ),
};
