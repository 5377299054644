import clsx from "clsx";
import RunningNumberBox from "../../pages/Overview/TopOverviewSection/RunningNumberBox";
import { FeatureEnabled } from "../../utils/FeaturesHelper";
import { SCALEOPS_COLORS } from "../../colors";
import PartialBorders from "../PartialBorders";

const HALF_DIV_CLASS_WRAP = `h-[134px]`;

const AverageSpan = () => <span className="text-text-disable text-[14px]">(avg.)</span>;
interface Props {
  cost: number;
  cpuUsage: number;
  cpuRequests: number;
  cpuAllocated: number;
  memoryUsage: number;
  memoryRequests: number;
  memoryAllocated: number;
  availableSavings: number;
}
const NodesTopOverview = ({
  cost,
  cpuUsage,
  cpuRequests,
  cpuAllocated,
  memoryUsage,
  memoryRequests,
  memoryAllocated,
  availableSavings,
}: Props) => {
  const cpuRequestPercentage = cpuRequests / cpuAllocated;
  const cpuUsagePercentage = cpuUsage / cpuAllocated;
  const memoryRequestPercentage = memoryRequests / memoryAllocated;
  const memoryUsagePercentage = memoryUsage / memoryAllocated;

  const showDemoStructure = FeatureEnabled("DemoVersion") && !FeatureEnabled("ShowNodeSimulation");

  return (
    <div className={`w-full rounded flex h-[270px] bg-white relative`}>
      {showDemoStructure ? (
        <PartialBorders right wrapperClassName="h-full relative">
          <PartialBorders bottom wrapperClassName={HALF_DIV_CLASS_WRAP}>
            <RunningNumberBox
              value={cost}
              title="Monthly cost"
              numberVariant="h4"
              numberClassName="text-text-lightBlack"
              prefix="$"
            />
          </PartialBorders>
          <PartialBorders wrapperClassName={HALF_DIV_CLASS_WRAP}>
            <RunningNumberBox
              value={availableSavings}
              title="Node group available savings"
              numberVariant="h4"
              numberClassName={clsx({
                "text-main-green": availableSavings >= 1,
                "text-text-lightBlack": availableSavings < 1,
              })}
              prefix="$"
            />
          </PartialBorders>
        </PartialBorders>
      ) : (
        <PartialBorders right wrapperClassName="w-[70%]">
          <RunningNumberBox
            value={cost}
            title="Monthly cost"
            numberVariant="h3"
            prefix="$"
            numberClassName="text-text-lightBlack"
          />
        </PartialBorders>
      )}
      <PartialBorders right wrapperClassName="h-full relative">
        <PartialBorders bottom wrapperClassName={HALF_DIV_CLASS_WRAP}>
          <RunningNumberBox
            value={cpuRequestPercentage * 100}
            barPercentageValue={cpuRequestPercentage}
            title={
              <>
                CPU request <AverageSpan />
              </>
            }
            barPercentageColor={SCALEOPS_COLORS.guideline.darkYellow}
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            suffix="%"
          />
        </PartialBorders>
        <PartialBorders wrapperClassName={HALF_DIV_CLASS_WRAP}>
          <RunningNumberBox
            value={cpuUsagePercentage * 100}
            barPercentageValue={cpuUsagePercentage}
            title={
              <>
                CPU usage <AverageSpan />
              </>
            }
            barPercentageColor={SCALEOPS_COLORS.main.blue}
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            suffix="%"
          />
        </PartialBorders>
      </PartialBorders>
      <PartialBorders>
        <PartialBorders bottom wrapperClassName={HALF_DIV_CLASS_WRAP}>
          <RunningNumberBox
            value={memoryRequestPercentage * 100}
            barPercentageValue={memoryRequestPercentage}
            title={
              <>
                Memory request <AverageSpan />
              </>
            }
            barPercentageColor={SCALEOPS_COLORS.guideline.darkYellow}
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            suffix="%"
          />
        </PartialBorders>
        <PartialBorders wrapperClassName={HALF_DIV_CLASS_WRAP}>
          <RunningNumberBox
            value={memoryUsagePercentage * 100}
            barPercentageValue={memoryUsagePercentage}
            title={
              <>
                Memory usage <AverageSpan />
              </>
            }
            barPercentageColor={SCALEOPS_COLORS.main.blue}
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            suffix="%"
          />
        </PartialBorders>
      </PartialBorders>
    </div>
  );
};

export default NodesTopOverview;
