import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  GetWorkloadAnnotations,
  GetWorkloadAnnotationsResponse,
  GetWorkloadLabels,
  GetWorkloadLabelsResponse,
} from "../../../api/fetcher";
import WorkloadPropertiesChip from "./WorkloadPropertiesChip";

interface Props {
  workloadName: string | undefined;
  name: string | undefined;
  namespace: string | undefined;
  kind: string | undefined;
}

const annotations = GetWorkloadAnnotations();
const labels = GetWorkloadLabels();

const PolicyTuningDialogueTitle = ({ workloadName, name, namespace, kind }: Props) => {
  const queriesParams = { name: String(name), namespace: String(namespace), kind: String(kind) };

  const { data: annotationsData } = useQuery<GetWorkloadAnnotationsResponse, Error>({
    queryKey: [annotations.queryKey, { name, namespace, kind }],
    queryFn: () => annotations.queryFn(queriesParams),
  });

  const { data: labelsData } = useQuery<GetWorkloadLabelsResponse, Error>({
    queryKey: [labels.queryKey, { name, namespace, kind }],
    queryFn: () => labels.queryFn(queriesParams),
  });

  return (
    <div className="flex items-center gap-2">
      <Typography fontWeight={500}>Workload overview</Typography>
      <ArrowRightAltIcon />
      <span>{workloadName}</span>
      <WorkloadPropertiesChip title="Annotations" items={annotationsData?.annotations ?? []} />
      <WorkloadPropertiesChip title="Labels" items={labelsData?.labels ?? []} />
    </div>
  );
};

export default PolicyTuningDialogueTitle;
