import { SCALEOPS_COLORS } from "../colors";

/**
 * Chart Style
 * The main style chart (recharts)
 */

export const LINE_CHART_TYPE = "linear";
export const STROKE_WIDTH = 2;
export const FILL_OPACITY = 0.4;

export const usageStyle = {
  type: LINE_CHART_TYPE,
  fill: SCALEOPS_COLORS.main.blue,
  fillOpacity: FILL_OPACITY,
  stroke: SCALEOPS_COLORS.main.blue,
  strokeWidth: STROKE_WIDTH,
};

export const requestStyle = {
  type: LINE_CHART_TYPE,
  fill: "none",
  fillOpacity: undefined,
  stroke: SCALEOPS_COLORS.guideline.darkYellow,
  strokeWidth: STROKE_WIDTH,
};

export const chartStyle = {
  usage: usageStyle,
  usageUnknown: usageStyle,
  request: requestStyle,
  requestUnknown: requestStyle,
  RequestsOrigin: {
    type: LINE_CHART_TYPE,
    fill: "none",
    fillOpacity: undefined,
    stroke: SCALEOPS_COLORS.primary.pink,
    strokeWidth: STROKE_WIDTH,
  },
  Recommendation: {
    type: LINE_CHART_TYPE,
    fill: "none",
    fillOpacity: undefined,
    stroke: SCALEOPS_COLORS.guideline.darkGreen,
    strokeWidth: STROKE_WIDTH,
  },
  Allocatable: {
    type: LINE_CHART_TYPE,
    fill: "none",
    fillOpacity: undefined,
    stroke: SCALEOPS_COLORS.main.orange,
    strokeWidth: STROKE_WIDTH,
  },
  Savings: {
    type: LINE_CHART_TYPE,
    fill: "none",
    fillOpacity: undefined,
    stroke: SCALEOPS_COLORS.main.green,
    strokeWidth: STROKE_WIDTH,
  },
};

/**
 * Table styles
 * Mainly used for MUI DataGrid
 */

export const SELECTED_BACKGROUND = "#E1E1FF";
export const HEADER_HEIGHT = 66;
export const ROW_HEIGHT = 66;
export const FOOTER_HEIGHT = 53;

export const getDataGridSx = (hasCheckboxFirstColumn?: boolean, hasColoredHoveredRows = true) => ({
  border: "1px solid #C4C4C4",
  borderRadius: "10px",
  "& .MuiDataGrid-columnHeaders": {
    borderRadius: "10px 10px 0 0",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    textOverflow: "ellipsis",
    whiteSpace: "break-spaces",
  },
  "& .MuiDataGrid-columnHeader": {
    background: "#EBECF2",
    paddingLeft: "20px",
    paddingRight: "20px",
    borderRight: "2px solid white",
  },
  "& .MuiDataGrid-columnHeader:last-child": {
    border: "none",
  },
  "& .MuiDataGrid-columnHeader:first-child": {
    borderRadius: "10px 0 0 0",
    paddingLeft: hasCheckboxFirstColumn ? "3px" : "20px",
    paddingRight: hasCheckboxFirstColumn ? "0px" : "20px",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-cell": {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .automatedRow": {
    background: "#53d39d1a",
  },
  "& .MuiDataGrid-row:last-child": {
    borderRadius: "0 0 10px 10px",
  },
  "& .MuiDataGrid-row:hover": {
    background: hasColoredHoveredRows && SELECTED_BACKGROUND,
  },
  "& .MuiDataGrid-row.Mui-selected": {
    background: hasColoredHoveredRows && SELECTED_BACKGROUND,
    "&:hover": {
      background: hasColoredHoveredRows && SELECTED_BACKGROUND,
    },
  },
  "& .fullCellTooltipWrapper.MuiDataGrid-cell": {
    padding: "0px",
  },
  "& .fullCellTooltipContent": {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  "& svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium": {
    width: "16px",
  },
});

export const SWITCH_SX = (theme: undefined | "green" | "purple" = "green") => {
  const offColor = "#c4c4c4";
  let onColor;
  switch (theme) {
    case "purple":
      onColor = SCALEOPS_COLORS.guideline.darkPurple;
      break;
    default:
      onColor = SCALEOPS_COLORS.guideline.lightGreen;
  }

  return {
    cursor: "pointer",
    float: "center",

    "& .MuiSwitch-thumb": {
      backgroundColor: offColor,
    },
    ".MuiSwitch-track": {
      backgroundColor: offColor,
    },
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked": {
        "+ .MuiSwitch-track": {
          backgroundColor: onColor,
        },
        ".MuiSwitch-thumb": {
          backgroundColor: onColor,
        },
      },
    },
  };
};

/**
 * General
 */

export const TOOLTIP_WRAPPER_CLASS_NAME =
  "bg-[rgba(255,255,255,0.75)] text-black py-4 px-8 rounded-lg border border-border flex flex-col text-[10px]";

export const pageContentClassName = "bg-white rounded-lg p-4";

export const roundedTabsDivWrapper = "bg-white rounded-lg rounded-tl-none p-8";

export const SLACK_AUBERGINE_COLOR = "#4A154B";

export const CHART_WRAPPER_CLASS_NAME = "px-4 pb-16 pt-6 bg-white unselectable-svg-text";

export const LINK_CLASS_NAME =
  "text-main-blue hover:text-guideline-darkBlue hover:text-guideline-lessDarkBlue hover:underline font-bold";

export const GAPPED_COLUMN = "flex flex-col gap-5";
