import * as React from "react";
import * as componentUtils from "../componentUtils/overview";
import NodeGroups from "./NodeGroups";
import { GetBetaFeaturesQuery, GetBetaFeaturesResponse } from "../../api/fetcher";
import { useQuery } from "@tanstack/react-query";
import CapacityConfiguration from "./CapacityConfiguration";

export default function Autoscaler() {
  const betaFeaturesQuery = GetBetaFeaturesQuery();
  const { data: betaFeaturesData } = useQuery<GetBetaFeaturesResponse, Error>({
    queryKey: [betaFeaturesQuery.queryKey],
    queryFn: betaFeaturesQuery.queryFn,
  });

  return (
    <div className="min-w-[1200px]">
      {componentUtils.windowBlock(
        "",
        <div>
          <div>
            <NodeGroups />
          </div>
          {!!betaFeaturesData?.enabled && (
            <CapacityConfiguration isBeta={betaFeaturesData?.betaFeatures?.nodeCapacityConfiguration} />
          )}
        </div>
      )}
    </div>
  );
}
