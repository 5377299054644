import FormSwitch from "../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";

interface Props {
  isCustomizedPolicy?: boolean;
}

const ZeroDowntimeRollout = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4 py-10 border-b border-strongBorder">
      <FormTitleAndDescription
        title="Zero downtime optimization"
        description="Allow updating deployments with a single replicas without downtime."
        info={
          <>
            ScaleOps will <b>create new pods</b> before evicting old ones
          </>
        }
      />
      <FormSwitch
        name="allowRollingUpdate"
        disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
        disableChange={!isCustomizedPolicy}
      />
    </div>
  );
};
export default ZeroDowntimeRollout;
