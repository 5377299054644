import { Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import { GetTopKQueryKey } from "../../../../api/fetcher";
import { SCALEOPS_COLORS } from "../../../../colors";
import Tab, { TABS_CONTAINER_GAP } from "../../../../components/Tab";
import InsightIcon from "../../../../Icons/InsightIcon";
import { memoryDataParser } from "../../../../utils/graphUtils";
import { DEFAULT_OPTION, NUMBER_OF_ELEMENTS_QUERY_PARAM_NAME } from "../NumberOfElementsFilter";
import { CORE_ELEMENT_CLASS_NAME, DateType } from "../utils";
import { PreBuiltViewGroups } from "../ViewGroups";
import useFreezeTooltip, { TooltipTrigger } from "./hooks/useFreezeTooltip";
import { FrozenTooltipType } from "./hooks/utils";
import TopKFilters from "./TopKFilters";
import TopKMultiDotChart from "./TopKMultiDotChart";
import TopKMultiLineChart, { QUANTILE, SUM } from "./TopKMultiLineChart";
import { NodeStatus, NodeStatusKeys } from "./topKUtils";
import useHasResourceQuota from "./useHasResourceQuota";

//const GROUP_BY = "day";
const HAS_TOPK_FILTERS = true;
const ELEMENT_CLASSNAME = "w-[calc(50%-20px)]";

enum AggregateBy {
  Namespaces = "Namespaces",
  Workloads = "Workloads",
}

const PERCENTAGE_Y_AXIS_DOMAIN = [0, 100];
const RESOURCE_QUOTA = <span className="text-text-darkGray">(resource quota)</span>;

interface Props {
  date: DateType;
  setDate: (date: DateType) => void;
  isMulticluster?: boolean;
  viewGroup: PreBuiltViewGroups;
}

enum graphs {
  NodeUtilization = "NodeUtilization",
  OutOfMemory = "OutOfMemory",
  Liveness = "Liveness",
  CpuThrottling = "CpuThrottling",
  AutomatedWorkloads = "AutomatedWorkloads",
  ExpensiveAndWasteful = "ExpensiveAndWasteful",
  WastedCpuOrMemory = "WastedCpuOrMemory",
  NotScalingDownReasons = "NotScalingDownReasons",
  UpdateEvictions = "UpdateEvicions",
  NodeAllocations = "NodeAllocations",
  ResourceQuotas = "ResourceQuotas",
  NoisyNeighbors = "NoisyNeighbors",
  OverOrUnderProvisioned = "OverOrUnderProvisioned",
  Downtime = "Downtime",
}

const inViewGroup = (graph: graphs, viewGroup: PreBuiltViewGroups) => {
  switch (viewGroup) {
    case PreBuiltViewGroups.all:
      return true;
    case PreBuiltViewGroups.performance:
      return [
        graphs.OutOfMemory,
        graphs.Liveness,
        graphs.CpuThrottling,
        graphs.UpdateEvictions,
        graphs.NoisyNeighbors,
        graphs.AutomatedWorkloads,
        graphs.NodeUtilization,
        graphs.NodeAllocations,
        graphs.ResourceQuotas,
        graphs.OverOrUnderProvisioned,
        graphs.Downtime,
      ].includes(graph);
    case PreBuiltViewGroups.highlight:
      return [
        graphs.NodeUtilization,
        graphs.OutOfMemory,
        graphs.Liveness,
        graphs.CpuThrottling,
        graphs.AutomatedWorkloads,
        graphs.ExpensiveAndWasteful,
      ].includes(graph);
    case PreBuiltViewGroups.cost:
      return [
        graphs.AutomatedWorkloads,
        graphs.ExpensiveAndWasteful,
        graphs.WastedCpuOrMemory,
        graphs.NotScalingDownReasons,
      ].includes(graph);
    default:
      return false;
  }
};

const formatGroupDescription = (viewGroup: PreBuiltViewGroups) => {
  switch (viewGroup) {
    case PreBuiltViewGroups.highlight:
      return `Compare your workloads liveness probes, cost, resources and automation using a comprehensive set of metrics.`;
    case PreBuiltViewGroups.cost:
      return `Analyze your workloads cost breakdown, expensive and wasteful namespaces along with automation insights.`;
    case PreBuiltViewGroups.performance:
      return `Examine your workloads performance issues using out of memory, liveness probe failures and node utilization metrics.`;
    default:
      return "";
  }
};

const TopKContainer = ({ date, setDate, isMulticluster, viewGroup }: Props) => {
  const hasResourceQuota = useHasResourceQuota();
  const [selectedTab, setSelectedTab] = useState<AggregateBy>(AggregateBy.Namespaces);

  const [numberOfElements] = useQueryParam(
    NUMBER_OF_ELEMENTS_QUERY_PARAM_NAME,
    withDefault(NumberParam, DEFAULT_OPTION)
  );

  const { tooltipTrigger, disabledZoom, updateActiveTooltips, setTooltipTrigger } = useFreezeTooltip({
    disable: isMulticluster,
  });

  useEffect(() => {
    setTooltipTrigger(TooltipTrigger.Hover);
  }, [selectedTab, viewGroup, date, numberOfElements]);

  let frozenAndClickableInWorkloadTab;
  let frozenAndNotClickableInWorkloadTab;

  switch (true) {
    case !isMulticluster && selectedTab === AggregateBy.Workloads:
      frozenAndClickableInWorkloadTab = FrozenTooltipType.FrozenAndClickable;
      frozenAndNotClickableInWorkloadTab = FrozenTooltipType.FrozenAndNotClickable;
      break;
    case !isMulticluster && selectedTab === AggregateBy.Namespaces:
      frozenAndClickableInWorkloadTab = FrozenTooltipType.FrozenAndNotClickable;
      frozenAndNotClickableInWorkloadTab = FrozenTooltipType.FrozenAndNotClickable;
      break;
    default:
      frozenAndClickableInWorkloadTab = FrozenTooltipType.Disabled;
      frozenAndNotClickableInWorkloadTab = FrozenTooltipType.Disabled;
  }

  return (
    <div className="w-full flex flex-col gap-8">
      <div className={clsx(CORE_ELEMENT_CLASS_NAME, "p-4 rounded-tl-none flex gap-3")}>
        <div className="flex items-center">
          <InsightIcon width={60} height={60} />
        </div>
        <div className="grow">
          <Typography variant="h6" fontWeight="bold">
            {viewGroup} View Profile
          </Typography>
          <Typography variant="body2" className="text-text-lightBlack flex items-center gap-2">
            {formatGroupDescription(viewGroup)}
          </Typography>
        </div>
      </div>
      <div>
        {!isMulticluster && (
          <div className={clsx("flex", TABS_CONTAINER_GAP)}>
            {Object.values(AggregateBy).map((name) => {
              return (
                <Tab
                  name={name}
                  isSelected={selectedTab === name}
                  onClick={() => {
                    setSelectedTab(name);
                  }}
                />
              );
            })}
          </div>
        )}
        <div className={clsx(CORE_ELEMENT_CLASS_NAME, "p-4 rounded-tl-none flex flex-wrap gap-[20px]")}>
          {HAS_TOPK_FILTERS && <TopKFilters />}
        </div>
        <div className={clsx(CORE_ELEMENT_CLASS_NAME, "p-4 rounded-tl-none flex flex-wrap gap-[20px]")}>
          {inViewGroup(graphs.OutOfMemory, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="Out of memory"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.OOMOverTime
                      : GetTopKQueryKey.OOMOverTimeByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
                dotted={true}
              />
            </div>
          )}
          {inViewGroup(graphs.Liveness, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="Liveness probe failures"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.Liveness
                      : GetTopKQueryKey.LivenessByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
                dotted={true}
              />
            </div>
          )}
          {inViewGroup(graphs.CpuThrottling, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="CPU throttling events"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.Throttling
                      : GetTopKQueryKey.ThrottlingByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
                dotted={true}
              />
            </div>
          )}
          {inViewGroup(graphs.UpdateEvictions, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title="ScaleOps update evictions"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.Evictions
                      : GetTopKQueryKey.EvictionsByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
                dotted={true}
              />
            </div>
          )}
          {inViewGroup(graphs.OverOrUnderProvisioned, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"CPU under-provisioned " + selectedTab.toLowerCase() + " on stressed nodes"}
                infoTooltip={
                  <>
                    <b>Sum</b> of <b>usage above request</b> for workloads running on <b>CPU stressed</b> nodes.
                  </>
                }
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.CpuUnderProvisionedWorkloadsStressedNode
                      : GetTopKQueryKey.CpuUnderProvisionedNamespacesStressedNode,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          )}
          {inViewGroup(graphs.OverOrUnderProvisioned, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Memory under-provisioned " + selectedTab.toLowerCase() + " on stressed nodes"}
                infoTooltip={
                  <>
                    <b>Sum</b> of <b>usage above request</b> for workloads running on <b>Memory stressed</b> nodes.
                  </>
                }
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.MemoryUnderProvisionedWorkloadsStressedNode
                      : GetTopKQueryKey.MemoryUnderProvisionedNamespacesStressedNode,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisFormatter={(x: string) => memoryDataParser(Number(x))}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          )}
          {inViewGroup(graphs.AutomatedWorkloads, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiDotChart
                title={`Automated ${selectedTab === AggregateBy.Workloads ? "workloads" : "namespaces"}`}
                dotColor={SCALEOPS_COLORS.main.green}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.WorkloadHasAutomated
                      : GetTopKQueryKey.WorkloadHasAutomatedByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          )}
          {inViewGroup(graphs.AutomatedWorkloads, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiDotChart
                title={`Un-Automated ${selectedTab === AggregateBy.Workloads ? "workloads" : "namespaces"}`}
                dotColor={SCALEOPS_COLORS.mainMenuBackgroundHover}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.WorkloadHasUnautomated
                      : GetTopKQueryKey.WorkloadHasUnUnautomatedByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          )}
          {inViewGroup(graphs.OverOrUnderProvisioned, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"CPU Under-provisioned  " + selectedTab.toLowerCase()}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.CpuUnderProvisioned
                      : GetTopKQueryKey.CpuUnderProvisionedNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          )}
          {inViewGroup(graphs.OverOrUnderProvisioned, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Memory Under-provisioned " + selectedTab.toLowerCase()}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.MemoryUnderProvisioned
                      : GetTopKQueryKey.MemoryUnderProvisionedNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          )}
          {inViewGroup(graphs.ExpensiveAndWasteful, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={
                  <>
                    Expensive {selectedTab.toLowerCase()}{" "}
                    <span className="text-[12px] text-strongBorder">(monthly)</span>
                  </>
                }
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.ExpensiveWorkloads
                      : GetTopKQueryKey.ExpensiveNamespaces,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisFormatter={(value) => `$${value}`}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          )}
          {inViewGroup(graphs.ExpensiveAndWasteful, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={
                  <>
                    Wasteful {selectedTab.toLowerCase()}{" "}
                    <span className="text-[12px] text-strongBorder"> (monthly)</span>
                  </>
                }
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.WastefulWorkloads
                      : GetTopKQueryKey.WastefulNamespaces,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisFormatter={(value) => `$${value}`}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          )}
          {inViewGroup(graphs.WastedCpuOrMemory, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Wasted CPU by " + selectedTab.toLowerCase()}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.WastedCpu
                      : GetTopKQueryKey.WastedCpuByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisFormatter={(value) => `${value}mil`}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          )}
          {inViewGroup(graphs.WastedCpuOrMemory, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Wasted memory by " + selectedTab.toLowerCase()}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.WastedMemory
                      : GetTopKQueryKey.WastedMemoryByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisFormatter={(value) => `${value}Mib`}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          )}
          {inViewGroup(graphs.Downtime, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Downtime by " + selectedTab.toLowerCase()}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.DowntimeByWorkload
                      : GetTopKQueryKey.DowntimeByNamespace,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          )}
          {inViewGroup(graphs.Downtime, viewGroup) && (
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={"Automated downtime by " + selectedTab.toLowerCase()}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey:
                    selectedTab === AggregateBy.Workloads
                      ? GetTopKQueryKey.DowntimeByWorkloadAuto
                      : GetTopKQueryKey.DowntimeByNamespaceAuto,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                tooltipTrigger={tooltipTrigger}
                disabledZoom={disabledZoom}
                frozenTooltipType={frozenAndClickableInWorkloadTab}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          )}
        </div>
      </div>
      <div className={clsx(CORE_ELEMENT_CLASS_NAME, "p-4 rounded-tl-none flex flex-wrap gap-[20px]")}>
        {inViewGroup(graphs.NodeUtilization, viewGroup) && (
          <div className={ELEMENT_CLASSNAME}>
            <TopKMultiLineChart
              title="Node CPU utilization (%)"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                queryKey: GetTopKQueryKey.NodeCpuUtilization,
              }}
              setDate={setDate}
              isMulticluster={isMulticluster}
              topK={numberOfElements}
              YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
              YAxisFormatter={(value) => `${value}%`}
              tooltipTrigger={tooltipTrigger}
              disabledZoom={disabledZoom}
              frozenTooltipType={frozenAndNotClickableInWorkloadTab}
              updateActiveTooltips={updateActiveTooltips}
            />
          </div>
        )}
        {inViewGroup(graphs.NodeUtilization, viewGroup) && (
          <div className={ELEMENT_CLASSNAME}>
            <TopKMultiLineChart
              title="Node memory utilization (%)"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                queryKey: GetTopKQueryKey.NodeMemoryUtilization,
              }}
              setDate={setDate}
              isMulticluster={isMulticluster}
              topK={numberOfElements}
              YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
              YAxisFormatter={(value) => `${value}%`}
              tooltipTrigger={tooltipTrigger}
              disabledZoom={disabledZoom}
              frozenTooltipType={frozenAndNotClickableInWorkloadTab}
              updateActiveTooltips={updateActiveTooltips}
            />
          </div>
        )}

        {inViewGroup(graphs.NodeAllocations, viewGroup) && (
          <div className={ELEMENT_CLASSNAME}>
            <TopKMultiLineChart
              title="Node CPU allocation (%)"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                queryKey: GetTopKQueryKey.NodeCpuAllocationUsage,
              }}
              setDate={setDate}
              isMulticluster={isMulticluster}
              topK={numberOfElements}
              YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
              YAxisFormatter={(value) => `${value}%`}
              tooltipTrigger={tooltipTrigger}
              disabledZoom={disabledZoom}
              frozenTooltipType={frozenAndNotClickableInWorkloadTab}
              updateActiveTooltips={updateActiveTooltips}
            />
          </div>
        )}
        {inViewGroup(graphs.NodeAllocations, viewGroup) && (
          <div className={ELEMENT_CLASSNAME}>
            <TopKMultiLineChart
              title="Node memory allocation (%)"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                queryKey: GetTopKQueryKey.NodeMemoryAllocationUsage,
              }}
              setDate={setDate}
              isMulticluster={isMulticluster}
              topK={numberOfElements}
              YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
              YAxisFormatter={(value) => `${value}%`}
              tooltipTrigger={tooltipTrigger}
              disabledZoom={disabledZoom}
              frozenTooltipType={frozenAndNotClickableInWorkloadTab}
              updateActiveTooltips={updateActiveTooltips}
            />
          </div>
        )}

        {inViewGroup(graphs.NotScalingDownReasons, viewGroup) && (
          <div className={ELEMENT_CLASSNAME}>
            <TopKMultiLineChart
              title="Node Not Scaling down reasons"
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                queryKey: GetTopKQueryKey.NodeNotScalingDown,
              }}
              setDate={setDate}
              isMulticluster={isMulticluster}
              topK={numberOfElements}
              renderNameFunction={(name: string | undefined) => {
                switch (true) {
                  case name === SUM:
                    return "total";
                  case name?.includes(QUANTILE):
                    return `${String(name?.replace(QUANTILE, ""))}90th-percentile`;
                  case NodeStatusKeys.includes(name as keyof typeof NodeStatus):
                    return NodeStatus[name as keyof typeof NodeStatus];
                  default:
                    return name;
                }
              }}
              updateActiveTooltips={updateActiveTooltips}
            />
          </div>
        )}
      </div>

      {hasResourceQuota && inViewGroup(graphs.ResourceQuotas, viewGroup) && (
        <div className={clsx(CORE_ELEMENT_CLASS_NAME, "p-4 rounded-tl-none flex flex-wrap gap-[20px]")}>
          <div className="flex w-full gap-[20px]">
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={<>Namespace limitation by Memory {RESOURCE_QUOTA} </>}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.ResourceQuotaLimitedByNamespaceMemory,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
                YAxisFormatter={(value) => `${value}%`}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={<>Namespace limitation by CPU {RESOURCE_QUOTA} </>}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.ResourceQuotaLimitedByNamespaceCPU,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
                YAxisFormatter={(value) => `${value}%`}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          </div>
          <div className="flex w-full gap-[20px]">
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={<>Namespace limitation by Pods {RESOURCE_QUOTA} </>}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.ResourceQuotaLimitedByNamespacePods,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
                YAxisFormatter={(value) => `${value}%`}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
            <div className={ELEMENT_CLASSNAME}>
              <TopKMultiLineChart
                title={<>Namespace limitation by ReplicaSets {RESOURCE_QUOTA} </>}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  queryKey: GetTopKQueryKey.ResourceQuotaLimitedByNamespaceReplicaSets,
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                topK={numberOfElements}
                YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
                YAxisFormatter={(value) => `${value}%`}
                updateActiveTooltips={updateActiveTooltips}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopKContainer;
