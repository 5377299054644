import { Typography } from "@mui/material";

interface Props {
  title: string;
  background: string;
  color: string;
}

const WorkloadTag = ({ title, background, color }: Props) => {
  return (
    <Typography
      variant="caption"
      className="w-fit px-2 border-border rounded-md"
      style={{
        background,
        color,
      }}
    >
      {title}
    </Typography>
  );
};

export default WorkloadTag;
