import clsx from "clsx";
import { Typography } from "@mui/material";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import Tooltip from "../../../components/Tooltip";
import Switch from "../../../components/Switch";
import YouHaveReadOnlyAccess from "../../../components/YouHaveReadOnlyAccess";
import { components } from "../../../api/schema";
import useUpdateAutomation from "./mutation/useUpdateAutomation";
import LockIcon from "../../../Icons/LockIcon";
import React from "react";

interface Props {
  isAutomate: boolean;
  setIsAutomate: (isAutomate: boolean) => void;
  fetchWorkloads: () => void;
  selectedWorkload: components["schemas"]["UtilsWorkload"];
}

const AutomationSwitch = ({ isAutomate, setIsAutomate, fetchWorkloads, selectedWorkload }: Props) => {
  const isEditable = selectedWorkload?.isEditable;
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const updateAutomation = useUpdateAutomation(fetchWorkloads);

  const handleClick = () => {
    updateAutomation.mutate({
      id: selectedWorkload.id,
      namespace: selectedWorkload.namespace,
      workloadName: selectedWorkload.workloadName,
      workloadType: selectedWorkload.type,
      state: !isAutomate,
    });
    setIsAutomate(!isAutomate);
  };

  const isAutomationExcluded = selectedWorkload.isAutomationExcluded;
  let tooltipContent = <></>;
  switch (true) {
    case isReadyOnlyFrontEnd:
      tooltipContent = <YouHaveReadOnlyAccess />;
      break;
    case !isEditable:
      tooltipContent = (
        <Typography variant="caption">
          You don't have <b>permissions</b> to save this workload.
        </Typography>
      );
      break;
    case isAutomationExcluded:
      tooltipContent = (
        <Typography variant="caption">
          This workload is <b>excluded</b> from <b>automation</b>.
        </Typography>
      );
      break;
    default:
      tooltipContent = <>Enable continuous automation to automatically apply recommendations to your workload.</>;
      break;
  }
  return (
    <Tooltip title={tooltipContent} maxWidth={500}>
      <div
        className={clsx("flex items-center px-4 border h-[42px] rounded", {
          " border-black bg-guideline-lessDarkPurple hover:bg-guideline-darkPurple text-white": !isReadyOnlyFrontEnd,
          "border-border text-text-disable": isReadyOnlyFrontEnd,
          "opacity-50 cursor-not-allowed": isAutomationExcluded,
          "hover:cursor-pointer": !isAutomationExcluded,
        })}
        onClick={isReadyOnlyFrontEnd || !isEditable || isAutomationExcluded ? undefined : handleClick}
      >
        <Typography variant="body2" fontWeight={500}>
          Automate
        </Typography>
        <Switch
          checked={isAutomate}
          onChange={handleClick}
          disabled={isReadyOnlyFrontEnd || !isEditable || isAutomationExcluded}
        />
        {isAutomationExcluded && (
          <div className="absolute right-[1.6rem]">
            <LockIcon />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default AutomationSwitch;
