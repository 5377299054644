import { Box } from "@mui/system";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import RangePicker, { RangeOptions } from "../RangePicker/RangePicker";
import { DateType } from "../../pages/Analytics/AnalyticsV2/utils";

dayjs.extend(dayjsPluginUTC);

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput: string) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
    </Box>
  );
}

interface EditToolbarAuditProps {
  date: DateType;
  setDate: (date: DateType) => void;
}

export default function EditToolbarAudit({ date, setDate }: EditToolbarAuditProps) {
  return (
    <div className="relative">
      <div className="flex pb-16 absolute z-50 top-[-80px] w-full">
        <div className="flex-grow flex items-end">
          <QuickSearchToolbar />
        </div>
        <div className="flex gap-8 items-end">
          <RangePicker date={date} setDate={setDate} rangeOptions={RangeOptions.MEDIUM} />
        </div>
      </div>
    </div>
  );
}
