import { IconProps } from "./utils/utils";

const OverviewIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <g>
        <path d="M10.0093 15C9.83658 15 9.69528 14.8604 9.69528 14.6875C9.69528 14.5146 9.83337 14.375 10.006 14.375H10.0093C10.1819 14.375 10.3218 14.5146 10.3218 14.6875C10.3218 14.8604 10.1819 15 10.0093 15Z" />
        <path d="M19.6875 14.375H0.327148C0.154419 14.375 0.0146484 14.2354 0.0146484 14.0625C0.0146484 13.8896 0.154419 13.75 0.327148 13.75H19.6875C19.8604 13.75 20 13.8896 20 14.0625C20 14.2354 19.8604 14.375 19.6875 14.375Z" />
        <path d="M19.0625 15.625H0.9375C0.420685 15.625 0 15.2043 0 14.6875V1.875C0 1.35818 0.420685 0.9375 0.9375 0.9375H19.0625C19.5793 0.9375 20 1.35818 20 1.875V14.6875C20 15.2043 19.5793 15.625 19.0625 15.625ZM0.9375 1.5625C0.765076 1.5625 0.625 1.70288 0.625 1.875V14.6875C0.625 14.8599 0.765076 15 0.9375 15H19.0625C19.2349 15 19.375 14.8599 19.375 14.6875V1.875C19.375 1.70288 19.2349 1.5625 19.0625 1.5625H0.9375Z" />
        <path d="M7.18781 18.125C7.16279 18.125 7.13715 18.122 7.11182 18.1157C6.94444 18.0737 6.84251 17.9041 6.88432 17.7368L7.50932 15.2368C7.55097 15.0691 7.72065 14.9672 7.88819 15.0093C8.05558 15.0513 8.15751 15.221 8.1157 15.3882L7.4907 17.8882C7.45499 18.0302 7.32789 18.125 7.18781 18.125Z" />
        <path d="M12.8122 18.125C12.6721 18.125 12.545 18.0302 12.5093 17.8882L11.8843 15.3882C11.8422 15.2209 11.9441 15.0513 12.1118 15.0093C12.2784 14.9672 12.4487 15.0688 12.4907 15.2368L13.1157 17.7368C13.1578 17.904 13.0559 18.0737 12.8882 18.1157C12.8629 18.1219 12.8372 18.125 12.8122 18.125Z" />
        <path d="M5.9375 18.75C5.76462 18.75 5.625 18.6104 5.625 18.4375C5.625 17.9207 6.04568 17.5 6.5625 17.5C6.73538 17.5 6.875 17.6396 6.875 17.8125C6.875 17.9854 6.73538 18.125 6.5625 18.125C6.39008 18.125 6.25 18.2651 6.25 18.4375C6.25 18.6104 6.11038 18.75 5.9375 18.75Z" />
        <path d="M14.0625 18.75C13.8896 18.75 13.75 18.6104 13.75 18.4375C13.75 18.2651 13.6099 18.125 13.4375 18.125C13.2646 18.125 13.125 17.9854 13.125 17.8125C13.125 17.6396 13.2646 17.5 13.4375 17.5C13.9543 17.5 14.375 17.9207 14.375 18.4375C14.375 18.6104 14.2354 18.75 14.0625 18.75Z" />
        <path d="M13.4375 18.125H6.5625C6.38962 18.125 6.25 17.9854 6.25 17.8125C6.25 17.6396 6.38962 17.5 6.5625 17.5H13.4375C13.6104 17.5 13.75 17.6396 13.75 17.8125C13.75 17.9854 13.6104 18.125 13.4375 18.125Z" />
        <path d="M5.9375 19.0625C5.76462 19.0625 5.625 18.9229 5.625 18.75V18.4375C5.625 18.2646 5.76462 18.125 5.9375 18.125C6.11038 18.125 6.25 18.2646 6.25 18.4375V18.75C6.25 18.9229 6.11038 19.0625 5.9375 19.0625Z" />
        <path d="M14.0625 19.0625H5.9375C5.76462 19.0625 5.625 18.9229 5.625 18.75C5.625 18.5771 5.76462 18.4375 5.9375 18.4375H14.0625C14.2354 18.4375 14.375 18.5771 14.375 18.75C14.375 18.9229 14.2354 19.0625 14.0625 19.0625Z" />
        <path d="M14.0625 19.0625C13.8896 19.0625 13.75 18.9229 13.75 18.75V18.4375C13.75 18.2646 13.8896 18.125 14.0625 18.125C14.2354 18.125 14.375 18.2646 14.375 18.4375V18.75C14.375 18.9229 14.2354 19.0625 14.0625 19.0625Z" />
        <path d="M17.5006 10H11.2506C11.0779 10 10.9381 9.85992 10.9381 9.6875C10.9381 9.51508 11.0779 9.375 11.2506 9.375H17.5006C17.6735 9.375 17.8131 9.51508 17.8131 9.6875C17.8131 9.85992 17.6735 10 17.5006 10Z" />
        <path d="M11.2506 10C11.0779 10 10.9381 9.85992 10.9381 9.6875V3.4375C10.9381 3.26508 11.0779 3.125 11.2506 3.125C11.4235 3.125 11.5631 3.26508 11.5631 3.4375V9.6875C11.5631 9.85992 11.4235 10 11.2506 10Z" />
        <path d="M12.4994 10C12.3265 10 12.1869 9.85992 12.1869 9.6875V7.5C12.1869 7.32758 12.3265 7.1875 12.4994 7.1875C12.6721 7.1875 12.8119 7.32758 12.8119 7.5V9.6875C12.8119 9.85992 12.6721 10 12.4994 10Z" />
        <path d="M13.7506 10C13.5779 10 13.4381 9.85992 13.4381 9.6875V5.625C13.4381 5.45258 13.5779 5.3125 13.7506 5.3125C13.9235 5.3125 14.0631 5.45258 14.0631 5.625V9.6875C14.0631 9.85992 13.9235 10 13.7506 10Z" />
        <path d="M16.2494 10C16.0765 10 15.9369 9.85992 15.9369 9.6875V6.5625C15.9369 6.39008 16.0765 6.25 16.2494 6.25C16.4221 6.25 16.5619 6.39008 16.5619 6.5625V9.6875C16.5619 9.85992 16.4221 10 16.2494 10Z" />
        <path d="M14.9994 10C14.8265 10 14.6869 9.85992 14.6869 9.6875V8.125C14.6869 7.95258 14.8265 7.8125 14.9994 7.8125C15.1721 7.8125 15.3119 7.95258 15.3119 8.125V9.6875C15.3119 9.85992 15.1721 10 14.9994 10Z" />
        <path d="M5.62439 10C3.7291 10 2.18689 8.45779 2.18689 6.5625C2.18689 4.66721 3.7291 3.125 5.62439 3.125C7.51968 3.125 9.06189 4.66721 9.06189 6.5625C9.06189 8.45779 7.51968 10 5.62439 10ZM5.62439 3.75C4.07349 3.75 2.81189 5.0119 2.81189 6.5625C2.81189 8.1131 4.07349 9.375 5.62439 9.375C7.17529 9.375 8.43689 8.1131 8.43689 6.5625C8.43689 5.0119 7.17529 3.75 5.62439 3.75Z" />
        <path d="M5.62439 7.5C5.10757 7.5 4.68689 7.07932 4.68689 6.5625C4.68689 6.04568 5.10757 5.625 5.62439 5.625C6.1412 5.625 6.56189 6.04568 6.56189 6.5625C6.56189 7.07932 6.1412 7.5 5.62439 7.5ZM5.62439 6.25C5.45181 6.25 5.31189 6.39038 5.31189 6.5625C5.31189 6.73462 5.45181 6.875 5.62439 6.875C5.79681 6.875 5.93689 6.73462 5.93689 6.5625C5.93689 6.39038 5.79681 6.25 5.62439 6.25Z" />
        <path d="M5.62439 6.25C5.45151 6.25 5.31189 6.10992 5.31189 5.9375V3.4375C5.31189 3.26508 5.45151 3.125 5.62439 3.125C5.79712 3.125 5.93689 3.26508 5.93689 3.4375V5.9375C5.93689 6.10992 5.79712 6.25 5.62439 6.25Z" />
        <path d="M3.41506 9.08431C3.33495 9.08431 3.255 9.05379 3.19411 8.99276C3.07189 8.87069 3.07189 8.67309 3.19411 8.55087L4.96154 6.78344C5.08376 6.66122 5.28121 6.66122 5.40343 6.78344C5.52566 6.90566 5.52566 7.10311 5.40343 7.22533L3.63586 8.99276C3.57467 9.0541 3.49502 9.08431 3.41506 9.08431Z" />
        <path d="M17.4994 11.25H11.2506C11.0779 11.25 10.9381 11.1104 10.9381 10.9375C10.9381 10.7646 11.0779 10.625 11.2506 10.625H17.4994C17.6721 10.625 17.8119 10.7646 17.8119 10.9375C17.8119 11.1104 17.6721 11.25 17.4994 11.25Z" />
        <path d="M15.6244 12.1875H13.1244C12.9515 12.1875 12.8119 12.0479 12.8119 11.875C12.8119 11.7021 12.9515 11.5625 13.1244 11.5625H15.6244C15.7971 11.5625 15.9369 11.7021 15.9369 11.875C15.9369 12.0479 15.7971 12.1875 15.6244 12.1875Z" />
        <path d="M8.74878 11.25H2.8125C2.63962 11.25 2.5 11.1104 2.5 10.9375C2.5 10.7646 2.63962 10.625 2.8125 10.625H8.74878C8.92151 10.625 9.06128 10.7646 9.06128 10.9375C9.06128 11.1104 8.92151 11.25 8.74878 11.25Z" />
        <path d="M7.18628 12.1875H4.68628C4.5134 12.1875 4.37378 12.0479 4.37378 11.875C4.37378 11.7021 4.5134 11.5625 4.68628 11.5625H7.18628C7.35901 11.5625 7.49878 11.7021 7.49878 11.875C7.49878 12.0479 7.35901 12.1875 7.18628 12.1875Z" />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OverviewIcon;
