import { Switch as MuiSwitch } from "@mui/material";

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
  disabled?: boolean;
}

const Switch = ({ checked, onChange, className, disabled }: Props) => {
  return (
    <MuiSwitch
      checked={checked}
      onChange={(event) => !disabled && onChange(event.target.checked)}
      className={className}
      sx={{
        "& .Mui-checked": {
          color: "#10AE6E",
        },
        "& .Mui-checked + .MuiSwitch-track": {
          backgroundColor: "#15BE7B",
        },
      }}
      disabled={disabled}
    />
  );
};

export default Switch;
