import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetWorkloadsFromAggregation, GetWorkloadsFromAggregationResponse } from "../../../../../api/fetcher";
import ExportCSV from "../../../../../components/exportCSV/ExportCSV";
import { Field } from "../../utils";
import useAggregationFilters from "../hooks/useAggregationFilters";
import ExportOptionButton from "./ExportOptionButton";

const BUTTON_TITLE = "Aggregated workloads";

const getKeyValueSeparatedString = ({ arr }: { arr: { key?: string | undefined; value?: string | undefined }[] }) => {
  return arr
    ?.map((item) => {
      return `${String(item.key)}=${String(item.value)}`;
    })
    .join(", ");
};

const { queryKey, queryFn } = GetWorkloadsFromAggregation();

type CSVExportType = {
  id: string;
  amountOfWorkloads: number;
  annotations: string;
  clusterName: string;
  labels: string;
  namespace: string;
  onDemand: number;
  ownerCpuRequest: number;
  ownerMemoryRequest: number;
  replicas: number;
  savingsAvailable: number;
  spot: number;
  totalCost: number;
  workloadName: string;
  workloadType: string;
};

const ExportAggregationWorkloadsCSV = () => {
  const filters = useAggregationFilters();

  const [rows, setRows] = useState<CSVExportType[]>([]);

  const { data, isLoading, isError } = useQuery<GetWorkloadsFromAggregationResponse, Error>({
    queryKey: [queryKey, filters],
    queryFn: () =>
      queryFn({
        ...filters,
        multiCluster: true,
      }),
    enabled: Object.keys(filters).length > 0,
  });

  useEffect(() => {
    if (data?.aggregatedWorkloads) {
      const dataToSet = data?.aggregatedWorkloads.map((wl) => {
        const annotations = getKeyValueSeparatedString({ arr: wl.annotations });
        const labels = getKeyValueSeparatedString({ arr: wl.labels });

        return {
          ...wl,
          annotations,
          labels,
        };
      });

      setRows(dataToSet);
    }
  }, [data]);

  if (isLoading || isError) {
    return <ExportOptionButton title={BUTTON_TITLE} disabled={true} />;
  }

  return (
    <div className="w-fit">
      <ExportCSV<CSVExportType>
        customButtonElement={<ExportOptionButton title={BUTTON_TITLE} />}
        filename="aggregated_workloads.csv"
        columns={[
          "workloadName",
          "id",
          "namespace",
          "totalCost",
          "savingsAvailable",
          "ownerCpuRequest",
          "ownerMemoryRequest",
          "amountOfWorkloads",
          "annotations",
          "onDemand",
          "clusterName",
          "labels",
          "replicas",
          "spot",
          "workloadType",
        ]}
        data={rows}
        columnsToRound={["ownerCpuRequest", "ownerMemoryRequest", "replicas", "savingsAvailable", "totalCost"]}
        columnsToSum={[
          "amountOfWorkloads",
          "onDemand",
          "ownerCpuRequest",
          "ownerMemoryRequest",
          "replicas",
          "savingsAvailable",
          "spot",
          "totalCost",
        ]}
        customColumnNames={{
          workloadName: "Workload Name",
          namespace: "Namespace",
          totalCost: "Total Cost",
          savingsAvailable: "Savings Available",
          ownerCpuRequest: "Owner CPU Request",
          ownerMemoryRequest: "Owner Memory Request",
          amountOfWorkloads: "Total Workloads",
          annotations: "Annotations",
          onDemand: "On-demand",
          clusterName: "Cluster",
          labels: "Labels",
          replicas: "Replicas",
          spot: "Spot",
          workloadType: "Workload Type",
          id: "ID",
        }}
        columnsToAverage={[Field.spot, Field.onDemand]}
      />
    </div>
  );
};

export default ExportAggregationWorkloadsCSV;
