import { components } from "../api/schema";
import { LimitStrategyItems } from "../pages/Policies/EditFormSections/LimitsStrategy";
import { quantityToScalar } from "./unitUtils";

export function policyUpdateAutohealingSettingsCpuMinStep(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy?.spec === undefined) {
    return;
  }
  if (policy?.spec?.autoHealing === undefined) {
    policy.spec.autoHealing = { minSteps: {}, multiplier: {} };
  }
  if (policy?.spec?.autoHealing?.minSteps === undefined) {
    policy.spec.autoHealing.minSteps = {};
  }
  policy.spec.autoHealing.minSteps["cpu"] = value;
}

export function policyUpdateAutoHealingSettingsMemoryMinStep(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy?.spec === undefined) {
    return;
  }
  if (policy?.spec?.autoHealing === undefined) {
    policy.spec.autoHealing = { minSteps: {}, multiplier: {} };
  }
  if (policy?.spec?.autoHealing?.minSteps === undefined) {
    policy.spec.autoHealing.minSteps = {};
  }
  policy.spec.autoHealing.minSteps["memory"] = value;
}

export function policyUpdateAutoHealingSettingsCpuMultiplier(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy?.spec === undefined) {
    return;
  }
  if (policy?.spec?.autoHealing === undefined) {
    policy.spec.autoHealing = { minSteps: {}, multiplier: {} };
  }
  if (policy?.spec?.autoHealing?.multiplier === undefined) {
    policy.spec.autoHealing.multiplier = {};
  }
  policy.spec.autoHealing.multiplier["cpu"] = value;
}

export function policyUpdateAutoHealingSettingsMemoryMultiplier(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy?.spec === undefined) {
    return;
  }
  if (policy?.spec?.autoHealing === undefined) {
    policy.spec.autoHealing = { minSteps: {}, multiplier: {} };
  }
  if (policy?.spec?.autoHealing?.multiplier === undefined) {
    policy.spec.autoHealing.multiplier = {};
  }
  policy.spec.autoHealing.multiplier["memory"] = value;
}

export function policyAutoHealingSettingsCpuMinStep(
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): number | undefined {
  if (policy !== undefined) {
    if (policy.spec?.autoHealing !== undefined) {
      if (policy.spec.autoHealing.minSteps !== undefined) {
        return policy.spec.autoHealing.minSteps["cpu"];
      }
    }
  }
  return undefined;
}

export function policyAutoHealingSettingsMemoryMinStep(
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): number | undefined {
  if (policy !== undefined) {
    if (policy.spec?.autoHealing !== undefined) {
      if (policy.spec.autoHealing.minSteps !== undefined) {
        return policy.spec.autoHealing.minSteps["memory"];
      }
    }
  }
  return undefined;
}

export function policyAutoHealingSettingsMemoryMultiplier(
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): number | undefined {
  if (policy !== undefined) {
    if (policy.spec?.autoHealing !== undefined) {
      if (policy.spec.autoHealing?.multiplier !== undefined) {
        return policy.spec.autoHealing.multiplier["memory"];
      }
    }
  }
  return undefined;
}

export function policyAutoHealingSettingsCpuMultiplier(
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): number | undefined {
  if (policy !== undefined) {
    if (policy.spec?.autoHealing !== undefined) {
      if (policy.spec.autoHealing?.multiplier !== undefined) {
        return policy.spec.autoHealing.multiplier["cpu"];
      }
    }
  }
  return undefined;
}

export function policyName(policy: components["schemas"]["V1alpha1Policy"] | undefined): string {
  return policy?.metadata?.name || "";
}

export function getCurrentPolicy(
  policies: components["schemas"]["V1alpha1Policy"][],
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): components["schemas"]["V1alpha1Policy"] | undefined {
  if (policyType(policy) != "Schedule") {
    return policy;
  } else {
    return policies.find((r) => r.metadata?.name == "cost-opt-day");
  }
}

export function policyType(policy: components["schemas"]["V1alpha1Policy"] | undefined): string {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.type != undefined) {
        return policy.spec.type;
      }
    }
  }
  return "Optimize";
}

export function policyCpuHeadroom(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"] != undefined) {
              return policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"].headroomPercentage || 0;
            }
          }
        }
      }
    }
  }
  return 0;
}

export function policyMemoryHeadroom(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"] != undefined) {
              return policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"].headroomPercentage || 0;
            }
          }
        }
      }
    }
  }
  return 0;
}

export function policyHistoryWindow(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  resource: string
): string {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.windowByResource != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.windowByResource[resource] != undefined) {
              return policy.spec.policyOptimize.rightSizePolicy.windowByResource[resource] || "";
            }
          }
        }
      }
    }
  }
  return "";
}

export const hasManagedLimit = (policy: components["schemas"]["V1alpha1Policy"] | undefined): boolean => {
  return policy?.metadata?.labels?.["scaleops.sh/policy-managed-limit"] === "true";
};

export const policyCpuLimitStrategy = (policy: components["schemas"]["V1alpha1Policy"] | undefined) => {
  switch (true) {
    case policyCpuLimitsEqualsToRequest(policy):
      return LimitStrategyItems.EqualsToRequest;
    case policyCpuKeepLimit(policy):
      return LimitStrategyItems.KeepLimit;
    case policyCpuLimitsSetLimitsValue(policy) > 0:
      return LimitStrategyItems.SetLimit;
    case policyCpuLimitsNoLimit(policy):
      return LimitStrategyItems.NoLimit;
    case hasManagedLimit(policy):
      return LimitStrategyItems.ManagedLimit;
    default:
      return LimitStrategyItems.KeepLimit;
  }
};

export const policyMemoryLimitStrategy = (policy: components["schemas"]["V1alpha1Policy"] | undefined) => {
  switch (true) {
    case policyMemoryLimitsEqualsToRequest(policy):
      return LimitStrategyItems.EqualsToRequest;
    case policyMemoryKeepLimit(policy):
      return LimitStrategyItems.KeepLimit;
    case policyMemoryLimitsSetLimitsValue(policy) > 0:
      return LimitStrategyItems.SetLimit;
    case policyMemoryLimitsNoLimit(policy):
      return LimitStrategyItems.NoLimit;
    case hasManagedLimit(policy):
      return LimitStrategyItems.ManagedLimit;
    default:
      return LimitStrategyItems.KeepLimit;
  }
};

export function policyCPUPercentile(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"] != undefined) {
              return policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"].percentilePercentage || 90;
            }
          }
        }
      }
    }
  }
  return 90;
}

export function policyMemoryPercentile(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"] != undefined) {
              return policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"].percentilePercentage || 0;
            }
          }
        }
      }
    }
  }
  return 95;
}

export function policyCpuMinAllowed(policy: components["schemas"]["V1alpha1Policy"] | undefined): number | undefined {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"] != undefined) {
              if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"].minAllowed == undefined) {
                return undefined;
              } else {
                return Number(
                  quantityToScalar(
                    policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"].minAllowed.toString()
                  )
                );
              }
            }
          }
        }
      }
    }
  }
  return undefined;
}

export const getPolicyKeepMemoryRequest = (policy: components["schemas"]["V1alpha1Policy"] | undefined) => {
  return !!policy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.memory?.keepRequest;
};

export const updatePolicyKeepMemoryRequest = (
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) => {
  if (policy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.memory) {
    policy.spec.policyOptimize.rightSizePolicy.requestsConfigs.memory.keepRequest = value;
  } else if (policy?.spec?.policyOptimize?.rightSizePolicy) {
    policy.spec.policyOptimize.rightSizePolicy = {
      ...policy.spec.policyOptimize.rightSizePolicy,
      requestsConfigs: {
        ...policy.spec.policyOptimize.rightSizePolicy.requestsConfigs,
        memory: {
          keepRequest: value,
        },
      },
    };
  }
};

export const getPolicyKeepCpuRequest = (policy: components["schemas"]["V1alpha1Policy"] | undefined) => {
  return !!policy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.cpu?.keepRequest;
};

export const updatePolicyKeepCpuRequest = (
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) => {
  if (policy?.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.cpu) {
    policy.spec.policyOptimize.rightSizePolicy.requestsConfigs.cpu.keepRequest = value;
  } else if (policy?.spec?.policyOptimize?.rightSizePolicy) {
    policy.spec.policyOptimize.rightSizePolicy = {
      ...policy.spec.policyOptimize.rightSizePolicy,
      requestsConfigs: {
        ...policy.spec.policyOptimize.rightSizePolicy.requestsConfigs,
        cpu: {
          keepRequest: value,
        },
      },
    };
  }
};

export function policyMemoryMinAllowed(
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): number | undefined {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"] != undefined) {
              if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"].minAllowed == undefined) {
                return undefined;
              } else {
                const toScalar = Math.round(
                  quantityToScalar(
                    policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"].minAllowed.toString()
                  )
                );
                return Number(BigInt(toScalar) / BigInt(1024 * 1024)) / 1024;
              }
            }
          }
        }
      }
    }
  }
  return undefined;
}

export function policyCpuLimitsSetLimitsValue(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  return Number(
    quantityToScalar(policy?.spec?.policyOptimize?.rightSizePolicy?.limitConfigs?.["cpu"]?.setLimit?.toString() ?? "")
  );
}

export function policyMemoryLimitsSetLimitsValue(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  const toScalar = Math.round(
    quantityToScalar(
      policy?.spec?.policyOptimize?.rightSizePolicy?.limitConfigs?.["memory"]?.setLimit?.toString() ?? ""
    )
  );
  return Number(BigInt(toScalar) / BigInt(1024 * 1024)) / 1024;
}

export function policyCpuMaxAllowed(policy: components["schemas"]["V1alpha1Policy"] | undefined): number | undefined {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"] != undefined) {
              if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"].maxAllowed == undefined) {
                return undefined;
              } else {
                return Number(
                  quantityToScalar(
                    policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"].maxAllowed.toString()
                  )
                );
              }
            }
          }
        }
      }
    }
  }
  return undefined;
}

export function policyMemoryMaxAllowed(
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): number | undefined {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"] != undefined) {
              if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"].maxAllowed == undefined) {
                return undefined;
              } else {
                const toScalar = Math.round(
                  quantityToScalar(
                    policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"].maxAllowed.toString()
                  )
                );
                return Number(BigInt(toScalar) / BigInt(1024 * 1024)) / 1024;
              }
            }
          }
        }
      }
    }
  }
  return undefined;
}

export function policyCpuLimitsHeadroom(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"] != undefined) {
              if (
                policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"].noLimit === undefined ||
                policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"].noLimit == false
              ) {
                return policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"].headroomPercentage || 0;
              }
            }
          }
        }
      }
    }
  }
  return 0;
}

export function policyCpuLimitsPercentile(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"] != undefined) {
              if (
                policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"].noLimit === undefined ||
                policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"].noLimit == false
              ) {
                return policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"].percentilePercentage || 0;
              }
            }
          }
        }
      }
    }
  }
  return 0;
}

export function policyMemoryLimitsPercentile(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"] != undefined) {
              if (
                policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"].noLimit === undefined ||
                policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"].noLimit == false
              ) {
                return policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"].percentilePercentage || 0;
              }
            }
          }
        }
      }
    }
  }
  return 0;
}

export function policyMemoryLimitsHeadroom(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"] != undefined) {
              if (
                policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"].noLimit === undefined ||
                policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"].noLimit == false
              ) {
                return policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"].headroomPercentage || 0;
              }
            }
          }
        }
      }
    }
  }
  return 0;
}

export function policyCpuLimitsNoLimit(policy: components["schemas"]["V1alpha1Policy"] | undefined): boolean {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"] != undefined) {
              if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"].noLimit != undefined) {
                return policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"].noLimit;
              }
            }
          }
        }
      }
    }
  }
  return false;
}

export function policyMemoryLimitsNoLimit(policy: components["schemas"]["V1alpha1Policy"] | undefined): boolean {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"]?.noLimit != undefined) {
              return policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"].noLimit;
            }
          }
        }
      }
    }
  }
  return false;
}

export function policyCpuLimitsEqualsToRequest(policy: components["schemas"]["V1alpha1Policy"] | undefined): boolean {
  return policy?.spec?.policyOptimize?.rightSizePolicy?.limitConfigs?.["cpu"]?.equalsToRequest ?? false;
}

export function policyMemoryLimitsEqualsToRequest(
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): boolean {
  return policy?.spec?.policyOptimize?.rightSizePolicy?.limitConfigs?.["memory"]?.equalsToRequest ?? false;
}

export function policyCpuLimitsKeepLimit(policy: components["schemas"]["V1alpha1Policy"] | undefined): boolean {
  return policy?.spec?.policyOptimize?.rightSizePolicy?.limitConfigs?.["cpu"]?.keepLimit ?? false;
}

export function policyMemoryLimitsKeepLimit(policy: components["schemas"]["V1alpha1Policy"] | undefined): boolean {
  return policy?.spec?.policyOptimize?.rightSizePolicy?.limitConfigs?.["memory"]?.keepLimit ?? false;
}

export function policyMaxUnavailablePodsPercentage(
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): number {
  const val = policy?.spec?.updatePolicy?.maxUnavailablePodsPercentage;
  return val === undefined || val === null ? 10 : Number(val);
}

export function policyEvictionScheduleScaleDown(policy: components["schemas"]["V1alpha1Policy"] | undefined): string {
  return policy?.spec?.updatePolicy?.evictionSchedule?.scaleDown || "* * * * *";
}

export function policyEvictionScheduleScaleUp(policy: components["schemas"]["V1alpha1Policy"] | undefined): string {
  return policy?.spec?.updatePolicy?.evictionSchedule?.scaleUp || "* * * * *";
}

export function policyBootTime(policy: components["schemas"]["V1alpha1Policy"] | undefined): boolean {
  return policy?.spec?.updatePolicy?.bootTime || false;
}

export function policyStabilizationScaleDownWindow(
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): number {
  return policy?.spec?.updatePolicy?.stabilizationWindowSeconds?.scaleDown || 1;
}

export function policyStabilizationScaleUpWindow(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  return policy?.spec?.updatePolicy?.stabilizationWindowSeconds?.scaleUp || 1;
}

export function policyMinChangeScaleDownCpu(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy?.spec?.updatePolicy?.minScaleDownChangeDiff != undefined) {
    if (policy?.spec?.updatePolicy?.minScaleDownChangeDiff["cpu"] != undefined) {
      return policy?.spec?.updatePolicy?.minScaleDownChangeDiff["cpu"] || 0.1;
    }
  }
  return 0.1;
}

export function policyMinChangeScaleDownMemory(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy?.spec?.updatePolicy?.minScaleDownChangeDiff != undefined) {
    if (policy?.spec?.updatePolicy?.minScaleDownChangeDiff["memory"] != undefined) {
      return policy?.spec?.updatePolicy?.minScaleDownChangeDiff["memory"] || 0.1;
    }
  }
  return 0.1;
}

export function policyMinChangeScaleUpCpu(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy?.spec?.updatePolicy?.minScaleUpChangeDiff != undefined) {
    if (policy?.spec?.updatePolicy?.minScaleUpChangeDiff["cpu"] != undefined) {
      return policy?.spec?.updatePolicy?.minScaleUpChangeDiff["cpu"] || 0.1;
    }
  }
  return 0.1;
}

export function policyMinChangeScaleUpMemory(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy?.spec?.updatePolicy?.minScaleUpChangeDiff != undefined) {
    if (policy?.spec?.updatePolicy?.minScaleUpChangeDiff["memory"] != undefined) {
      return policy?.spec?.updatePolicy?.minScaleUpChangeDiff["memory"] || 0.1;
    }
  }
  return 0.1;
}

export function policyUpdateMode(policy: components["schemas"]["V1alpha1Policy"] | undefined): string {
  if (policy?.spec?.updatePolicy?.updateMode != undefined) {
    return policy?.spec?.updatePolicy.updateMode;
  }
  return "Ongoing";
}

export function policyUpdateDeploymentMode(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  if (policy?.spec?.updatePolicy?.updateByTypeMode?.deployment !== undefined) {
    return policy?.spec?.updatePolicy?.updateByTypeMode?.deployment ?? "Ongoing";
  }
  return "Ongoing";
}

export function policyUpdateDeploymentConfigMode(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  if (policy?.spec?.updatePolicy?.updateByTypeMode?.deploymentConfig !== undefined) {
    return policy?.spec?.updatePolicy?.updateByTypeMode?.deploymentConfig ?? "Ongoing";
  }
  return "Ongoing";
}

export function policyUpdateDaemonSetMode(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  if (policy?.spec?.updatePolicy?.updateByTypeMode?.daemonSet !== undefined) {
    return policy?.spec?.updatePolicy?.updateByTypeMode?.daemonSet ?? "Ongoing";
  }
  return "Ongoing";
}

export function policyUpdateJobMode(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  if (policy?.spec?.updatePolicy?.updateByTypeMode?.job !== undefined) {
    return policy?.spec?.updatePolicy?.updateByTypeMode?.job ?? "Ongoing";
  }
  return "Ongoing";
}

export function policyUpdateStatefulSetMode(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  if (policy?.spec?.updatePolicy?.updateByTypeMode?.statefulSet !== undefined) {
    return policy?.spec?.updatePolicy?.updateByTypeMode?.statefulSet ?? "Ongoing";
  }
  return "Ongoing";
}
export function policyUpdateFamilySetMode(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  if (policy?.spec?.updatePolicy?.updateByTypeMode?.family !== undefined) {
    return policy?.spec?.updatePolicy?.updateByTypeMode?.family ?? "OnCreate";
  }
  return "OnCreate";
}

export function policyRolloutMinReplicas(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy?.spec?.updatePolicy?.minReplicas != undefined) {
    return policy?.spec?.updatePolicy?.minReplicas;
  }
  return 1;
}

export function getPolicyPriorityName(policy: components["schemas"]["V1alpha1Policy"] | undefined): string {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.priority != undefined) {
          if (policy.spec.policyOptimize.priority.name != undefined) {
            return policy.spec.policyOptimize.priority.name || "";
          }
        }
      }
    }
  }
  return "";
}

export function getScaleoutEnabled(/*policy: components["schemas"]["V1alpha1Policy"] | undefined*/): boolean {
  return true;
  // if (policy?.spec?.policyOptimize?.scaleOutPolicy?.enabled != undefined) {
  //   return policy.spec.policyOptimize.scaleOutPolicy.enabled || false;
  // }
  // return false;
}

export function getScaleoutGoalsConfigured(policy: components["schemas"]["V1alpha1Policy"] | undefined): boolean {
  if (policy?.spec?.policyOptimize?.scaleOutPolicy?.metricGoals != undefined) {
    return policy.spec.policyOptimize.scaleOutPolicy.metricGoals.length > 0;
  }
  return false;
}

export function getScaleoutMinReplicas(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy?.spec?.policyOptimize?.scaleOutPolicy?.minReplicas != undefined) {
    return policy.spec.policyOptimize.scaleOutPolicy.minReplicas || 0;
  }
  return 0;
}

export function getScaleoutMaxReplicas(policy: components["schemas"]["V1alpha1Policy"] | undefined): number {
  if (policy?.spec?.policyOptimize?.scaleOutPolicy?.maxReplicas != undefined) {
    return policy.spec.policyOptimize.scaleOutPolicy.maxReplicas || 0;
  }
  return 0;
}

export function getScaleoutMetricGoals(
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): components["schemas"]["V1alpha1MetricConfWithValue"][] {
  if (policy?.spec?.policyOptimize?.scaleOutPolicy?.metricGoals != undefined) {
    return policy.spec.policyOptimize.scaleOutPolicy.metricGoals || [];
  }
  return [];
}

export function getScaleoutPolicyBehavior(
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): components["schemas"]["V1alpha1ScaleOutBehavior"] | undefined {
  if (policy?.spec?.policyOptimize?.scaleOutPolicy != undefined) {
    return policy?.spec?.policyOptimize?.scaleOutPolicy?.scaleOutBehavior || undefined;
  }
  return undefined;
}

export function PolicyUpdateScaleOutPolicyBehavior(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  behavior: components["schemas"]["V1alpha1ScaleOutBehavior"] | undefined
) {
  if (policy?.spec?.policyOptimize?.scaleOutPolicy != undefined) {
    policy.spec.policyOptimize.scaleOutPolicy.scaleOutBehavior = behavior;
  }
}

export function policyUpdateCpuHeadroom(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: number) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"].headroomPercentage = value;
            }
          }
        }
      }
    }
  }
}

export function policyAddDefaultScheduleProperties(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  if (policy?.spec) {
    policy.spec.policySchedule = {
      overrides: [
        {
          policyOverriderName: "production",
          period: {
            days: [0, 1, 2, 3, 4, 5, 6],
            beginTime: "07:00",
            endTime: "21:00",
          },
        },
        {
          policyOverriderName: "production",
          period: {
            days: [0, 1, 2, 3, 4, 5, 6],
            beginTime: "21:01",
            endTime: "06:59",
            scaleToZeroEnabled: true,
          },
        },
      ],
    };
  }
}

export function policyUpdateMemoryHeadroom(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: number) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"].headroomPercentage = value;
            }
          }
        }
      }
    }
  }
  return 0;
}

export function policyUpdateHistoryWindow(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: string,
  resource: string
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.windowByResource != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.windowByResource[resource] != undefined) {
              return (policy.spec.policyOptimize.rightSizePolicy.windowByResource[resource] = value);
            }
          }
        }
      }
    }
  }
}

export function policyUpdateCpuPercentile(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
): void {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined)
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"] != undefined) {
              if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"].percentilePercentage != undefined) {
                policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"].percentilePercentage = value;
              }
            }
        }
      }
    }
  }
}

export function policyUpdateMemoryPercentile(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined)
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"] != undefined) {
              if (
                policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"].percentilePercentage != undefined
              ) {
                return (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"].percentilePercentage =
                  value);
              }
            }
        }
      }
    }
  }
}

export function policyUpdateCpuLimitsPercentile(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"].percentilePercentage = value;
            }
          }
        }
      }
    }
  }
}

export function policyUpdateMemoryLimitsPercentile(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"].percentilePercentage = value;
            }
          }
        }
      }
    }
  }
  return 0;
}

export function policyUpdateCpuLimitsHeadroom(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"].headroomPercentage = value;
            }
          }
        }
      }
    }
  }
}

export function policyUpdateMemoryLimitsHeadroom(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"].headroomPercentage = value;
            }
          }
        }
      }
    }
  }
  return 0;
}

export function policyUpdateCpuLimitsNoLimit(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"].noLimit = value;
            }
          }
        }
      }
    }
  }
}

export function policyUpdateMemoryLimitsNoLimit(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"].noLimit = value;
            }
          }
        }
      }
    }
  }
  return 0;
}

export function policyUpdateCpuLimitsEqualsToRequest(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"].equalsToRequest = value;
            }
          }
        }
      }
    }
  }
}

export function policyUpdateMemoryLimitsEqualsToRequest(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"].equalsToRequest = value;
            }
          }
        }
      }
    }
  }
  return 0;
}

export function policyUpdateCpuLimitsKeepLimit(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.limitConfigs["cpu"].keepLimit = value;
            }
          }
        }
      }
    }
  }
}

export function policyUpdateMemoryLimitsKeepLimit(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.limitConfigs["memory"].keepLimit = value;
            }
          }
        }
      }
    }
  }
  return 0;
}

export function policyUpdateCpuLimitStrategy(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: string,
  setLimit: number,
  limitPercentile: number,
  limitHeadroom: number
) {
  switch (value) {
    case LimitStrategyItems.KeepLimit:
      policyUpdateCpuLimitsKeepLimit(policy, true);
      policyUpdateCpuLimitsNoLimit(policy, false);
      policyUpdateCpuLimitsEqualsToRequest(policy, false);
      policyUpdateCpuLimitsSetLimit(policy);
      policyUpdateCpuLimitsPercentile(policy, 0);
      policyUpdateCpuLimitsHeadroom(policy, 0);
      break;
    case LimitStrategyItems.NoLimit:
      policyUpdateCpuLimitsKeepLimit(policy, false);
      policyUpdateCpuLimitsNoLimit(policy, true);
      policyUpdateCpuLimitsEqualsToRequest(policy, false);
      policyUpdateCpuLimitsSetLimit(policy);
      policyUpdateCpuLimitsPercentile(policy, 0);
      policyUpdateCpuLimitsHeadroom(policy, 0);
      break;
    case LimitStrategyItems.SetLimit:
      policyUpdateCpuLimitsKeepLimit(policy, false);
      policyUpdateCpuLimitsNoLimit(policy, false);
      policyUpdateCpuLimitsEqualsToRequest(policy, false);
      policyUpdateCpuLimitsSetLimit(policy, setLimit);
      policyUpdateCpuLimitsPercentile(policy, 0);
      policyUpdateCpuLimitsHeadroom(policy, 0);
      break;
    case LimitStrategyItems.ManagedLimit:
      policyUpdateCpuLimitsKeepLimit(policy, false);
      policyUpdateCpuLimitsNoLimit(policy, false);
      policyUpdateCpuLimitsEqualsToRequest(policy, false);
      policyUpdateCpuLimitsSetLimit(policy);
      policyUpdateCpuLimitsPercentile(policy, limitPercentile);
      policyUpdateCpuLimitsHeadroom(policy, limitHeadroom);
      break;
    case LimitStrategyItems.EqualsToRequest:
      policyUpdateCpuLimitsKeepLimit(policy, false);
      policyUpdateCpuLimitsNoLimit(policy, false);
      policyUpdateCpuLimitsEqualsToRequest(policy, true);
      policyUpdateCpuLimitsSetLimit(policy);
      policyUpdateCpuLimitsPercentile(policy, 0);
      policyUpdateCpuLimitsHeadroom(policy, 0);
      break;
  }
}
export function policyUpdateMemoryLimitStrategy(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: string,
  setLimit: number,
  limitPercentile: number,
  limitHeadroom: number
) {
  switch (value) {
    case LimitStrategyItems.KeepLimit:
      policyUpdateMemoryLimitsKeepLimit(policy, true);
      policyUpdateMemoryLimitsNoLimit(policy, false);
      policyUpdateMemoryLimitsEqualsToRequest(policy, false);
      policyUpdateMemoryLimitsSetLimit(policy);
      policyUpdateMemoryLimitsPercentile(policy, 0);
      policyUpdateMemoryLimitsHeadroom(policy, 0);
      break;
    case LimitStrategyItems.NoLimit:
      policyUpdateMemoryLimitsKeepLimit(policy, false);
      policyUpdateMemoryLimitsNoLimit(policy, true);
      policyUpdateMemoryLimitsEqualsToRequest(policy, false);
      policyUpdateMemoryLimitsSetLimit(policy);
      policyUpdateMemoryLimitsPercentile(policy, 0);
      policyUpdateMemoryLimitsHeadroom(policy, 0);
      break;
    case LimitStrategyItems.SetLimit:
      policyUpdateMemoryLimitsKeepLimit(policy, false);
      policyUpdateMemoryLimitsNoLimit(policy, false);
      policyUpdateMemoryLimitsEqualsToRequest(policy, false);
      policyUpdateMemoryLimitsSetLimit(policy, setLimit);
      policyUpdateMemoryLimitsPercentile(policy, 0);
      policyUpdateMemoryLimitsHeadroom(policy, 0);
      break;
    case LimitStrategyItems.ManagedLimit:
      policyUpdateMemoryLimitsKeepLimit(policy, false);
      policyUpdateMemoryLimitsNoLimit(policy, false);
      policyUpdateMemoryLimitsEqualsToRequest(policy, false);
      policyUpdateMemoryLimitsSetLimit(policy);
      policyUpdateMemoryLimitsPercentile(policy, limitPercentile);
      policyUpdateMemoryLimitsHeadroom(policy, limitHeadroom);
      break;
    case LimitStrategyItems.EqualsToRequest:
      policyUpdateMemoryLimitsKeepLimit(policy, false);
      policyUpdateMemoryLimitsNoLimit(policy, false);
      policyUpdateMemoryLimitsEqualsToRequest(policy, true);
      policyUpdateMemoryLimitsSetLimit(policy, setLimit);
      policyUpdateMemoryLimitsPercentile(policy, 0);
      policyUpdateMemoryLimitsHeadroom(policy, 0);
      break;
  }
}

export function policyUpdatePriorityName(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: string) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.priority == undefined) {
          policy.spec.policyOptimize.priority = {};
        }
        if (value == "None") {
          value = "";
        }
        policy.spec.policyOptimize.priority.name = value;
      }
    }
  }
}

export function policyUpdateUpdateMode(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: string) {
  if (policy?.spec?.updatePolicy?.updateMode != undefined) {
    return (policy.spec.updatePolicy.updateMode = value);
  }
}

export function policyUpdateUpdateDaemonSetMode(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: string
) {
  if (policy?.spec?.updatePolicy?.updateByTypeMode?.daemonSet !== undefined) {
    return (policy.spec.updatePolicy.updateByTypeMode.daemonSet = value);
  }
}

export function policyUpdateUpdateDeploymentMode(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: string
) {
  if (policy?.spec?.updatePolicy?.updateByTypeMode?.deployment !== undefined) {
    return (policy.spec.updatePolicy.updateByTypeMode.deployment = value);
  }
}

export function policyUpdateUpdateDeploymentConfigMode(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: string
) {
  if (policy?.spec?.updatePolicy?.updateByTypeMode?.deploymentConfig !== undefined) {
    return (policy.spec.updatePolicy.updateByTypeMode.deploymentConfig = value);
  }
}

export function policyUpdateUpdateJobMode(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: string) {
  if (policy?.spec?.updatePolicy?.updateByTypeMode?.job !== undefined) {
    return (policy.spec.updatePolicy.updateByTypeMode.job = value);
  }
}

export function policyUpdateUpdateStatefulSetMode(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: string
) {
  if (policy?.spec?.updatePolicy?.updateByTypeMode?.statefulSet !== undefined) {
    return (policy.spec.updatePolicy.updateByTypeMode.statefulSet = value);
  }
}

export function policyUpdateUpdateFamilySetMode(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: string
) {
  if (policy?.spec?.updatePolicy?.updateByTypeMode?.family !== undefined) {
    return (policy.spec.updatePolicy.updateByTypeMode.family = value);
  }
}

export function policyUpdateMaxUnavailablePodsPercentage(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy?.spec?.updatePolicy?.maxUnavailablePodsPercentage != undefined) {
    return (policy.spec.updatePolicy.maxUnavailablePodsPercentage = value);
  }
}

export function policyUpdateEvictionScheduleScaleUp(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: string
) {
  if (policy?.spec?.updatePolicy?.evictionSchedule?.scaleUp != undefined) {
    return (policy.spec.updatePolicy.evictionSchedule.scaleUp = value);
  }
}

export function policyUpdateEvictionScheduleScaleDown(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: string
) {
  if (policy?.spec?.updatePolicy?.evictionSchedule?.scaleDown != undefined) {
    return (policy.spec.updatePolicy.evictionSchedule.scaleDown = value);
  }
}

export function policyUpdateStabilizationWindowSecondsScaleDown(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy?.spec?.updatePolicy != undefined) {
    if (policy?.spec?.updatePolicy?.stabilizationWindowSeconds == undefined) {
      policy.spec.updatePolicy.stabilizationWindowSeconds = {};
    }

    policy.spec.updatePolicy.stabilizationWindowSeconds.scaleDown = value;
  }
}

export function policyUpdateStabilizationWindowSecondsScaleUp(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy?.spec?.updatePolicy != undefined) {
    if (policy?.spec?.updatePolicy?.stabilizationWindowSeconds == undefined) {
      policy.spec.updatePolicy.stabilizationWindowSeconds = {};
    }

    policy.spec.updatePolicy.stabilizationWindowSeconds.scaleUp = value;
  }
}

export function policyUpdateRolloutMinReplicas(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy?.spec?.updatePolicy?.minReplicas != undefined) {
    return (policy.spec.updatePolicy.minReplicas = value);
  }
}

export function policyUpdateBootTime(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: boolean) {
  if (policy?.spec != undefined) {
    if (policy?.spec?.updatePolicy !== undefined) {
      policy.spec.updatePolicy.bootTime = value;
    }
  }
}

export function policyUpdateMinChangeScaleDownCpu(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy?.spec?.updatePolicy?.minScaleDownChangeDiff != undefined) {
    if (policy?.spec?.updatePolicy?.minScaleDownChangeDiff["cpu"] != undefined) {
      return (policy.spec.updatePolicy.minScaleDownChangeDiff["cpu"] = value);
    }
  }
}

export function policyUpdateMinChangeScaleDownMemory(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy?.spec?.updatePolicy?.minScaleDownChangeDiff != undefined) {
    if (policy?.spec?.updatePolicy?.minScaleDownChangeDiff["memory"] != undefined) {
      return (policy.spec.updatePolicy.minScaleDownChangeDiff["memory"] = value);
    }
  }
}

export function policyUpdateMinChangeScaleUpCpu(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy?.spec?.updatePolicy?.minScaleUpChangeDiff != undefined) {
    if (policy?.spec?.updatePolicy?.minScaleUpChangeDiff["cpu"] != undefined) {
      return (policy.spec.updatePolicy.minScaleUpChangeDiff["cpu"] = value);
    }
  }
}

export function policyUpdateMinChangeScaleUpMemory(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy?.spec?.updatePolicy?.minScaleUpChangeDiff != undefined) {
    if (policy?.spec?.updatePolicy?.minScaleUpChangeDiff["memory"] != undefined) {
      return (policy.spec.updatePolicy.minScaleUpChangeDiff["memory"] = value);
    }
  }
}

export function policyUpdateCpuMinAllowed(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: number) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"].minAllowed =
                (value * 1000).toString() + "m";
            }
          }
        }
      }
    }
  }
}

export function policyUpdateMemoryMinAllowed(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"].minAllowed = value.toString() + "Gi";
            }
          }
        }
      }
    }
  }
}

export function policyUpdateCpuLimitsSetLimit(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value?: number
) {
  if (policy?.spec?.policyOptimize?.rightSizePolicy?.limitConfigs?.cpu != undefined) {
    policy.spec.policyOptimize.rightSizePolicy.limitConfigs.cpu.setLimit =
      value && value > 0 ? (value * 1000).toString() + "m" : undefined;
  }
}

export function policyUpdateMemoryLimitsSetLimit(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value?: number
) {
  if (policy?.spec?.policyOptimize?.rightSizePolicy?.limitConfigs?.memory != undefined) {
    policy.spec.policyOptimize.rightSizePolicy.limitConfigs.memory.setLimit =
      value && value > 0 ? value.toString() + "Gi" : undefined;
  }
}

export function policyUpdateCpuMaxAllowed(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: number) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["cpu"].maxAllowed =
                (value * 1000).toString() + "m";
            }
          }
        }
      }
    }
  }
}

export function policyUpdateMemoryMaxAllowed(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policyOptimize != undefined) {
        if (policy.spec.policyOptimize.rightSizePolicy != undefined) {
          if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs != undefined) {
            if (policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"] != undefined) {
              policy.spec.policyOptimize.rightSizePolicy.requestsConfigs["memory"].maxAllowed = value.toString() + "Gi";
            }
          }
        }
      }
    }
  }
}

export function updatePolicyName(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: string) {
  if (policy != undefined) {
    if (policy.metadata == undefined) {
      policy.metadata = { name: value };
    } else {
      policy.metadata.name = value;
    }
  }
}

export function updatePolicyType(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: string) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      policy.spec.type = value;
    }
  }
}

export function addPolicyScheduleOverride(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  newOverrider: components["schemas"]["V1alpha1Override"] | undefined
) {
  if (newOverrider != undefined) {
    if (policy != undefined) {
      if (policy.spec != undefined) {
        if (policy.spec.policySchedule == undefined) {
          policy.spec.policySchedule = {};
        }
        if (policy.spec.policySchedule.overrides != undefined) {
          policy.spec.policySchedule.overrides = policy.spec.policySchedule.overrides.concat([newOverrider]);
        } else {
          policy.spec.policySchedule.overrides = [newOverrider];
        }
      }
    }
  }
}

export function deletePolicyScheduleOverride(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  index: number
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policySchedule != undefined) {
        if (policy.spec.policySchedule.overrides != undefined) {
          if (index < policy.spec.policySchedule.overrides.length) {
            policy.spec.policySchedule.overrides = policy.spec.policySchedule.overrides.concat([]); // Hook to make the list render
            policy.spec.policySchedule.overrides.splice(index, 1);
          }
        }
      }
    }
  }
}

export function updatePolicyOverrideSchedule(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  index: number,
  overrider: components["schemas"]["V1alpha1Override"]
) {
  if (policy != undefined) {
    if (policy.spec != undefined) {
      if (policy.spec.policySchedule != undefined) {
        if (policy.spec.policySchedule.overrides != undefined) {
          if (index < policy.spec.policySchedule.overrides.length) {
            if (policy.spec.policySchedule.overrides[index] != undefined) {
              policy.spec.policySchedule.overrides[index] = overrider;
            }
          }
        }
      }
    }
  }
}

export function addScaleoutMetricGoal(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  metricGoal: components["schemas"]["V1alpha1MetricConfWithValue"]
) {
  if (metricGoal != undefined) {
    if (policy?.spec?.policyOptimize?.scaleOutPolicy != undefined) {
      if (policy.spec.policyOptimize.scaleOutPolicy?.metricGoals != undefined) {
        policy.spec.policyOptimize.scaleOutPolicy.metricGoals =
          policy.spec.policyOptimize.scaleOutPolicy.metricGoals.concat([metricGoal]);
      } else {
        policy.spec.policyOptimize.scaleOutPolicy.metricGoals = [metricGoal];
      }
    }
  }
}

export function updatePolicyScaleoutMetricGoal(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  index: number,
  metricWithVal: components["schemas"]["V1alpha1MetricConfWithValue"]
) {
  // if (metricTrigger.promQuery == "") {
  //   metricTrigger.promQuery = 'histogram_quantile(0.99, sum(rate(http_latency_ms_bucket{job="*"}[5m])) by (le))';
  // }
  if (policy?.spec?.policyOptimize?.scaleOutPolicy?.metricGoals != undefined) {
    if (index < policy.spec.policyOptimize.scaleOutPolicy.metricGoals.length) {
      if (policy.spec.policyOptimize.scaleOutPolicy.metricGoals[index] != undefined) {
        policy.spec.policyOptimize.scaleOutPolicy.metricGoals[index] = metricWithVal;
      }
    }
  }
}

export function updatePolicyScaleoutMetricGoals(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  metricGoals: components["schemas"]["V1alpha1MetricConfWithValue"][]
) {
  if (policy?.spec?.policyOptimize?.scaleOutPolicy != undefined) {
    policy.spec.policyOptimize.scaleOutPolicy.metricGoals = metricGoals;
  }
}

export function updatePolicyEnableScaleout(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  enable: boolean
) {
  if (enable == true) {
    if (policy?.spec?.policyOptimize != undefined) {
      if (policy.spec.policyOptimize.scaleOutPolicy == undefined) {
        policy.spec.policyOptimize.scaleOutPolicy = {};
      }
    }
  }
  if (policy?.spec?.policyOptimize?.scaleOutPolicy != undefined) {
    policy.spec.policyOptimize.scaleOutPolicy.enabled = enable;
  }
}

export function updatePolicyScaleoutMinReplicas(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  minReplicas: number
) {
  if (policy?.spec?.policyOptimize?.scaleOutPolicy != undefined) {
    policy.spec.policyOptimize.scaleOutPolicy.minReplicas = minReplicas;
  }
}

export function updatePolicyScaleoutMaxReplicas(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  maxReplicas: number
) {
  if (policy?.spec?.policyOptimize?.scaleOutPolicy != undefined) {
    policy.spec.policyOptimize.scaleOutPolicy.maxReplicas = maxReplicas;
  }
}

export function policyRequiredHistoryCoveragePercentage(
  policy: components["schemas"]["V1alpha1Policy"] | undefined
): number {
  return policy?.spec?.updatePolicy?.requiredWindowCoveragePercentage || 0;
}

export function policyUpdateRequiredHistoryCoveragePercentage(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: number
) {
  if (policy?.spec?.updatePolicy != undefined) {
    policy.spec.updatePolicy.requiredWindowCoveragePercentage = value;
  }
}

export function policyJvmEnvXmsMxs(policy: components["schemas"]["V1alpha1Policy"] | undefined): boolean {
  return policy?.spec?.updatePolicy?.overrideJvmXmsXmxEnv || false;
}

export function policyUpdateJvmEnvXms(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: boolean) {
  if (policy?.spec?.updatePolicy != undefined) {
    policy.spec.updatePolicy.overrideJvmXmsXmxEnv = value;
  }
}

export function policyNodeCapping(policy: components["schemas"]["V1alpha1Policy"] | undefined): boolean {
  return !!policy?.spec?.policyOptimize?.rightSizePolicy?.nodeCappingPolicy?.nodeCappingAuto;
}

export function policyUpdateNodeCapping(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: boolean) {
  if (policy?.spec?.policyOptimize?.rightSizePolicy) {
    policy.spec.policyOptimize.rightSizePolicy.nodeCappingPolicy = { nodeCappingAuto: value };
  }
}

export function policyAllowRollingUpdate(policy: components["schemas"]["V1alpha1Policy"] | undefined): boolean {
  return policy?.spec?.updatePolicy?.allowRollingUpdate || false;
}

export function policyManageHPA(policy: components["schemas"]["V1alpha1Policy"] | undefined): boolean {
  return policy?.spec?.hpa?.manageHPA || false;
}

export function policyUpdateAllowRollingUpdate(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) {
  if (policy?.spec?.updatePolicy != undefined) {
    policy.spec.updatePolicy.allowRollingUpdate = value;
  }
}

export function policyUpdateManageHPA(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: boolean) {
  if (policy?.spec?.hpa != undefined) {
    policy.spec.hpa.manageHPA = value;
  }
}

export function policyCpuKeepLimit(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  return policy?.spec?.policyOptimize?.rightSizePolicy?.limitConfigs?.cpu?.keepLimit;
}

export function policyUpdateCpuKeepLimit(policy: components["schemas"]["V1alpha1Policy"] | undefined, value: boolean) {
  if (policy?.spec?.policyOptimize?.rightSizePolicy?.limitConfigs?.cpu?.keepLimit !== undefined) {
    policy.spec.policyOptimize.rightSizePolicy.limitConfigs.cpu.keepLimit = value;
  }
}

export function policyMemoryKeepLimit(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  return policy?.spec?.policyOptimize?.rightSizePolicy?.limitConfigs?.memory?.keepLimit;
}

export function policyUpdateMemoryKeepLimit(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) {
  if (policy?.spec?.policyOptimize?.rightSizePolicy?.limitConfigs?.memory?.keepLimit !== undefined) {
    policy.spec.policyOptimize.rightSizePolicy.limitConfigs.memory.keepLimit = value;
  }
}

export function policyCpuCostReductionOnly(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  return policy?.spec?.policyOptimize?.rightSizePolicy?.costReductionRecommendationOnly?.cpu;
}

export function policyUpdateCpuCostReductionOnly(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) {
  if (policy?.spec?.policyOptimize?.rightSizePolicy?.costReductionRecommendationOnly?.cpu !== undefined) {
    policy.spec.policyOptimize.rightSizePolicy.costReductionRecommendationOnly.cpu = value;
  }
}

export function policyMemoryCostReductionOnly(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  return policy?.spec?.policyOptimize?.rightSizePolicy?.costReductionRecommendationOnly?.memory;
}

export function policyUpdateMemoryCostReductionOnly(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) {
  if (policy?.spec?.policyOptimize?.rightSizePolicy?.costReductionRecommendationOnly?.memory !== undefined) {
    policy.spec.policyOptimize.rightSizePolicy.costReductionRecommendationOnly.memory = value;
  }
}

export function policyBinPackUnEvictablePods(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  return policy?.spec?.updatePolicy?.binPackUnEvictablePods;
}

export function policyAdvancedBinPackCasLimitations(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  return policy?.spec?.updatePolicy?.advancedBinPackCasLimitations;
}

export function policyUpdateBinPackUnEvictablePods(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) {
  if (policy?.spec?.updatePolicy?.binPackUnEvictablePods !== undefined) {
    policy.spec.updatePolicy.binPackUnEvictablePods = value;
  }
}

export function policyUpdateAdvancedBinPackCasLimitations(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) {
  if (policy?.spec?.updatePolicy?.advancedBinPackCasLimitations !== undefined) {
    policy.spec.updatePolicy.advancedBinPackCasLimitations = value;
  }
}

export function policyHpaRightSizeCapping(policy: components["schemas"]["V1alpha1Policy"] | undefined) {
  return policy?.spec?.policyOptimize?.rightSizePolicy?.hpaUtilTriggerRightsizeProtection;
}

export function policyUpdateHpaRightSizeCapping(
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) {
  if (policy?.spec?.policyOptimize?.rightSizePolicy?.hpaUtilTriggerRightsizeProtection !== undefined) {
    policy.spec.policyOptimize.rightSizePolicy.hpaUtilTriggerRightsizeProtection = value;
  }
}

export const getSkipRolloutUponAutomation = (policy: components["schemas"]["V1alpha1Policy"] | undefined) => {
  return policy?.spec?.updatePolicy?.skipRolloutUponAutomation;
};

export const updateSkipRolloutUponAutomation = (
  policy: components["schemas"]["V1alpha1Policy"] | undefined,
  value: boolean
) => {
  if (policy?.spec?.updatePolicy !== undefined) {
    policy.spec.updatePolicy.skipRolloutUponAutomation = value;
  } else if (policy?.spec !== undefined) {
    policy.spec.updatePolicy = { skipRolloutUponAutomation: value };
  }
};

export const getFastReaction = (policy: components["schemas"]["V1alpha1Policy"] | undefined) => {
  return policy?.spec?.policyOptimize?.fastReaction?.enabled?.["cpu"];
};

export const updateFastReaction = (policy: components["schemas"]["V1alpha1Policy"] | undefined, value: boolean) => {
  if (policy?.spec?.policyOptimize?.fastReaction?.enabled !== undefined) {
    policy.spec.policyOptimize.fastReaction.enabled["cpu"] = value;
  } else if (policy?.spec?.policyOptimize !== undefined) {
    policy.spec.policyOptimize.fastReaction = {
      ...policy.spec.policyOptimize.fastReaction,
      enabled: {
        ...(policy.spec.policyOptimize.fastReaction?.enabled || {}),
        cpu: value,
        memory: value,
      },
    };
  }
};

export const getAutoHealingEnabled = (policy: components["schemas"]["V1alpha1Policy"] | undefined) => {
  return policy?.spec?.policyOptimize?.fastReaction?.enabled?.["cpu"];
};

export const isBuiltInPolicy = (policy: components["schemas"]["V1alpha1Policy"] | undefined): boolean => {
  if (policy !== undefined) {
    if (
      policy.metadata?.labels?.["scaleops.sh/builtin-policy"] === "true" ||
      policy.metadata?.labels?.["scaleops.sh/demo-builtin-policy"] === "true" ||
      // meta.helm.sh/release-name and meta.helm.sh/release-namespace are deprecated!
      // It can be removed after all clients using scaleops.sh/builtin-policy
      (policy.metadata?.annotations?.["meta.helm.sh/release-name"] === "scaleops" &&
        policy.metadata?.annotations?.["meta.helm.sh/release-namespace"] === "scaleops-system")
    ) {
      return true;
    }
  }
  return false;
};
