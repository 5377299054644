import React from "react";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import Tooltip from "../Tooltip";
import { Typography } from "@mui/material";
import Switch from "../Switch";
import { East } from "@mui/icons-material";
import { GetCaConfigurationDeploymentResponse } from "../../api/fetcher";

export type RowType = {
  name: React.ReactNode; // info
  id: string; // title
  description: React.ReactNode; // info
  isDisabled?: boolean;
  values: {
    current?: number | string | boolean;
    recommended?: number | string | boolean;
  };
  isChecked: boolean;
  originalOrder?: number;
  onChange: (value: boolean) => void;
};

export type OrderEntry = {
  id: string;
  originalOrder?: number;
};

export const getOriginalValue = (value: keyof OrderEntry, id: string, order: OrderEntry[] | undefined) => {
  if (!order || order.length === 0) return undefined;

  const row = order.find((row) => row.id === id);

  return row && row[value];
};

export const getOriginalOrder = (row: RowType) => {
  let originalOrder;

  switch (true) {
    case !row.isChecked && !row.isDisabled:
      originalOrder = 1;
      break;
    case row.isChecked && !row.isDisabled:
      originalOrder = 2;
      break;
    case !row.isChecked && row.isDisabled:
      originalOrder = 3;
      break;
    case row.isChecked && row.isDisabled:
      originalOrder = 4;
      break;
  }
  return originalOrder;
};

export interface ValueProps {
  value: string | number | boolean | undefined;
}

export const OFF = () => <div className="text-disable px-2 rounded w-12 text-center">false</div>;

export const GreenOFF = () => <div className="text-guideline-darkGreen px-2 rounded w-12 text-center">false</div>;

export const ON = () => <div className="text-guideline-darkGreen px-2 rounded w-12 text-center">true</div>;

export const BlackON = () => <div className="text-disable px-2 rounded w-12 text-center">true</div>;

export const RedValue = ({ value }: ValueProps) => <p className="text-main-red">{value}</p>;

export const GreenValue = ({ value }: ValueProps) => <p className="text-guideline-darkGreen">{value}</p>;

export const renderCurrentVsRecommended = (
  current: string | number | boolean | undefined,
  recommended: string | number | boolean | undefined,
  id?: string
) => {
  let reverseColors = false;

  if (id === "skip-nodes-with-system-pods" || id === "skip-nodes-with-local-storage") {
    reverseColors = true;
  }

  if (id === "expander" || id === "scale-down-unneeded-time") {
    let defaultValue;
    switch (id) {
      case "expander":
        defaultValue = "random";
        break;
      case "scale-down-unneeded-time":
        defaultValue = "5m";
        break;
      default:
        defaultValue = "-";
    }

    current = current === undefined ? defaultValue : current;

    return (
      <Typography className="flex items-center justify-center gap-2" variant="body2">
        {current === recommended ? <GreenValue value={current} /> : <RedValue value={current} />}
        <East style={{ fontSize: "medium" }} />
        <GreenValue value={recommended} />
      </Typography>
    );
  }

  let currentToDisplay;
  let recommendedToDisplay;
  switch (true) {
    case typeof current === "boolean" && !reverseColors:
      currentToDisplay = current ? <ON /> : <OFF />;
      break;
    case typeof current === "boolean" && reverseColors:
      currentToDisplay = current ? <BlackON /> : <GreenOFF />;
      break;
    case typeof current === "number" && typeof recommended === "number" && current >= recommended:
      currentToDisplay = <GreenValue value={current} />;
      break;
    case typeof current === "number" && typeof recommended === "number" && current < recommended:
      currentToDisplay = <RedValue value={current} />;
      break;
    default:
      currentToDisplay = <p>{current}</p>;
  }

  switch (true) {
    case typeof recommended === "boolean":
      recommendedToDisplay = recommended ? <ON /> : <OFF />;
      break;
    case typeof recommended === "number":
      recommendedToDisplay = <GreenValue value={recommended} />;
      break;
    default:
      recommendedToDisplay = <p>{recommended}</p>;
  }

  return (
    <Typography className="flex items-center justify-center gap-2" variant="body2">
      <p>{currentToDisplay}</p>
      <East style={{ fontSize: "medium" }} />
      <p className="text-guideline-darkGreen">{recommendedToDisplay}</p>
    </Typography>
  );
};

export const valueRenderCell = (params: GridRenderCellParams<string, RowType, string>) => {
  return renderCurrentVsRecommended(params.row.values.current, params.row.values.recommended, params.row.id);
};

export const getColumns = (): GridColumns<RowType> => [
  {
    field: "name",
    headerName: "Name",
    width: 300,
    type: "string",
    align: "left",
    headerAlign: "left",
    sortable: false,
    renderCell: (params: GridRenderCellParams<string, RowType, string>) => {
      return (
        <Typography className="truncate cursor-pointer" variant="body2" fontWeight={600}>
          {params.row.name}
        </Typography>
      );
    },
  },
  {
    field: "description",
    headerName: "Description",
    flex: 2,
    type: "string",
    align: "left",
    headerAlign: "left",
    sortable: false,
    renderCell: (params: GridRenderCellParams<string, RowType, string>) => {
      return (
        <Typography className="truncate cursor-auto" variant="body2" sx={{ whiteSpace: "normal" }}>
          {params.row.description}
        </Typography>
      );
    },
  },
  {
    field: "values",
    headerName: "Current / Recommended",
    flex: 1,
    type: "string",
    align: "center",
    headerAlign: "center",
    sortable: false,
    renderCell: valueRenderCell,
  },
  {
    field: "isChecked",
    headerName: "Optimize",
    flex: 1,
    type: "string",
    align: "center",
    headerAlign: "center",
    sortable: false,
    renderCell: (params: GridRenderCellParams<string, RowType, string>) => (
      <Tooltip title={params.row.description} disabled={!params.row.isDisabled}>
        <Switch checked={params.row.isChecked} onChange={params.row.onChange} disabled={params.row.isDisabled} />
      </Tooltip>
    ),
  },
  {
    field: "originalOrder",
    headerName: "originalOrder",
    sortable: true,
    hide: true,
  },
];

export const AZURE_CODE = `
az aks update \\
  --resource-group <RESOURCE_GROUP_NAME> \\
  --name <CLUSTER_NAME> \\
  --cluster-autoscaler-profile scale-down-utilization-threshold=0.7 skip-nodes-with-local-storage=false skip-nodes-with-system-pods=false scale-down-unneeded-time=5m expander=least-waste
`;

export const GENERAL_CODE = `
kubectl patch deployment <CLUSTER_AUTOSCALER_DEPLOYMENT> --namespace <CLUSTER_NAMESPACE> --type='json' \\
  -p='[{"op": "add", "path": "/spec/template/spec/containers/0/command/-1", "value": "--scale-down-utilization-threshold=0.7"},
  {"op": "add", "path": "/spec/template/spec/containers/0/command/-1", "value": "--skip-nodes-with-local-storage=false"},
  {"op": "add", "path": "/spec/template/spec/containers/0/command/-1", "value": "--skip-nodes-with-system-pods=false"},
  {"op": "add", "path": "/spec/template/spec/containers/0/command/-1", "value": "--expander=least-waste"},
  {"op": "add", "path": "/spec/template/spec/containers/0/command/-1", "value": "--scale-down-unneeded-time=5m"}]'
`;

export const getOptimisticValues = (key: string, deploymentData: GetCaConfigurationDeploymentResponse) => {
  let rollbackValueExist;
  switch (true) {
    case key === "scale-down-utilization-threshold":
      rollbackValueExist =
        deploymentData.scaleopsNodesAnnotations &&
        deploymentData.scaleopsNodesAnnotations["scale-down-utilization-threshold"]?.exist;
      return {
        on: 0.7,
        off:
          rollbackValueExist && deploymentData.scaleopsNodesAnnotations
            ? deploymentData.scaleopsNodesAnnotations["scale-down-utilization-threshold"]?.value
            : 0.5,
      };
    case key === "expander":
      rollbackValueExist =
        deploymentData.scaleopsNodesAnnotations && deploymentData.scaleopsNodesAnnotations["expander"]?.exist;
      return {
        on: "least-waste",
        off:
          rollbackValueExist && deploymentData.scaleopsNodesAnnotations
            ? deploymentData.scaleopsNodesAnnotations["expander"]?.value
            : "random",
      };
    case key === "scale-down-unneeded-time":
      rollbackValueExist =
        deploymentData.scaleopsNodesAnnotations &&
        deploymentData.scaleopsNodesAnnotations["scale-down-unneeded-time"]?.exist;
      return {
        on: "5m",
        off:
          rollbackValueExist && deploymentData.scaleopsNodesAnnotations
            ? deploymentData.scaleopsNodesAnnotations["scale-down-unneeded-time"]?.value
            : "10m",
      };
    default:
      return {
        on: true,
        off: false,
      };
  }
};
