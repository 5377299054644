import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import TopOverviewSection from "../pages/Overview/TopOverviewSection/TopOverviewSection";
import { FeatureEnabled } from "../utils/FeaturesHelper";
import { useMainContext } from "../MainContext";
import { GetDashboardByNamespaceResponse, getDashboardByNamespace } from "../api/fetcher";
import useFilterQueryParams, { FilterParamObject } from "./WorkloadStatusByNamespace/useFilterQueryParams";
import { CircularProgress } from "@mui/material";

const DEFAULT_FETCH_INTERVAL = 12000;
const DEMO_FETCH_INTERVAL = 5000;

interface Props {
  disableAnimation?: boolean;
}

const TopOverviewContainer = ({ disableAnimation }: Props) => {
  const [refetchInterval, setRefetchInterval] = useState<number>(DEFAULT_FETCH_INTERVAL);
  const dashboardByNamespace = getDashboardByNamespace();
  const filterQueryParams: FilterParamObject = useFilterQueryParams();
  const { didClusterChanged } = useMainContext();

  useEffect(() => {
    if (FeatureEnabled("DemoVersion")) {
      setRefetchInterval(DEMO_FETCH_INTERVAL);
    }
  }, []);

  const { data, error } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey, filterQueryParams],
    queryFn: () => dashboardByNamespace.queryFn(filterQueryParams),
    refetchInterval,
  });

  if (error && didClusterChanged) {
    toast.error("Error fetching data for this cluster");
    console.log(error);
  }

  if (!data && !error) {
    return (
      <div className="w-full rounded-bl-lg rounded-br-lg rounded-tr-lg rounded-border flex items-center justify-center h-[220px] py-[20px] bg-white shadow-sm relative mt-8">
        <CircularProgress />
      </div>
    );
  }

  return (
    <TopOverviewSection
      monthlyForecast={data?.totalNamespaceSummary?.totalNodesCost}
      defaultOptimizedCost={data?.totalNamespaceSummary?.optimized}
      underProvisionedWorkloads={data?.totalNamespaceSummary?.underProvisionedWorkloads}
      overProvisionedWorkloads={data?.totalNamespaceSummary?.overProvisionedWorkloads}
      totalAutomatedWorkloads={data?.totalNamespaceSummary?.automatedWorkloads}
      totalUnAutomatedWorkloads={data?.totalNamespaceSummary?.unautomatedWorkloads}
      monthlySavingsAvailable={data?.totalNamespaceSummary?.monthlySavingsAvailable}
      activeMonthlySavingsAvailable={data?.totalNamespaceSummary?.activeMonthlySavingsAvailable}
      disableAnimation={disableAnimation}
      totalUnoptimizedNotEvictableWorkloads={data?.totalNamespaceSummary?.totalUnoptimizedNotEvictableWorkloads}
    />
  );
};

export default TopOverviewContainer;
