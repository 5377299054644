import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Checkbox, ListItemText, MenuItem, Select, Typography } from "@mui/material";
import clsx from "clsx";
import { FixedSizeList as List } from "react-window";
import { LogicalOperator } from "../api/fetcher";
import { camelCaseToSnakeSpace, capitalizeFirstLetter } from "../utils/formatterUtils";
import Tooltip from "./Tooltip";
import useSelectSearch from "./useSelectSearch";
import AddIcon from "../Icons/AddIcon";

const MAX_LIST_HEIGHT = 300;
const LIST_SIZE = 54;

const SELECT_BUTTON_CLASS_NAME = "hover:bg-guideline-lightGray border border-border rounded px-2 cursor-pointer";

const defaultRenderValue = (selected: (string | undefined)[]) => {
  if (selected.length === 0) {
    return "Selected (0)";
  }
  return `${selected?.join(", ")}`;
};

const defaultOptionRenderFunction = (
  option: string,
  index: number,
  selected: (string | undefined)[],
  dataTestId?: string,
  capitalize?: boolean
) => (
  <MenuItem
    value={option}
    key={`${index}-multi-select-option`}
    data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
    className="min-w-fit"
  >
    <div className="min-w-[235px] flex items-center">
      <Checkbox checked={selected.indexOf(option) > -1} />
      <ListItemText primary={capitalize ? capitalizeFirstLetter(option) : option} />
    </div>
  </MenuItem>
);

export const camelCaseToSnakeSpaceOptionRenderFunction = (
  option: string,
  index: number,
  selected: (string | undefined)[],
  dataTestId?: string
) => (
  <MenuItem
    value={option}
    key={`${index}-multi-select-option`}
    data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
  >
    <Checkbox checked={selected.indexOf(option) > -1} />
    <ListItemText primary={camelCaseToSnakeSpace(option)} />
  </MenuItem>
);

interface MultiSelectProps {
  label?: React.ReactNode;
  options: string[];
  selected: (string | undefined)[];
  setSelected: (props: (string | undefined)[]) => void;
  className?: string;
  labelClassName?: string;
  listHeight?: string;
  listWidth?: string;
  optionRenderFunction?: (
    option: string,
    index: number,
    selected: (string | undefined)[],
    dataTestId?: string,
    capitalize?: boolean
  ) => JSX.Element;
  isSearchable?: boolean;
  sort?: "asc" | "desc";
  customIcon?: JSX.Element;
  customInput?: React.ReactNode;
  disabled?: boolean;
  isExclude?: boolean | null | undefined;
  setIsExclude?: (value: boolean | null | undefined) => void;
  logicalOperator?: LogicalOperator;
  setLogicalOperator?: (value: LogicalOperator) => void;
  dataTestId?: string;
  width?: number | undefined;
  capitalize?: boolean;
  tooltipPrefix?: React.ReactNode;
  hasSelectAllAndClearSelection?: boolean;
  hasVirtualizedList?: boolean;
  fontSize?: string;
  wrapperClassName?: string;
  renderValue?: (selected: (string | undefined)[]) => string;
  customOnClose?: () => void;
  addCustomValueFnc?: (searchTerm: string) => void;
}

const MultiSelect = ({
  selected,
  setSelected,
  label,
  options,
  className,
  labelClassName,
  listHeight,
  listWidth,
  optionRenderFunction = defaultOptionRenderFunction,
  isSearchable,
  sort,
  customIcon,
  customInput,
  disabled,
  isExclude,
  setIsExclude,
  logicalOperator,
  setLogicalOperator,
  dataTestId,
  width,
  capitalize,
  tooltipPrefix,
  hasSelectAllAndClearSelection = true,
  hasVirtualizedList,
  fontSize,
  wrapperClassName,
  renderValue = defaultRenderValue,
  customOnClose,
  addCustomValueFnc,
}: MultiSelectProps) => {
  const { search, searchDiv, includeExcludeDiv, logicalOperatorDiv } = useSelectSearch(
    isSearchable,
    isExclude,
    setIsExclude,
    undefined,
    logicalOperator,
    setLogicalOperator
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const filteredOptions = options
    .filter((entity) => entity !== undefined)
    .filter((entity) => {
      if (!isSearchable) return true;
      return entity?.includes(search) || false;
    })
    .sort((a, b) => {
      if (sort === "asc") {
        return a.localeCompare(b);
      }
      if (sort === "desc") {
        return b.localeCompare(a);
      }
      return 0;
    });

  const LIST_HEIGHT = Math.min(MAX_LIST_HEIGHT, LIST_SIZE * filteredOptions.length);

  const hasNoResults = !filteredOptions?.length && search.length > 0;

  return (
    <div className={clsx(wrapperClassName, "flex flex-col gap-1 relative")}>
      {label && <Typography className={clsx(labelClassName, "text-text-lightBlack")}>{label}</Typography>}
      {customIcon && (
        <div
          className={clsx(className, "absolute mt-[2px] w-48 h-10 flex justify-center items-center")}
          style={{ width: width }}
        >
          {customIcon}
        </div>
      )}
      <Tooltip
        title={
          <>
            {tooltipPrefix ?? ""}
            {selected?.join(", ")}.
          </>
        }
        disabled={isOpen || selected?.length === 0}
      >
        <Select
          data-testid={dataTestId}
          disabled={disabled}
          multiple
          value={selected}
          displayEmpty
          renderValue={renderValue}
          onChange={(event) => {
            setSelected(event.target.value as (string | undefined)[]);
          }}
          className={clsx(className, "h-10 w-48")}
          IconComponent={(props) => <KeyboardArrowDownIcon className="mr-2" {...props} />}
          sx={{
            opacity: customIcon ? 0 : 1,
            width: width,
            fontSize: fontSize,
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            classes: {
              paper: "bg-white",
            },
            style: {
              opacity: 1,
              maxHeight: listHeight,
              maxWidth: listWidth,
            },
          }}
          onOpen={() => setIsOpen(true)}
          onClose={() => {
            customOnClose && customOnClose();
            setIsOpen(false);
          }}
          open={isOpen}
        >
          {isSearchable && searchDiv}
          {customInput}
          <div className="flex gap-4">
            {setIsExclude && includeExcludeDiv}
            {setIsExclude && setLogicalOperator && (
              <div className="flex items-end mt-[12px] w-[1px] h-[14px] bg-text-disable" />
            )}
            {setLogicalOperator && logicalOperatorDiv}
          </div>
          <div className="flex items-center px-[25px] mt-[10px]">
            {hasSelectAllAndClearSelection && (
              <p className="text-[12px] flex justify-start align-middle gap-2">
                <span
                  onClick={() => {
                    setSelected([...selected, ...filteredOptions.filter((option) => !selected.includes(option))]);
                  }}
                  className={SELECT_BUTTON_CLASS_NAME}
                >
                  select all
                </span>
                <span
                  onClick={() => {
                    setSelected([]);
                  }}
                  className={SELECT_BUTTON_CLASS_NAME}
                >
                  clear selection
                </span>
              </p>
            )}
            {addCustomValueFnc && (
              <p className="text-[12px] flex justify-end items-center grow">
                <span
                  onClick={() => hasNoResults && addCustomValueFnc(search)}
                  className={clsx(
                    {
                      "opacity-50 cursor-not-allowed": !hasNoResults,
                    },
                    SELECT_BUTTON_CLASS_NAME,
                    "flex gap-1 items-center"
                  )}
                >
                  <AddIcon width={12} height={12} />
                  Add custom value
                </span>
              </p>
            )}
          </div>
          {hasVirtualizedList ? (
            <List height={LIST_HEIGHT} itemCount={filteredOptions?.length ?? 0} itemSize={LIST_SIZE} width={500}>
              {({ index, style }) => (
                <div
                  style={style}
                  onClick={() => {
                    if (selected.includes(filteredOptions[index])) {
                      setSelected(selected.filter((option) => option !== filteredOptions[index]));
                    } else {
                      setSelected([...selected, filteredOptions[index]]);
                    }
                  }}
                >
                  {optionRenderFunction(filteredOptions[index], index, selected, dataTestId, capitalize)}
                </div>
              )}
            </List>
          ) : (
            filteredOptions.map((option, index) =>
              optionRenderFunction(option, index, selected, dataTestId, capitalize)
            )
          )}
        </Select>
      </Tooltip>
    </div>
  );
};

export default MultiSelect;
