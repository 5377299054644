import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "./Tooltip";
import clsx from "clsx";
import DuplicateIcon from "../Icons/DuplicateIcon";
import HideIcon from "../Icons/HideIcon";
import ShowIcon from "../Icons/ShowIcon";

export enum THEME {
  dark = "dark",
  light = "light",
}
interface Props {
  title?: string;
  description?: React.ReactNode;
  info?: React.ReactNode;
  codeSnippet: string;
  codeSnippetToCopy?: string;
  className?: string;
  theme?: THEME;
}

const CodeSnippet = ({
  title,
  description,
  info,
  codeSnippet,
  codeSnippetToCopy,
  className,
  theme = THEME.dark,
}: Props) => {
  const [showCopied, setShowCopied] = useState<boolean>(false);
  const [showToken, setShowToken] = useState<boolean>(false);

  const iconClassName = clsx({
    "hover:text-primary-lightPurpleBlue": theme === THEME.dark,
    "hover:text-guideline-darkPurple": theme === THEME.light,
  });

  const codeToDisplay = showToken && codeSnippetToCopy ? codeSnippetToCopy : codeSnippet;

  useEffect(() => {
    if (showCopied) {
      const timeout = setTimeout(() => {
        setShowCopied(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [showCopied]);

  const onClick = () => {
    setShowCopied(true);
    let codeToCopy = codeSnippetToCopy ?? codeSnippet;
    codeToCopy = codeToCopy.trim();
    navigator.clipboard.writeText(codeToCopy);
  };

  return (
    <div className={clsx(className, "flex flex-col gap-4")}>
      {title && (
        <Typography variant="body1" fontWeight={700} className="flex gap-2 item-center">
          {title}
          {info && (
            <Tooltip title={info}>
              <InfoOutlinedIcon style={{ width: 14 }} />
            </Tooltip>
          )}
        </Typography>
      )}
      {description && <Typography variant="body2">{description}</Typography>}
      <div
        className={clsx(
          "rounded-md text-sm font-mono overflow-x-auto hide-scroll-bar cursor-pointer p-2 px-[24px] relative",
          {
            "bg-black text-white": theme === THEME.dark,
            "bg-background-chip text-black": theme === THEME.light,
          }
        )}
        onClick={onClick}
      >
        <div className="w-full flex justify-end gap-2 h-[20px]">
          {codeSnippetToCopy && (
            <Tooltip title="Show/Hide">
              <div
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  setShowToken(!showToken);
                }}
              >
                {showToken ? <ShowIcon className={iconClassName} /> : <HideIcon className={iconClassName} />}
              </div>
            </Tooltip>
          )}
          <DuplicateIcon className={iconClassName} />
        </div>
        <div className="px-[24px] pb-[24px] pt-[4px] whitespace-pre-wrap">{codeToDisplay.trim()}</div>
        <div
          className={clsx(
            "bg-[rgba(255,255,255,0.8)] absolute bottom-[2px] right-[2px] text-black rounded-md p-2 fade-in-and-out ",
            {
              hidden: !showCopied,
            }
          )}
        >
          <Typography variant="body2">Copied to clipboard</Typography>
        </div>
      </div>
    </div>
  );
};

export default CodeSnippet;
