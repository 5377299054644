import { Tooltip as MuiTooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import clsx from "clsx";
import ScaleopsIcon from "../Icons/ScaleopsIcon";
import { SCALEOPS_COLORS } from "../colors";

const SCALEOPS_ICON_SIZE = 60;

const HtmlTooltip = styled(
  ({ className, ...props }: TooltipProps & { maxWidth?: number; backgroundColor?: string }) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
  )
)(({ maxWidth = 220, backgroundColor = "white" }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor,
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth,
    borders: "1px solid #E5E7ED",
    bordersRadius: "4px",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    padding: "20px 20px",
  },
}));

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
  disabled?: boolean;
  maxWidth?: number;
  isOpened?: boolean;
  enterDelay?: number;
  className?: string;
  tooltipClassName?: string;
  onClick?: () => void;
  addScaleOpsCubeIconToTopLeft?: boolean;
  backgroundColor?: string;
  isTourGuideTooltip?: boolean;
}

const Tooltip = ({
  children,
  placement = "top",
  title,
  disabled,
  maxWidth,
  isOpened,
  enterDelay,
  className,
  tooltipClassName,
  onClick,
  addScaleOpsCubeIconToTopLeft,
  backgroundColor,
  isTourGuideTooltip,
}: Props) => {
  return !disabled ? (
    <HtmlTooltip
      open={isOpened}
      title={
        <Typography variant="caption">
          <div
            className={clsx({
              relative: addScaleOpsCubeIconToTopLeft || isTourGuideTooltip,
            })}
          >
            {(addScaleOpsCubeIconToTopLeft || isTourGuideTooltip) && (
              <ScaleopsIcon
                className="absolute left-[-40px] top-[-40px] py-[10px] info-icon-animation"
                width={SCALEOPS_ICON_SIZE}
                height={SCALEOPS_ICON_SIZE}
              />
            )}
            {title}
          </div>
        </Typography>
      }
      placement={placement}
      maxWidth={maxWidth}
      backgroundColor={backgroundColor ?? isTourGuideTooltip ? SCALEOPS_COLORS.background.chip : "white"}
      enterDelay={enterDelay}
      enterNextDelay={enterDelay}
      onClick={onClick}
      className={tooltipClassName}
    >
      <div className={className}>{children}</div>
    </HtmlTooltip>
  ) : (
    <>{children}</>
  );
};

export default Tooltip;
