import { MenuItem, Select, Typography } from "@mui/material";

interface Props {
  options: string[];
  onChange: (value: string) => void;
  disabled?: boolean;
  defaultValue?: string;
  preSelectText?: React.ReactNode;
  postSelectText?: React.ReactNode;
}

const SelectActionItem = ({ options, onChange, disabled, defaultValue, preSelectText, postSelectText }: Props) => (
  <div>
    {preSelectText}
    <Select
      disabled={disabled}
      variant={"standard"}
      sx={{
        minWidth: "90px",
        height: 20,
        paddingTop: "11px",
        marginTop: "-1px",
        "& .MuiSvgIcon-root": {
          marginTop: "3px",
        },
      }}
      defaultValue={defaultValue}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    >
      {options.map((option) => (
        <MenuItem value={option}>
          <Typography variant="body2">{option}</Typography>
        </MenuItem>
      ))}
    </Select>
    {postSelectText}
  </div>
);

export default SelectActionItem;
