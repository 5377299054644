import { useEffect, useState } from "react";
import { ScaleOps } from "../api/api";
import * as React from "react";
import Box from "@mui/material/Box";
import { Switch, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { GetBetaFeaturesQuery, GetBetaFeaturesResponse } from "../api/fetcher";
import { useQuery } from "@tanstack/react-query";

export default GeneralSettingsTab;

function GeneralSettingsTab(props: { handleSave: (success: boolean, message: string) => void; disable: boolean }) {
  const { handleSave, disable } = props;
  const api = ScaleOps();
  const [betaFeaturesEnabled, setBetaFeaturesEnabled] = useState<boolean>(false);
  const betaFeaturesQuery = GetBetaFeaturesQuery();
  const { data: betaFeatures } = useQuery<GetBetaFeaturesResponse, Error>({
    queryKey: [betaFeaturesQuery.queryKey],
    queryFn: betaFeaturesQuery.queryFn,
  });
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    api
      .getFetcher()
      .path("/api/v1/settings/beta-features")
      .method("post")
      .create()({
        enabled: betaFeaturesEnabled,
      })
      .then(() => {
        handleSave(true, "");
      })
      .catch((reason) => {
        handleSave(false, "Save Failed");
        console.error(reason);
      });
  };

  useEffect(() => {
    if (betaFeatures) {
      setBetaFeaturesEnabled(!!betaFeatures?.enabled);
    }
  }, [betaFeatures]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <FormControl variant="standard">
        <Typography variant="body2" className="flex items-center gap-1">
          <Switch
            name="betaFeaturesEnabled"
            id="betaFeaturesEnabled"
            checked={betaFeaturesEnabled}
            key={betaFeaturesEnabled ? "true" : "false"}
            onChange={(_, checked) => {
              setBetaFeaturesEnabled(checked);
            }}
            disabled={disable}
          />
          <div>Enable Beta Features</div>
        </Typography>
      </FormControl>
      <div className="w-full flex justify-end">
        <Button type="submit" variant="contained" disabled={disable}>
          Save
        </Button>
      </div>
    </Box>
  );
}
