import { CircularProgress, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { NavLink } from "react-router-dom";
import { GetNotEvictable, GetNotEvictableResponse } from "../../../api/fetcher";
import InfoIcon from "../../../Icons/InfoIcon";
import MultipleCubes from "../../../Icons/MultipleCubes";
import { useMainContext } from "../../../MainContext";
import RunningNumberBox from "../../../pages/Overview/TopOverviewSection/RunningNumberBox";
import TotalAutomatedPieChart from "../../../pages/Overview/TopOverviewSection/TotalAutomatedPieChart";
import Button, { BUTTON_VARIANTS } from "../../Button";
import Tooltip from "../../Tooltip";
import NotSupportingBinPackingPoliciesLinks from "./NotSupportingBinPackingPoliciesLinks";
import { blockedNodesInfo, unEvictableWorkloadsInfo } from "./utils";

const HorizontalDivider = () => <div className="bg-strongBorder h-[1px] w-[80%]" />;
const VerticalDivider = () => <div className="bg-strongBorder w-[1px] h-[80%]" />;

const { queryFn, queryKey } = GetNotEvictable();

const UnEvictableVisibilityContainer = () => {
  const { currentCluster } = useMainContext();

  const { data, isLoading, isError, error } = useQuery<GetNotEvictableResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
  });

  let savingsAvailable = data?.savingsAvailable ?? 0;
  savingsAvailable = Number.isNaN(savingsAvailable) ? 0 : savingsAvailable;

  let notSupportingBinPackingPolicies = data?.notSupportingBinPackingPolicies?.length ?? 0;
  if (notSupportingBinPackingPolicies < 0 || Number.isNaN(notSupportingBinPackingPolicies)) {
    notSupportingBinPackingPolicies = 0;
  }

  const blockedNodesCount: number =
    data?.blockedNodesCount && !Number.isNaN(data.blockedNodesCount) && data?.blockedNodesCount >= 0
      ? data.blockedNodesCount
      : 0;

  const totalAutomatedWorkloads = data?.notEvictableAutomatedWorkloadsCount ?? 0;
  const totalUnAutomatedWorkloads = data?.notEvictableNotAutomatedWorkloadsCount ?? 0;

  let link = `/?currentClusterURLParam=${currentCluster ?? ""}&isPdb=1&isUnAutomated=1&selectAllOnInitialLoading=1`;
  if (notSupportingBinPackingPolicies > 0) {
    link = link + "&isPolicyExclude=1";
    data?.notSupportingBinPackingPolicies?.forEach((policy) => {
      link = link + `&policy=${policy}`;
    });
  }

  if (isError) {
    console.log("Error fetching not evictable pods", error);
    return null;
  }

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg px-8 py-8 flex justify-center gap-8">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg px-8 py-8 h-[243px] flex items-center">
      <div className="grow flex gap-10 relative h-fit pr-8">
        <MultipleCubes width={90} height={90} />
        <div className="flex flex-col justify-center gap-1">
          <Tooltip title={unEvictableWorkloadsInfo} className="w-max flex items-center" maxWidth={500}>
            <Typography variant="h6" fontWeight={700}>
              Optimize Your Unevictable Workloads
            </Typography>
            <InfoIcon width={14} height={14} />
          </Tooltip>
          <Typography variant="body2" className="text-text-lightBlack max-w-[413px]">
            Click to view <b>unevictable and unoptimized workloads</b>. Bin-pack your unevictable workloads to{" "}
            <b>reduce your cost.</b>
          </Typography>
          <Tooltip
            title={
              <NotSupportingBinPackingPoliciesLinks
                notSupportingBinPackingPolicies={data?.notSupportingBinPackingPolicies}
              />
            }
            className="w-max flex justify-center mt-3"
            maxWidth={600}
          >
            <NavLink to={link}>
              <Button
                label="EXPLORE AND AUTOMATE WORKLOADS"
                variant={BUTTON_VARIANTS.mediumDarkPurple}
                onClick={() => {
                  console.log("clicked");
                }}
                fontSize={14}
              />
            </NavLink>
          </Tooltip>
        </div>
      </div>
      <VerticalDivider />
      <div className="grow min-w-[24%] flex flex-col gap-4 justify-center items-center">
        <RunningNumberBox
          value={savingsAvailable}
          title="Savings available"
          numberVariant="h4"
          prefix={"$"}
          numberClassName="text-main-green"
          disableAnimation={true}
        />
        <HorizontalDivider />
        <RunningNumberBox
          value={blockedNodesCount}
          title={
            //Blocked Nodes
            <Tooltip title={blockedNodesInfo} maxWidth={600} className="flex items-center">
              <span>Blocked nodes</span>
              <InfoIcon width={12} height={12} />
            </Tooltip>
          }
          numberVariant="h4"
          numberClassName="text-main-red"
          disableAnimation={true}
        />
      </div>
      <VerticalDivider />

      <div className="min-w-[370px] grow">
        <TotalAutomatedPieChart
          totalAutomatedWorkloads={totalAutomatedWorkloads}
          totalUnAutomatedWorkloads={totalUnAutomatedWorkloads}
          subtitle="unevictable workloads"
          // disableAnimation
          showAutomatedAndUnAutomatedLabels
        />
      </div>
    </div>
  );
};

export default UnEvictableVisibilityContainer;
