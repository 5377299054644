import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  GetCostBreakdown,
  GetCostBreakdownAggregations,
  GetCostBreakdownAggregationsResponse,
  GetCostBreakdownResponse,
} from "../../../api/fetcher";
import GraphCircularLoader from "../../../components/GraphCircularLoader";
import useAggregationFilters from "../Tables/Aggregation/hooks/useAggregationFilters";
import { TableType } from "../Tables/utils";
import useWorkloadsFilters from "../Tables/Workloads/hooks/useWorkloadsFilters";
import DailyChart from "./DailyChart";
import { DailyData } from "./utils";

const TOP_K = 9;
const { queryKey, queryFn } = GetCostBreakdown();
const { queryKey: aggregationQueryKey, queryFn: aggregationQueryFn } = GetCostBreakdownAggregations();

const getUniqueKeys = (data: GetCostBreakdownResponse | undefined) => {
  if (!data || !data.costs) return [];

  const uniqueKeys = [...new Set(data.costs.map((el) => el.costs?.map((item) => item?.id)).flat())]
    .filter((el) => el)
    .map((el) => el as string);
  return uniqueKeys;
};

const getGraphData = (data: GetCostBreakdownResponse) => {
  if (!data || !data.costs) return [];

  const graphData = data.costs.map((el) => {
    const values = el.costs?.reduce((acc, item) => {
      if (item.id && item.value) acc[item.id] = item.value;
      return acc;
    }, {} as Record<string, number>);

    return {
      timestamp: el.timestamp,
      ...values,
    } as DailyData;
  });

  return graphData;
};

const getElementOrder = (data: GetCostBreakdownResponse) => {
  let uniqueKeys = getUniqueKeys(data);
  uniqueKeys = uniqueKeys.filter((key) => key !== "timestamp");

  const sumByUnqiueKey = uniqueKeys.map((key) => {
    const sum = data.costs?.reduce((acc, item) => {
      const value = item.costs?.find((el) => el.id === key)?.value;
      return acc + (value || 0);
    }, 0);
    return { key, sum };
  });

  const elementOrder = sumByUnqiueKey.sort((a, b) => (b.sum ?? 0) - (a.sum ?? 0)).map((el) => el.key);

  return elementOrder;
};

interface Props {
  selectedTable: TableType;
}

const DailyChartContainer = ({ selectedTable }: Props) => {
  const workloadsFilters = useWorkloadsFilters();
  const workloadsFiltersLength = Object.keys(workloadsFilters).length;
  const aggregationFilters = useAggregationFilters();
  const aggregationFiltersLength = Object.keys(aggregationFilters).length;

  const [uniqueKeys, setUniqueKeys] = useState<string[]>([]);
  const [graphData, setGraphData] = useState<DailyData[]>([]);
  const [elementOrder, setElementOrder] = useState<string[]>([]);

  const { data, isLoading, error } = useQuery<GetCostBreakdownResponse, Error>({
    queryKey: [queryKey, workloadsFilters],
    queryFn: () =>
      queryFn({
        ...workloadsFilters,
        aggregation: 1,
        multiCluster: true,
        topk: TOP_K,
      }),
    enabled: selectedTable === TableType.Workloads && !!workloadsFiltersLength,
  });

  const {
    data: aggregationData,
    isLoading: aggregationIsLoading,
    error: aggregationError,
  } = useQuery<GetCostBreakdownAggregationsResponse, Error>({
    queryKey: [aggregationQueryKey, aggregationFilters],
    queryFn: () =>
      aggregationQueryFn({
        ...aggregationFilters,
        aggregation: 1,
        multiCluster: true,
        topk: TOP_K,
      }),
    enabled: selectedTable === TableType.Aggregation && !!aggregationFiltersLength,
  });

  useEffect(() => {
    switch (selectedTable) {
      case TableType.Workloads:
        if (data?.costs !== undefined) {
          setUniqueKeys(getUniqueKeys(data ?? []));
          setGraphData(getGraphData(data ?? []));
          setElementOrder(getElementOrder(data ?? []));
        }
        break;
      case TableType.Aggregation:
        if (aggregationData?.costs) {
          setUniqueKeys(getUniqueKeys(aggregationData));
          setGraphData(getGraphData(aggregationData));
          setElementOrder(getElementOrder(aggregationData));
        }
        break;
    }
  }, [data, aggregationData, selectedTable]);

  if (error || aggregationError) {
    console.log("Error fetching cost breakdown", error || aggregationError);
  }

  return (
    <div className="relative flex items-center align-center w-full h-full">
      {(isLoading && selectedTable === TableType.Workloads) ||
      (aggregationIsLoading && selectedTable === TableType.Aggregation) ? (
        <GraphCircularLoader />
      ) : null}
      <DailyChart data={graphData} uniqueKeys={uniqueKeys} elementOrder={elementOrder} />
    </div>
  );
};

export default DailyChartContainer;
