import { Bar, BarChart, XAxis, YAxis } from "recharts";
import Tooltip from "../../../components/Tooltip";

const WASTE_COLOR = "#FF6161";
const OTHER_COLOR = "#e1eaf9";

interface Props {
  waste?: number;
  optimized?: number;
  disableTooltip?: boolean;
  disableAnimation?: boolean;
}

const WasteVsOptimizedBarCharts = ({ waste = 0, optimized = 0, disableTooltip, disableAnimation }: Props) => {
  return (
    <Tooltip
      title={
        <>
          <b className="text-main-red">{Math.round(waste)}%</b> of your total cloud spend{" "}
          <b className="text-main-red">is being wasted.</b>
          <br />
          <br />
          <b>Automate your workloads</b> to reduce your waste.
        </>
      }
      placement="bottom"
      disabled={disableTooltip}
    >
      <BarChart
        width={200}
        height={30}
        data={[
          {
            name: "waste",
            waste: Number.isNaN(waste) ? 0 : waste,
            optimized: Number.isNaN(optimized) ? 0 : optimized,
          },
        ]}
        layout="vertical"
      >
        <Bar dataKey="waste" stackId="a" fill={WASTE_COLOR} isAnimationActive={!disableAnimation} />
        <Bar dataKey="optimized" stackId="a" fill={OTHER_COLOR} isAnimationActive={!disableAnimation} />
        <XAxis type="number" hide={true} />
        <YAxis type="category" dataKey="a" hide={true} />
      </BarChart>
    </Tooltip>
  );
};

export default WasteVsOptimizedBarCharts;
