import React, { useState } from "react";
import { Typography } from "@mui/material";
import clsx from "clsx";
import CloseIcon from "../Icons/CloseIcon";

export enum AlertBarTheme {
  Default = "default",
  Green = "green",
  Red = "red",
  LightRed = "lightRed",
  Yellow = "yellow",
  Black = "black",
}

const CLOSE = "close";

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode[];
  localStorageKey?: string;
  sessionStorageKey?: string;
  hideCloseButton?: boolean;
  wrapperClassName?: string;
  theme?: AlertBarTheme;
}

const AlertBar = ({
  title,
  description,
  localStorageKey,
  sessionStorageKey,
  hideCloseButton = false,
  wrapperClassName,
  theme = AlertBarTheme.Default,
}: Props) => {
  const [isClosed, setIsClosed] = useState<string | null>(
    localStorageKey
      ? localStorage.getItem(localStorageKey)
      : sessionStorageKey
      ? sessionStorage.getItem(sessionStorageKey)
      : null
  );

  const handleClose = () => {
    // if (!localStorageKey && !sessionStorageKey) return null;
    localStorageKey && localStorage.setItem(localStorageKey, CLOSE);
    sessionStorageKey && sessionStorage.setItem(sessionStorageKey, CLOSE);
    setIsClosed(CLOSE);
  };

  if (isClosed === CLOSE) return null;

  return (
    <div
      className={clsx(
        wrapperClassName,
        {
          "bg-main-info": theme === AlertBarTheme.Default,
          "bg-main-green text-white": theme === AlertBarTheme.Green,
          "bg-main-red text-white": theme === AlertBarTheme.Red,
          "bg-[#ffdede] border border-main-red text-black": theme === AlertBarTheme.LightRed,
          "bg-main-yellow text-black": theme === AlertBarTheme.Yellow,
          "bg-black text-white": theme === AlertBarTheme.Black,
        },
        "w-full rounded-lg px-8 py-3"
      )}
    >
      {!hideCloseButton && (
        <CloseIcon
          className="absolute top-2 right-2 cursor-pointer hover:text-guideline-darkBlue"
          onClick={handleClose}
        />
      )}
      <div className={"flex flex-col gap-3"}>
        <Typography variant="body2">
          <b>{title}</b>
          <ul className={"list-outside list-disc ml-[17px]"}>
            {description?.map((item) => (
              <li key={item?.toString()}>{item}</li>
            ))}
          </ul>
        </Typography>
      </div>
    </div>
  );
};

export default AlertBar;
