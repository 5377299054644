import clsx from "clsx";

interface Props {
  color: string;
  value: React.ReactNode;
  label: React.ReactNode;
  isDashed?: boolean;
  className?: string;
}

const ChartTooltipElement = ({ label, value, color, isDashed, className }: Props) => (
  <div className={clsx(className, "flex gap-2 items-center text-[10px]")}>
    <div className="relative h-[4px] w-[24px]">
      <div className="h-full w-full rounded-lg" style={{ background: color }} />
      {isDashed && (
        <div className="absolute w-full top-0 right-0 flex justify-evenly">
          {[...new Array<number>(3)].map((_, index) => (
            <div key={index} className="h-[4px] w-[10%] bg-white" />
          ))}
        </div>
      )}
    </div>
    <div className="flex">
      {label}: {value}
    </div>
  </div>
);

export default ChartTooltipElement;
