import { components } from "../../../../api/schema";
import { SCALEOPS_COLORS } from "../../../../colors";

const workloadHasTagFncByTerm = (term: string) => {
  const fnc = (workload: components["schemas"]["UtilsWorkload"]) =>
    !!(workload.smartPolicyWorkloadType && workload.smartPolicyWorkloadType === term);
  return fnc;
};

const SEARCH_TERMS_ARRAY = [
  ["ElasticSearch", "Elastic Search"],
  ["Mysql"],
  ["Kafka"],
  ["RabbitMQ"],
  ["Redis"],
  ["Postgres"],
  ["Mongo"],
  ["Flink"],
  ["Spark"],
  ["Memcached"],
  ["MariaDB"],
  ["ArgoController", "Argo Controller"],
  ["DockerRegistry", "Docker Registry"],
];

export type TagArray = {
  title: string;
  background: string;
  color: string;
  workloadHasTagFnc: (workload: components["schemas"]["UtilsWorkload"]) => boolean;
};

export const tagArray: TagArray[] = [
  {
    title: "Java",
    background: SCALEOPS_COLORS.events.throttling,
    color: SCALEOPS_COLORS.text.lightBlack,
    workloadHasTagFnc: (workload) => !!workload.isJavaWorkload,
  },
  {
    title: "Unevictable",
    background: SCALEOPS_COLORS.workload.unevictable,
    color: SCALEOPS_COLORS.text.lightBlack,
    workloadHasTagFnc: (workload) => workload.smartPolicyWorkloadType === "Not Evictable",
  },
  ...SEARCH_TERMS_ARRAY.map((term, index) => ({
    title: term[1] ?? term[0],
    background: SCALEOPS_COLORS.workloadTags[index],
    color: SCALEOPS_COLORS.text.lightBlack,
    workloadHasTagFnc: workloadHasTagFncByTerm(term[0]),
  })),
];
