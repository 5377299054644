import { Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import clsx from "clsx";
import prettyBytes from "pretty-bytes";
import { useEffect, useState } from "react";
import { components } from "../../../api/schema";
import { currencyFormatter } from "../../../utils/formatterUtils";
import { getDataGridSx } from "../../../utils/styleUtils";
import Tooltip from "../../Tooltip";
import { ConsolidationState } from "./utils";

const PAGE_SIZE = 4;

type ROW = components["schemas"]["Node_detailsNodeData"] & { status?: string };

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    headerAlign: "center",
    flex: 10,
    sortable: true,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, ROW, string>) => {
      return (
        <Tooltip className="max-w-full" maxWidth={800} title={<Typography variant="body2">{params.value}</Typography>}>
          <Typography variant="body2" className="truncate grow">
            {params.value}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: "monthlyCost",
    headerName: "Savings",
    headerAlign: "center",
    flex: 3,
    sortable: true,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, ROW, string>) => {
      const roundedValue = currencyFormatter(Number(params.row.monthlyCost) || 0.0);
      return (
        <div className="w-full flex justify-center text-guideline-darkGreen">
          {roundedValue}{" "}
          <Typography variant="caption" className="text-text-darkGray">
            /mo
          </Typography>
        </div>
      );
    },
  },
  {
    field: "cpu",
    headerName: "CPU",
    headerAlign: "center",
    flex: 3,
    sortable: true,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, ROW, string>) => {
      const displayValue = !Number.isNaN(params.value) ? Math.round(Number(params.value) * 100) / 100 : 0;
      return <div className="w-full flex justify-center text-guideline-darkGreen">{displayValue}</div>;
    },
  },
  {
    field: "memory",
    headerName: "Memory",
    headerAlign: "center",
    flex: 3,
    sortable: true,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, ROW, string>) => {
      const value = !Number.isNaN(params.value) ? Number(params.value) * 1024 * 1024 * 1024 : 0;
      const displayValue = prettyBytes(value, { bits: false, binary: true });
      return <div className="w-full flex justify-center text-guideline-darkGreen">{displayValue}</div>;
    },
  },
  {
    field: "status",
    headerName: "Stauts",
    headerAlign: "center",
    flex: 3,
    sortable: true,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, ROW, string>) => {
      let displayValue = <></>;
      switch (params.row.status) {
        case ConsolidationState.Pending:
          displayValue = <span>Ready</span>;
          break;
        case ConsolidationState.Running:
          displayValue = <span className="text-guideline-lessDarkPurple">In progress</span>;
          break;
        case ConsolidationState.Completed:
          displayValue = <span className="text-guideline-darkGreen">Completed</span>;
          break;
        case ConsolidationState.Failed:
          displayValue = <span className="text-main-red">Failed</span>;
          break;
        default:
          break;
      }
      return <div className="w-full flex justify-center">{displayValue}</div>;
    },
  },
];

interface Props {
  nodeData:
    | {
        [key: string]: ROW | undefined;
      }
    | undefined;
  state?: ConsolidationState | undefined;
}

const ConsolidateTable = ({ nodeData, state }: Props) => {
  const [rows, setRows] = useState<ROW[]>([]);

  useEffect(() => {
    const rowsData = Object.entries(nodeData ?? {}).map(([key, value]) => ({ ...value, name: key, id: key }));

    setRows(rowsData);
  }, [nodeData]);

  return (
    <div
      className={clsx("w-full", {
        "opacity-30": state === ConsolidationState.Pending,
      })}
    >
      <DataGrid
        columns={columns}
        rows={rows}
        pagination={true}
        headerHeight={40}
        rowHeight={50}
        autoHeight={true}
        sx={{
          ...getDataGridSx(),
          "& .MuiDataGrid-row:hover": {
            background: "none !important",
          },
          "& .MuiDataGrid-footer": {
            fontSize: "0.5rem",
          },
          "& .MuiTablePagination-displayedRows": {
            fontSize: "0.75rem",
          },
        }}
        initialState={{
          pagination: {
            pageSize: PAGE_SIZE,
          },
        }}
        disableSelectionOnClick
        hideFooter={rows.length <= PAGE_SIZE}
      />
    </div>
  );
};

export default ConsolidateTable;
