const AverageUsageColor = "#3B8BFF";
const MaxUsageColor = "#18135B";
const PercentileUsageColor = "#6C70FF";
const MinAllowedColor = "#074E31";
const MaxAllowedColor = "#053C26";
const Styles = {
  currentRequest: {
    stroke: "#EAB832",
    strokeDasharray: "",
  },
  originRequest: {
    stroke: "#ff517a",
    strokeDasharray: "",
  },
  currentLimit: {
    stroke: "#FFCC43",
    strokeDasharray: "8 8",
  },
  recommendedLimit: {
    stroke: "#10AE6E",
    strokeDasharray: "8 8",
  },
  recommendedRequest: {
    stroke: "#52D39D",
    strokeDasharray: "",
  },
  podsAvgUsage: {
    stroke: AverageUsageColor,
    strokeDasharray: "",
  },
  podsMaxUsage: {
    stroke: MaxUsageColor,
    strokeDasharray: "",
  },
  podsP90Usage: {
    stroke: PercentileUsageColor,
    strokeDasharray: "",
  },
  minAllowed: {
    stroke: MinAllowedColor,
    strokeDasharray: "",
  },
  maxAllowed: {
    stroke: MaxAllowedColor,
    strokeDasharray: "",
  },
};

const TriggerBasedColor1 = "#3B8BFF";
const TriggerBasedColor2 = "#18135B";
const TriggerBasedColor3 = "#6C70FF";
const TriggerBasedColor4 = "#074E31";
const PredictionBasedColor1 = "#8605f0";
const PredictionBasedColor2 = "#b582cf";
const PredictionBasedColor3 = "#cb09ed";
const PredictionBasedColor4 = "#480254";
const CurrentReplicasColor = "#EAB832";
const RecommendedReplicasColor = "#52D39D";
const OriginReplicasColor = "#ff517a";
const OriginMinReplicasColor = "#8f1e38";
const CurrentMinReplicasColor = "#c78c0c";
const RecommendedMinReplicasColor = "#208A3B";

export {
  AverageUsageColor,
  MaxUsageColor,
  PercentileUsageColor,
  MinAllowedColor,
  MaxAllowedColor,
  Styles,
  TriggerBasedColor1,
  TriggerBasedColor2,
  TriggerBasedColor3,
  TriggerBasedColor4,
  PredictionBasedColor1,
  PredictionBasedColor2,
  PredictionBasedColor3,
  PredictionBasedColor4,
  CurrentReplicasColor,
  RecommendedReplicasColor,
  OriginReplicasColor,
  OriginMinReplicasColor,
  CurrentMinReplicasColor,
  RecommendedMinReplicasColor,
};
export default Styles;
