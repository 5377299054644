import React, { Ref, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { REF } from "../WorkloadsAggregation/utils";

type ID = { id: string | number };

const createCSV = <T extends ID>(
  columns: (keyof T)[],
  data: T[],
  columnsToRound?: (keyof T)[],
  customColumnNames?: { [key in keyof T]?: string }
) => {
  const csv = [];
  csv.push(columns.map((column) => customColumnNames?.[column] ?? column));
  data.forEach((row) => {
    const rowData: string[] = [];
    columns.forEach((column) => {
      if (columnsToRound && columnsToRound.includes(column)) {
        let roundNumber = Math.round(Number(row[column]) * 100) / 100;
        roundNumber = isNaN(roundNumber) ? 0 : roundNumber;
        rowData.push(String(roundNumber));
      } else {
        rowData.push(String(row[column] ?? ""));
      }
    });
    csv.push(rowData);
  });
  return csv;
};

const getSummaryTable = <T extends ID>(
  columns: (keyof T)[],
  columnsToSum: (keyof T)[],
  data: T[],
  columnsToAverage: (keyof T)[]
) => {
  const csv = [];
  const columnsToSumMap = new Map([...columnsToSum, ...columnsToAverage].map((column) => [column, column]));
  const spacedColumnsToSum = columns.map((column) => (columnsToSumMap.has(column) ? column : ""));
  const rowData: string[] = [];
  spacedColumnsToSum.forEach((column, index) => {
    if (index === 0) {
      rowData.push("total");
      return;
    }
    if (column === "") {
      rowData.push("");
      return;
    }
    let sum = 0;
    data.forEach((row) => {
      let numberToAdd = Number(row[column] || 0);
      numberToAdd = isNaN(numberToAdd) ? 0 : numberToAdd;
      sum += numberToAdd;
      sum = Math.round(sum * 100) / 100;
    });
    sum = columnsToAverage.includes(column) ? Math.round(sum / data.length) : sum;
    rowData.push(String(sum));
  });

  csv.push(rowData);
  return csv;
};

interface Props<T> {
  columns: (keyof T)[];
  data: T[];
  columnsToRound?: (keyof T)[];
  columnsToSum?: (keyof T)[];
  columnsToAverage?: (keyof T)[];
  customColumnNames?: { [key in keyof T]?: string };
  filename?: string;
  children?: React.ReactNode;
  buttonRef?: Ref<REF>;
}
const CSVWrapper = <T extends ID>({
  columns,
  data,
  columnsToRound,
  columnsToSum,
  columnsToAverage,
  customColumnNames,
  filename = "export.csv",
  children,
  buttonRef,
}: Props<T>) => {
  const [csvToExport, setCsvToExport] = useState<(string | number | symbol)[][]>([]);

  useEffect(() => {
    const csvData = createCSV(columns, data, columnsToRound, customColumnNames);
    const summaryTable =
      columnsToSum || columnsToSum ? getSummaryTable(columns, columnsToSum ?? [], data, columnsToAverage ?? []) : [];

    setCsvToExport([...csvData, ...summaryTable]);
  }, [data, columns, columnsToRound, columnsToSum]);

  return (
    <CSVLink data={csvToExport} filename={filename} ref={buttonRef}>
      {children}
    </CSVLink>
  );
};

export default CSVWrapper;
