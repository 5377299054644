import { memo, useEffect, useState } from "react";
import dayjs from "dayjs";
import { ErrorBoundary } from "react-error-boundary";
import { useWorkloadsContext } from "../../../WorkloadsContext";
import {
  GetPolicyTuningConfigParamsResponse,
  getPolicyTuningHpaCurrentData,
  GetPolicyTuningHpaCurrentDataResponse,
} from "../../../api/fetcher";
import { components } from "../../../api/schema";
import CustomLegend from "../../../components/CustomLegend";
import DefaultFallback from "../../../components/DefaultFallback";
import { Policy } from "./PolicyTuning";
import SelectPolicy from "./SelectPolicy";
import SelectViewPeriod from "./SelectViewPeriod";
import { displayNameFormatter, useViewPeriodQueryParams, ViewPeriodOptions, getReplicasComponentsStyle } from "./utils";
import ReplicasChart from "./ReplicasChart";
import CostBarChart from "./CostBarChart";
import { useQuery } from "@tanstack/react-query";
import PredictableBadge from "./PredictableBadge";
import { HpaChartComponent } from "./UsageChart/UsageHpaChart";

const TopSectionDivider = () => <div className="bg-border w-[1px] h-16" />;

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  setWorkloadName: (title: string) => void;
  fetchWorkloads: () => void;
  tuningParams: GetPolicyTuningConfigParamsResponse;
  setTuningParams: React.Dispatch<React.SetStateAction<GetPolicyTuningConfigParamsResponse>>;
  selectedPolicy: Policy | undefined;
  setSelectedPolicy: React.Dispatch<React.SetStateAction<Policy | undefined>>;
  selectedChartComponents: HpaChartComponent[];
  setSelectedChartComponents: React.Dispatch<React.SetStateAction<HpaChartComponent[]>>;
}

const PolicyTuningHpa = memo(
  ({
    selectedWorkload,
    setWorkloadName,
    tuningParams,
    selectedPolicy,
    setSelectedPolicy,
    selectedChartComponents,
    setSelectedChartComponents,
  }: Props) => {
    const { overriddenWorkloadsIds } = useWorkloadsContext();
    const namespace = selectedWorkload.namespace;
    const name = `${selectedWorkload.type.toLocaleLowerCase()}-${selectedWorkload.workloadName}`;
    const [hpaChartComponents, setHpaChartComponents] = useState<Record<string, HpaChartComponent>>(
      {} as Record<string, HpaChartComponent>
    );
    const [, setSelectedViewPeriod] = useViewPeriodQueryParams();
    const policyTuningHpaCurrentData = getPolicyTuningHpaCurrentData();
    const { data: currentData } = useQuery<GetPolicyTuningHpaCurrentDataResponse, Error>(
      [policyTuningHpaCurrentData.queryKey, name, namespace],
      () => {
        return policyTuningHpaCurrentData.queryFn({
          name,
          namespace,
        });
      },
      {
        retry: 10,
        refetchOnWindowFocus: false,
      }
    );

    useEffect(() => {
      const isCreatedAtLessThan4Hours =
        selectedWorkload.createdAt?.length > 0 &&
        Number(selectedWorkload.createdAt) !== 0 &&
        dayjs().diff(dayjs(selectedWorkload.createdAt), "hour") < 4;

      setSelectedViewPeriod(isCreatedAtLessThan4Hours ? ViewPeriodOptions["4 hours"] : ViewPeriodOptions["7 days"]);
    }, []);

    useEffect(() => {
      if (selectedWorkload) {
        setWorkloadName(`${selectedWorkload.type}: ${selectedWorkload.namespace}/${selectedWorkload.workloadName}`);
      }
    }, [selectedWorkload]);

    return (
      <div className="flex flex-col gap-4 w-full">
        <div className="flex gap-6 p-4 items-center border rounded-lg border-border">
          <div className="w-fit flex gap-8">
            {selectedPolicy && (
              <ErrorBoundary
                fallback={<DefaultFallback message="Failed to load Selected Policy. Please check your setup." />}
              >
                <SelectPolicy
                  selectedPolicy={selectedPolicy}
                  setSelectedPolicy={setSelectedPolicy}
                  isOverridePolicy={overriddenWorkloadsIds.includes(selectedWorkload.id)}
                  smartPolicyName={selectedWorkload.smartPolicyName}
                />
              </ErrorBoundary>
            )}
            <ErrorBoundary
              fallback={<DefaultFallback message="Failed to load Selected View Period. Please check your setup." />}
            >
              <SelectViewPeriod />
            </ErrorBoundary>
          </div>
          <TopSectionDivider />
          <div className="grow">
            <div className="max-w-[500px]">
              <CostBarChart
                currentCost={currentData?.currentCost ?? 0}
                optimizedCost={currentData?.optimizedCost ?? 0}
              />
            </div>
          </div>
          <TopSectionDivider />
          <div className="flex flex-col w-fit justify-end gap-1.5">
            <ErrorBoundary
              fallback={<DefaultFallback message="Failed to load Cost Bar Chart. Please check your setup." />}
            >
              <div className="relative cursor-default">
                <PredictableBadge predictionByHistory={currentData?.predictionByHistory} />
              </div>
            </ErrorBoundary>
          </div>
        </div>
        <div>
          {tuningParams && selectedPolicy && (
            <ErrorBoundary fallback={<DefaultFallback message="Failed to load Chart. Please check your setup." />}>
              <div className="border rounded-lg border-border">
                <ReplicasChart
                  selectedWorkload={selectedWorkload}
                  policyName={selectedPolicy.name}
                  selectedChartComponents={selectedChartComponents}
                  setSelectedChartComponents={setSelectedChartComponents}
                  setHpaChartComponents={setHpaChartComponents}
                />
                <CustomLegend<HpaChartComponent>
                  selectedChartComponents={selectedChartComponents}
                  setSelectedChartComponents={setSelectedChartComponents}
                  componentStyle={getReplicasComponentsStyle(hpaChartComponents)}
                  ChartComponents={hpaChartComponents}
                  className="mt-1 pb-3"
                  displayNameFormatter={displayNameFormatter}
                />
              </div>
            </ErrorBoundary>
          )}
        </div>
      </div>
    );
  }
);

export default PolicyTuningHpa;
