import { Typography } from "@mui/material";
import Tooltip from "../../../components/Tooltip";
import InfoIcon from "../../../Icons/InfoIcon";

const ICON_SIZE = 11;

interface Props {
  title: string;
  items: string[];
}

const WorkloadPropertiesChip = ({ title, items }: Props) => {
  const tooltipContent = !items.length ? (
    <Typography variant="caption">
      This workload has no <b>{title.toLocaleLowerCase()}</b>.
    </Typography>
  ) : (
    <Typography variant="caption">
      <b className="underline">Workload {title.toLocaleLowerCase()}:</b>
      <ul className="list-disc pl-4 text-[12px]">
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </Typography>
  );

  return (
    <Tooltip
      title={tooltipContent}
      className="bg-background-chip w-fit flex items-center justify-center px-3 rounded-lg text-black cursor-default"
      maxWidth={500}
      placement="bottom"
    >
      <Typography variant="caption">{title}</Typography>
      <InfoIcon width={ICON_SIZE} height={ICON_SIZE} />
    </Tooltip>
  );
};
export default WorkloadPropertiesChip;
