import { Typography } from "@mui/material";
import clsx from "clsx";

type VARIANTS =
  | "regular"
  | "mediumPurple"
  | "mediumDarkPurple"
  | "mediumGray"
  | "mediumGreen"
  | "small"
  | "smallGreen"
  | "extraSmall";

export enum BUTTON_VARIANTS {
  regular = "regular",
  mediumPurple = "mediumPurple",
  mediumDarkPurple = "mediumDarkPurple",
  mediumGray = "mediumGray",
  mediumGreen = "mediumGreen",
  small = "small",
  smallGreen = "smallGreen",
  extraSmall = "extraSmall",
}
interface Props {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void | (() => void) | (() => boolean | undefined | null);
  className?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  label: React.ReactNode;
  fontSize?: number;
  variant?: VARIANTS;
  dataTestId?: string;
}

const CLASS_NAMES = {
  regular: {
    main: "bg-transparent font-semibold py-2 px-4 border rounded",
    notDisabled: "hover:bg-guideline-lessDarkPurple text-black hover:text-white hover:border-transparent border-black",
    disabled: "text-text-disable",
  },
  mediumGreen: {
    main: "bg-guideline-darkGreen text-white font-semibold py-2 px-4 rounded-[10px] h-[42px]",
    notDisabled: "hover:bg-guideline-darkerGreen text-black hover:text-white border-black",
    disabled: "text-text-disable opacity-50 cursor-not-allowed",
  },
  mediumPurple: {
    main: "bg-guideline-lessDarkPurple text-white font-semibold py-2 px-4 rounded",
    notDisabled: "hover:bg-guideline-darkPurple text-black hover:text-white border-black",
    disabled: "text-text-disable opacity-50 cursor-not-allowed",
  },
  mediumDarkPurple: {
    main: "bg-guideline-darkPurple text-white font-semibold py-2 px-4 rounded-[10px] h-[42px] scaleopsShadow",
    notDisabled: "hover:bg-guideline-lessDarkBlue text-black hover:text-white border-black",
    disabled: "text-text-disable opacity-50 cursor-not-allowed",
  },
  mediumGray: {
    main: "bg-background-gray text-white font-semibold py-2 px-4 rounded-[10px] h-[42px]",
    notDisabled: "hover:bg-background-scrollbar text-black hover:text-white border-black",
    disabled: "text-text-disable opacity-50 cursor-not-allowed",
  },
  extraSmall: {
    main: "w-fit px-2 <py-1></py-1> border border-strongBorder rounded-lg",
    notDisabled: "hover:bg-background-chipHover cursor-pointer",
    disabled: "text-text-disable",
  },
  small: {
    main: "w-fit px-4 py-2 border border-strongBorder rounded-lg",
    notDisabled: "hover:bg-background-chipHover cursor-pointer",
    disabled: "text-text-disable",
  },
  smallGreen: {
    main: "w-fit px-4 py-2 border rounded-lg",
    notDisabled:
      "bg-guideline-darkGreen text-white border-guideline-darkGreen cursor-pointer hover:bg-guideline-darkerGreen",
    disabled: "border-strongBorder text-text-disable",
  },
};

const Button = ({ onClick, className, disabled, label, fontSize, type, variant = "regular", dataTestId }: Props) => {
  switch (true) {
    case !fontSize &&
      (variant === "mediumGreen" ||
        variant === "mediumPurple" ||
        variant === "mediumDarkPurple" ||
        variant === "mediumGray"):
      fontSize = 16;
      break;
    case (!fontSize && variant === "small") || variant === "smallGreen":
      fontSize = 11;
      break;
    case !fontSize && variant === "extraSmall":
      fontSize = 12;
      break;
    default:
      break;
  }

  return (
    <button
      disabled={disabled}
      data-testid={dataTestId}
      onClick={onClick}
      type={type}
      className={clsx(className, CLASS_NAMES[variant].main, {
        [CLASS_NAMES[variant].notDisabled]: !disabled,
        [CLASS_NAMES[variant].disabled]: disabled,
      })}
    >
      <Typography fontSize={fontSize}>{label}</Typography>
    </button>
  );
};

export default Button;
