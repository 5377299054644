import { components } from "../api/schema";
import { getCustomerPrefix, getToken } from "../utils/configUtils";
import { GetConfig } from "../utils/ConfigHelper";

export const docsBaseUrl = "https://scaledocs:HandsFree22@docs.scaleops.com/docs/";
export const ssoProviderLink = (
  ssoConf: components["schemas"]["TypesAuthInfo"] | undefined,
  section: string
): string => {
  const conf = GetConfig();
  if (ssoConf !== undefined) {
    return (
      docsBaseUrl +
      "integrations/sso/" +
      providerNameToLinkName(ssoConf.provider) +
      `/${conf.token ? "?token=" + conf.token : ""}#` +
      section
    );
  }
  return "";
};

export const providerNameToLinkName = (
  ssoProvider: "none" | "oauth2" | "saml" | "basic" | "google" | "github" | "unknown" | undefined | unknown
): string => {
  switch (ssoProvider) {
    case "oauth2":
      return "oidc";
    case "github":
      return "github";
    case "google":
      return "google";
    default:
      return "";
  }
};

export const toTradeMarkedName = (
  provider:
    | "none"
    | "oauth2"
    | "saml"
    | "basic"
    | "google"
    | "github"
    | "openshift"
    | "simpleAuth"
    | "unknown"
    | undefined
): string => {
  switch (provider) {
    case "oauth2":
      return "OIDC";
    case "github":
      return "GitHub";
    case "google":
      return "Google";
    case "openshift":
      return "Openshift";
    case "simpleAuth":
      return "Built In";
    case undefined:
      return "";
    default:
      return provider;
  }
};

interface HelmSetupProps {
  isChild: boolean;
  conf: components["schemas"]["ConfGetConfResponse"];
  showToken: boolean;
}

export const helmSetup = ({ isChild, conf, showToken }: HelmSetupProps) => {
  const ssoConf: components["schemas"]["TypesAuthInfo"] = conf?.ssoConf || { enabled: false, provider: "none" };

  const displayScaleOpsToken = showToken ? getToken(conf) : getToken(conf).replace(/./g, "*");
  const displayAuthApiKey = showToken ? conf.ssoConf?.apiKey : conf.ssoConf?.apiKey?.replace(/./g, "*");
  const displayTokenUrl = showToken ? conf.ssoConf?.tokenUrl : conf.ssoConf?.tokenUrl?.replace(/./g, "*");

  let base = `helm upgrade --install --create-namespace -n scaleops-system \\
  --set scaleopsToken=${displayScaleOpsToken ?? "<SCALEOPS_TOKEN>"} \\
  --set clusterName=${getCustomerPrefix(conf)}-$(kubectl config current-context) \\
`;
  if (isChild) {
    // Used for Connect Cluster
    base += `  --set multicluster.parent.url=${encodeURI(window.location.protocol + "//" + window.location.host)} \\
`;
    if (ssoConf.enabled) {
      switch (ssoConf.provider) {
        case "simpleAuth":
          base += `  --set multicluster.parent.auth.type=BuiltInAuth \\
  --set multicluster.parent.auth.apiKey=${displayAuthApiKey ?? "<API_KEY>"} \\
`;
          break;
        case "oauth2":
          base += `  --set multicluster.parent.auth.type=Oauth2ClientCredentials \\
  --set multicluster.parent.auth.tokenUrl=${displayTokenUrl ?? "<TOKEN_URL>"} \\
  --set multicluster.parent.auth.clientId=<CLIENT_ID> \\
  --set multicluster.parent.auth.clientSecret=<CLIENT_ID> \\
  --set multicluster.parent.auth.scopes="{api}" \\
`;
          break;
        case "github":
          base += `  --set multicluster.parent.auth.type=Github \\
  --set multicluster.parent.auth.githubToken=<Your Personal Access Token> \\
`;
          break;
        case "google":
          base += `  --set multicluster.parent.auth.type=GoogleServiceAccount \\
  --set-file googleServiceAccountCredentials=</full/path/to/service/credentials.json> \\
`;
          break;
        case "openshift":
          base += `  --set multicluster.parent.auth.type=OpenShift \\
  --set multicluster.parent.auth.openshiftToken=<token> \\
`;
          break;
        default: // none, default, saml, basic and unknown goes here since they don't exist
          base += "";
      }
    }
  } else {
    // Used for Setup Roles And Permissions
    base += `  --set authProvider.rolesEnabled=true \\
`;
    if (ssoConf.enabled) {
      base += ssoConfigForRbac(ssoConf);
    } else {
      base += ssoConfigForRbac({ enabled: false, provider: "oauth2" });
    }
  }
  return base + "scaleops scaleops/scaleops";
};

const ssoConfigForRbac = (ssoConf: components["schemas"]["TypesAuthInfo"]): string => {
  switch (ssoConf.provider) {
    case "oauth2": // TODO(Vlad): identify azure and expand, identify Okta and expand
      return `  --set authProvider.defaultAdminGroup=${ssoConf.suggestedDefaultAdminGroup || "scaleops-admins"} \\
  --set authProvider.defaultViewerGroup=${ssoConf.suggestedDefaultViewerGroup || "scaleops-viewers"} \\
  --set authProvider.defaultOperatorGroup=${ssoConf.suggestedDefaultOperatorGroup || "scaleops-operators"} \\
  --set authProvider.oauth2.groupsClaim=groups \\
  --set authProvider.oauth2.issuerUrl=${ssoConf.issuerUrl || "<ISSUER_URL>"} \\
  --set authProvider.oauth2.clientID=<CLIENT_ID> \\
  --set authProvider.oauth2.clientSecret=<CLIENT_SECRET> \\
`;
    case "github":
      return `  --set authProvider.defaultAdminGroup=${
        ssoConf.suggestedDefaultAdminGroup || "github-org:scaleops-admins"
      } \\
  --set authProvider.defaultViewerGroup=${ssoConf.suggestedDefaultViewerGroup || "github-org:scaleops-viewers"} \\
  --set authProvider.defaultOperatorGroup=${ssoConf.suggestedDefaultOperatorGroup || "github-org:scaleops-operators"} \\
  --set authProvider.github.clientID=<CLIENT_ID> \\
  --set authProvider.github.clientSecret=<CLIENT_SECRET> \\
`;
    case "google":
      return `  --set authProvider.defaultAdminGroup=${
        ssoConf.suggestedDefaultAdminGroup || "scaleops-admins@company.com"
      } \\
  --set authProvider.defaultViewerGroup=${ssoConf.suggestedDefaultViewerGroup || "scaleops-viewers@company.com"} \\
  --set authProvider.defaultOperatorGroup=${
    ssoConf.suggestedDefaultOperatorGroup || "scaleops-operators@company.com"
  } \\
  --set authProvider.google.clientID=<CLIENT_ID> \\
  --set authProvider.google.clientSecret=<CLIENT_SECRET> \\
  --set-file googleServiceAccountCredentials=</full/path/to/service/credentials.json> \\
`;
    default: // none, default, saml, basic and unknown goes here since they don't exist
      return ssoConfigForRbac({ enabled: false, provider: "oauth2" });
  }
};
