import { CircularProgress, Typography } from "@mui/material";
import useGetUserRole from "../../../MainMenu/useGetUserRole";
import { SCALEOPS_COLORS } from "../../../colors";
import Button, { BUTTON_VARIANTS } from "../../Button";
import useStartNodeRebalance from "./useStartNodeRebalance";
import useStopNodeConsolidation from "./useStopNodeConsolidation";
import { ConsolidationState } from "./utils";

const BUTTON_WIDTH = "w-[185px]";
const STATUS_BUTTON_CLASS_NAME =
  "flex justify-center items-center gap-4 h-[42px] border border-strongBorder rounded-lg";

const CancelingInProgress = () => (
  <div className={`${BUTTON_WIDTH} ${STATUS_BUTTON_CLASS_NAME}`}>
    <CircularProgress size={30} style={{ color: SCALEOPS_COLORS.text.darkGray }} />
    <Typography variant="body2">
      Canceling
      <span className="animate-ping">...</span>
    </Typography>
  </div>
);

const ConsolidateInProgress = ({ isProgressPercentage }: { isProgressPercentage: number | undefined }) => (
  <div className={`${BUTTON_WIDTH} ${STATUS_BUTTON_CLASS_NAME}`}>
    <CircularProgress size={30} style={{ color: SCALEOPS_COLORS.text.darkGray }} />
    {isProgressPercentage !== undefined && (
      <Typography variant="body2">
        {isProgressPercentage}% in progress
        <span className="animate-ping">...</span>
      </Typography>
    )}
  </div>
);

interface ButtonsContainerProps {
  state: ConsolidationState | undefined;
  allNodes?: number;
  successfulNodes?: number;
  numberOfCandidates?: number;
}

const ButtonsContainer = ({ state, allNodes, successfulNodes, numberOfCandidates }: ButtonsContainerProps) => {
  const { userRole } = useGetUserRole();
  const startNodeRebalance = useStartNodeRebalance();
  const stopNodeConsolidation = useStopNodeConsolidation();

  const isRunning = state === ConsolidationState.Running || state === ConsolidationState.Verify;
  const isCanceling = state === ConsolidationState.Canceled;
  const isPending = state === ConsolidationState.Pending;
  const isProgressPercentage = allNodes && successfulNodes ? Math.round((allNodes / successfulNodes) * 100) : 0;

  return (
    <div className="flex justify-end gap-4">
      {isCanceling ? (
        <CancelingInProgress />
      ) : (
        <Button
          label="CANCEL"
          className={BUTTON_WIDTH}
          variant={BUTTON_VARIANTS.mediumGray}
          disabled={!isRunning || userRole !== "Admin"}
          onClick={() => stopNodeConsolidation.mutate()}
        />
      )}
      {isRunning ? (
        <ConsolidateInProgress isProgressPercentage={isProgressPercentage} />
      ) : (
        <Button
          label="CONSOLIDATE"
          className={BUTTON_WIDTH}
          variant={BUTTON_VARIANTS.mediumGreen}
          onClick={() => startNodeRebalance.mutate()}
          disabled={!numberOfCandidates || isCanceling || isPending || userRole !== "Admin"}
        />
      )}
    </div>
  );
};

export default ButtonsContainer;
