export type DailyData = {
  timestamp: string;
  [key: string]: number | string;
};

export type EntireWindowData = {
  name: string;
  cost: number;
};

export enum ChartType {
  Daily = "Daily",
  EntireWindow = "Entire window",
}
