import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress, Typography } from "@mui/material";
import {
  SkarpenterInstancesResponse,
  GetSkarpenterInstances,
  GetCurrentStateResponse,
  GetCurrentState,
} from "../../api/fetcher";
import ScaleopsIcon from "../../Icons/ScaleopsIcon";
import { useMainContext } from "../../MainContext";
import Switch from "../Switch";
import { NodeCostRow, RecommendedCostDataSum, sumData } from "./nodesCostUtils";
import RecommendedNodesCostTables, { Theme } from "./RecommendedNodesCostTables";

const NodesCostTablesContainer = () => {
  const currentState = GetCurrentState();
  const skarpenterInstances = GetSkarpenterInstances();
  const [recommendedCostData, setRecommendedCostData] = useState<undefined | NodeCostRow[]>();
  const [currentCostData, setCurrentCostData] = useState<undefined | NodeCostRow[]>();

  const [recommendedCostDataSum, setRecommendedCostDataSum] = useState<undefined | RecommendedCostDataSum>();
  const [currentCostDataSum, setCurrentCostDataSum] = useState<undefined | RecommendedCostDataSum>();

  const { setNodeCost } = useMainContext();

  const { data: skarpenterInstancesData, isLoading: skarpenterInstancesIsLoading } = useQuery<
    SkarpenterInstancesResponse,
    Error
  >({
    queryKey: [skarpenterInstances.queryKey],
    queryFn: skarpenterInstances.queryFn,
  });

  const { data: getCurrentStateData, isLoading: getCurrentStateIsLoading } = useQuery<GetCurrentStateResponse, Error>({
    queryKey: [currentState.queryKey],
    queryFn: currentState.queryFn,
  });

  useEffect(() => {
    const recommendedCostDataContent: NodeCostRow[] =
      skarpenterInstancesData?.instances?.map((instance, index) => {
        return {
          id: index,
          count: instance.count,
          cpuCores: instance.cpuCores,
          monthlyCost: instance.monthlyCost,
          instanceType: instance.instanceType,
          maxPods: instance.maxPods,
          memoryGiB: instance.memoryGiB,
          spot: instance.spot,
        };
      }) ?? [];

    const currentCostDataContent: NodeCostRow[] =
      getCurrentStateData?.groups?.map((instance, index) => {
        return {
          id: index,
          count: instance.count,
          cpuCores: instance.CpuCores,
          monthlyCost: instance.monthlyCost,
          instanceType: instance.instanceName,
          maxPods: instance.maxPods,
          memoryGiB: instance.MemoryGiB,
          spot: instance.spot,
        };
      }) ?? [];

    setRecommendedCostData(recommendedCostDataContent);
    setRecommendedCostDataSum(sumData(recommendedCostDataContent));

    setCurrentCostData(currentCostDataContent);
    setCurrentCostDataSum(sumData(currentCostDataContent));

    setNodeCost({
      recommendedCostDataSum: sumData(recommendedCostDataContent),
      currentCostDataSum: sumData(currentCostDataContent),
    });
  }, [skarpenterInstancesData, getCurrentStateData]);

  const handleClick = () => console.log("xxx");

  const isAutomate = false;

  if (skarpenterInstancesIsLoading || getCurrentStateIsLoading) {
    return (
      <div className="bg-white w-full rounded-lg relative px-4 py-8 flex flex-col gap-4 items-center justify-center h-[250px]">
        <CircularProgress />
      </div>
    );
  }

  const baseHeight = Math.max(Number(currentCostData?.length), Number(recommendedCostData?.length));

  return (
    <div className="bg-white w-full rounded-lg relative px-4 py-8 flex flex-col gap-4 items-center justify-center min-w-[1200px]">
      <div className="w-full flex items-center gap-4">
        <Typography variant="body2" className="flex gap-[4px] items-center justify-start w-full">
          <ScaleopsIcon width={10} height={10} />
          <span
            className="text-guideline-darkPurple"
            style={{
              fontWeight: 600,
            }}
          >
            ScaleOps
          </span>
          will automate your nodes to optimize your costs and performance.
        </Typography>
        <div
          className="flex items-center border-2 border-main-green rounded-lg px-4 text-main-green h-[42px] hover:cursor-pointer w-fit hover:bg-main-green hover:text-white"
          onClick={handleClick}
        >
          <Typography variant="body2" fontWeight={500}>
            Automate
          </Typography>
          <Switch checked={isAutomate} onChange={handleClick} />
        </div>
      </div>
      <div className="flex items-center justify-center gap-4 w-full pb-[62px]">
        {getCurrentStateData && currentCostData && currentCostDataSum && (
          <div className="grow">
            <RecommendedNodesCostTables
              rows={currentCostData}
              recommendedCostDataSum={currentCostDataSum}
              theme={Theme.RED}
              baseHeight={baseHeight}
            />
          </div>
        )}
        {skarpenterInstancesData && recommendedCostData && recommendedCostDataSum && (
          <div className="grow">
            <RecommendedNodesCostTables
              rows={recommendedCostData}
              recommendedCostDataSum={recommendedCostDataSum}
              theme={Theme.GREEN}
              baseHeight={baseHeight}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NodesCostTablesContainer;
