import { useEffect, useState } from "react";
import clsx from "clsx";
import { TypographyVariant } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { GetSingleAnalytics, GetSingleAnalyticsParams, GetSingleAnalyticsResponse } from "../../../api/fetcher";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../../utils/useGetTimeoutSeconds";
import Loading from "../../../components/Loading";
import RunningNumberBox from "../../Overview/TopOverviewSection/RunningNumberBox";
import { getMemoryValue, getShortNumber } from "./utils";
import SingleMetricCompareLabel from "./SingleMetricCompareLabel";
import useFilterQueryParams from "./useFilterQueryParams";

const HAS_METRIC_COMPARE_LABEL = false;

export enum Formats {
  "Memory",
  "Currency",
  "Number",
}
interface Props {
  queryParams: GetSingleAnalyticsParams;
  memoryFormat?: boolean;
  title: React.ReactNode;
  format?: Formats;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  thousandSeparator?: boolean;
  headerVariant?: TypographyVariant;
  numberVariant?: TypographyVariant;
  titleFontWeight?: "regular" | "medium" | "bold";
  boxClassName?: string;
  leftChild?: React.ReactNode;
  valueDataTestId?: string;
  positiveNumberClassName?: string;
  negativeNumberClassName?: string;
  defaultNumberClassName?: string;
  hasGreenNegativeTheme?: boolean;
  labelWrapperClassName?: string;
  isMulticluster?: boolean;
}

const SingleMetricBox = ({
  queryParams,
  title,
  format,
  prefix,
  suffix,
  thousandSeparator,
  headerVariant,
  numberVariant,
  titleFontWeight,
  boxClassName,
  leftChild,
  positiveNumberClassName,
  negativeNumberClassName,
  defaultNumberClassName,
  hasGreenNegativeTheme,
  labelWrapperClassName,
  isMulticluster,
}: Props) => {
  const { queryFn, queryKey } = GetSingleAnalytics();
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);

  const filtersQueryParams = useFilterQueryParams();

  const { data, isLoading, error, isError } = useQuery<GetSingleAnalyticsResponse, Error>({
    queryKey: [queryKey, queryParams, filtersQueryParams, isMulticluster ? "multicluster" : undefined],
    queryFn: () =>
      queryFn({
        ...queryParams,
        multiCluster: isMulticluster,
        tags: filtersQueryParams.tags,
        clusters: filtersQueryParams.clusters,
        timeoutSeconds: timeoutSeconds,
      }),
    enabled: isQueryEnabled,
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMulticluster });

  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  useEffect(() => {
    if (isMulticluster && !!data) {
      setIsQueryEnabled(false);
    }
  }, [data]);

  useEffect(() => {
    if (isLoading || isError) {
      setIsQueryEnabled(true);
    }
  }, [queryParams, filtersQueryParams, isLoading, isError]);

  if (isLoading) {
    return <Loading hasTitle={false} />;
  }

  if (isError) {
    console.log(error);
    return null;
  }

  let value = 0;

  const { value: memoryValue, unit: memoryUnit } = getMemoryValue(data?.value ?? 0);
  const { value: shortNumberValue, unit: shortNumberUnit } = getShortNumber(data?.value ?? 0);

  switch (format) {
    case Formats.Memory:
      value = memoryValue;
      suffix = memoryUnit;
      break;
    case Formats.Currency:
      prefix = "$";
      value = shortNumberValue;
      suffix = shortNumberUnit;
      break;
    case Formats.Number:
      value = shortNumberValue;
      suffix = shortNumberUnit;
      break;
    default:
      value = data?.value || 0;
  }

  return (
    <div className="relative w-full">
      <RunningNumberBox
        value={isError || !data?.value || data?.value < 0 ? 0 : value}
        title={title}
        prefix={prefix}
        suffix={suffix}
        thousandSeparator={thousandSeparator}
        numberClassName={
          isError || value < 0 ? negativeNumberClassName : value > 0 ? positiveNumberClassName : defaultNumberClassName
        }
        headerVariant={headerVariant}
        numberVariant={numberVariant}
        titleFontWeight={titleFontWeight}
        className={boxClassName}
        leftChild={leftChild}
        valueDataTestId={`single-metric-box-${String(queryParams.type)}`}
        showRoundedValue={!suffix}
      />
      <div
        className={clsx(labelWrapperClassName, {
          "absolute top-[30px] minWidth1660:top-[20px] right-[20px]": !labelWrapperClassName,
        })}
      >
        {HAS_METRIC_COMPARE_LABEL && (
          <SingleMetricCompareLabel
            originalParams={queryParams}
            originalValue={data?.value ?? 0}
            hasGreenNegativeTheme={hasGreenNegativeTheme}
            title={title}
          />
        )}
      </div>
    </div>
  );
};

export default SingleMetricBox;
