import { Typography } from "@mui/material";
import clsx from "clsx";
import { components } from "../../../api/schema";
import InfoIcon from "../../../Icons/InfoIcon";
import useGetUserRole from "../../../MainMenu/useGetUserRole";
import CustomCheckBox from "../../CustomCheckBox";
import Tooltip from "../../Tooltip";
import useSetRebalanceSpec from "./useSetRebalanceSpec";
import { ConsolidationState } from "./utils";

const ALWAYS_IGNORE = "AlwaysIgnore";
const ICON_SIZE = 12;

interface Props {
  spec: components["schemas"]["Node_detailsRebalancePlanSpec"] | undefined;
  state?: ConsolidationState | undefined;
}

const SwitchUnEvictableNodes = ({ spec, state }: Props) => {
  const { userRole } = useGetUserRole();

  const setRebalanceSpec = useSetRebalanceSpec();
  const disabled = !spec || state !== ConsolidationState.Ready;

  return (
    <Tooltip
      title={
        <Typography variant="caption">
          If enabled, the system will ignore the <b>Pod Disruption Budgets (PDB</b>) and <b>Safe To Evict</b>{" "}
          annotations when determine which nodes to scale.
        </Typography>
      }
      maxWidth={500}
      className={clsx("flex gap-2 items-center justify-start w-fit", {
        "opacity-30": disabled,
      })}
    >
      <CustomCheckBox
        value={spec?.ignorePDB === ALWAYS_IGNORE && spec?.ignoreSafeToEvict === ALWAYS_IGNORE}
        onClick={(checked) => {
          setRebalanceSpec.mutate({
            ...spec,
            ignorePDB: checked ? ALWAYS_IGNORE : undefined,
            ignoreSafeToEvict: checked ? ALWAYS_IGNORE : undefined,
            skipNodesWithScaleOpsPods: !checked,
          });
        }}
        disabled={disabled || userRole !== "Admin"}
      />
      <Typography variant="body2" className="flex gap-[2px] items-center justify-start cursor-default">
        <span>Allow to scale down nodes with unevictable pods</span>
        <InfoIcon width={ICON_SIZE} height={ICON_SIZE} />
      </Typography>
    </Tooltip>
  );
};

export default SwitchUnEvictableNodes;
