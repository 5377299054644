import { Typography } from "@mui/material";
import clsx from "clsx";
import Tooltip from "../../../components/Tooltip";
import { METRIC_BOX_CLASS_NAME } from "./utils";
import CreativeInfoIcon from "../../../Icons/CreativeInfoIcon";
import { roundToTwoDecimal } from "../../../components/autoscalers/nodesCostUtils";
import { components } from "../../../api/schema";

interface Props {
  predictionByHistory?: {
    [historyDuration: string]: components["schemas"]["UtilsPredictionMetadata"] | undefined;
  };
}

type PredictionMetdata = {
  predictable: boolean;
  predictionError: number;
  idleStable: boolean;
};

const PredictableBadge = ({ predictionByHistory }: Props) => {
  const PredictionMetdata = Object.values(predictionByHistory ?? {}).reduce<PredictionMetdata>(
    (acc, prediction) => {
      if (prediction?.predictable) {
        acc.predictable = true;
        const minPredictionError =
          acc.predictionError == -1
            ? prediction?.predictionErrorScore ?? -1
            : Math.min(acc.predictionError, prediction?.predictionErrorScore ?? acc.predictionError);
        acc.predictionError = roundToTwoDecimal(minPredictionError * 100);
      }
      if (prediction?.idleStable) {
        acc.idleStable = true;
      }
      return acc;
    },
    { predictable: false, predictionError: -1, idleStable: false }
  );

  return (
    <>
      <Tooltip
        title={
          <Typography variant="caption">
            <b>Workload predictability</b>
            <p className="italic">{PredictionMetdata.predictable}</p>
            {PredictionMetdata.predictable
              ? Object.entries(predictionByHistory ?? {}).map(([historyDuration, value]) => {
                if (!value || !value.predictable || (!!value.predictionErrorScore && value.predictionErrorScore < 0)) {
                  return <></>;
                }
                return (
                  <p className="italic pt-3">
                    {`Workload is Predictable by ${historyDuration} window, with prediction error score of ${roundToTwoDecimal(
                      (value.predictionErrorScore ?? -1) * 100
                    )}%`}
                  </p>
                );
              })
              : "Workload is Unpredictable "}
          </Typography>
        }
        placement="left"
        maxWidth={400}
      >
        <div className={clsx(METRIC_BOX_CLASS_NAME, "mt-2 flex items-center justify-center gap-1")}>
          {PredictionMetdata.predictable
            ? `Predictable with ${Math.round(PredictionMetdata.predictionError)}% error`
            : "Unpredictable"
          }
          <div className="w-[12px]">
            <CreativeInfoIcon width={16} height={12} />
          </div>
        </div>
      </Tooltip>
      <Tooltip
        title={
          <Typography variant="caption">
            <b>Workload stable trigger</b>
            <p className="italic">{PredictionMetdata.idleStable}</p>
            {PredictionMetdata.idleStable
              ? Object.entries(predictionByHistory ?? {}).map(([historyDuration, value]) => {
                if (!value || !value.idleStable) {
                  return <></>;
                }
                return (
                  <p className="italic pt-3">
                    {`Workload is Stable by ${historyDuration} window based on its trigger values`}
                  </p>
                );
              })
              : "Workload is Unstable "}
          </Typography>
        }
        placement="left"
        maxWidth={400}
      >
        <div className={clsx(METRIC_BOX_CLASS_NAME, "mt-2 flex items-center justify-center gap-1")}>
          {PredictionMetdata.idleStable ? "Stable" : "Unstable"}{" "}
          <div className="w-[12px]">
            <CreativeInfoIcon width={16} height={12} />
          </div>
        </div>
      </Tooltip>
    </>
  );
};

export default PredictableBadge;
