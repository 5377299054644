import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import dayjs from "dayjs";
import numeral from "numeral";
import { Bar, CartesianGrid, ComposedChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { GetAnalyticsGraph, GetAnalyticsGraphResponse, GetAnalyticsGraphResponseParams } from "../../../../api/fetcher";
import Loading from "../../../../components/Loading";
import useWindowSize from "../../../../components/useWindowSize";
import { DAY_MONTH_FORMAT } from "../../../../utils/formatterUtils";
import { CHART_WRAPPER_CLASS_NAME } from "../../../../utils/styleUtils";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../../../utils/useGetTimeoutSeconds";
import useFilterQueryParams from "../useFilterQueryParams";
import { DateType, getParsedData } from "../utils";
import CustomTooltip from "./CustomTooltip";
import { SYNC_ID } from "./graphUtils";

const HEIGHT = "h-[300px]";

interface Props {
  title: React.ReactNode;
  color: string;
  label: string;
  queryParams: GetAnalyticsGraphResponseParams;
  shouldRoundValues?: boolean;
  wrapDivClassName?: string;
  setDate: (date: DateType) => void;
  tooltipValuePrefix?: string;
  isMulticluster?: boolean;
}

const BarChart = ({
  title,
  color,
  queryParams,
  label,
  shouldRoundValues,
  wrapDivClassName,
  setDate,
  tooltipValuePrefix,
  isMulticluster,
}: Props) => {
  const { queryFn, queryKey } = GetAnalyticsGraph();
  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);

  const { width: windowWidth } = useWindowSize();

  const filtersQueryParams = useFilterQueryParams();

  const { data, isLoading, error, isError } = useQuery<GetAnalyticsGraphResponse, Error>({
    queryKey: [queryKey, queryParams, filtersQueryParams, isMulticluster ? "multicluster" : undefined],
    queryFn: () =>
      queryFn({ ...queryParams, multiCluster: isMulticluster, timeoutSeconds: timeoutSeconds, ...filtersQueryParams }),
    enabled: isQueryEnabled,
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMulticluster });

  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  useEffect(() => {
    if (isMulticluster && !!data) {
      setIsQueryEnabled(false);
    }
  }, [data]);

  useEffect(() => {
    if (isLoading || isError) {
      setIsQueryEnabled(true);
    }
  }, [queryParams, filtersQueryParams, isLoading, isError]);

  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0);
      setDate({ from: from, to: to, range: "" });
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    window.addEventListener("mouseup", setDateRage);
    return () => {
      window.removeEventListener("mouseup", setDateRage);
    };
  }, [selectPosition, setDateRage]);

  if (isLoading) {
    return (
      <div className={HEIGHT}>
        <Loading hasTitle={false} hasFullWrapper />
      </div>
    );
  }

  if (isError) {
    console.log(error);
    return null;
  }

  const graphData = getParsedData(
    data,
    false,
    shouldRoundValues,
    queryParams.from,
    queryParams.to,
    queryParams.groupBy,
    true
  );

  const firstXPointString = String(graphData[0]?.date);
  const firstXPointEpoch = dayjs(firstXPointString).unix() * 1000;
  let lastXPointString = graphData && String(graphData[graphData.length - 1]?.date);
  lastXPointString = lastXPointString && `${lastXPointString} GMT`;
  const lastXPointEpoch = dayjs(lastXPointString).unix() * 1000;

  return (
    <div className={clsx(wrapDivClassName, HEIGHT, CHART_WRAPPER_CLASS_NAME, "w-[100%] border border-border rounded")}>
      <Typography variant="body2" className="w-full text-center">
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height="100%" className="pt-[20px]">
        <ComposedChart
          syncId={SYNC_ID}
          data={graphData}
          onMouseDown={(e) => {
            e.activeLabel &&
              setSelectPosition({
                ...selectPosition,
                from: dayjs(`${String(e.activeLabel)} GMT`).unix() * 1000,
                fromX: e.activeLabel,
              });
          }}
          onMouseMove={(e) => {
            selectPosition?.from &&
              setSelectPosition({
                ...selectPosition,
                to: dayjs(`${String(e.activeLabel)} GMT`).unix() * 1000,
                toX: e.activeLabel,
              });
          }}
          onMouseLeave={() => {
            if (selectPosition?.from && selectPosition?.to) {
              if (selectPosition?.from < selectPosition?.to) {
                setSelectPosition({
                  ...selectPosition,
                  to: lastXPointEpoch,
                  toX: lastXPointString,
                });
              } else {
                setSelectPosition({
                  to: selectPosition.from,
                  toX: selectPosition.fromX,
                  from: firstXPointEpoch,
                  fromX: firstXPointString,
                });
              }
            }
          }}
        >
          <CartesianGrid strokeDasharray="4 4" opacity={0.4} />
          <Tooltip content={<CustomTooltip color={color} tooltipValuePrefix={tooltipValuePrefix} />} />
          <Bar
            type="monotone"
            stackId="1"
            dataKey={queryParams?.types && queryParams?.types.length > 0 ? queryParams.types[0] : label}
            name={label}
            strokeWidth={2}
            stroke={color}
            fill={color}
            fillOpacity={0.4}
          />
          <XAxis
            dataKey="date"
            style={{ fontSize: "x-small" }}
            interval={Math.floor(graphData.length / (Number(windowWidth) / 300))}
            strokeWidth={2}
            tickFormatter={(value) => dayjs(String(value)).format(DAY_MONTH_FORMAT)}
          />
          <YAxis
            style={{ fontSize: "x-small" }}
            strokeWidth={2}
            tickFormatter={(tick: number) => `${tooltipValuePrefix ?? ""}${numeral(Number(tick ?? 0)).format("0,0")}`}
          />
          {selectPosition?.fromX && selectPosition?.toX ? (
            <ReferenceArea
              x1={selectPosition?.fromX}
              x2={selectPosition?.toX}
              stroke={color}
              fill={color}
              fillOpacity={0.3}
              strokeOpacity={0.3}
            />
          ) : null}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChart;
