import { Typography } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { LogicalOperator } from "../api/fetcher";

interface IncludeTabProps {
  selected: boolean | null | undefined;
  label: string;
  onClick: () => void;
}

const IncludeTab = ({ selected, label, onClick }: IncludeTabProps) => (
  <span onClick={onClick} className={clsx("hover:bg-guideline-lightGray", { "font-bold underline": selected })}>
    {label}
  </span>
);

const useSelectSearch = (
  isSearchable?: boolean,
  isExclude?: boolean | null | undefined,
  setIsExclude?: (value: boolean | null | undefined) => void,
  searchDivClassName?: string,
  logicalOperator?: LogicalOperator,
  setLogicalOperator?: (value: LogicalOperator) => void
) => {
  if (!isSearchable) {
    return { search: "", searchDiv: <></> };
  }

  const [search, setSearch] = useState("");

  const searchDiv = (
    <div className={clsx(searchDivClassName, "px-6 flex flex-col mt-4")}>
      <input
        placeholder="search..."
        value={search}
        onChange={(event) => {
          event.stopPropagation();
          setSearch(event.target.value.trim());
        }}
        className="border border-border rounded-md px-2 py-1 focus:outline-none"
        onClickCapture={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onKeyDown={(e) => e.stopPropagation()}
      />
      {search.length > 0 && (
        <Typography
          onClick={() => {
            setSearch("");
          }}
          className="cursor-pointer hover:underline"
          variant="caption"
          classes="text-text-disabled"
          sx={{
            fontSize: "10px",
            marginLeft: "4px",
          }}
        >
          clear search
        </Typography>
      )}
    </div>
  );

  const includeExcludeDiv = setIsExclude ? (
    <p className="text-[12px] pl-[25px] mt-[10px] cursor-pointer">
      <IncludeTab
        label="include"
        selected={!isExclude}
        onClick={() => {
          setIsExclude(false);
        }}
      />{" "}
      /
      <IncludeTab
        label="exclude"
        selected={isExclude}
        onClick={() => {
          setIsExclude(true);
        }}
      />
    </p>
  ) : null;

  const logicalOperatorDiv = setLogicalOperator ? (
    <p className="text-[12px] mt-[10px] cursor-pointer">
      <IncludeTab
        label="OR"
        selected={logicalOperator === LogicalOperator.OR || logicalOperator === undefined}
        onClick={() => {
          setLogicalOperator(LogicalOperator.OR);
        }}
      />{" "}
      /
      <IncludeTab
        label="AND"
        selected={logicalOperator === LogicalOperator.AND}
        onClick={() => {
          setLogicalOperator(LogicalOperator.AND);
        }}
      />{" "}
    </p>
  ) : null;

  return { search, searchDiv, includeExcludeDiv, logicalOperatorDiv };
};

export default useSelectSearch;
