import { useEffect, useState } from "react";
import { Typography, TypographyVariant } from "@mui/material";
import clsx from "clsx";
import numeral from "numeral";
import { animated, useSpring } from "react-spring";
import { SCALEOPS_COLORS } from "../../../colors";
import Tooltip from "../../../components/Tooltip";

const BAR_CHART_WIDTH = 180;
const BAR_CHART_HEIGHT = 15;
interface Props {
  value: number;
  title: React.ReactNode;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  thousandSeparator?: boolean;
  numberClassName?: string;
  headerVariant?: TypographyVariant;
  numberVariant?: TypographyVariant;
  titleFontWeight?: "regular" | "medium" | "bold";
  className?: string;
  leftChild?: React.ReactNode;
  valueDataTestId?: string;
  barPercentageValue?: number;
  barPercentageColor?: string;
  showRoundedValue?: boolean;
  disableAnimation?: boolean;
}

const RunningNumberBox = ({
  value,
  title,
  prefix,
  suffix,
  numberClassName = "",
  thousandSeparator = true,
  numberVariant = "h6",
  headerVariant,
  titleFontWeight = "regular",
  className,
  leftChild,
  valueDataTestId,
  barPercentageValue,
  barPercentageColor,
  showRoundedValue = true,
  disableAnimation,
}: Props) => {
  const [numberToDisplay, setNumberToDisplay] = useState(0);

  const barSpring = useSpring({
    from: { width: 0 },
    to: { width: BAR_CHART_WIDTH * (barPercentageValue ?? 0) },
  });

  useEffect(() => {
    const valueToSet = Number.isNaN(value) ? 0 : value;
    setNumberToDisplay(valueToSet);
  }, [value]);

  const spring = useSpring({
    from: { number: 0 },
    number: numberToDisplay,
  });

  return (
    <div className={clsx(className, "flex flex-col items-center justify-center")}>
      <Typography variant={headerVariant} fontWeight={titleFontWeight} className="text-center">
        {title}
      </Typography>
      <Typography className={`${numberClassName} flex`} variant={numberVariant}>
        {prefix}
        {!disableAnimation && (
          <animated.div data-testid={valueDataTestId}>
            {spring.number
              .to((n) => (showRoundedValue ? n.toFixed(0) : n.toFixed(2)))
              .to((n) => {
                if (thousandSeparator) {
                  return n.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
                return n;
              })}
          </animated.div>
        )}
        {disableAnimation && (
          <span>
            {showRoundedValue ? numeral(numberToDisplay).format("0,0") : numeral(numberToDisplay).format("0,0.00")}
          </span>
        )}
        {suffix}
        {leftChild}
      </Typography>
      {barPercentageValue && numberToDisplay && !Number.isNaN(numberToDisplay) ? (
        <Tooltip
          title={
            <>
              {title}: {`${(barPercentageValue * 100).toFixed(2)}%`}
            </>
          }
          placement="bottom"
        >
          <div className={`flex justify-start bg-border h-[${BAR_CHART_HEIGHT}px] w-[${BAR_CHART_WIDTH}px]`}>
            <animated.div
              style={{
                backgroundColor: barPercentageColor ?? SCALEOPS_COLORS.main.green,
                ...barSpring,
              }}
            />
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default RunningNumberBox;
