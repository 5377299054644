import SearchNodeFilter from "./SearchNodeFilter";
import MultiSelect from "../MultiSelect";
import { Checkbox, Chip as MUIChip, ListItemText, MenuItem, Typography } from "@mui/material";
import { GridFilterListIcon } from "@mui/x-data-grid";
import * as React from "react";
import { ArrayParam, useQueryParam } from "use-query-params";
import { GetNodeFilterValues, GetNodeFilterValuesResponse } from "../../api/fetcher";
import { useQuery } from "@tanstack/react-query";
import { components } from "../../api/schema";
import { NodePoolFilterQueryParams } from "./NodeGroups";
import CustomSelectedFilterChip from "../CustomSelectedFilterChip";
import UnFilterIcon from "../../Icons/UnFilterIcon";

const convertReasonToTextToDisplay = (option: string) => {
  let displayTestArr = option.split(/([A-Z][a-z0-9]*)/g).filter((word) => word !== "");
  displayTestArr = displayTestArr.map((word, index) => (index === 0 ? word : word.toLowerCase()));
  return displayTestArr
    .join(" ")
    ?.replace(/([a-zA-Z])([0-9])/g, "$1 $2")
    ?.replace(/([0-9])([a-zA-Z])/g, "$1 $2");
};

const optionRenderFunction = (option: string, index: number, selected: (string | undefined)[], dataTestId?: string) => {
  const displayText = convertReasonToTextToDisplay(option);

  return (
    <MenuItem
      value={option}
      key={`${index}-multi-select-option`}
      data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
    >
      <Checkbox checked={selected.indexOf(option) > -1} />
      <ListItemText primary={displayText} />
    </MenuItem>
  );
};

// we can't change the enum PodOwnerReachedToMinimumReplicas itself due to multicluster compatibility, so we artificially
// display a different text for it
const renameScaleDownReasonEnum = (
  option: string,
  index: number,
  selected: (string | undefined)[],
  dataTestId?: string
) => {
  const displayText =
    option === "PodOwnerReachedToMinimumReplicas" ? "Pod has no owner" : convertReasonToTextToDisplay(option);

  return (
    <MenuItem
      value={option}
      key={`${index}-multi-select-option`}
      data-testid={dataTestId ? `${dataTestId}-option-${index}` : undefined}
    >
      <Checkbox checked={selected.indexOf(option) > -1} />
      <ListItemText primary={displayText} />
    </MenuItem>
  );
};

const convertLabelsToSimpleStrings = (labels: components["schemas"]["NodeGroupsKeyValue"][]) => {
  return labels.map((label) => `${label?.key}=${label?.value}`);
};
export const ConvertArrayParamToStringArray = (param: (string | null)[] | null | undefined) => {
  if (param === null || param === undefined) {
    return [];
  }
  return param.filter((item) => item ?? "").map((item) => item as string);
};

export const NodeFilterBar = () => {
  const [selectedNodeGroups, setNodeGroups] = useQueryParam(NodePoolFilterQueryParams.NodeGroups, ArrayParam);
  const [selectedNodePools, setNodePools] = useQueryParam(NodePoolFilterQueryParams.NodePools, ArrayParam);
  const [selectedProvisioners, setProvisioners] = useQueryParam(NodePoolFilterQueryParams.Provisioners, ArrayParam);
  const [selectedAvailabilityZones, setAvailabilityZones] = useQueryParam(
    NodePoolFilterQueryParams.AvailabilityZones,
    ArrayParam
  );
  const [selectedScaleDownReasons, setScaleDownReasons] = useQueryParam(
    NodePoolFilterQueryParams.ScaleDownReasons,
    ArrayParam
  );
  const [selectedLabels, setLabels] = useQueryParam(NodePoolFilterQueryParams.NodeLabels, ArrayParam);

  const nodeFiltersQuery = GetNodeFilterValues();

  const { data } = useQuery<GetNodeFilterValuesResponse, Error>({
    queryKey: [nodeFiltersQuery.queryKey],
    queryFn: nodeFiltersQuery.queryFn,
    refetchInterval: 1000 * 60 * 5,
  });

  const availableNodeGroups = data?.nodeGroups || [];
  const availableAvailabilityZones = data?.availabilityZones || [];
  const availableNodePools = data?.nodePools || [];
  const availableLabels = data?.labels || [];
  const availableProvisioners = data?.provisioners || [];
  const availableScaleDownReasons = data?.scaleDownReasons || [];

  const displayAvailableLabels = convertLabelsToSimpleStrings(availableLabels);

  if (data === undefined) {
    return <div></div>;
  }

  const isAnySelected = (param: (string | null)[] | null | undefined) => {
    return param?.length || 0 > 0;
  };

  return (
    <div className={"flex flex-col gap-1 mb-4"}>
      <div className="w-auto flex gap-2 item flex-wrap">
        <SearchNodeFilter />
        {availableScaleDownReasons && availableScaleDownReasons.length > 0 && (
          <MultiSelect
            selected={ConvertArrayParamToStringArray(selectedScaleDownReasons)}
            setSelected={setScaleDownReasons as (props: (string | undefined)[]) => void}
            options={availableScaleDownReasons}
            className="mt-[2.5px] w-[176px]"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedScaleDownReasons) ? "#595959" : undefined,
                  color: isAnySelected(selectedScaleDownReasons) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  marginTop: "-4px",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    scale down blockers
                  </div>
                }
              />
            }
            optionRenderFunction={renameScaleDownReasonEnum}
          />
        )}
        {availableNodeGroups && availableNodeGroups.length > 0 && (
          <MultiSelect
            selected={ConvertArrayParamToStringArray(selectedNodeGroups)}
            setSelected={setNodeGroups as (props: (string | undefined)[]) => void}
            options={availableNodeGroups}
            className="mt-[2.5px] w-[128px]"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedNodeGroups) ? "#595959" : undefined,
                  color: isAnySelected(selectedNodeGroups) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  marginTop: "-4px",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    node groups
                  </div>
                }
              />
            }
          />
        )}
        {availableProvisioners && availableProvisioners.length > 0 && (
          <MultiSelect
            selected={ConvertArrayParamToStringArray(selectedProvisioners)}
            setSelected={setProvisioners as (props: (string | undefined)[]) => void}
            options={availableProvisioners}
            className="mt-[2.5px] w-[123px]"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedProvisioners) ? "#595959" : undefined,
                  color: isAnySelected(selectedProvisioners) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  marginTop: "-4px",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    provisioners
                  </div>
                }
              />
            }
            optionRenderFunction={optionRenderFunction}
          />
        )}
        {availableNodePools && availableNodePools.length > 0 && (
          <MultiSelect
            selected={ConvertArrayParamToStringArray(selectedNodePools)}
            setSelected={setNodePools as (props: (string | undefined)[]) => void}
            options={availableNodePools}
            className="mt-[2.5px] w-[105px]"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedNodePools) ? "#595959" : undefined,
                  color: isAnySelected(selectedNodePools) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  marginTop: "-4px",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    node pools
                  </div>
                }
              />
            }
            optionRenderFunction={optionRenderFunction}
          />
        )}
        {availableAvailabilityZones && availableAvailabilityZones.length > 0 && (
          <MultiSelect
            selected={ConvertArrayParamToStringArray(selectedAvailabilityZones)}
            setSelected={setAvailabilityZones as (props: (string | undefined)[]) => void}
            options={availableAvailabilityZones}
            className="mt-[2.5px] w-[156px]"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedAvailabilityZones) ? "#595959" : undefined,
                  color: isAnySelected(selectedAvailabilityZones) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  marginTop: "-4px",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    availability zones
                  </div>
                }
              />
            }
          />
        )}
        {displayAvailableLabels && displayAvailableLabels.length > 0 && (
          <MultiSelect
            isSearchable={true}
            hasVirtualizedList={true}
            selected={ConvertArrayParamToStringArray(selectedLabels)}
            setSelected={setLabels as (props: (string | undefined)[]) => void}
            options={displayAvailableLabels}
            className="mt-[2.5px] w-[87.1px]"
            customIcon={
              <MUIChip
                variant={"outlined"}
                sx={{
                  background: isAnySelected(selectedLabels) ? "#595959" : undefined,
                  color: isAnySelected(selectedLabels) ? "white" : "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  marginTop: "-4px",
                }}
                label={
                  <div className="flex items-center justify-center">
                    <GridFilterListIcon fontSize={"small"} />
                    labels
                  </div>
                }
              />
            }
          />
        )}
      </div>
      <div className={"flex gap-2 flex-wrap mb-1.5"}>
        {selectedScaleDownReasons?.map((reason) => {
          const parsedReason = convertReasonToTextToDisplay(reason ?? "");
          return (
            <CustomSelectedFilterChip
              label={parsedReason}
              onClick={() => {
                setScaleDownReasons(selectedScaleDownReasons?.filter((item) => item !== reason));
              }}
              hasTooltip
              tooltipContent={
                <>
                  scale down blockers: <br />
                  {parsedReason}
                </>
              }
              className="max-w-[250px] truncate"
            />
          );
        })}
        {selectedNodeGroups?.map((nodeGroup) => (
          <CustomSelectedFilterChip
            label={nodeGroup ?? ""}
            onClick={() => {
              setNodeGroups(selectedNodeGroups?.filter((item) => item !== nodeGroup));
            }}
            hasTooltip
            tooltipContent={
              <>
                node group: <br />
                {nodeGroup}
              </>
            }
            className="max-w-[250px] truncate"
          />
        ))}
        {selectedProvisioners?.map((provisioner) => (
          <CustomSelectedFilterChip
            label={provisioner ?? ""}
            onClick={() => {
              setProvisioners(selectedProvisioners?.filter((item) => item !== provisioner));
            }}
            hasTooltip
            tooltipContent={
              <>
                provisioner: <br />
                {provisioner}
              </>
            }
            className="max-w-[250px] truncate"
          />
        ))}
        {selectedNodePools?.map((nodePool) => (
          <CustomSelectedFilterChip
            label={nodePool ?? ""}
            onClick={() => {
              setNodePools(selectedNodePools?.filter((item) => item !== nodePool));
            }}
            hasTooltip
            tooltipContent={
              <>
                node pool: <br />
                {nodePool}
              </>
            }
            className="max-w-[250px] truncate"
          />
        ))}
        {selectedAvailabilityZones?.map((availabilityZone) => (
          <CustomSelectedFilterChip
            label={availabilityZone ?? ""}
            onClick={() => {
              setAvailabilityZones(selectedAvailabilityZones?.filter((item) => item !== availabilityZone));
            }}
            hasTooltip
            tooltipContent={
              <>
                availability zone: <br />
                {availabilityZone}
              </>
            }
            className="max-w-[250px] truncate"
          />
        ))}
        {selectedLabels?.map((label) => (
          <CustomSelectedFilterChip
            label={label ?? ""}
            onClick={() => {
              setLabels(selectedLabels?.filter((item) => item !== label));
            }}
            hasTooltip
            tooltipContent={
              <>
                label: <br />
                {label}
              </>
            }
            className="max-w-[250px] truncate"
          />
        ))}
      </div>
      {(selectedScaleDownReasons?.length ||
        selectedNodeGroups?.length ||
        selectedProvisioners?.length ||
        selectedNodePools?.length ||
        selectedAvailabilityZones?.length ||
        selectedLabels?.length) && (
        <Typography
          variant="caption"
          onClick={() => {
            setNodeGroups([]);
            setNodePools([]);
            setProvisioners([]);
            setAvailabilityZones([]);
            setScaleDownReasons([]);
            setLabels([]);
          }}
          className="w-fit hover:cursor-pointer hover:text-guideline-lessDarkBlue bg-white hover:bg-guideline-lightGray px-2 py-1 border-[0.5px] border-black hover:border-border rounded-lg flex items-center justify-center gap-1"
        >
          <UnFilterIcon width={15} height={15} />
          <p>Reset filters</p>
        </Typography>
      )}
    </div>
  );
};

export const HasNodeFilters = () => {
  const [selectedNodeGroups] = useQueryParam(NodePoolFilterQueryParams.NodeGroups, ArrayParam);
  const [selectedNodePools] = useQueryParam(NodePoolFilterQueryParams.NodePools, ArrayParam);
  const [selectedProvisioners] = useQueryParam(NodePoolFilterQueryParams.Provisioners, ArrayParam);
  const [selectedAvailabilityZones] = useQueryParam(NodePoolFilterQueryParams.AvailabilityZones, ArrayParam);
  const [selectedScaleDownReasons] = useQueryParam(NodePoolFilterQueryParams.ScaleDownReasons, ArrayParam);
  const [selectedLabels] = useQueryParam(NodePoolFilterQueryParams.NodeLabels, ArrayParam);

  return !!(
    selectedLabels?.length ||
    selectedNodePools?.length ||
    selectedNodeGroups?.length ||
    selectedProvisioners?.length ||
    selectedAvailabilityZones?.length ||
    selectedScaleDownReasons?.length
  );
};
