const FullLogo = () => {
  return (
    <svg width="159" height="33" viewBox="0 0 159 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_235_766)">
        <mask id="mask0_235_766" maskUnits="userSpaceOnUse" x="0" y="0" width="159" height="33">
          <path d="M158.591 0H0V32.5625H158.591V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_235_766)">
          <path
            d="M45.7227 22.8329L46.4834 20.3457C48.9041 21.1015 51.426 21.4832 53.9619 21.4776C54.8123 21.4776 58.3408 21.4405 58.3408 19.9004C58.3408 18.9726 57.0791 18.8953 53.999 18.7314C50.2694 18.5273 46.2051 18.3047 46.2051 14.7413C46.2051 11.178 50.2501 10.5146 53.5165 10.5146C55.7516 10.5347 57.9783 10.7897 60.16 11.2754L59.5848 13.8182C57.4404 13.3411 55.2495 13.1046 53.0527 13.1131C52.3847 13.1131 49.044 13.1309 49.044 14.5975C49.044 15.8593 51.5311 15.9706 54.1289 16.0665C57.7664 16.2149 61.1805 16.6076 61.1805 19.7775C61.1805 23.4112 56.6902 24.0644 53.4609 24.0644C50.833 24.0675 48.2212 23.6541 45.7227 22.8398"
            fill="white"
          />
          <path
            d="M75.4481 23.2777C73.7544 23.8282 71.9807 24.0918 70.2001 24.0577C64.7805 24.0577 62.4805 21.886 62.4805 18.8987C62.4805 15.9113 64.7774 13.762 70.2001 13.762C71.9803 13.7262 73.7539 13.9875 75.4481 14.5351L74.9278 16.9666C73.3902 16.5506 71.8046 16.3383 70.2117 16.335C66.4451 16.335 65.3132 17.4119 65.3132 18.8963C65.3132 20.3808 66.4451 21.4755 70.2117 21.4755C71.8045 21.4719 73.39 21.2598 74.9278 20.8446L75.4481 23.2777Z"
            fill="white"
          />
          <path
            d="M90.8877 23.7786C90.1374 23.943 89.3733 24.0365 88.6055 24.0577C86.3967 24.0577 86.2861 22.9436 86.2297 22.3499C85.3205 23.4075 83.1681 24.0577 81.461 24.0577C78.7705 24.0577 76.9336 22.8137 76.9336 20.5315C76.9336 18.1564 79.3086 17.507 81.6837 17.507C83.1707 17.5123 84.6501 17.7182 86.082 18.1193C85.9884 17.1359 85.0607 16.3566 82.5187 16.3566C81.1329 16.3433 79.7521 16.5255 78.4172 16.8978L77.5274 14.4671C79.1479 13.9901 80.8294 13.7525 82.5187 13.762C87.1945 13.762 88.8838 15.7652 88.8838 18.4379V20.8345C88.8838 21.1871 89.0137 21.5025 89.793 21.5025C90.1613 21.4948 90.5279 21.4513 90.8877 21.3726V23.7786ZM81.9434 21.7004C83.7216 21.7004 86.007 20.8284 86.0998 19.7151V19.3625H81.5383C80.6477 19.3625 79.7756 19.5852 79.7756 20.3459C79.7756 21.3108 80.759 21.7004 81.9465 21.7004"
            fill="white"
          />
          <path d="M92.3711 10.7893L95.1543 10.5117V23.7793H92.3711V10.7893Z" fill="white" />
          <path
            d="M99.6443 19.8624C100.071 20.9394 101.333 21.4767 103.896 21.4767C105.647 21.4689 107.391 21.2575 109.093 20.8466L109.612 23.2765C107.786 23.8118 105.892 24.0746 103.989 24.0566C99.0721 24.0566 96.6445 22.2568 96.6445 18.9718C96.6445 16.1112 98.7598 13.7578 103.157 13.7578C107.389 13.7578 110.784 15.7803 109.467 19.8655L99.6443 19.8624ZM99.5886 18.0069H106.659C106.936 16.9493 105.638 16.2813 103.3 16.2813C101.017 16.2813 99.9968 16.9122 99.6443 17.8399L99.5886 18.0069Z"
            fill="white"
          />
          <path
            d="M120.143 10.5146C125.878 10.5146 128.865 13.427 128.865 17.288C128.865 21.149 125.878 24.0606 120.143 24.0606C114.409 24.0606 111.422 21.1474 111.422 17.288C111.422 13.4286 114.411 10.5146 120.143 10.5146ZM120.143 21.4629C124.17 21.4629 126.025 19.7373 126.025 17.288C126.025 14.8387 124.17 13.1131 120.143 13.1131C116.117 13.1131 114.261 14.8387 114.261 17.288C114.261 19.7373 116.116 21.4629 120.143 21.4629Z"
            fill="white"
          />
          <path
            d="M133.3 22.3683L133.318 23.7785V27.304H130.535V14.1662L133.226 13.8879L133.303 14.6487L133.322 14.6672C134.759 14.0579 136.307 13.7485 137.868 13.758C142.192 13.758 144.14 15.929 144.14 18.8421C144.14 21.8859 142.006 24.0568 138.258 24.0568C136.455 24.1342 134.691 23.5237 133.322 22.349L133.3 22.3683ZM133.318 16.9124V19.7923C133.43 20.4603 135.601 21.48 137.902 21.48C140.299 21.48 141.298 20.3853 141.298 18.8645C141.298 17.4165 140.407 16.3403 137.587 16.3403C136.148 16.3832 134.717 16.576 133.318 16.9155"
            fill="white"
          />
          <path
            d="M145.805 20.6982C147.79 21.1965 149.827 21.4581 151.873 21.4775C152.878 21.4775 155.751 21.4404 155.751 20.6982C155.751 20.123 154.026 20.1786 152.003 20.1415C149.09 20.0642 145.545 19.789 145.545 17.0614C145.545 13.9441 149.59 13.7578 151.799 13.7578C153.791 13.7755 155.777 13.9617 157.737 14.3145L157.291 16.8758C155.315 16.5079 153.308 16.3339 151.298 16.3563C149.498 16.3563 148.384 16.504 148.384 17.0792C148.384 17.7294 149.999 17.7294 151.929 17.7479C154.879 17.7842 158.591 17.9141 158.591 20.6796C158.591 23.9082 153.488 24.0574 151.556 24.0574C149.425 24.0736 147.302 23.7987 145.246 23.2402L145.805 20.6982Z"
            fill="white"
          />
        </g>
        <path d="M14.1016 0V16.2797L28.2011 24.4215V8.13869L14.1016 0Z" fill="#B8B7FD" />
        <path d="M14.101 0L0 8.13869V24.4215L14.101 16.2797V0Z" fill="#FF507A" />
        <path d="M0 24.4211L14.1018 32.5621L28.2013 24.4211L14.1018 16.2793L0 24.4211Z" fill="#5353E0" />
        <path d="M0 24.4211L14.1018 32.5621L28.2013 24.4211L14.1018 16.2793L0 24.4211Z" fill="#5353E0" />
        <path d="M28.2031 0V8.14024L35.2556 12.2107V4.06973L28.2031 0Z" fill="#B8B7FD" />
        <path d="M28.2025 0L21.1523 4.06973V12.2107L28.2025 8.14024V0Z" fill="#FF507A" />
        <path d="M21.1523 12.2111L28.2032 8.14062L35.2557 12.2111L28.2056 16.2816L21.1523 12.2111Z" fill="#5353E0" />
        <path d="M21.1523 12.2111L28.2032 8.14062L35.2557 12.2111L28.2056 16.2816L21.1523 12.2111Z" fill="#5353E0" />
      </g>
      <defs>
        <clipPath id="clip0_235_766">
          <rect width="158.591" height="32.5625" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FullLogo;
