import { Paper, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import * as React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { useMainContext } from "../../MainContext";
import { GetAudits, GetAuditsResponse } from "../../api/fetcher";
import { components } from "../../api/schema";
import { DEFAULT_DATE_TIME_FORMAT, TimeStandard } from "../../utils/formatterUtils";
import { getDataGridSx, HEADER_HEIGHT } from "../../utils/styleUtils";
import Tooltip from "../Tooltip";
import EditToolbarAudit from "./AuditToolBar";
import { DateType, getFromDate } from "../../pages/Analytics/AnalyticsV2/utils";

dayjs.extend(dayjsPluginUTC);

const { queryFn, queryKey } = GetAudits();

export default function AuditView() {
  const { currentCluster } = useMainContext();

  const [audits, setAudits] = useState<GridRowsProp<components["schemas"]["MiddlewaresAuditObject"]>>([]);
  const [timeStandard] = useQueryParam("timeStandard", withDefault(StringParam, TimeStandard.LOCAL));
  const [showUser, setShowUser] = useState<boolean>(false);

  const [currentEpoch] = useState(Date.now());
  const [fromTime, setFromTime] = React.useState<number | undefined>(getFromDate(3, currentEpoch));
  const [toTime, setToTime] = React.useState<number | undefined>(currentEpoch);
  const [range, setRange] = useState<string | undefined>("3d");
  const date: DateType = {
    from: fromTime,
    to: toTime,
    range: range,
  };
  const setDate = (date: DateType) => {
    setFromTime(date.from);
    setToTime(date.to);
    setRange(date.range);
  };

  const { data, error, isLoading } = useQuery<GetAuditsResponse, Error>({
    queryKey: [queryKey, currentCluster, fromTime, toTime, range],
    queryFn: () =>
      queryFn({
        from: range ? undefined : fromTime,
        to: range ? undefined : toTime,
        range: range,
      }),
  });

  useEffect(() => {
    if (data?.audits) {
      setAudits(data.audits);
      setShowUser(!!data.audits.find((audit) => audit.user));
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setAudits([]);
      setShowUser(false);
      toast.error("Failed to fetch audits for cluster");
      console.log("Failed to fetch audits for cluster:", error);
    }
  }, [error]);

  const columns: GridColDef[] = [
    {
      field: "startTimestamp",
      headerName: "Start Time",
      renderCell: (params) => {
        return <div>{params.value ? dayjs(String(params.value)).format(DEFAULT_DATE_TIME_FORMAT) : null}</div>;
      },
      flex: 1,
      type: "string",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: false,
      maxWidth: 250,
    },
    {
      field: "operation",
      headerName: "Operation",
      flex: 1,
      type: "string",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      maxWidth: 250,
    },
    {
      field: "user",
      headerName: "User",
      flex: 1,
      type: "string",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      maxWidth: 250,
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      type: "string",
      align: "left",
      headerAlign: "center",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<string, components["schemas"]["MiddlewaresAuditObject"], string>) => {
        // for bulk actions the message is in the format of "operation [workload1, workload2, ...]"
        const messages = params.value?.split("[");
        let workloads: string[] = [];
        if (messages?.length === 2) {
          workloads = messages[1].split("]")[0].split(",");
          if (workloads.length === 1) {
            // for old format, workloads are separated by space
            workloads = workloads[0].split(" ");
          }
        }
        const title = (
          <>
            <p>{messages?.[0]}</p>
            <ul>
              {workloads?.map((workload) => (
                <li key={workload} style={{ listStyleType: "disc", listStylePosition: "inside" }}>
                  {workload}
                </li>
              ))}
            </ul>
          </>
        );
        return (
          <Tooltip title={<div className="max-h-[190px] overflow-auto">{title}</div>} maxWidth={700} className={""}>
            <p className="cursor-default line-clamp-2 whitespace-normal max-w-full">{params.value}</p>
          </Tooltip>
        );
      },
    },
  ];

  let auditToDisplay = audits;
  if (timeStandard === TimeStandard.LOCAL) {
    auditToDisplay = audits.map((audit) => {
      return {
        ...audit,
        startTimestamp: audit.startTimestamp
          ? dayjs(`${audit.startTimestamp.replaceAll("-", "/")} UTC`).format(DEFAULT_DATE_TIME_FORMAT)
          : audit.startTimestamp,
        endTimestamp: audit.endTimestamp
          ? dayjs(`${audit.endTimestamp.replaceAll("-", "/")} UTC`).format(DEFAULT_DATE_TIME_FORMAT)
          : audit.endTimestamp,
      };
    });
  }
  return (
    <Paper>
      <div className="px-8 pt-8">
        <Typography variant="h6">Audits</Typography>
        <Typography className="max-w-[620px]" sx={{ fontSize: 12 }}>
          Review your <b>operations and messages</b>. Search for a specific message and filter by date. Customized the
          start time by selecting a specific <b>Time Standard</b> filter (UTC/Local).
        </Typography>
      </div>
      <div className="p-8 mt-[86px]">
        <DataGrid
          headerHeight={HEADER_HEIGHT}
          rowHeight={90}
          sx={{
            ...getDataGridSx(),
          }}
          key={auditToDisplay.toString()}
          componentsProps={{
            toolbar: {
              date: date,
              setDate: setDate,
            },
          }}
          components={{
            Toolbar: EditToolbarAudit,
          }}
          hideFooter={true}
          columns={columns}
          rows={auditToDisplay}
          style={{ height: "100%" }}
          hideFooterPagination={true}
          autoHeight={true}
          initialState={{
            sorting: { sortModel: [{ sort: "desc", field: "startTimestamp" }] },
            columns: {
              columnVisibilityModel: {
                user: showUser,
              },
            },
          }}
          disableSelectionOnClick
          loading={isLoading}
        />
      </div>
    </Paper>
  );
}
