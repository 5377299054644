import { SCALEOPS_COLORS } from "../../../../colors";

export const WORKLOAD_ANALYTICS_SYNCH_ID = "WORKLOAD_ANALYTICS_SYNCH_ID";
export const DOT_RADIUS = 16;
export const DOT_STROKE_WIDTH = 4;

export const CHART_HEIGHT = "h-[320px]";

export const recommendedGetElementsFormat = (key: string, regexp: string, color: string) => {
  let label = key.replace(regexp, "");
  label = label === "recommended" ? "Optimized request" : label;
  if (key.startsWith("memory_request_recommended") || key.startsWith("cpu_request_recommended")) {
    return {
      key: key,
      dataKey: key,
      label: label,
      color: SCALEOPS_COLORS.main.green,
      tooltipValueColor: color,
      fill: "none",
    };
  }

  return {
    key: key,
    dataKey: key,
    label: key.replace(regexp, ""),
    color: color,
    tooltipValueColor: color,
    fill: "none",
  };
};

export const recommendedLegendSortFunction = (a: { [x: string]: string }, b: { [y: string]: string }) => {
  if (!a || !b) return 0;
  const aKey = Object.keys(a)[0];
  const bKey = Object.keys(b)[0];

  if (aKey.startsWith("memory_request_recommended") || aKey.startsWith("cpu_request_recommended")) {
    return -1;
  }

  return aKey.localeCompare(bKey);
};
