import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useSpring, animated } from "react-spring";
import Tooltip from "../Tooltip";
import { SCALEOPS_COLORS } from "../../colors";

const roundNumber = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

const BASIC_ANIMATION_CLASS_NAME = "text-black flex items-center justify-center";

export enum Elements {
  Request = "request",
  Usage = "usage",
  Allocatable = "allocatable",
  MaxPods = "max pods",
  RunningPods = "running pods",
  DaemonSetPods = "daemon set pods",
}

const styles = {
  request: {
    backgroundColor: "#EAB832",
    borderColor: "#EAB832",
  },
  usage: {
    backgroundColor: "#3B8BFF",
    borderColor: "#3B8BFF",
  },
  white: {
    backgroundColor: "#FFFFFF",
    borderColor: "#C6C6D660",
  },
  allocatable: {
    borderColor: "#FF9141",
    backgroundColor: "#FF9141",
  },
  max_pods: {
    borderColor: "#FF9141",
    backgroundColor: "#FF9141",
  },
  running_pods: {
    backgroundColor: "#3B8BFF",
    borderColor: "#060606",
  },
  daemon_set_pods: {
    backgroundColor: SCALEOPS_COLORS.main.burgundy,
    borderColor: SCALEOPS_COLORS.main.burgundy,
  },
};
interface BarProps {
  style: {
    backgroundColor: string;
    borderColor: string;
  };
  width: number;
  top?: number;
  left?: number;
  height?: number;
}

const hasElement = (elementsToDisplay: (Elements | undefined)[] | undefined, element: Elements) => {
  return elementsToDisplay?.includes(element) ?? false;
};
const Bar = ({ style, width, top = 0, left = 0, height = 12 }: BarProps) => {
  const spring = useSpring({
    from: { width: 0 },
    width: width,
  });

  return (
    <animated.div
      style={{
        position: "absolute",
        top,
        left,
        height,
        width: spring.width.to((w) => `${w}%`),
        backgroundColor: style.backgroundColor,
        border: `1px solid ${style.borderColor}`,
        borderRadius: 100,
      }}
    />
  );
};

interface UsageAndRequestChartProps {
  usage: number;
  request: number;
  elementToDisplay: Elements[];
  tooltipData: {
    usage: number;
    request: number;
    allocatable: number;
  };
  showMetricsTitles?: boolean;
  showAllocatableBellow?: boolean;

  styleOverride?: {
    usage?: {
      backgroundColor: string;
      borderColor: string;
    };
    request?: {
      backgroundColor: string;
      borderColor: string;
    };
    allocatable?: {
      backgroundColor: string;
      borderColor: string;
    };
  };
}

const UsageAndRequestChart = ({
  request,
  usage,
  tooltipData,
  elementToDisplay,
  showMetricsTitles = true,
  showAllocatableBellow,
  styleOverride = undefined,
}: UsageAndRequestChartProps) => {
  const [requestToDisplay, setRequestToDisplay] = useState(0);
  const [usageToDisplay, setUsageToDisplay] = useState(0);
  const [podScheduledToDisplay, setPodScheduledToDisplay] = useState(0);

  useEffect(() => {
    setUsageToDisplay(usage > 100 ? 100 : Math.round(usage));
  }, [usage]);

  useEffect(() => {
    setRequestToDisplay(request > 100 ? 100 : Math.round(request));
  }, [request]);

  useEffect(() => {
    setPodScheduledToDisplay(request > 100 ? 100 : Math.round(request));
  }, [request]);

  const requestSpring = useSpring({
    from: { number: 0 },
    number: requestToDisplay,
  });

  const usageSpring = useSpring({
    from: { number: 0 },
    number: usageToDisplay,
  });

  const podScheduledSpring = useSpring({
    from: { number: 0 },
    number: podScheduledToDisplay,
  });

  const TooltipOption = ({ name, value, background }: { name: string; value: number; background: string }) => {
    return (
      <p className="flex gap-2 items-center">
        <div
          style={{
            background: background,
          }}
          className="w-6 h-2 rounded"
        />
        {name}: {value}
      </p>
    );
  };

  const tooltipContent = (
    <div>
      {hasElement(elementToDisplay, Elements.Allocatable) && (
        <TooltipOption
          name="Allocatable"
          value={roundNumber(tooltipData.allocatable)}
          background={styleOverride?.allocatable?.borderColor || styles.allocatable.borderColor}
        />
      )}
      {hasElement(elementToDisplay, Elements.Usage) && (
        <TooltipOption
          name="Usage"
          value={roundNumber(tooltipData.usage)}
          background={styleOverride?.allocatable?.borderColor || styles.usage.borderColor}
        />
      )}
      {hasElement(elementToDisplay, Elements.Request) && (
        <TooltipOption
          name="Request"
          value={roundNumber(tooltipData.request)}
          background={styleOverride?.request?.borderColor || styles.request.borderColor}
        />
      )}
      {hasElement(elementToDisplay, Elements.MaxPods) && (
        <TooltipOption
          name="Max pods"
          value={roundNumber(tooltipData.allocatable)}
          background={styleOverride?.allocatable?.borderColor || styles.max_pods.borderColor}
        />
      )}
      {hasElement(elementToDisplay, Elements.RunningPods) && (
        <TooltipOption
          name="Running pods"
          value={roundNumber(tooltipData.usage)}
          background={styleOverride?.request?.borderColor || styles.running_pods.borderColor}
        />
      )}
      {hasElement(elementToDisplay, Elements.DaemonSetPods) && (
        <TooltipOption
          name="Daemon set pods"
          value={roundNumber(tooltipData.usage)}
          background={styleOverride?.request?.borderColor || styles.daemon_set_pods.borderColor}
        />
      )}
    </div>
  );

  return (
    <div className="flex flex-col gap-1 w-full">
      <Tooltip title={tooltipContent}>
        <div
          className="h-[12px] relative rounded-full"
          style={{
            background: "#E5E7ED",
            opacity: 1,
          }}
        >
          {hasElement(elementToDisplay, Elements.Request) && hasElement(elementToDisplay, Elements.Usage) && (
            <Bar style={styles.white} width={Math.max(request, usage)} />
          )}
          {hasElement(elementToDisplay, Elements.Request) && (
            <Bar style={styleOverride?.request || styles.request} width={request} />
          )}
          {hasElement(elementToDisplay, Elements.MaxPods) && hasElement(elementToDisplay, Elements.RunningPods) && (
            <Bar style={styleOverride?.request || styles.request} width={usage} />
          )}
          {hasElement(elementToDisplay, Elements.Usage) && usage > 0 && (
            <Bar
              style={styleOverride?.usage || styles.usage}
              width={usage}
              top={usage < 3 ? 3 : 1}
              left={1}
              height={usage < 3 ? 6 : 10}
            />
          )}
          {hasElement(elementToDisplay, Elements.DaemonSetPods) && (
            <Bar
              style={styleOverride?.usage || styles.daemon_set_pods}
              width={usage}
              top={usage < 3 ? 3 : 1}
              left={1}
              height={usage < 3 ? 6 : 10}
            />
          )}
        </div>
        <Typography variant="caption" className="w-full flex justify-center gap-2" fontWeight={400}>
          {hasElement(elementToDisplay, Elements.Usage) && (
            <span className={BASIC_ANIMATION_CLASS_NAME}>
              {showMetricsTitles && <>Usage:&nbsp;</>}
              <animated.div>{usageSpring.number.to((n) => n.toFixed(0)).to((n) => n)}</animated.div>%
            </span>
          )}
          {hasElement(elementToDisplay, Elements.Usage) && hasElement(elementToDisplay, Elements.Request) && (
            <span className="text-strongBorder">|</span>
          )}
          {hasElement(elementToDisplay, Elements.MaxPods) && hasElement(elementToDisplay, Elements.RunningPods) && (
            <span className={BASIC_ANIMATION_CLASS_NAME}>
              <animated.div>{podScheduledSpring.number.to((n) => n.toFixed(0)).to((n) => n)}</animated.div>%
            </span>
          )}
          {hasElement(elementToDisplay, Elements.Request) && (
            <span className={BASIC_ANIMATION_CLASS_NAME}>
              {showMetricsTitles && <>Request:&nbsp;</>}
              <animated.div>{requestSpring.number.to((n) => n.toFixed(0)).to((n) => n)}</animated.div>%
            </span>
          )}
          {hasElement(elementToDisplay, Elements.DaemonSetPods) && (
            <span className={BASIC_ANIMATION_CLASS_NAME}>
              <animated.div>{usageSpring.number.to((n) => n.toFixed(0)).to((n) => n)}</animated.div>%
            </span>
          )}
          {showAllocatableBellow && (
            <>
              <span className="grow text-center" />
              <span className={BASIC_ANIMATION_CLASS_NAME}>{Math.round(tooltipData.allocatable * 100) / 100}</span>
            </>
          )}
        </Typography>
      </Tooltip>
    </div>
  );
};

export default UsageAndRequestChart;
