import { Typography } from "@mui/material";
import clsx from "clsx";

interface Props {
  label: string;
  className?: string;
}

const ErrorLabel = ({ label, className }: Props) => {
  return (
    <Typography
      variant="caption"
      className={clsx(className, "text-guideline-lightRed absolute top-[60px] w-[200px] truncate")}
    >
      *{label}
    </Typography>
  );
};

export default ErrorLabel;
