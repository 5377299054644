import { useState } from "react";
import Tab from "../../../components/Tab";
import AggregationFilters from "./Aggregation/AggregationFilters";
import AggregationTable from "./Aggregation/AggregationTable";
import { Field, TableType } from "./utils";
import WorkloadsFilters from "./Workloads/WorkloadsFilters";
import WorkloadsTable from "./Workloads/WorkloadsTable";

interface Props {
  selectedTable: TableType;
  setSelectedTable: (table: TableType) => void;
}

const TablesContainer = ({ selectedTable, setSelectedTable }: Props) => {
  const [selectedWorkloadsColumns, setSelectedWorkloadsColumns] = useState<(string | undefined)[]>([
    Field.workloadName,
    Field.clusterName,
    Field.totalCost,
    Field.savingsAvailable,
    Field.spot,
    Field.onDemand,
    Field.cpu,
    Field.memory,
    // ColumnNames.replicas,
  ]);
  const [selectedAggregationColumns, setSelectedAggregationColumns] = useState<(string | undefined)[]>([
    Field.id,
    Field.clusterName,
    Field.totalCost,
    Field.savingsAvailable,
    // Field.spot,
    // Field.onDemand,
    Field.cpu,
    Field.memory,
    // ColumnNames.replicas,
  ]);

  return (
    <div className="bg-white rounded-lg rounded-tl-none relative top-[36px] p-8 flex flex-col gap-4">
      <div className="flex absolute right-0 top-[-36px] w-full">
        {Object.entries(TableType).map(([key, value]) => {
          return (
            <Tab
              key={key}
              isSelected={selectedTable === value}
              onClick={() => {
                setSelectedTable(value);
              }}
              name={value}
              dataTestId={`cost-page-${key}-tab`}
            />
          );
        })}
      </div>
      {selectedTable === TableType.Workloads && (
        <>
          <WorkloadsFilters
            selectedColumns={selectedWorkloadsColumns}
            setSelectedColumns={setSelectedWorkloadsColumns}
          />
          <WorkloadsTable selectedColumns={selectedWorkloadsColumns} />
        </>
      )}
      {selectedTable === TableType.Aggregation && (
        <>
          <AggregationFilters
            selectedColumns={selectedAggregationColumns}
            setSelectedColumns={setSelectedAggregationColumns}
          />
          <AggregationTable selectedColumns={selectedAggregationColumns} />
        </>
      )}
    </div>
  );
};

export default TablesContainer;
