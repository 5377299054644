import clsx from "clsx";
import numeral from "numeral";
import { Typography } from "@mui/material";
import { GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../api/schema";
import * as formatterUtils from "../../utils/formatterUtils";
import RightArrowIcon from "../../Icons/RightArrowIcon";
import React from "react";
import prettyBytes from "pretty-bytes";
import { CSVLink } from "react-csv";

export const AGGREGATION_LABEL_QUERY_KEY = "aggregateBylabels";
export const AGGREGATION_ANNOTATION_QUERY_KEY = "aggregateByannotations";
export const AGGREGATION_NAMESPACES_QUERY_KEY = "aggregateByNamespaces";

export const ALL_WORKLOADS_STRING = "All workloads";

export type ROW = { id: string } & components["schemas"]["DashAggregatedOverviewGroup"];

export type AGGREGATION_WORKLOAD = components["schemas"]["DashAggregationWorkload"];

export type AGGREGATION_WORKLOAD_DATA = { workloads: AGGREGATION_WORKLOAD[] };

export type JSON_TYPE = Record<string, string | object>;

export type REF = CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement };

export enum Columns {
  Name = "name",
  TotalCost = "totalCost",
  SavingsAvailable = "savingsAvailable",
  ActiveSavings = "activeSavings",
  CpuDiff = "cpuDiff",
  CpuRequests = "cpuRequests",
  CpuRecommended = "cpuRecommended",
  MemRequests = "memRequests",
  MemRecommended = "memRecommended",
  MemDiff = "memDiff",
  AutomationPercentage = "automationPercentage",
  OverProvisioned = "overProvisioned",
  UnderProvisioned = "underProvisioned",
}

export const ColumnNames = {
  [Columns.Name]: "Name",
  [Columns.TotalCost]: "Total Cost",
  [Columns.SavingsAvailable]: "Savings Available",
  [Columns.ActiveSavings]: "Active Savings",
  [Columns.CpuDiff]: "CPU Request",
  [Columns.CpuRequests]: "CPU Request",
  [Columns.CpuRecommended]: "CPU Recommended",
  [Columns.MemRequests]: "Memory Request",
  [Columns.MemRecommended]: "Memory Recommended",
  [Columns.MemDiff]: "Memory Request",
  [Columns.AutomationPercentage]: "Automation %",
  [Columns.OverProvisioned]: "Over Provisioned",
  [Columns.UnderProvisioned]: "Under Provisioned",
};

export const NumericValueCell = (params: GridRenderCellParams<number, ROW>) => {
  const value = numericalValue(params.value);
  const displayValue = numeral(value).format(value % 1 !== 0 && value < 1000 ? "$0,0.00" : "$0,0");
  return (
    <Typography fontWeight={500} variant="body2" className={clsx({ "text-guideline-darkGreen": value > 0 })}>
      {displayValue}
    </Typography>
  );
};

export const ProvisionersValueCell = (params: GridRenderCellParams<number, ROW>) => {
  const value = numericalValue(params.value);
  const displayValue = Math.round(value * 100) / 100;
  return (
    <Typography fontWeight={500} variant="body2" className={clsx({ "text-main-red": value > 0 })}>
      {displayValue}
    </Typography>
  );
};

export const numericalValue = (value: number | undefined) => {
  return value && !Number.isNaN(value) && value > 0 ? value : 0;
};

export const CurrencyValueCell = (params: GridRenderCellParams<number, ROW>) => {
  return (
    <Typography fontWeight={500} variant="body2">
      {params.value == undefined || params.value < 0
        ? formatterUtils.currencyFormatter(0)
        : formatterUtils.currencyFormatter(params.value)}
    </Typography>
  );
};

const cpuFormatter = formatterUtils.CpuFormatter();

export const getCpuDisplayValue = (value: number | undefined) => {
  const cpuRequestsDisplayValue = value ? cpuFormatter.format(value / 1000) : "0";
  return Number.isNaN(cpuRequestsDisplayValue) ? "0" : cpuRequestsDisplayValue;
};

export const CPURequestCell = (params: GridRenderCellParams<number, ROW>) => {
  const cpuRequestsDisplayValue = getCpuDisplayValue(params.row.cpuRequests);
  const cpuRecommendedDisplayValue = getCpuDisplayValue(params.row.cpuRecommended);

  return (
    <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
      <div className="flex justify-end items-center gap-[.625rem]">
        <span>{cpuRequestsDisplayValue}</span>
        <>
          <RightArrowIcon width={10} height={10} />
          <span className="text-guideline-darkGreen">{cpuRecommendedDisplayValue}</span>
        </>
      </div>
    </Typography>
  );
};

export const getMemoryDisplayValue = (value: number | undefined) => {
  return prettyBytes(Number(value) || 0.0, { bits: false, binary: true });
};

export const MemoryRequestCell = (params: GridRenderCellParams<number, ROW>) => {
  const currentDisplayValue = getMemoryDisplayValue(params.row.memRequests);
  const recommendedDisplayValue = getMemoryDisplayValue(params.row.memRecommended);

  return (
    <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
      <div className="flex justify-center items-center gap-[.625rem]">
        <span>{currentDisplayValue}</span>
        <>
          <RightArrowIcon width={10} height={10} />
          <span className="text-guideline-darkGreen">{recommendedDisplayValue}</span>
        </>
      </div>
    </Typography>
  );
};

export const MonthlyHeader = (params: GridColumnHeaderParams<number, ROW>) => (
  <Typography variant="body2" fontWeight={700} sx={{ whiteSpace: "normal ", wordBreak: "break-word" }}>
    {params.colDef.headerName}
    <br />
    <span className="text-text-darkGray text-[10px]">(monthly)</span>
  </Typography>
);

export const getUniqueName = (obj: JSON_TYPE | string): string => {
  let uniqueName = "";
  if (typeof obj === "string") {
    uniqueName = obj;
  } else if (typeof obj === "object") {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "string") {
        uniqueName += `${String(obj[key])}, `;
      } else if (typeof obj[key] === "object") {
        uniqueName += getUniqueName(obj[key] as JSON_TYPE);
      }
    });
  }

  if (uniqueName === "") {
    uniqueName = ALL_WORKLOADS_STRING;
  }

  return uniqueName;
};

export type AGGREGATION_PARAMS = {
  namespace?: string;
  labels?: { key?: string; value?: string }[];
  annotations?: { key?: string; value?: string }[];
};

export const getAggregationParams = (paramsJson: string | undefined): AGGREGATION_PARAMS => {
  if (!paramsJson) {
    return {} as AGGREGATION_PARAMS;
  }
  return JSON.parse(paramsJson) as AGGREGATION_PARAMS;
};

export const getAggregationAsKeyValueObject = (params: AGGREGATION_PARAMS): { [key: string]: string } => {
  const result: { [key: string]: string } = {};
  if (params.namespace) {
    result["namespace"] = params.namespace;
  }
  if (params.labels) {
    params.labels.forEach((label) => {
      if (label?.value && label?.key) {
        result[label.key] = label.value;
      }
    });
  }
  if (params.annotations) {
    params.annotations.forEach((annotation) => {
      if (annotation?.value && annotation?.key) {
        result[annotation.key] = annotation.value;
      }
    });
  }
  return result;
};

export const getAggregationValuesAsString = (params: AGGREGATION_PARAMS): string => {
  let values: string[] = [];
  if (params.namespace) {
    values.push(`ns:${params.namespace}`);
  }
  if (params?.labels?.length) {
    values = values.concat(
      params.labels.map((label) => (label?.value && label?.key ? `l:${label.key}=${label.value}` : ""))
    );
  }
  if (params?.annotations?.length) {
    values = values.concat(
      params.annotations.map((annotation) =>
        annotation?.value && annotation?.key ? `a:${annotation?.key}=${annotation?.value}` : ""
      )
    );
  }
  return values.length ? values.join(",") : ALL_WORKLOADS_STRING;
};

export const getAggregationValuesAsTooltipContent = (params: AGGREGATION_PARAMS): JSX.Element => {
  if (!params.namespace && !params.labels?.length && !params.annotations?.length) {
    return <div>{ALL_WORKLOADS_STRING}</div>;
  }

  return (
    <>
      {params.namespace && (
        <div>
          <b>Namespace:</b> {params.namespace}
        </div>
      )}
      {!!params.labels?.length && (
        <div>
          <b>Labels:</b>
          <ul>
            {params.labels.map((label) => (
              <li key={label.key} style={{ listStyleType: "disc", listStylePosition: "inside" }}>
                {label.value}
              </li>
            ))}
          </ul>
        </div>
      )}
      {!!params.annotations?.length && (
        <div>
          <b>Annotations:</b>
          <ul>
            {params.annotations.map((annotation) => (
              <li key={annotation.key} style={{ listStyleType: "disc", listStylePosition: "inside" }}>
                {annotation.value}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
